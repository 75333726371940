import React from 'react'

function Information() {
    return(
        <div className="section">
             <div class="sectionheader- pb-3 bg-primary overflow-hidden text-center border-light pt-5 bg-footer-yotta-blue">
        <div class="container z-2">
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 row justify-content-center">
                        <div class="d-flex">
                            <div class="alert-inner--text">
                                <h1>Yotta21 Bilgi</h1>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
        <section class="section section-lg bg-primary pt-4 bg-footer-yotta-blue">
        <div class="container">
            <div class="row">
                
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="our desk"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="bilgi_detay.html" class="h4">Açıklama-1</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/kullanilabilir.png" alt="Neil avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">Neil Sims Curran</h3>
                            </div>
                            <p class="mb-0">Richard Thomas was born in 1990, and at only 29 years old, his trajectory is good. When he is asked how he describes himself, he responds, "designer but in a broad sense". His projects?</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="web desk"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="./blog-post.html" class="h4">Açıklama-2</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" alt="David avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">David L. Brown</h3>
                            </div>
                            <p class="mb-0">Following the release of the 16-inch MacBook Pro in late 2019, Apple was praised for the larger screen, the increased performance, premium materials and the improved keyboard.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="pixel room"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="./blog-post.html" class="h4">Açıklama-3</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" alt="Jose avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">Jose Evans</h3>
                            </div>
                            <p class="mb-0">As great as Google Maps might be, not all users get to take advantage of the app’s brand new features at the same time, as these updates are rolled out gradually to more and more people.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="designer office"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="./blog-post.html" class="h4">Google launches Cloud AI Platform Pipelines</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" alt="James avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">James Curran</h3>
                            </div>
                            <p class="mb-0">Richard Thomas was born in 1990, and at only 29 years old, his trajectory is good. When he is asked how he describes himself, he responds, "designer but in a broad sense". His projects?</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="white laptop"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="./blog-post.html" class="h4">Apple Details Reveal Remarkable MacBook</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" alt="Bonnie avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">Bonnie Green</h3>
                            </div>
                            <p class="mb-0">Following the release of the 16-inch MacBook Pro in late 2019, Apple was praised for the larger screen, the increased performance, premium materials and the improved keyboard.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 mb-lg-5">
                    <div class="card bg-primary shadow-soft border-light p-4 rounded">
                        <a href="./blog-post.html">
                            <img src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" class="card-img-top rounded" alt="photoshop books"/>
                        </a>
                        <div class="card-body p-0 pt-4">
                            <a href="./blog-post.html" class="h4">One of Google Maps’ best new features</a>
                            <div class="d-flex align-items-center my-4">
                                <img class="avatar avatar-sm rounded-circle" src="https://storageapi.fleek.co/esongur-team-bucket/image/yotta21-1440.png" alt="Joseph avatar"/> 
                                <h3 class="h6 small ml-2 mb-0">Joseph Garth</h3>
                            </div>
                            <p class="mb-0">As great as Google Maps might be, not all users get to take advantage of the app’s brand new features at the same time, as these updates are rolled out gradually to more and more people.</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#">Previous</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">1</a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">4</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">5</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </section>
        </div>
       

    );
    
}

export default Information;