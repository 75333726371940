import Notify from 'bnc-notify'
import Onboard from 'bnc-onboard'

// EThereum Network List

// 1 main Network
// 3 ropsten Test Network
// 4 rinkeby Test Network
// 5 goerli Test Network
// 42 kovan Test Network
// 56  bsc Binance Smart Chain Network
// 100 xdai POA Network

const networkId = 42
const rpcUrl = 'https://kovan.infura.io/v3/68b95c15d28a4daeadfb0afd372682e5';
const dappId = process.env.REACT_APP_DAPP_ID;


export function initOnboard(subscriptions) {
    const onboard = Onboard;
    return onboard({
        dappId,
        hideBranding: true,
        networkId,
        subscriptions,
        darkMode: false,
        walletSelect: {
            wallets: [
                { walletName: 'metamask' },
                {
                    walletName: "walletConnect",
                    infuraKey: "68b95c15d28a4daeadfb0afd372682e5"
                  },
                {
                    walletName: 'trezor',
                    appUrl: 'https://reactdemo.blocknative.com',
                    email: 'aaron@blocknative.com',
                    rpcUrl
                },
                {
                    walletName: 'ledger',
                    rpcUrl
                },

                // { walletName: 'cobovault', appName: 'Yotta21 Dapp', rpcUrl },
                // {
                //     walletName: 'lattice',
                //     appName: 'Yotta21 Dapp',
                //     rpcUrl
                // },
                { walletName: 'coinbase' },
                 { walletName: 'status' },
                 { walletName: 'walletLink', rpcUrl },
                // {
                //     walletName: 'fortmatic',
                //     apiKey: fortmatic_id,
                // },
                { walletName: 'torus' },
                { walletName: 'trust', rpcUrl },
                // { walletName: 'opera' },
                // { walletName: 'operaTouch' },
                // { walletName: 'imToken', rpcUrl },
                // { walletName: 'meetone' },
                // { walletName: 'mykey', rpcUrl },
                { walletName: 'huobiwallet', rpcUrl },
                // { walletName: 'hyperpay' },
                // { walletName: 'atoken' },
                // { walletName: 'liquality' },
                // { walletName: 'frame' },
                // { walletName: 'tokenpocket', rpcUrl },
                // { walletName: 'authereum', disableNotifications: true },
                // { walletName: 'ownbit' },
            ]
        },
        walletCheck: [
            { checkName: 'derivationPath' },
            { checkName: 'connect' },
            { checkName: 'accounts' },
            { checkName: 'network' },
            { checkName: 'balance', minimumBalance: '0' }
        ]
    })
}

export function initNotify() {
    const notify = Notify
    return notify({
        dappId,
        networkId,
    })
}