import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button, Table } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import axios from 'axios';


var range;
var imageurl
var count = 0;
var maxPrice
var minPrice

const display = {
    display: 'none ',

}
const divstye2 = {
    padding: '50px',

}

const divstye3 = {
    backgroundColor: "transparent"

}
const divstye4 = {
    backgroundImage: "https://storageapi.fleek.co/esongur-team-bucket/image/yotta21.png",

}
const divstye5 = {
    display: "grid",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px 5px 10px 5px",


}
const divstye7 = {
    backgroundColor: "#39babc",



}
const divstylecontent = {
    justifyContent: "flex-end",
    alignıtems: "baseline"
}

var divstyledot = {
    height: "10px",
    width: "10px",
    backgroundColor: "#007d35",
    borderRadius: "50%",
    display: "inline-block"
}


const divstyledotclosed = {
    height: "10px",
    width: "10px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block"
}


const divstyledotoutpfrange = {
    height: "10px",
    width: "10px",
    backgroundColor: "yellow",
    borderRadius: "50%",
    display: "inline-block"
}



function Delegator() {
    return (

        <section id="root pt-0" >


            <div className="row mb-5 justify-content-center pt-5 " >
                {/* col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 */}


                <div className=" justify-content-center col-12 col-md-12 col-lg-9 mb-5 mb-lg-0 pt-0">

                    <div className="  p-3" id="divcard" >
                        {/* ds */}
                        <div className="  row justify-content-center  p-2 mb-2 pt-0 " >
                            <div className="row justify-content-center "  >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 ">
                                    <div >

                                        <div className="row justify-content-center">

                                            <div className=" col-12 ml-12 pb-2 btn-group" >
                                                <Button className="btn btn-pill active " id="btn_delegator" onClick={()=>{
                                                    document.getElementById("koordinator").style.display="none";
                                                    document.getElementById("broker").style.display="none";
                                                    document.getElementById("group").style.display="none";
                                                    document.getElementById("delegator").style.display="";

                                                    document.getElementById("btn_delegator").className="btn btn-pill active btn-secondary";
                                                    document.getElementById("btn_koordinator").className="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_broker").className="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_group").className="btn btn-pill btn-secondary ";

                                                }}><i className="fas fa-plus " style={divstye3} ></i> Delagator</Button> 

                                                <Button className="btn btn-pill " id="btn_koordinator" onClick={()=>{
                                                    document.getElementById("koordinator").style.display="";
                                                    document.getElementById("broker").style.display="none";
                                                    document.getElementById("group").style.display="none";
                                                    document.getElementById("delegator").style.display="none";

                                                    document.getElementById("btn_delegator").classList="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_koordinator").classList="btn btn-pill active  btn-secondary";
                                                    document.getElementById("btn_broker").classList="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_group").classList="btn btn-pill btn-secondary ";

                                                }}><i className="fas fa-plus " style={divstye3} ></i>Kordinator </Button>

                                                <Button className="btn btn-pill " id="btn_broker" onClick={()=>{
                                                    document.getElementById("koordinator").style.display="none";
                                                    document.getElementById("broker").style.display="";
                                                    document.getElementById("group").style.display="none";
                                                    document.getElementById("delegator").style.display="none";

                                                    document.getElementById("btn_delegator").classList="btn btn-pill btn-secondary";
                                                    document.getElementById("btn_koordinator").classList="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_broker").classList="btn btn-pill active  btn-secondary";
                                                    document.getElementById("btn_group").classList="btn btn-pill btn-secondary";

                                                }}><i className="fas fa-plus " style={divstye3} ></i>Broker </Button>

                                                <Button className="btn btn-pill " id="btn_group" onClick={()=>{
                                                    document.getElementById("koordinator").style.display="none";
                                                    document.getElementById("broker").style.display="none";
                                                    document.getElementById("group").style.display="";
                                                    document.getElementById("delegator").style.display="none";

                                                    document.getElementById("btn_delegator").classList="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_koordinator").classList="btn btn-pill btn-secondary ";
                                                    document.getElementById("btn_broker").classList="btn btn-pill btn-secondary";
                                                    document.getElementById("btn_group").classList  ="btn btn-pill active btn-secondary ";

                                                }} ><i className="fas fa-plus " style={divstye3} ></i> Group</Button>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* dsasa */}
                        <div className=" p-0 mb-2 pt-0 col-lg-12 div-white rounded" id="positiondiv" >

                            <div className="contanier justify-content-center col-lg-12 rounded  " >
                                <Table hover className="border-0 table"  id="delegator">
                                    <thead>
                                        <tr >
                                            <th ><a>Cüzdan Adresi</a> </th>
                                            <th>Delegasyon</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody >

                                        <tr>
                                            
                                                <td>
                                                    adress1
                                                </td>

                                                <td>
                                                    10
                                                </td>
                                                <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                            </tr>
                                            <tr>
                                            
                                            <td>
                                                adress3
                                            </td>

                                            <td>
                                                100
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                        <tr>
                                            
                                            <td>
                                                adress2
                                            </td>

                                            <td>
                                                200
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table hover className="border-0 table" style={display}  id="koordinator">
                                    <thead>
                                        <tr >
                                            <th ><a>Cüzdan Adresi</a> </th>
                                            <th>Delegasyon</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody >

                                        <tr>
                                            
                                                <td>
                                                    adress12
                                                </td>

                                                <td>
                                                    10
                                                </td>
                                                <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                            </tr>
                                            <tr>
                                            
                                            <td>
                                                adress32
                                            </td>

                                            <td>
                                                100
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                        <tr>
                                            
                                            <td>
                                                adress22
                                            </td>

                                            <td>
                                                200
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table hover className="border-0 table" style={display} id="broker">
                                    <thead>
                                        <tr >
                                            <th ><a>Cüzdan Adresi</a> </th>
                                            <th>Delegasyon</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody >

                                        <tr>
                                            
                                                <td>
                                                    adress13
                                                </td>

                                                <td>
                                                    10
                                                </td>
                                                <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                            </tr>
                                            <tr>
                                            
                                            <td>
                                                adress33
                                            </td>

                                            <td>
                                                100
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                        <tr>
                                            
                                            <td>
                                                adress23
                                            </td>

                                            <td>
                                                200
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table hover className="border-0 table" style={display} id="group">
                                    <thead>
                                        <tr >
                                            <th ><a>Cüzdan Adresi</a> </th>
                                            <th>Delegasyon</th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody >

                                        <tr>
                                            
                                                <td>
                                                    adress14
                                                </td>

                                                <td>
                                                    10
                                                </td>
                                                <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                            </tr>
                                            <tr>
                                            
                                            <td>
                                                adress34
                                            </td>

                                            <td>
                                                100
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                        <tr>
                                            
                                            <td>
                                                adress24
                                            </td>

                                            <td>
                                                200
                                            </td>
                                            <td><a href='https://linktr.ee/yotta21'><i  className='fas fa-plus'></i></a></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                
                               
                            </div>
                        </div>


                    </div>

                </div>



            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi 

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>
        </section>

    );
}

export default Delegator;