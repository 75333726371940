import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { dividendAddress, ottaAddress } from '../protocolAdress'
import { Button, Table } from 'reactstrap';

//e to number convert
function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}
const clear = { clear: "both" }

Modal.setAppElement("#index");

const divstye3 = {
    backgroundColor: "transparent"

}

function RewardsEntry(props) {

    //transaction data 
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', end => {
            if (_transactiontype == "approve") {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = true;
                }

            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;
                }
            }
        })
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approve") {
                document.getElementById("btn-approve").disabled = true;
                document.getElementById("btn-approve").innerText = "işleminiz Onaylandı";
                console.log(end);
            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;
                }
            }
            setTimeout(() => {
                window.location.reload()
            }, 3000);

        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            if (document.getElementById("btn-approve") != null) {
                document.getElementById("btn-approve").disabled = false;
            }
        })

        emitter.on('txFailed', end => {
            console.log(end);
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        if (document.getElementById("btn-approve") != null) {
            document.getElementById("btn-approve").disabled = false;
        }


    }

    //function that dividend get time
    async function getTime() {
        var _data = {
            "owner": props.address,
        }

        let result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/getDividendUiData", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/swap/getBalance", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // this function,
    /*
        -"isLockingEpoch",
        -"getLockedAmount"
                            */
    //returns values.
    async function isLockingEpochs() {
        var _data = {
            "owner": props.address,

        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/isLockingEpochAndGetAmount", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // This function shows the user's locked otta.
    async function LockOtta(_lockAmount) {
        var _data = {
            "owner": props.address,
            "lockAmount": _lockAmount

        }
        console.log("lock data", _data)
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/lockOtta", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // This funck
    function CalculatingFRomValue() {
        var value;

        //  document.getElementById("priceRangeMax1").value = 0;
        value = 0

        const captureValue = function (e) {




            value = e.target.value
            isLockingEpochs().then((ver) => {

                if (ver.isLockingEpoch) {
                    if (document.getElementById("priceRangeMax1").value > Number(document.getElementById("frombalance ").innerText) || document.getElementById("priceRangeMax1").value == 0) {

                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."


                    }
                    else {

                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Onay"
                    }

                }
                else {
                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Giriş dönemi değil"
                }
            })
        }






        document.getElementById("priceRangeMax1").addEventListener("input", captureValue);

    }

    //This function compares the value that the user writes in the textbox with the balance and makes a transaction.
    function walletControl() {

        document.getElementById("modal-from").disabled = true
        if (props.address == null) {

            document.getElementById("modal-from").innerText = "Cüzdan Bağlayınız"


        }
        else {
            isLockingEpochs().then((ver) => {

                if (ver.isLockingEpoch) {
                    if (document.getElementById("priceRangeMax1").value == null) {

                        LockOtta(0).then((ver) => {
                            if (!ver.isAllowed) {
                                if (document.getElementById("btn-approve") == null) {
                                    var Button = document.createElement("button");
                                    Button.className = "btn btn-yotta21 col-12";
                                    var btninnertext = "Yotta21 Protokolünün" + document.getElementById("fromid").innerText + " kullanmasına İzin Verin"
                                    Button.innerText = btninnertext;
                                    Button.setAttribute("id", "btn-approve");
                                    Button.setAttribute("name", "btn-approve");
                                    document.getElementById("approvediv").appendChild(Button);

                                    Button.onclick = function () {
                                        sendHash(ver.to, ver.methodAbi, ver.gasEstimate, "approve")
                                        document.getElementById("btn-approve").disabled = true;

                                    }
                                }


                            }
                            else {
                                if (document.getElementById("btn-approve") != null) {
                                    var deneme = document.getElementById("approvediv")
                                    var deneme2 = document.getElementById("btn-approve");
                                    deneme.removeChild(deneme2);
                                    document.getElementById("modal-from").disabled = false;
                                    CalculatingFRomValue()
                                }

                            }


                        })



                    }
                    else {

                        LockOtta(0).then((ver) => {
                            if (!ver.isAllowed) {
                                if (document.getElementById("btn-approve") == null) {
                                    var Button = document.createElement("button");
                                    Button.className = "btn btn-yotta21 col-12";
                                    var btninnertext = "Yotta21 Protokolünün" + document.getElementById("fromid").innerText + " kullanmasına İzin Verin"
                                    Button.innerText = btninnertext;
                                    Button.setAttribute("id", "btn-approve");
                                    Button.setAttribute("name", "btn-approve");
                                    document.getElementById("approvediv").appendChild(Button);

                                    Button.onclick = function () {
                                        sendHash(ver.to, ver.methodAbi, ver.gasEstimate, "approve")
                                        document.getElementById("btn-approve").disabled = true;

                                    }
                                }

                            }
                            else {
                                if (document.getElementById("btn-approve") != null) {
                                    var deneme = document.getElementById("approvediv")
                                    var deneme2 = document.getElementById("btn-approve");
                                    deneme.removeChild(deneme2);
                                    document.getElementById("modal-from").disabled = false;
                                    CalculatingFRomValue()

                                }
                                else {
                                    document.getElementById("modal-from").disabled = false;
                                    document.getElementById("modal-from").innerText = "Onay"
                                    CalculatingFRomValue()
                                }

                            }
                        })



                    }
                }
                else {
                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Onay"
                    CalculatingFRomValue()

                }

            })
        }
    }


    useEffect(async () => {

        balance(ottaAddress, "OTTA").then((ver) => {
            if (Number(ver) < 1 && Number(ver) > 0) {

                document.getElementById("frombalance ").innerText = toFixed(Number(ver).toFixed(10))
            }
            else {
                document.getElementById("frombalance ").innerText = toFixed(Number(ver).toFixed(10))

            }
            document.getElementById("fromid").value = "OTTA";
        })


        document.getElementById("modal-from").disabled = true;
        document.getElementById("modal-from").innerText = "Lütfen Değer Giriniz"

        var counter
        var dividendTime
        var dividendDay
        var lockDay
        var interval
        var totalEthDividend

        await getTime().then((ver) => {
            counter = ver.dividendDayCounter

            if (ver.dividendTime == 0) {
                dividendTime = 1642512256
            }
            else dividendTime = ver.dividendTime

            dividendDay = ver.dividendDay
            lockDay = ver.lockDay
            interval = ver.interval
            totalEthDividend = ver.totalEthDividend
        })



        var totolETH = Number(totalEthDividend)

        document.getElementById("priceRangeMax2").innerText = Number(totolETH).toPrecision(8)

        if (counter >= 0 && counter < dividendDay) {
            counter = dividendDay
            document.getElementById("massage").innerText = "kilitleme dönemine kalan süre"

        }
        else {
            counter = (Number(dividendDay) + Number(lockDay))
            document.getElementById("massage").innerText = "kilitleme döneminin bitmesine kalan süre"

        }




        var onedaytominute = interval * 1000



        var countDownDate = (counter * onedaytominute) + (dividendTime * 1000)


        var dayText = "Gün";
        var hourText = "Saat";
        var minuteText = "Dakika";

        if (countDownDate) { //tarih var ise
            var x = setInterval(function () { //sayacı belirli aralıklarla yenile
                var now = new Date().getTime(); //şimdiki zamanı al
                var distance1 = (countDownDate) - (now); //geri sayılacak tarih ile şimdiki tarih arasındaki zaman farkını al
                if (distance1 < 0) { //zaman farkı yok ise belirtilen zamanı geçti
                    clearInterval(x); //sayacı sil
                    document.getElementById("countdown_timer").innerHTML = "Geçiş döneminde..."

                } else { //zaman farkı var ise
                    //aradaki zaman farkını gün,saat,dakika,saniye olarak böl
                    var days = Math.floor(distance1 / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance1 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance1 % (1000 * 60 * 60)) / (1000 * 60));

                    var dayss = (days ? '<div id="days"><div>' + days + '</div><div>' + dayText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + dayText + '</div></div>') //gun varsa gun degerini yaz
                    var hourss = (hours ? '<div id="hours"><div>' + hours + '</div><div>' + hourText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + hourText + '</div></div>') //saat varsa saat degerini yaz
                    var minutess = (minutes ? '<div id="minutes"><div>' + minutes + '</div><div>' + minuteText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + minuteText + '</div></div>'); //dakika varsa dakika degerini yaz
                    document.getElementById("countdown_timer").innerHTML = dayss + hourss + minutess; //yazdır

                }

            }, 1000); //1 saniyede bir sayaç güncellenecek
        }
        walletControl()
    })


    return (
        <section id="root ">
            <div className=" col-12 col-lg-12  row justify-content-center  P-0  pt-4 " >
                <div className="row justify-content-center "  >
                    {/* From component start  */}
                    <div className="col-12 col-lg-12 ">
                        <div >

                            <div className="row justify-content-center">

                                <div className=" col-12 ml-12 pb-2 btn-group" >
                                <Button className="btn btn-pill  " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill  btn-secondary ";
                                        var url = "/#/Dividend_Sdr21Shares_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> Sdr21Shares</Button>

                                    <Button className="btn btn-pill active " id="btn_delegator" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "";

                                        // document.getElementById("btn_delegator").className = "btn btn-pill active btn-secondary";
                                        // document.getElementById("btn_koordinator").className = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").className = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_group").className = "btn btn-pill btn-secondary ";
                                        var url = "/#/Dividend_Delegator_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i> Delegator</Button>

                                    <Button className="btn btn-pill " id="btn_koordinator" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill active  btn-secondary";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_group").classList = "btn btn-pill btn-secondary ";
                                        var url = "/#/Dividend_Kordinator_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i>Coordinator </Button>

                                    <Button className="btn btn-pill  " id="btn_broker" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill active  btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill btn-secondary";
                                        var url = "/#/Dividend_Broker_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i>Broker</Button>

                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Group_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i>  Groups</Button>


                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Dao21NFT_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> DAO21NFT</Button>

                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Yotta21_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> Yotta21Hub</Button>

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row mb-5 justify-content-center" >
                <div className="  justify-content-center col-12 col-md-12 col-lg-5 mb-5 mb-lg-0 pt-0">
                    <div className="border-light p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 text-center ">
                                    <br />
                                    <h4><strong>DelegatorÇekme Hakkı</strong> </h4>
                                </div>
                            </div>
                        </div>

                        {/* rEWARD sÜRE DİV */}
                        <div className=" mb-2 pt-0 "  >
                            <div className="container justify-content-center "  >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 p-2 mb-2 pt-0 py-0 " >
                                    <div className="text-center mb-0 font-medium   ">
                                        <span className="ml-1" id="massage"></span>
                                    </div>
                                    <div id="countdown_container">
                                        <div id="countdown_timer">
                                        </div>
                                        <div style={clear}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="   mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12  btn-group ">
                                    <button className="col-12 col-lg-6 btn active div-white " id="buy" onClick={() => {
                                        var url = "/#/Dividend_Delegator_Entry/"
                                        window.location.href = url
                                    }}>Giriş</button>
                                    <button className="col-11 col-lg-6 btn  div-white " id="sell" onClick={() => {
                                        var url = "/#/DividendDelegatorExt/"
                                        window.location.href = url
                                    }}>Çıkış</button>
                                </div>
                            </div>
                        </div>
                        {/* dsasa */}
                        <div className=" shadow-soft border border-light rounded p-2 mb-2 pt-0 div-white " >
                            <div className="text-center mb-0 h5 border-bottom-black" > <span> Katılım Miktarı</span></div>
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light py-2 ">
                                        <div className="row">
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="App" >
                                                    <button type="text" value={ottaAddress} className="border col-md-10 py-2 border-light rounded col-md-12" style={divstye3}  >
                                                        <img id="imageidfrom" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span id="fromid" className="font-small" > Delegator </span>
                                                    </button>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="row justify-content-left  m-0">&nbsp;&nbsp;&nbsp;
                                                        <span className="font-small"> Bakiye: <span className="font-small" id="frombalance "> 0 </span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row justify-content-end">
                                                        <div className="text-right col-12 col-lg-12 text-right py-2 " id="text-right" style={divstye3}>
                                                            <input className="form-control div-white " id="priceRangeMax1" min="0" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" type="number" style={divstye3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 py-0 div-white" >
                            <div className="text-center mb-0 h5 border-bottom-black" > <span> Dağıtılacak miktar</span></div>
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light py-1 ">
                                        {/* Swap ÖZeti */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0 " >
                                                <div className="App" >
                                                    <button type="text" value="ETH" className="border border-light rounded py-2 col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span id="tokenid" className="font-small " value="Jeton Seçiniz" >ETH </span>&nbsp;&nbsp;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">
                                                        <div className="text-right col-12  pb-5 py-3 " id="text-right" style={divstye3}>
                                                            <output id="priceRangeMax2" type="number" style={divstye3} >0</output>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* to component end */}
                        <div id="approvediv"></div>
                        <button type="button" id="modal-from" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0" data-toggle="modal" data-target="#modal-form"
                            onClick={() => {
                                LockOtta(document.getElementById("priceRangeMax1").value).then((ver) => {
                                    if (document.getElementById("priceRangeMax1").value != "" && document.getElementById("priceRangeMax1").value != 0) {
                                        sendHash(ver.to, ver.methodAbi, ver.gasEstimate)
                                    }
                                    var x = document.getElementById("snackbartorewardentry");
                                    x.className = "show";
                                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                                    document.getElementById("priceRangeMax1").value = null
                                })
                            }} >
                            Onay
                        </button>
                    </div>
                    <div id="snackbartorewardentry">İşlem cüzdanınıza gönderildi.</div>
                </div>
            </div>
        </section>
    );

}

export default RewardsEntry