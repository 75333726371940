import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import {ottaAddress, taumAddress, ttfAddress, wethAddress} from '../protocolAdress'



function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

var range = "-"
var backurl;
var slippage = 0.5
var deadlineMinute = 30;

const display = {
    display: 'none'
}
const divstye3 = {
    backgroundColor: "transparent"
}
const divstye4 = {
    alignitems: "center",
    margin: "10px"
}
const imagestyle = {
    height: "0.5rem",
    alignItems: "center"
}
const divstye7 = {
    padding: "0.15rem 0.15rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    width: "25px"
}
const divstyledot = {
    height: "10px",
    width: "10px",
    backgroundColor: "#007d35",
    borderRadius: "50%",
    display: "inline-block",
}

Modal.setAppElement("#index");

function Incraese(props) {

    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }
    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    const { nftid } = useParams();
    backurl = "/#/pool/" + nftid;

    var amountRatio;
    increaseLiqudity(nftid, "1", deadlineMinute, true, slippage).then((data1) => {
        amountRatio = data1.amountRatio
    })

    async function nftDetail(_nftid) {
        var _datanft = {
            "nftId": _nftid,
            "recipient": props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_NFT, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datanft)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    async function increaseLiqudity(_nftid, _amountDesired, _deadlineMinute, _isToken0Value, _slippage) {
        var _dataIncreaseLiqudity = {
            "nftId": _nftid,
            "recipient": props.address,
            "amountDesired": _amountDesired,
            "deadlineMinute": _deadlineMinute,
            "isToken0Value": _isToken0Value,
            "slippage": _slippage

        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_LQINC, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_dataIncreaseLiqudity)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: "0x0",
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', (ver)=>{
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
             }, 3000);
        })

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', (ver)=>{
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
             }, 3000);
        })
    }

    function CalculatingFromValue() {
        var value;
        value = 0;

        const captureValue = function (e) {
            value = e.target.value * Number(amountRatio)
            document.getElementById("priceRangeMax2").value = Number(toFixed(value))
            value = 0;
            if (token0Balance < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                document.getElementById("togglebtn").disabled = true;
                document.getElementById("togglebtn").innerText = "Bakiye Yetersiz..."
            }
            else {
                if (document.getElementById("dangertoken1") != null) {
                    document.getElementById("togglebtn").disabled = false;
                    document.getElementById("togglebtn").innerText = "Devam"
                    document.getElementById("priceRangeMax2").value = 0
                }
                else {
                    if (Number(token1Balance) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        document.getElementById("togglebtn").disabled = true;
                        document.getElementById("togglebtn").innerText = "Bakiye Yetersiz..."
                    }
                    else {
                        document.getElementById("togglebtn").disabled = false;
                        document.getElementById("togglebtn").innerText = "Devam"
                    }
                }
            }
        }
        document.getElementById("priceRangeMax1").addEventListener("input", captureValue);
    }

    function CalculatingToValue() {
        var value;
        value = 0;

        const captureValue = function (e) {
            value = e.target.value * (1 / Number(amountRatio))
            document.getElementById("priceRangeMax1").value = Number(toFixed(value))
            value = 0;

            if (token1Balance < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                document.getElementById("togglebtn").disabled = true;
                document.getElementById("togglebtn").innerText = "Bakiye Yetersiz..."
            }
            else {
                if (document.getElementById("dangertoken0") != null) {
                    document.getElementById("togglebtn").disabled = false;
                    document.getElementById("togglebtn").innerText = "Devam"
                    document.getElementById("priceRangeMax1").value = 0
                }
                else {
                    if (token0Balance < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        document.getElementById("togglebtn").disabled = true;
                        document.getElementById("togglebtn").innerText = "Bakiye Yetersiz..."
                    }
                    else {
                        document.getElementById("togglebtn").disabled = false;
                        document.getElementById("togglebtn").innerText = "Devam"
                    }
                }
            }
        }

        document.getElementById("priceRangeMax2").addEventListener("input", captureValue);
    }

    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    var token0Balance
    var token1Balance

    function walletControl() {
        if (props.address == null) {
            document.getElementById("togglebtn").innerText = "Cüzdan Bağlayınız"
            document.getElementById("togglebtn").disabled = true
        }
        else {
            document.getElementById("togglebtn").disabled = true
            document.getElementById("togglebtn").innerText = "Devam"
        }
    }

    useEffect(() => {
        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        document.getElementById("togglebtn").disabled = true;
        walletControl()

        nftDetail(nftid).then((data) => {
            CalculatingFromValue();
            CalculatingToValue();
            document.getElementById("range").innerText = range
            document.getElementById("token0").innerText = data.token0
            document.getElementById("token1").innerText = data.token1
            document.getElementById("token0name").innerText = data.token0
            document.getElementById("token1name").innerText = data.token1
            document.getElementById("tabs-text-1-tab").innerHTML = "&nbsp;&nbsp;" + data.token0 + "&nbsp;&nbsp;"
            document.getElementById("tabs-text-2-tab").innerText = data.token1
            document.getElementById("fromid").innerText = data.token0
            document.getElementById("tokenid").innerText = data.token1
            document.getElementById("currentpricetoken-2-1-1").innerText = data.token1
            document.getElementById("currentpricetoken-2-2-1").innerText = data.token0
            // img ayarlanması
            //token0 img
            if (data.token0 == "TTFF") {
                document.getElementById("img0").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                document.getElementById("token0fromimageid").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            }
            else if (data.token0 == "TpTTFF") {
                document.getElementById("img0").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                document.getElementById("token0fromimageid").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            }
            else if (data.token0 == "OTTA") {
                document.getElementById("img0").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                document.getElementById("token0fromimageid").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            }
            else if (data.token0 == "WETH") {
                document.getElementById("img0").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                document.getElementById("token0fromimageid").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                document.getElementById("token0image").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            }
            else {
                document.getElementById("img0").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                document.getElementById("token0fromimageid").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            }

            // token1 img
            if (data.token1 == "WETH") {
                document.getElementById("img1").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                document.getElementById("imageId").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                document.getElementById("token1image").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            }
            else if (data.token1 == "OTTA") {
                document.getElementById("img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            }
            else if (data.token1 == "TpTTFF") {
                document.getElementById("img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            }
            else if (data.token1 == "TTFF") {
                document.getElementById("img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            }
            else {
                document.getElementById("img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            }

            //Token0 Balance İçin kontrol 
            if (data.token0 == "WETH") {
                balance(wethAddress, "WETH").then((data) => {
                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                    token0Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token0 == "TTFF") {
                balance(ttfAddress, "TTFF").then((data) => {
                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                    token0Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token0 == "OTTA") {
                balance(ottaAddress, "OTTA").then((data) => {
                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                    token0Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token0 == "LpTTFF") {
                balance(taumAddress, "LpTTFF").then((data) => {
                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                    token0Balance = Number(data).toPrecision(5)
                })
            }
            //Token1 Balance İçin kontrol 
            if (data.token1 == "WETH") {
                balance(wethAddress, "WETH").then((data) => {
                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                    token1Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token1 == "TTFF") {
                balance(ttfAddress, "TTFF").then((data) => {
                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                    token1Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token1 == "OTTA") {
                balance(ottaAddress, "OTTA").then((data) => {
                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                    token1Balance = Number(data).toPrecision(5)
                })
            }
            else if (data.token1 == "LpTTFF") {
                balance(taumAddress, "LpTTFF").then((data) => {
                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                    token1Balance = Number(data).toPrecision(5)
                })
            }
            document.getElementById("fee").innerText = data.fee
            if (data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || data.maxPrice == 338492131855223780000000000000000000000) {
                document.getElementById("token0value").innerText = ((Number(data.token0NftAmount) / Math.pow(10, 18))).toFixed(8)
                document.getElementById("token1value").innerText = ((Number(data.token1NftAmount) / Math.pow(10, 18))).toFixed(8)
                document.getElementById("minprice").innerText = "0"
                document.getElementById("maxprice").innerText = "∞"
                document.getElementById("minprice2").innerText = "0"
                document.getElementById("maxprice2").innerText = "∞"
            }
            else {
                document.getElementById("token0value").innerText = Number(data.token0NftAmount).toFixed(8)
                document.getElementById("token1value").innerText = Number(data.token1NftAmount).toFixed(8)
                document.getElementById("minprice").innerText = (1 / Number(data.maxPrice)).toFixed(8)
                document.getElementById("maxprice").innerText = (1 / Number(data.minPrice)).toFixed(8)
                document.getElementById("minprice2").innerText = (Number(data.minPrice)).toFixed(8)
                document.getElementById("maxprice2").innerText = (Number(data.maxPrice)).toFixed(8)
            }
            document.getElementById("currentprice-1").innerText = (1 / Number(data.currentPrice)).toFixed(8)
            document.getElementById("currentprice-2").innerText = (Number(data.currentPrice)).toFixed(8)
            document.getElementById("token-1-1-1").innerText = data.token0
            document.getElementById("token-1-1-2").innerText = data.token1
            document.getElementById("token-2-1-1").innerText = data.token0
            document.getElementById("token-2-1-2").innerText = data.token1
            document.getElementById("currentpricetoken-1-1-1").innerText = data.token0
            document.getElementById("currentpricetoken-1-1-2").innerText = data.token1
            document.getElementById("token2-1-1-1").innerText = data.token1
            document.getElementById("token2-1-1-2").innerText = data.token0
            document.getElementById("token2-2-1-1").innerText = data.token1
            document.getElementById("token2-2-1-2").innerText = data.token0
            document.getElementById("currentpricetoken-1-1-1").innerText = data.token1
            document.getElementById("currentpricetoken-1-1-2").innerText = data.token0


            if (Number(data.currentPrice) >= Number(data.minPrice) && Number(data.currentPrice) <= Number(data.maxPrice)) {
                range = "Aralıkta";
                document.getElementById("range").innerText = range;
                document.getElementById("imageurl").src = "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png"
            }
            else {
                range = "Aralık Dışı"
                document.getElementById("range").innerText = range;
                document.getElementById("imageurl").src = "https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png"
            }

            if (Number(data.token1NftAmount) == 0) {
                document.getElementById("token1id").disabled = true;
                document.getElementById("token1balance2").disabled = true;
                document.getElementById("priceRangeMax2").disabled = true;

                if (document.getElementById("dangertoken1") == null) {
                    const input = document.createElement("output")
                    input.setAttribute("id", "dangertoken1")
                    input.innerText = "Piyasa fiyatı, belirttiğiniz fiyat aralığının dışında. Yalnızca tek varlıklı para yatırma yapılabilir."
                    document.getElementById("danger2").className = "fas fa-exclamation-circle";
                    document.getElementById("div2").appendChild(input);
                }
            }
            else {
                if (document.getElementById("dangertoken1") != null) {
                    document.getElementById("danger2").className = "fas";
                    document.getElementById("dangertoken1").innerText = " "
                }
                if (document.getElementById("token1id").disabled == true) {
                    document.getElementById("token1id").disabled = false;
                    document.getElementById("token1balance").disabled = false;
                    document.getElementById("priceRangeMax2").disabled = false;
                }
            }

            if (Number(data.token0NftAmount) == 0) {
                document.getElementById("token0fromid").disabled = true;
                document.getElementById("token0balance").disabled = true;
                document.getElementById("priceRangeMax1").disabled = true;

                if (document.getElementById("dangertoken0") == null) {
                    const input = document.createElement("output")
                    input.setAttribute("id", "dangertoken0")
                    input.innerText = "Piyasa fiyatı, belirttiğiniz fiyat aralığının dışında. Yalnızca tek varlıklı para yatırma yapılabilir."
                    document.getElementById("danger1").className = "fas fa-exclamation-circle";
                    document.getElementById("div1").appendChild(input)
                }
                else {
                    document.getElementById("danger1").className = "fas";
                    document.getElementById("1danger").innerText = "."
                }
            }
            else {
                if (document.getElementById("token0fromid").disabled = true) {
                    document.getElementById("token0fromid").disabled = false;
                    document.getElementById("token0balance").disabled = false;
                    document.getElementById("priceRangeMax1").disabled = false;
                }
            }

            if (Number(data.token0NftAmount) == 0 && Number(data.token1NftAmount) == 0) {
                document.getElementById("token1id").disabled = false;
                document.getElementById("token1balance2").disabled = false;
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("token0fromid").disabled = false;
                document.getElementById("token0balance").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                document.getElementById("danger1").className = "";
                document.getElementById("dangertoken0").innerText = ""
                document.getElementById("danger2").className = "";
                document.getElementById("dangertoken1").innerText = ""
            }
            CalculatingFromValue()
            CalculatingToValue()

        })
    })


    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-light rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Değer aralık dışında"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }

    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-light rounded div-white form-control"
                document.getElementById("toleranscheck").className = "border border-light rounded div-white"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("toleranscheck").style.borderColor = "red"
                document.getElementById("danger").innerText = " "

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Değer aralık dışında"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }


    return (
        <section id="root pt-0">
            <div className="  rounded p-0 mb-2 pt-6 " >
                <div className=" row mb-5 justify-content-center pt-0  " >
                    <div className="  justify-content-center col-12 col-md-12 col-lg-auto mb-4 mb-lg-0 pt-0 div-white rounded" >
                        <div className="  border-black p-3" id="divcardliqudity" >
                            {/* ds */}
                            <div className="   p-2 mb-2 pt-0 " >
                                <div className="row" >
                                    {/* From component start  */}
                                    <div className="col-12 col-lg-6  ">
                                        <div >
                                            <br />
                                            <div className="row ">
                                                <div className=" col-12 ml-12 " >
                                                    <Button className="btn btn-pill  btn-yotta21 div-yotta21  " style={divstye7} href={backurl}><i className="fas fa-long-arrow-alt-left"></i></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            <Button className=" btn-pill btn-yotta21  div-yotta21"  ><i className="fas fa-cog  " style={divstye3} onClick={() => {
                                                                toggleModal3()
                                                                setTimeout(() => {
                                                                    document.getElementById("slippage").value = slippage
                                                                    document.getElementById("deadline").value = deadlineMinute
                                                                }, 1000);
                                                            }} ></i></Button>
                                                        </div>
                                                        {/* modal setting start */}
                                                        <Modal
                                                            isOpen={isOpen3}
                                                            onRequestClose={toggleModal3}
                                                            contentLabel="My dialog"
                                                            className="mymodal3" /*"modal-dialog modal-dialog-centered rounded"*/
                                                            overlayClassName="myoverlay"
                                                            closeTimeoutMS={500}
                                                            aria-hidden="true"
                                                        >
                                                            <div className="rounded " id="sums">
                                                                <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                                                    document.getElementById("deadline").value = 0
                                                                    document.getElementById("slippage").value = 0
                                                                }}>
                                                                    <span aria-hidden="true" id="close" onClick={() => {
                                                                        if (document.getElementById("dangers") != null) {
                                                                            document.getElementById("danger").removeChild(document.getElementById("dangers"))
                                                                            document.getElementById("slippage").style.borderColor = "white"
                                                                        }
                                                                        if (document.getElementById("dangers2") != null) {
                                                                            document.getElementById("danger2").removeChild(document.getElementById("dangers2"))
                                                                            document.getElementById("deadline").style.borderColor = "white"
                                                                        }
                                                                        toggleModal3()
                                                                    }}>×</span>
                                                                </button>
                                                                <div className="card-header  text-center pb-0 pt-0">
                                                                    <h2 className="h4 font-medium text-center pt-0">Ayarlar</h2>
                                                                </div>
                                                                <div className="card-body text-center pb-0 pt-0 font-smedium  div-white " >
                                                                    <div className="row ">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium">Kayma Toleransı</a>
                                                                                <button className="border-0 font-medium div-white" id="Popover1" >
                                                                                    <i className="far fa-question-circle"></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen1} target="Popover1" toggle={toggle1}>
                                                                                    <PopoverBody>Fiyatın istenmeyen şekilde bu yüzdeden daha fazla değişmesi durumunda işleminiz geri döner.</PopoverBody>
                                                                                </Popover>
                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0  font-medium py-2">
                                                                                <output className="text-right div-white col-lg-8" >
                                                                                    <InputGroup >
                                                                                        <InputGroupAddon addonType="prepend" >
                                                                                            <InputGroupText className="div-white">
                                                                                                <Input addon type="checkbox" aria-label="Checkbox for following text input" id="toleranscheck" className="div-white" onChange={() => {
                                                                                                    if (document.getElementById("toleranscheck").checked == true) {
                                                                                                     document.getElementById("slippage").value = 0.05;
                                                                                                    }
                                                                                                    else {
                                                                                                        document.getElementById("slippage").value = null
                                                                                                    }
                                                                                                }} />&nbsp;  Otomatik 
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input className="div-white " placeholder="%0.5" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="50" id="slippage" onChange={() => {
                                                                                            settingControlslippage()
                                                                                        }}></Input>
                                                                                    </InputGroup>
                                                                                </output>
                                                                            </div>
                                                                        </div>
                                                                        <div id="danger"></div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left " >
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium">İşlem Son Tarihi</a>
                                                                                <button className="border-0 div-white" id="Popover2"  >
                                                                                    <i className="far fa-question-circle font-medium" ></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen2} target="Popover2" toggle={toggle2} className="div-white" >
                                                                                    <PopoverBody>İşleminiz bu süreden daha uzun bir süredir beklemedeyse geri alınacaktır.</PopoverBody>
                                                                                </Popover>
                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0 font-medium py-2">
                                                                                <output className="text-left col-lg-6 font-medium div-white" >
                                                                                    <Input placeholder="30" id="deadline" className="div-white" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="180" onChange={() => {
                                                                                        settingControldeadline()
                                                                                    }} />
                                                                                </output>
                                                                                <output className="col-lg-3 text-left" >
                                                                                    <a className="font-left">Dakika</a>
                                                                                </output>
                                                                            </div>
                                                                        </div>
                                                                        <div id="danger2"></div>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <button className="btn btn-yotta21 col-lg-12" onClick={() => {
                                                                    if (document.getElementById("toleranscheck").checked == true) {
                                                                        slippage = 0.05
                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value
                                                                        }
                                                                    }
                                                                    else {
                                                                        if (document.getElementById("slippage").value == "" || document.getElementById("slippage").value == null) {
                                                                            slippage = 0.05
                                                                        }
                                                                        else {
                                                                            slippage = document.getElementById("slippage").value
                                                                        }

                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value
                                                                        }
                                                                    }

                                                                    toggleModal3()
                                                                }} id="save">Kaydet</button>
                                                            </div>
                                                        </Modal>
                                                        {/* modal setting end */}
                                                        {/* modal setting end */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-12 col-lg-12 ">
                                        <div className="row  text-center justify-content-center ">
                                            <h3 className="font-xl"><strong>Likidite Ekle </strong> </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 card-tags card-headers-tabs pt-0 " >
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-11   ">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            {/* jeton1 gelecek */}
                                                            <div className=" col-12 ml-12 pt-2 " >
                                                                <img className="rounded token1increase  " id="img0" src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/TaumTokenlogo1.png" height="25px" width="25px" alt="ice museum" />
                                                                <img className="rounded token2increase " id="img1" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="25px" width="25px" alt="ice museum" />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <span className="font-weight-bolder  font-base py-2 " >
                                                                    <span className="font-weight-bolder  font-base " id="token0">-</span>
                                                                    <span className="font-weight-bolder  font-base "> / </span>
                                                                    <span className="font-weight-bolder  font-base " id="token1">-</span>
                                                                </span> &nbsp;&nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 card-tags py-2">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-11">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            <div className='py-'>
                                                                <img id="imageurl" style={imagestyle} src="https://upload.wikimedia.org/wikipedia/commons/7/70/Solid_white.svg"></img>
                                                                &nbsp;<span className="font-base " id="range">-</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="  mb-0">
                                {/* djadjskajkdk */}
                                <div className="row pt-0 p-0 pl-0  justify-content-center border-0 rounded">
                                    <div className="card card-body-tabs col-11 p-0 mt-0  div-white border border-black " >
                                        <div className="row justify-content-start pt-2 p-0" >
                                            {/*  component start  */}
                                            <div className=" col-lg-8 ">
                                                <div className="container ">
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <img className="image-xs " id="token0image" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img>&nbsp;
                                                    <span className="font-weight-bolder  font-small" id="token0name"></span>
                                                </div>
                                                <br />
                                                <div className="container text-left">
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <img className="image-xs " id="token1image" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img>&nbsp;
                                                    <span className="font-weight-bolder  font-small   " id="token1name"></span>
                                                </div>
                                                <br></br>
                                                <div className="container text-left">
                                                    &nbsp;&nbsp;
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <span className="font-weight-bolder  font-small   " >Ücret KAtmanı</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 p-1">
                                                <div className="container text-center" >
                                                    <span className="font-small" id="token0value">0,0</span>
                                                    <br></br>
                                                    <br></br>
                                                </div>
                                                <div className="container text-center" >
                                                    <span className="font-small" id="token1value">0,0</span>
                                                    <br></br>
                                                    <br></br>
                                                </div>
                                                <div className="container text-center p-3" >
                                                    <span className="font-small" id="fee">0%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* djadjskajkdk */}
                                </div>
                            </div>
                            <div className="row pt-1" >
                                <div className="col-md-12 col-lg-12">
                                    {/* <!-- Tab Nav --> */}
                                    <div className="nav-wrapper  mb-0">
                                        <ul className="nav nav-pills  justify-content-start  " id="tabs-text" role="tablist">
                                            <li className="  col-lg-7 " >
                                                <h5 className="text-left" style={divstye4}>Seçilen Aralık
                                                </h5>
                                            </li>
                                            <li className=" mr-sm-0 mr-md-0 text-right">
                                                {/*id="fromtoken"*/}
                                                <a className="nav-link  mb-md-0 active font-xsmall text-right" id="tabs-text-1-tab" data-toggle="tab" href="#tabs-text-1" role="tab" aria-controls="tabs-text-1" aria-selected="true" disabled >-</a>
                                            </li>
                                            &nbsp;&nbsp;
                                            <li className=" mr-sm-0 mr-md-0 text-right ">
                                                {/*id="totoken"*/}
                                                <a className="nav-link  mb-md-0 font-xsmall" id="tabs-text-2-tab" data-toggle="tab" href="#tabs-text-2" role="tab" aria-controls="tabs-text-2" aria-selected="false" >&nbsp;&nbsp;-&nbsp;&nbsp;</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- End of Tab Nav --> */}
                                    {/* <!-- Tab Content --> */}
                                    <div className="card-tabs shadow-inset  border-light p-3 rounded mb-3  div-white" >
                                        <div className="card-body-tabs p-0"  >
                                            <div className="tab-content" id="tabcontent1">
                                                <div className="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
                                                    {/* ds */}
                                                    <div className="   p-2 mb-2 pt-0 " >
                                                        <div className="row" >
                                                            <div className="col-12 col-lg-6 card-tags card-headers-tabs ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-end"  >
                                                                                    {/* jeton1 gelecek */}
                                                                                    <button type="input" value="WETH" className=" border border-black rounded col-md-12 div-white" id="btnselect" style={divstye3}>
                                                                                        <a className="font-small"  >Min. Fiyat </a>
                                                                                        <br />
                                                                                        <a className="col-md-10 font-base text-center" id="minprice" style={divstye3} disabled >0,0 </a>
                                                                                        <br />
                                                                                        <a className="font-xsmall " id="token-1-1-1">- </a>
                                                                                        <a className="font-xsmall "> / </a>
                                                                                        <a className="font-xsmall " id="token-1-1-2"> -</a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 card-tags card-headers-tabs ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-end"  >
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button type="input" value="WETH" className="border border-black rounded col-md-12 div-white" style={divstye3}>
                                                                                        <a className="font-small"  >Max. Fiyat </a>
                                                                                        <br />
                                                                                        <a className="col-md-10 font-base text-center" id="maxprice" style={divstye3} disabled >0,0 </a>
                                                                                        <br />
                                                                                        <a className="font-xsmall " id="token-2-1-1">- </a>
                                                                                        <a className="font-xsmall "> / </a>
                                                                                        <a className="font-xsmall " id="token-2-1-2"> -</a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container col-12 col-lg-12 div-white">
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 pt-3">
                                                                        <div>
                                                                            <div className="card-tabs border-light rounded div-white "  >
                                                                                <div className="row justify-content-end ">
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button type="input" value="WETH" className=" border border-black rounded col-md-12 mb-1 div-white" id="btnselect" style={divstye3}>
                                                                                        <a className="font-small"  > Mevcut Fiyat </a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentprice-1">0.0</a>
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentpricetoken-1-1-1">-</a>
                                                                                        <a className="font-xsmall"> / </a>
                                                                                        <a className="font-xsmall" id="currentpricetoken-1-1-2">- </a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* token pair 2 start  */}
                                                <div className="tab-pane fade" id="tabs-text-2" role="tabpanel" aria-labelledby="tabs-text-2-tab">
                                                    <div className="   p-2 mb-2 pt-0 " >
                                                        <div className="row" >
                                                            <div className="col-12 col-lg-6 card-tags card-headers-tabs ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-center"  >
                                                                                    {/* jeton1 gelecek */}
                                                                                    <button type="input" value="WETH" className="border border-black rounded col-md-12 div-white" id="btnselect" style={divstye3}>
                                                                                        <a className="font-small text-center"  >Min. Fiyat </a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="col-md-10 font-base text-center" id="minprice2" style={divstye3} disabled >0,0 </a>
                                                                                        <br />
                                                                                        <a className="font-xsmall " id="token2-1-1-1">- </a>
                                                                                        <a className="font-xsmall "> / </a>
                                                                                        <a className="font-xsmall " id="token2-1-1-2"> -</a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 card-tags card-headers ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-end"  >
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button type="input" value="WETH" className="border border-black rounded col-md-12 div-white" id="btnselect" style={divstye3}>
                                                                                        <a id="maxprice" className="font-small"  >Max. Fiyat </a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="col-md-10 font-small " id="maxprice2" style={divstye3} disabled >0,0 </a>
                                                                                        <br />
                                                                                        <a className="font-xsmall " id="token2-2-1-1">- </a>
                                                                                        <a className="font-xsmall "> / </a>
                                                                                        <a className="font-xsmall " id="token2-2-1-2"> -</a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container col-12 col-lg-12">
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 pt-3 div-white">
                                                                        <div>
                                                                            <div className="card-tabs  border-light rounded div-white"   >
                                                                                <div className="row justify-content-end">
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button type="input" value="WETH" className="border border-black rounded col-md-12 mb-1 div-white" id="btnselect" style={divstye3}>
                                                                                        <a className="font-small"  >  Mevcut Fiyat</a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentprice-2">0 .0</a>
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentpricetoken-2-1-1">- </a>
                                                                                        <a className="font-xsmall"> /  </a>
                                                                                        <a className="font-xsmall" id="currentpricetoken-2-2-1">  - </a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End of Tab Content --> */}
                                </div>
                            </div>
                            &nbsp;
                            <a className="font-medium text-center  pt-1">Daha Fazla Likidite Ekleyin</a> 
                              <br></br>
                            {/* dsasa */}
                            <div className="card-body-tabs shadow-soft border border-light rounded p-0 mb-2 pt-0 div-white" >
                                <div className="row" >
                                    {/* From component start  */}
                                    <div className="col-12 col-lg-6 ">
                                        <div className="border-light py-4 ">
                                            <div className="row">
                                                <div className=" col-12 ml-12 pb-2" >
                                                    <div className="App" >
                                                        &nbsp;  <button id="token0fromid" type="button" value="0xad6d458402f60fd3bd25163575031acdce07538d" className="btn btn-primary col-md-11" style={divstye3}  >
                                                            <img id="token0fromimageid" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <a id="fromid" >- </a>
                                                        </button>
                                                        <br></br>
                                                        <br></br>
                                                        <div className="row justify-content-left pt- m-0">&nbsp;&nbsp;&nbsp;
                                                            <span className="text-small text-bold" id="token0balance">  Bakiye: <span id="frombalance" className="text-small"> 0</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 py-1">
                                        <div className="row " >
                                            <div className="col-12 col-md-6 col-lg-11">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <div className="row">
                                                            <div className="text-right col-12  pb-4 " id="text-right" style={divstye3}>
                                                                <br></br>
                                                                <input className="form-control div-white" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" row justify-content-start text-center col-12 col-lg-12" >
                                    <a id="div1">
                                        <i id="danger1" ></i>
                                    </a>
                                </div>
                            </div>
                            <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                                <div className="row" >
                                    <div className="col-12 col-lg-6">
                                        <div className="border-light py-4 ">
                                            <div className="row ">
                                                <div className=" col-12 ml-12 pb-2 " >
                                                    <div className="App" >
                                                        <button type="button" value="WETH" className="btn btn-primary col-md-12" id="token1id" style={divstye3}>
                                                            <img id="imageId" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <a id="tokenid" className="font-small" value="Jeton Seçiniz" >- </a>&nbsp;&nbsp;
                                                        </button>
                                                        <br></br>
                                                        <br></br>
                                                        <div className="row pt- m-0" >&nbsp;&nbsp;&nbsp;
                                                            <span className="font-small" id="token1balance2">Bakiye: <span className="font-small" id="balanceid">0 </span>  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 py-1">
                                        <div className="row " >
                                            <div className="col-12 col-md-6 col-lg-11">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <div className="row">
                                                            <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>
                                                                <br></br>
                                                                <input className="form-control" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" row justify-content-start text-center col-12 col-lg-12" >
                                    <a id="div2">
                                        <i id="danger2" ></i>
                                    </a>
                                </div>
                            </div>
                            {/* to component end */}
                            <div id="approvediv"></div>
                            <button type="button" id="togglebtn" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                                onClick={() => {
                                    increaseLiqudity(nftid, document.getElementById("priceRangeMax2").value, deadlineMinute, false, slippage).then((data1) => {
                                    document.getElementById("token0Name").innerText = document.getElementById("token0").innerText
                                    document.getElementById("token1Name").innerText = document.getElementById("token1").innerText
                                    document.getElementById("token0sum").innerText = document.getElementById("token0").innerText
                                    document.getElementById("token1sum").innerText = document.getElementById("token1").innerText
                                    document.getElementById("token0img").src = document.getElementById("img0").src
                                    document.getElementById("token1img").src = document.getElementById("img1").src
                                    document.getElementById("token0image1").src = document.getElementById("img0").src
                                    document.getElementById("token1image1").src = document.getElementById("img1").src
                                    document.getElementById("value0sum").innerText = Number(document.getElementById("priceRangeMax1").value).toFixed(8)
                                    document.getElementById("value1sum").innerText = Number(document.getElementById("priceRangeMax2").value).toFixed(8)
                                    document.getElementById("feesum").innerText = document.getElementById("fee").innerText
                                    document.getElementById("feetier").innerText = document.getElementById("fee").innerText
                                    document.getElementById("minprice1").innerText = document.getElementById("minprice").innerText
                                    document.getElementById("maxprice1").innerText = document.getElementById("maxprice").innerText
                                    document.getElementById("currentprice-11").innerText = document.getElementById("currentprice-1").innerText
                                    document.getElementById("token0minprice").innerText = document.getElementById("token0").innerText
                                    document.getElementById("token1minprice").innerText = document.getElementById("token1").innerText
                                    document.getElementById("token0maxprice").innerText = document.getElementById("token0").innerText
                                    document.getElementById("token1maxprice").innerText = document.getElementById("token1").innerText
                                    document.getElementById("currentpricetoken0").innerText = document.getElementById("token0").innerText
                                    document.getElementById("currentpricetoken1").innerText = document.getElementById("token1").innerText
                                    document.getElementById("tabs-text-1-tab1").innerText = document.getElementById("token0").innerText
                                    document.getElementById("tabs-text-2-tab2").innerText = document.getElementById("token1").innerText
                                    document.getElementById("rangesum").innerText = document.getElementById("range").innerText
                                    })
                                    toggleModal()
                                }} >
                                Devam
                            </button>
                            {/* Modal Start */}

                            {/* <!-- Modal Content --> */}
                            <Modal
                                isOpen={isOpen}
                                onRequestClose={toggleModal}
                                contentLabel="My dialog"
                                className="mymodal-increase" /*"modal-dialog modal-dialog-centered rounded"*/
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                                aria-hidden="true"
                            >
                                <div className="" >
                                    <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" id="close" onClick={()=>{
                                            toggleModal()
                                        }}>×</span>
                                    </button>
                                    <div className="card-header  text-center pb-0 pt-0">
                                        <h2 className="h4 font-medium text-center pt-0"><strong> İşlem Özeti</strong></h2>
                                    </div>
                                    <div className="row justify-content-end " >
                                        {/*  component start  */}
                                        <div className="col-12 col-lg-11 ">
                                            <div >
                                                <div className="row justify-content-end">
                                                    <div className=" col-12 ml-12 pt-2" >
                                                        &nbsp;&nbsp;
                                                        <img className="rounded token1increase  " id="token0img" src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/TaumTokenlogo1.png" height="25px" width="25px" alt="ice museum" />
                                                        <img className="rounded token2increase " id="token1img" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="25px" width="25px" alt="ice museum" />
                                                        &nbsp;&nbsp;                                                        <span className=" font-base   ">
                                                            <span className="  font-base   " id="token0Name">  DAI</span>
                                                            <span className=" font-base   ">    /</span>
                                                            <span className="  font-base   " id="token1Name">   ETH</span>
                                                        </span> &nbsp;
                                                        <span className=" font-base " id="feesum" > 0,3%</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <img className="font-medium text-right" id="imageurl1 " style={divstyledot} src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png" />    &nbsp;
                                                        <span className="font-base text-right" id="rangesum" ></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body text-left pb-0 pt-0 font-smedium py-2  ">
                                        <div className="row justify-content-start pt-0 ">
                                            <div className="col-12 col-sm-12 mt-12 pt-0 border border-black rounded text-center" >
                                                <div className=" col-12 col-lg-12 text-left  font-base " >
                                                    <output className=" col-lg-6 text-left font-base py-2">
                                                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token0image1" height="30px" />
                                                        &nbsp;
                                                        <a className="font-base pt-2 " id="token0sum">-</a>
                                                    </output>
                                                    <output className="col-lg-6 text-right font-base"  >
                                                        <a className="text-right" id="value0sum">0,0
                                                        </a>
                                                    </output>
                                                    <output className=" col-lg-6 text-left font-base py-2">
                                                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token1image1" height="30px" />
                                                        &nbsp;
                                                        <a className="font-base" id="token1sum"> -</a>
                                                    </output>
                                                    <output className="col-lg-6 text-right font-base" >
                                                        <a className="text-left" id="value1sum">0,0</a>
                                                    </output>
                                                    <output className=" col-lg-6 text-left font-base">
                                                        <img src="https://storageapi.fleek.co/deneme-team-bucket/icon/share.svg" height="30px" />
                                                        &nbsp;
                                                        <a className="font-base"> Ücret Katmanı</a>
                                                    </output>
                                                    <output className="col-lg-6 text-right font-base" >
                                                        <a className="text-left " id="feetier">0,0</a>
                                                    </output>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-0 py-1" >
                                            <div className="col-md-12 col-lg-12">
                                                {/* <!-- Tab Nav --> */}
                                                <div className="nav-wrapper  mb-0">
                                                    <ul className="nav nav-pills  justify-content-start  " id="tabs-text" role="tablist">
                                                        <li className=" nav-item  mr-sm-5 mr-md-4 " >
                                                            <h5 className="text-left" style={divstye4}> Seçilen Aralık &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </h5>
                                                        </li>
                                                        <li className=" mr-sm-0 mr-md-0">
                                                            {/*id="fromtoken"*/}
                                                            <a className="nav-link mb-sm-3 mb-md-0 active font-xsmall" id="tabs-text-1-tab1" data-toggle="tab" href="#tabs-text-1" role="tab" aria-controls="tabs-text-1" aria-selected="true" onClick={() => {
                                                                var minprice1 = toFixed(1 / Number(document.getElementById("minprice1").innerText))
                                                                document.getElementById("minprice1").innerText = minprice1.toPrecision(8)
                                                                var maxprice1 = toFixed(1 / Number(document.getElementById("maxprice1").innerText))
                                                                document.getElementById("maxprice1").innerText = maxprice1.toPrecision(8)
                                                                var currentprice_11 = toFixed(1 / Number(document.getElementById("currentprice-11").innerText))
                                                                document.getElementById("currentprice-11").innerText = currentprice_11.toPrecision(8)
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("token0minprice").innerText = token0min
                                                                document.getElementById("token1minprice").innerText = token1min
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("token0maxprice").innerText = token0min
                                                                document.getElementById("token1maxprice").innerText = token1min
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("currentpricetoken0").innerText = token0min
                                                                document.getElementById("currentpricetoken1").innerText = token1min
                                                                document.getElementById("tabs-text-2-tab").ariaSelected = true
                                                                document.getElementById("tabs-text-2-tab").ariaSelected = false
                                                            }} >WETH</a>
                                                        </li>
                                                        &nbsp;
                                                        <li className=" mr-sm-0 mr-md-0">
                                                            {/*id="totoken"*/}
                                                            <a className="nav-link mb-sm-3 mb-md-0 font-xsmall" id="tabs-text-2-tab2" data-toggle="tab" href="#tabs-text-2" role="tab" aria-controls="tabs-text-2" aria-selected="false" onClick={() => {
                                                                var minprice1 = toFixed(1 / Number(document.getElementById("minprice1").innerText))
                                                                document.getElementById("minprice1").innerText = minprice1.toPrecision(8)
                                                                var maxprice1 = toFixed(1 / Number(document.getElementById("maxprice1").innerText))
                                                                document.getElementById("maxprice1").innerText = maxprice1.toPrecision(8)
                                                                var currentprice_11 = toFixed(1 / Number(document.getElementById("currentprice-11").innerText))
                                                                document.getElementById("currentprice-11").innerText = currentprice_11.toPrecision(8)
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("token0minprice").innerText = token1min
                                                                document.getElementById("token1minprice").innerText = token0min
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("token0maxprice").innerText = token1min
                                                                document.getElementById("token1maxprice").innerText = token0min
                                                                var token0min = document.getElementById("token0").innerText
                                                                var token1min = document.getElementById("token1").innerText
                                                                document.getElementById("currentpricetoken0").innerText = token1min
                                                                document.getElementById("currentpricetoken1").innerText = token0min
                                                                document.getElementById("tabs-text-2-tab").ariaSelected = false
                                                                document.getElementById("tabs-text-2-tab").ariaSelected = true
                                                            }} >&nbsp;&nbsp;DAI&nbsp;&nbsp;</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* <!-- End of Tab Nav --> */}
                                                {/* <!-- Tab Content --> */}
                                                <div className="   pt-0 py-0 border-light  rounded text-center " >
                                                    <div className="card-body-tabs p-0"  >
                                                        <div className="tab-content" id="tabcontent1">
                                                            <div className="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
                                                                {/* ds */}
                                                                <div className="  py-0 pt-0" >
                                                                    <div className="row" >
                                                                        <div className="col-12 col-lg-6 card-tags card-headers-tabs ">
                                                                            <div className="row " >
                                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                                    <div>
                                                                                        <div className="card-body-tabs" >
                                                                                            <div className="row justify-content-end py-1"  >
                                                                                                {/* jeton1 gelecek */}
                                                                                                <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect" style={divstye3}>
                                                                                                    <a className="font-small"  >Min. Fiyat </a>
                                                                                                    <br />
                                                                                                    <a className="col-md-10 font-base text-center" id="minprice1" style={divstye3} disabled >555,555 </a>
                                                                                                    <br />
                                                                                                    <a className="font-xsmall " id="token0minprice">-</a>
                                                                                                    <a className="font-xsmall "> / </a>
                                                                                                    <a className="font-xsmall " id="token1minprice"> -</a>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 card-tags card-headers-tabs ">
                                                                            <div className="row " >
                                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                                    <div>
                                                                                        <div className="card-body-tabs" >
                                                                                            <div className="row justify-content-end py-1"  >
                                                                                                {/* jeton2 gelecek */}
                                                                                                <button type="button" value="WETH" className="btn btn-primary col-md-12" style={divstye3}>
                                                                                                    <a className="font-small"  >Max. Fiyat </a>
                                                                                                    <br />
                                                                                                    <a className="col-md-10 font-base text-center" id="maxprice1" style={divstye3} disabled >555,555 </a>
                                                                                                    <br />
                                                                                                    <a className="font-xsmall " id="token0maxprice">- </a>
                                                                                                    <a className="font-xsmall "> / </a>
                                                                                                    <a className="font-xsmall " id="token1maxprice"> -</a>
                                                                                                </button>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-12 card-tags card-headers ">
                                                                            <div className="row" >
                                                                                <div className="col-12 col-md-12 col-lg-12 pt-3 border-0 rounded ">
                                                                                    <div>
                                                                                        <div className="card-body-tabs  text-center  "  >
                                                                                            <div className="row justify-content-end ">
                                                                                                {/* jeton2 gelecek */}
                                                                                                <button type="button" value="WETH" className="border    btn btn-primary col-md-12 mb-1" id="btnselect" style={divstye3}>
                                                                                                    <a className="font-small" > Mevcut Fiyat</a>&nbsp;&nbsp;
                                                                                                    <br />
                                                                                                    <a className="font-small" id="currentprice-11">560 .801</a>
                                                                                                    <br />
                                                                                                    <a className="font-xsmall" id="currentpricetoken0">-</a>
                                                                                                    <a className="font-xsmall"> / </a>
                                                                                                    <a className="font-xsmall" id="currentpricetoken1">-</a>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- End of Tab Content --> */}
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" id="togglebtn" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0" data-toggle="modal" data-target="#modal-form" onClick={() => {
                                        if (Number(document.getElementById("priceRangeMax1").value) == 0) {
                                            increaseLiqudity(nftid, document.getElementById("priceRangeMax2").value, deadlineMinute, false, slippage).then((data1) => {
                                                sendHashoriginal(data1.to, data1.methodABI, data1.gasEstimate)
                                            })
                                        }
                                        else {
                                            increaseLiqudity(nftid, document.getElementById("priceRangeMax1").value, deadlineMinute, true, slippage).then((data1) => {
                                                sendHashoriginal(data1.to, data1.methodABI, data1.gasEstimate)
                                            })
                                        }
                                        toggleModal()
                                        var x = document.getElementById("snackbartottffbuy");
                                        x.className = "show";
                                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                                    }} >
                                        Devam
                                    </button>
                                </div>
               </Modal>
        </div>
        <div id="snackbartottffbuy">İşlem Cüzdana Gönderildi</div>
                    </div >
                </div >
            </div >
    {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi */}
        < div id = "countdown_container" style = { display } >
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div >
        </section >
    );
}
export default Incraese;