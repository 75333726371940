import React, { useEffect } from 'react';
import AnyChart from 'anychart'
import { Table } from 'reactstrap';
import { ottaAddress, taumAddress, ttfAddress, wethAddress } from '../protocolAdress'

function Treemap(props) {

    const display = {
        display: "none"

    }

    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    var TaumPrice = 3000000
    var ttfPrice = 3000000
    var ottaPrice = 3000000
    var wethPrice = 0

    useEffect(async () => {

        document.getElementById("marketcap").innerText = "Market Cap"
        await balance(taumAddress, "TAUM").then((data) => {
            // document.getElementById("taumspanvalue").innerText = Number(data).toPrecision(5) + " TAUM"
            TaumPrice = 100
        })
        await balance(ottaAddress, "OTTA").then((data) => {
            //document.getElementById("Ottaspanvalue").innerText = Number(data).toPrecision(5) + " OTTA"
            ottaPrice = 200

        })

        await balance(ttfAddress, "TTFF").then((data) => {
            //document.getElementById("ttfspanvalue").innerText = Number(data).toPrecision(5) + " TTFF"
            ttfPrice = 300

        })


        document.getElementById("taumspanvalue").innerText = "$" + TaumPrice
        document.getElementById("Ottaspanvalue").innerText = "$" + ottaPrice
        document.getElementById("ttfspanvalue").innerText = "$" + ttfPrice

        document.getElementById("anychart").remove()
        var div = document.createElement("div")
        div.setAttribute("id", "anychart");
        div.className = "row col-12 col-lg-12 justify-content-center  pt-0"
        document.getElementById("anychartfirst").appendChild(div)

        var data = [
            {
                name: "Cüzdan Bağlı değil", children: [
                    { name: "LpDaiSdr21", value: TaumPrice, fill: "#39ffbc", tokenvalue: 62.6 },
                    { name: "Sdr21Shares ", value: ottaPrice, fill: "#559827", tokenvalue: 31.30 },
                    {
                        name: "Sdr21", value: ttfPrice, fill: "#39ffbc", tokenvalue: 15,
                        normal: { fill: "#39babc", stroke: "4 white" },
                        hovered: { fill: "#39bab0", stroke: "5 white" },
                        selected: { fill: "#b30059", stroke: "5 white" }
                    }

                ]
            }
        ];



        var chart = AnyChart.treeMap(data, "as-tree");

        // set the container id
        chart.background("transparent").fill();

        // configure tooltips
        chart.tooltip().format(
            "Value: {%value}\ntoken %: {%tokenvalue}"
        );
        // enable HTML for labels
        chart.labels().useHtml(true);

        // configure labels
        chart.labels().format(
            "<span style='font-weight:bold'>{%name}</span><br>{%value}"
        );
        chart.headers(false);

        if (document.getElementById("anychart").firstElementChild == null) {
            chart.container("anychart");
            chart.draw();
        }




    }, [TaumPrice])

    useEffect(async () => {
        document.getElementById("taumspanvalue").innerText = "$" + TaumPrice
        document.getElementById("Ottaspanvalue").innerText = "$" + ottaPrice
        document.getElementById("ttfspanvalue").innerText = "$" + ttfPrice


        var x = setTimeout(async () => {
            if (document.getElementById("walletId").innerText == "Wallet Connect" || props.address == null) {

                document.getElementById("marketcap").innerText = "Market Cap"



                document.getElementById("taumspanvalue").innerText = "$" + TaumPrice
                document.getElementById("Ottaspanvalue").innerText = "$" + ottaPrice
                document.getElementById("ttfspanvalue").innerText = "$" + ttfPrice

                document.getElementById("anychart").remove()
                var div = document.createElement("div")
                div.setAttribute("id", "anychart");
                div.className = "row col-12 col-lg-12 justify-content-center  pt-0"
                document.getElementById("anychartfirst").appendChild(div)

                var data = [
                    {
                        name: "Cüzdan Bağlı değil", children: [
                            { name: "LpDaiSdr21", value: TaumPrice, fill: "#39ffbc", tokenvalue: 62.6 },
                            { name: "Sdr21Shares", value: ottaPrice, fill: "#559827", tokenvalue: 31.30 },
                            {
                                name: "Sdr21", value: ttfPrice, fill: "#39ffbc", tokenvalue: 15,
                                normal: { fill: "#39babc", stroke: "4 white" },
                                hovered: { fill: "#39bab0", stroke: "5 white" },
                                selected: { fill: "#b30059", stroke: "5 white" }
                            }

                        ]
                    }
                ];



                var chart = AnyChart.treeMap(data, "as-tree");

                // set the container id
                chart.background("transparent").fill();

                // configure tooltips
                chart.tooltip().format(
                    "Value: {%value}\ntoken %: {%tokenvalue}"
                );
                // enable HTML for labels
                chart.labels().useHtml(true);

                // configure labels
                chart.labels().format(
                    "<span style='font-weight:bold'>{%name}</span><br>{%value}"
                );
                chart.headers(false);

                if (document.getElementById("anychart").firstElementChild == null) {
                    chart.container("anychart");
                    chart.draw();
                }



            }
            else {
                document.getElementById("marketcap").innerText = "Balance"

                await balance(taumAddress, "TAUM").then((data) => {
                    document.getElementById("taumspanvalue").innerText = Number(data).toPrecision(5) + " LpDaiSdr21"
                    TaumPrice = Number(data).toPrecision(5)
                })
                await balance(ottaAddress, "OTTA").then((data) => {
                    document.getElementById("Ottaspanvalue").innerText = Number(data).toPrecision(5) + " Sdr21Shares"
                    ottaPrice = Number(data).toPrecision(5)

                })

                await balance(ttfAddress, "TTFF").then((data) => {
                    document.getElementById("ttfspanvalue").innerText = Number(data).toPrecision(5) + " Sdr21"
                    ttfPrice = Number(data).toPrecision(5)

                })

                await balance(wethAddress, "WETH").then((data) => {
                    wethPrice = "$" + Number(data).toPrecision(5) + " WETH"

                })


                if (ottaPrice == 0 && TaumPrice == 0 && ttfPrice == 0) {
                    document.getElementById("anychart").remove()
                    var div = document.createElement("div")
                    div.setAttribute("id", "anychart");
                    div.className = "row col-12 col-lg-12 justify-content-center  pt-0"
                    document.getElementById("anychartfirst").appendChild(div)
                    var data = [
                        {
                            name: "Cüzdan Bağlı değil", children: [
                                { name: "LpDaiSdr21", value: 1000000.23, fill: "#39ffbc", tokenvalue: 62.6 },
                                { name: "Sdr21Shares", value: 5000000.85, fill: "#559827", tokenvalue: 31.30 },
                                {
                                    name: "Sdr21", value: 50250555.85, fill: "#39ffbc", tokenvalue: 15,
                                    normal: { fill: "#39babc", stroke: "4 white" },
                                    hovered: { fill: "#39bab0", stroke: "5 white" },
                                    selected: { fill: "#b30059", stroke: "5 white" }
                                }

                            ]
                        }
                    ];


                    // create a chart and set the data
                    var chart = AnyChart.treeMap(data, "as-tree");

                    // set the container id
                    chart.background("transparent").fill();

                    // configure tooltips
                    chart.tooltip().format(
                        "Value: {%value}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}"
                    );
                    // enable HTML for labels
                    chart.labels().useHtml(true);

                    // configure labels
                    chart.labels().format(
                        "<span style='font-weight:bold'>{%name}</span><br>{%value}"
                    );
                    chart.headers(false);


                    if (document.getElementById("anychart").firstElementChild == null) {
                        chart.container("anychart");
                        chart.draw();
                    }
                }
                else {
                    document.getElementById("anychart").remove()
                    var div = document.createElement("div")
                    div.setAttribute("id", "anychart");
                    div.className = "row col-12 col-lg-12 justify-content-center  pt-0"
                    document.getElementById("anychartfirst").appendChild(div)

                    var data = [
                        {
                            name: "Cüzdan Bağlı ", children: [
                                { name: "LpDaiSdr21", value: Number(TaumPrice), fill: "#39ffbc", tokenvalue: Number(TaumPrice) },
                                { name: "Sdr21Shares ", value: Number(ottaPrice), fill: "#559827", tokenvalue: Number(ottaPrice) },
                                { name: "ETH", value: (Number(props.balance / Math.pow(10, 19))).toPrecision(5), fill: "#91ff12", tokenvalue: (Number(props.balance / Math.pow(10, 19))).toPrecision(5) },
                                { name: "Varlığınız yok", value: 0, fill: "#b30059", tokenvalue: 0 },
                                { name: "WETH", value: Number(wethPrice), fill: "#b30059", tokenvalue: Number(wethPrice) },
                                {
                                    name: "Sdr21", value: Number(ttfPrice), fill: "#157421", tokenvalue: Number(ttfPrice),
                                    normal: { fill: "#39babc", stroke: "4 white" },
                                    hovered: { fill: "#39bab0", stroke: "5 white" },
                                    selected: { fill: "#b30059", stroke: "5 white" },
                                }

                            ]
                        }
                    ];


                    // create a chart and set the data
                    var chart = AnyChart.treeMap(data, "as-tree");

                    // set the container id
                    chart.background("transparent").fill();

                    // configure tooltips
                    chart.tooltip().format(
                        "Value: {%value}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}\ntoken %: {%tokenvalue}"
                    );
                    // enable HTML for labels
                    chart.labels().useHtml(true);

                    // configure labels
                    chart.labels().format(
                        "<span style='font-weight:bold'>{%name}</span><br>{%value}"
                    );
                    chart.headers(false);


                    if (document.getElementById("anychart").firstElementChild == null) {

                        chart.container("anychart");
                        chart.draw();
                    }
                }




                //  document.getElementById("taumspanvalue").innerText="1.2"
                //  document.getElementById("Ottaspanvalue").innerText="1.3"
                //  document.getElementById("ttfspanvalue").innerText="1.6"




            }
        }, 1000);



        // initiate drawing the chart

        // chart.listen("pointsSelect", function (e) {
        //     // get the value of the point
        //     var value = e.point.getStat("value");
        //     var name = e.point.getStat("name");
        //     var percent = e.point.getStat("tokenvalue");

        //     // change the chart title
        //     //chart.title(name + " $" + value);
        //     document.getElementById("tokenname").innerText = name;
        //     document.getElementById("tokenvalue").innerText = value
        //     document.getElementById("tokenpercent").innerText = percent + "%"


        // });





    })

    return (
        <section>
            <div className="row justify-content-center mb-1 pt-2 py-0 ">
                {/* Component to Card start */}
                <div className=" col-12 col-md-12 col-lg-12 mb-4 mb-lg-0 pt-0">
                    <div className="  border-light p-4 rounded" id="anychartfirst">
                        <div className=" row col-12 col-lg-12 justify-content-center  pt-0" id="anychart" >
                        </div>
                    </div>
                </div>
                <div className=" col-9"  >
                    <div className="row pt-0  " >
                        <div className=" col-12 col-md-12 col-lg-12 pt-0 " >
                            <div className="  p-3 rounded mb-3 " >
                                <div className="card-body-tabs p-0 col-12"  >
                                    <Table hover className="table-hover"  >
                                        <thead>
                                            <tr >
                                                <th >İsim </th>
                                                <th id="marketcap">Market cap</th>
                                                <th>Fiyat</th>
                                            </tr>
                                        </thead>
                                        <tbody id="tabletr">
                                            <tr >
                                                <td  >
                                                    <a href="/#/TaumEntry" className="a">
                                                        <img height="25px" className=" font-medium" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"></img>&nbsp;&nbsp;&nbsp;
                                                        <span >LpDaiSdr21 </span></a>
                                                </td>
                                                <td>
                                                    <a href="/#/TaumEntry">
                                                        <span className="font-base" id="taumspanvalue">-</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="/#/TaumEntry">
                                                        <span className="font-base" > $550.00</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="/#/Yotta21Buy">
                                                        <img height="25px" className=" font-medium" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png" ></img>&nbsp;&nbsp;&nbsp;
                                                        <span >Sdr21Shares</span>
                                                    </a>
                                                </td>

                                                <td>
                                                    <a href="/#/Yotta21Buy">
                                                        <span className="font-base" id="Ottaspanvalue" > -</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="/#/Yotta21Buy">
                                                        <span className="font-base" > $685.00</span>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <a href="/#/TtfBuy/0xc778417E063141139Fce010982780140Aa0cD5Ab/TTF">
                                                        <img height="25px" className=" font-medium" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"></img>&nbsp;&nbsp;&nbsp;
                                                        <span >Sdr21</span>
                                                    </a>
                                                </td>
                                                <td >
                                                    <a href="/#/TtfBuy/0xc778417E063141139Fce010982780140Aa0cD5Ab/TTF">
                                                        <span className="font-base" id="ttfspanvalue"> -</span>
                                                    </a>
                                                </td>
                                                <td >
                                                    <a href="/#/TtfBuy/0xc778417E063141139Fce010982780140Aa0cD5Ab/TTF">
                                                        <span className="font-base" > $150,00</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi  */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div>
        </section>
    );
}

export default Treemap







