
import { EtherscanProvider } from "@ethersproject/providers";
import { useEffect } from "react"

// console.log("ts",ts)

function BlockNumber() {
 
    async function BlockNumberto(epoch, apikey) {

        var result;
        var address = "https://api-kovan.etherscan.io/api?module=block&action=getblocknobytime&timestamp=" + epoch + "&closest=before&apikey=" + apikey
        console.log("daasads", address)
        return result = await new Promise((resolve, reject) => {

            fetch(address, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {

        setInterval(() => {
            var apikey = "5V8T9CF4YDZ61NM4AJGFZDBE7RMDYKYDM7"
            // var epoch =  Date.now()
            var epoch = Math.round((new Date()).getTime() / 1000);
            BlockNumberto(epoch, apikey).then((data) => {
                if (document.getElementById("blocknumber").innerText != data.result) {
                    document.getElementById("blocknumber").innerHTML = data.result
                   var block = data.result
                   var etherscanLink = "https://kovan.etherscan.io/block/" + block
                    document.getElementById("blocknumber").href = etherscanLink
                }
            })
        }, 10000);

    })
    return (
        <section className="text-right">
            <div>
                <a  target="_blank" id="blocknumber" href="" aria className="text-right"></a>
                <input className="ioyDYv text-left"></input>&nbsp;
            </div>

        </section>
    )
}
export default BlockNumber