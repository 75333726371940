import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button, Table } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import { governorBravoDelegateAddress, governorBravoDelegatorAddress } from '../protocolAdress';

const web3 = require('./web3Provider.js').web3;
const governorAbi = require('./governorAbi.json').abi; // delegate
const governorAddress = governorBravoDelegateAddress;
const governor = new web3.eth.Contract(governorAbi, governorAddress);

const governorxAbi = require('./governorxAbi.json').abi; //delegator
const governorxAddress = governorBravoDelegatorAddress;
const governorx = new web3.eth.Contract(governorAbi, governorxAddress);


const spanstyle = {
    textDecoration: "underline",
    width: "100%",
    color: "#39babc"
}

const display = {
    display: 'none ',

}
const divstye3 = {
    backgroundColor: "transparent"
}

var addresses


function Vote(props) {

    let history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }
    //Transaction oluşturmak için kullanılan fonksiyon
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', end => {

            if (_transactiontype == "approvetoken0") {
                var aprove = document.getElementById("approvediv")
                var button0 = document.getElementById("btn-approve-token0")
                button0.disabled = true
                button0.innerText = "Approve başarılı"
                aprove.removeChild(button0)
                console.log(end);

                if (document.getElementById("btn-approve-token1") != null) document.getElementById("btn-approve-token1").className = "btn btn-yotta21 col-12"

            }
            else if (_transactiontype == "approvetoken1") {

                var aprove = document.getElementById("approvediv")
                var button1 = document.getElementById("btn-approve-token1")
                button1.disabled = true
                button1.innerText = "Approve başarılı"
                aprove.removeChild(button1)
                console.log(end);

                if (document.getElementById("btn-approve-token0") != null) document.getElementById("btn-approve-token0").className = "btn btn-yotta21 col-12"

            }
            else {

                console.log(end);
            }
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            console.log(end);


        })
        emitter.on('txFailed', end => {
            console.log(end);
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })


    }


    //Kullanıcının Oy Gücünü Döndüren Apiye Çağrı yapılması
    async function getCurrentVote() {
        var _datagetCurrentVote = {
            "owner": props.address,
            "addressToDelegate": props.address,
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getDelegationData", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datagetCurrentVote)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }


    // Kullanıcının oy kullanıp kullanmadığını döndüren api 
    async function checkIfUserVoted(_id, _address) {
        var _datacheckIfUserVoted = {
            "id": _id,
            "address": _address,
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/checkIfUserVoted", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datacheckIfUserVoted)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    // Active and Passive vote list
    async function Votelist() {

        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getAllProposals", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    //Kullanıcının Oy Gücünü  Kendisine veya Başkasına Vermesi Delagasyon verilmesi
    async function Delegate(_delagateaddress) {
        var _datadelegate = {
            "owner": props.address,
            "addressToDelegate": _delagateaddress
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/delegate", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datadelegate)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    // id değeri ile oylama zamanlarının alınması 
    async function getProposalDetails(_id) {
        var _datagetProposalDetails = {
            "id": _id,

        }

        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getProposalDetails", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datagetProposalDetails)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    var response;
    var currentvote
    var delegates
    var date

    useEffect(() => {
        addresses = window.localStorage.getItem(
            'WalletAddress'
        )
    }, addresses)

    useEffect(async () => {



        Votelist().then((ver) => {
           ver =ver.allProposals
            // if (document.getElementById("votediv") == null) {
            for (let index = 0; index < ver.length; index++) {
                getProposalDetails(ver[index].id).then((data) => {


                    if (ver[index].isActive == false) {
                        if (document.getElementById(ver[index].id) == null) {
                            document.getElementById("tabletr-passive ").innerHTML += `
                    
                            <tr id=${ver[index].id}>
        
                            <td>
                                <br></br>
                                <a href="/#/Proporsal/1/${ver[index].id}">
                                    <span class="font-base">1/${ver[index].id}</span>
                                </a>
                            </td>
        
                            <td>
                                <a href="/#/Proporsal/1/${ver[index].id}">
        
                                    <br></br>
                                    <span class="font-base" > <strong>${ver[index].title}</strong></span>
                                </a>
                            </td>
        
                            <td class="row justify-content-end">
                                <a href="/#/Proporsal/1/${ver[index].id}">
        
                                    <span class="font-base  text-border-yurutuldu" id="votestatus${ver[index].id}" ></span>
                                </a>
                            </td>
        
                        </tr>`
                        }

                    }


                    else {
                        if (document.getElementById(ver[index].id) == null) {

                            document.getElementById("tabletr-active").innerHTML += ` 
                        <tr id=${ver[index].id}>

                    <td class='text-left'>
                        <br></br>
                        <a href="/#/Proporsal/active/${ver[index].id}">
                            <span class="font-base">1/${ver[index].id}</span>
                        </a>
                    </td>

                    <td class='text-left'>
                        <a href="/#/Proporsal/avctive/${ver[index].id}">

                            <span class="font-base" > <strong>${ver[index].title}</strong></span>
                            <br></br>
                             <span class="font-base  text-border-gecti" id="votestatus${ver[index].id}" ></span>
                            <span class="font-base " id="time${ver[index].id}" ></span>

                        </a>
                    </td>

                    <td class="row justify-content-end">
                        <div class="pt-2 " id="votediv${ver[index].id}">
                        </div>
                    </td>

                         </tr>`
                        }
                    }

                    // status belirleme
                    if (ver[index].state == "0") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Pending"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-yurutuldu"
                    }
                    else if (ver[index].state == "1") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Aktif"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-yurutuldu"

                    }
                    else if (ver[index].state == "2") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "İptal "
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-maglup"

        
        
                    }
                    else if (ver[index].state == "3") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Mağlup"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-maglup"

                    }
                    else if (ver[index].state == "4") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Geçti"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-gecti"

                    }
                    else if (ver[index].state == "5") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Kuyruğa alındı"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-yurutuldu"

                    }
                    else if (ver[index].state == 6) {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Süresi Dolmuş"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-yurutuldu"

                    }
                    else if (ver[index].state == "7") {
                        document.getElementById("votestatus"+ver[index].id).innerText = "Başarılı"
                        document.getElementById("votestatus"+ver[index].id).className="font-base  text-border-gecti"

                    }


                   
                    //state değerine göre oylama durumlarını blirleme
                    if (ver[index].state == "0") {
                        if (document.getElementById("pending" + ver[index].id) == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "pending" + ver[index].id)
                            span.className = "font-base text-border-yurutuldu"
                            span.innerText = "Pending"
                            document.getElementById("votediv" + ver[index].id).appendChild(span)
                         if(ver[index].history.Create.Date != null) {
                            var date1= new Date(Number(ver[index].history.Create.Date._seconds)+(Number(data.votingDelay)*4000))
                            document.getElementById("time" + ver[index].id).innerText = date1.toLocaleString() + " tarihinde" + " Oylama başlayacakır."

                         }
                            

                        }


                    }
                    else if (ver[index].state == "1") {
                        if (document.getElementById("pending" + ver[index].id) == null) {
                            if (document.getElementById("btnyes" + ver[index].id) == null && document.getElementById("btnno" + ver[index].id) == null) {
                                var buttonyes = document.createElement("button")
                                buttonyes.setAttribute("id", "btnyes" + ver[index].id)
                                buttonyes.className = "btn"
                                buttonyes.style.backgroundColor = "#39babc"
                                buttonyes.innerText = "Evet"

                                var buttonno = document.createElement("button")
                                buttonno.setAttribute("id", "btnno" + ver[index].id)
                                buttonno.className = "btn"
                                buttonno.style.backgroundColor = "#39babc"
                                buttonno.innerText = "Hayır"
                                document.getElementById("votediv" + ver[index].id).appendChild(buttonyes)
                                document.getElementById("votediv" + ver[index].id).appendChild(buttonno)
                                if(ver[index].history.Create.Date != null) {
                                    var date1= new Date(Number(ver[index].history.Create.Date._seconds)+((Number(data.votingDelay)+Number(data.votingPeriod))*4000))
                                    document.getElementById("time" + ver[index].id).innerText = date1.toLocaleString() + " tarihinde" + " Oylama sona erecektir."
        
                                 }
                            }




                        }

                        if (document.getElementById("btnyes" + ver[index].id) != null) {
                            if (ver[index].isActive == true) {
                                checkIfUserVoted(ver[index].id, addresses).then((data) => {
        
        
                                    if (data[0] == true) {
                                        if (document.getElementById("btnyes" + ver[index].id) != null) {
                                            document.getElementById("votediv" + ver[index].id).removeChild(document.getElementById("btnyes" + ver[index].id))
                                            document.getElementById("votediv" + ver[index].id).removeChild(document.getElementById("btnno" + ver[index].id))
                                            var span = document.createElement("span")
                                            var span2 = document.createElement("span")
                                            span2.innerText = "Kullandığınız Oy: "
                                            if (document.getElementById("voted" + ver[index].id) == null) {
                                                span.setAttribute("id", "voted" + ver[index].id)
                                                if (data[1] == 1) {
                                                    span.className = "font-base text-border-gecti"
                                                    span.innerText = "Evet"
                                                }
                                                else {
                                                    span.innerText = "Hayır"
                                                    span.className = "font-base text-border-maglup"
                                                }
                                                document.getElementById("votediv" + ver[index].id).appendChild(span2)
        
                                                document.getElementById("votediv" + ver[index].id).appendChild(span)
                                            }
        
                                        }
        
        
                                    }
        
        
        
                                },checkIfUserVoted)
                            }
        
                        }

                    }
                    else if (ver[index].state == "4") {
                        if (document.getElementById("btnqueued" + ver[index].id) == null) {
                            var button = document.createElement("button")
                            button.setAttribute("id", "btnqueued" + ver[index].id)
                            button.className = "btn"
                            button.style.backgroundColor = "#39babc"
                            button.innerText = "Queue"
                            document.getElementById("votediv" + ver[index].id).appendChild(button)

                        }


                    }
                    else if (ver[index].state == "5") {
                        var now = Date.now()
                        var eta = data.eta * 1000


                        if (now >= eta) {
                            if (document.getElementById("btnexecuted" + ver[index].id) == null) {
                                var executetime = Number(data.eta) * 1000 + Number(data.gracePeriod)
                                if (executetime < now) {
                                    var button = document.createElement("button")
                                    button.setAttribute("id", "btnexecuted" + ver[index].id)
                                    button.className = "btn"
                                    button.style.backgroundColor = "#39babc"
                                    button.innerText = "Execute"
                                    document.getElementById("votediv" + ver[index].id).appendChild(button)
                                    if(ver[index].history.Queued.Date != null) {
                                        var date1= new Date(Number(eta)+(Number(data.gracePeriod)))
                                        document.getElementById("time" + ver[index].id).innerText = date1.toLocaleString() + " tarihine kadar" + " Execute işlemi yapılabilir."
            
                                     }
                                }
                                else {
                                    if (document.getElementById("pass" + ver[index].id) == null) {
                                        var span = document.createElement("span")
                                        span.setAttribute("id", "pass" + ver[index].id)
                                        span.className = "font-base text-border-yurutuldu"
                                        span.innerText = "Pass"
                                        document.getElementById("votediv" + ver[index].id).appendChild(span)

                                    }
                                }

                            }
                        }
                        else {
                            if (document.getElementById("queueded"+ver[index].id) == null) {
                                var span = document.createElement("span")
                                span.setAttribute("id", "queueded"+ver[index].id)
                                span.className = "font-base text-border-maglup"
                                span.innerText = "Queued"
                                document.getElementById("votediv" + ver[index].id).appendChild(span)
                                var date1 = new Date(eta);
                                document.getElementById("time" + ver[index].id).innerText = date1.toLocaleString() + " tarihinde" + " Execute butonu aktif olacaktır."
                            }

                        }




                    }
                    // else if(ver[index].state == "3") {
                    //     var span = document.createElement("span")
                    //     span.setAttribute("id","defeated")
                    //     span.innerText="defeated"
                    //     span.className = "font-base text-border-maglup"
                    //     document.getElementById("votediv").appendChild(span)

                    // }

                    //oylama aktif olduğu durumda oy kullanabilmesi için kullanıcıya evet hayır butonlarının event'ları





                })
               
                const castVoteyes = governor.methods.castVote(ver[index].id, 1).encodeABI();
                const castVoteno = governor.methods.castVote(ver[index].id, 0).encodeABI();
                const queueData = governor.methods.queue(ver[index].id).encodeABI();
                const executeData = governor.methods.execute(ver[index].id).encodeABI();

                if (document.getElementById("btnqueued" + ver[index].id) != null) {
                    document.getElementById("btnqueued" + ver[index].id).addEventListener("click", () => {

                        sendHash(governorBravoDelegatorAddress, queueData, 800000)
                    })
                }
                if (document.getElementById("btnexecuted" + ver[index].id) != null) {
                    document.getElementById("btnexecuted" + ver[index].id).addEventListener("click", () => {

                        sendHash(governorBravoDelegatorAddress, executeData, 800000)
                    })
                }

                if (document.getElementById("btnyes" + ver[index].id) != null) {
                    document.getElementById("btnyes" + ver[index].id).addEventListener("click", () => {
                        sendHash(governorBravoDelegatorAddress, castVoteyes, 800000)
                    })
                    document.getElementById("btnno" + ver[index].id).addEventListener("click", () => {
                        sendHash(governorBravoDelegatorAddress, castVoteno, 800000)

                    })
                }
            }

            // }


        })

       





    });

    useEffect(()=>{
        getCurrentVote().then((ver) => {

            currentvote = ver.currentVotes
            delegates = ver.delegates
            document.getElementById("CurrentVote").innerText = Number(currentvote).toFixed(5)
            //delegate adres kısa yazımı
            if (delegates != null) {
                var con = delegates.slice(0, 4);
                var con2 = delegates.slice(39, 42)
                var conwalletid = con + "......." + con2;
            }

            if (delegates == "0x0000000000000000000000000000000000000000") {
                document.getElementById("delagateto").style.display = "none"

            }
            else {
                if (Number(currentvote) == 0) {
                    document.getElementById("delegate").innerText = conwalletid
                    document.getElementById("delagateto").style.display = "block"
                    document.getElementById("delagateto").href = "https://kovan.etherscan.io/address/" + delegates
                }
                else {
                    document.getElementById("delegate").innerText = "self"
                    document.getElementById("delagateto").style.display = "block"
                    document.getElementById("delagateto").href = "https://kovan.etherscan.io/address/" + delegates
                }
            }

        })
    })

    return (
        <section id="root pt-0" >
            <div className="row mb-5 justify-content-center pt-6 " >
                <div className=" justify-content-center col-12 col-md-12 col-lg-10 mb-5 mb-lg-0 pt-0">
                    <div className="  p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row" >
                                <div className='col-12 col-lg-12'>
                                    <div className=" col-12 ml-12 pb-2 text-center" >
                                        <h4 className="h3"> <strong>Oylama</strong></h4>
                                    </div>
                                </div>
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ml-4  ">
                                    <div className=' pt-1' >
                                        <div className="row justify-content-start  py-1 pt-1  ">
                                            <a className='text-border-yurutuldu'><strong>Oy Gücünüz:</strong> <span id="CurrentVote">0</span> </a> &nbsp;
                                            <a className='text-border-yurutuldu' target="_blank" id="delagateto"><strong>Delegasyon: </strong> <span target="_blank" id="delegate"></span> </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5 ml-5">
                                    <div className="row justify-content-end" >
                                        <div className="col-12  col-lg-12">
                                            <div>
                                                <div className="py-">
                                                    <div className="row justify-content-end ">
                                                        <Button className="btn btn-pill " id="modal-from" onClick={() => {
                                                            toggleModal()
                                                        }}><i className="fas fa-plus " style={divstye3} ></i> Delegasyon</Button>
                                                        <Button className="btn btn-pill " href="/#/NewVote"><i className="fas fa-plus " style={divstye3} ></i> Yeni Oylama</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal Content --> */}
                        <Modal
                            isOpen={isOpen}
                            onRequestClose={toggleModal}
                            contentLabel="My dialog"
                            className="mymodal3" /*"modal-dialog modal-dialog-centered rounded"*/
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                            aria-hidden="true"
                        >
                            <div className="" id="ttffsum" >
                                <button type="button" className=" pt-0 close ml-auto " >
                                    <span aria-hidden="true" id="close" onClick={() => {
                                        toggleModal()
                                    }}>×</span>
                                </button>
                                <div className="card-header  text-center pb-0 pt-0  border-bottomblack">
                                    <h2 className="h4 font-medium text-left pt-0">Delegasyon Seçimi </h2>
                                </div>
                                <div className=" ">
                                    <div className="col-auto px-0" data-dropdown-content="">
                                        <div className="list-group list-group-flush">
                                            <a className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4" onClick={() => {
                                                Delegate(props.address).then((ver) => {
                                                    sendHash(ver.to, ver.methodABI, ver.gasEstimate)
                                                })
                                                toggleModal()
                                            }}>
                                                <span className="icon icon-sm icon-secondary">
                                                    <span className="far fa-check-circle"></span>
                                                </span>
                                                <div className="ml-4">
                                                    <span className=" font-medium  d-block">Manuel Delegasyon
                                                        {/* <span className="badge badge-sm badge-secondary ml-2">v1.0</span> */}
                                                    </span>
                                                    <span className="font-small">Bu seçenek, doğrudan bağlı cüzdanınızdan tekliflere oy vermenizi sağlar.</span>
                                                </div>
                                            </a>
                                            <a className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4" id="modal-from-2" onClick={() => {
                                                toggleModal2()
                                                toggleModal()
                                            }}>
                                                <span className="icon icon-sm icon-secondary">
                                                    <span className="fas fa-arrow-circle-right"></span>
                                                </span>
                                                <div className="ml-4">
                                                    <span className=" font-medium  d-block">Delegasyon Devretme</span>
                                                    <span className="font-small">Bu seçenek, oylarınızı başka bir Ethereum adresine devretmenizi sağlar. Sdr21Shares'i asla göndermezsiniz, yalnızca oy haklarınızı gönderirsiniz ve istediğiniz zaman yetki devrini iptal edebilirsiniz</span>
                                                </div>
                                            </a>
                                            <a className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4" onClick={() => {
                                                Delegate(props.address).then((ver) => {
                                                    sendHash(ver.to, ver.methodABI, ver.gasEstimate)
                                                })
                                            }}>
                                                <span className="icon icon-sm icon-secondary">
                                                    <span className="fas fa-arrow-circle-left"></span>
                                                </span>
                                                <div className="ml-4">
                                                    <span className=" font-medium  d-block">Delegasyonu Geri Al</span>
                                                    <span className="font-small">Bu seçenek,  başka bir Ethereum adresine devretiğiniz oy gücünüzü geri almanızı sağlar sağlar.</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            isOpen={isOpen2}
                            onRequestClose={toggleModal2}
                            contentLabel="My dialog"
                            className="mymodal3" /*"modal-dialog modal-dialog-centered rounded"*/
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                            aria-hidden="true"
                        >
                            {/* <!-- Modal Content 2 start --> */}
                            <div className="" id="ttffsum" >
                                <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" id="close" onClick={() => { toggleModal2() }}>×</span>
                                </button>
                                <div className="card-header  text-center pb-0 pt-0  border-bottomblack">
                                    <h2 className="h4 font-medium text-left pt-0">Delegasyon Devretme </h2>
                                </div>
                                <div className="pt-3 ">
                                    <div className="col-auto px-0" data-dropdown-content="">
                                        <div className="list-group list-group-flush">

                                            <div className="ml-4 pt-2">
                                                <span className=" font-medium  d-block border-bottomblack">Adres Seçiniz
                                                    {/* <span className="badge badge-sm badge-secondary ml-2">v1.0</span> */}
                                                </span>
                                                <br></br>
                                                <span className="font-small py-2">Yetki vermek istediğiniz adresi aşağıda belirtiniz ve oy gücünüzü o cüzdana devrediniz.</span>
                                            </div>
                                            <br></br>
                                            <div className="ml-4">
                                                <span className=" font-medium  d-block border-bottomblack py-2"> Temsilci Adresi
                                                    {/* <span className="badge badge-sm badge-secondary ml-2">v1.0</span> */}
                                                </span>
                                                <br></br>
                                                <input type='text' placeholder='Lütfen Ox Adresi Giriniz' className='col-lg-12 form-control' id="delegateAddress"></input>
                                            </div>
                                            <br></br>
                                            <div className="ml-4">
                                                <a>
                                                    <button className='btn col-lg-12 ' data-toggle="modal" data-target="#modal-form-2" onClick={() => {
                                                        Delegate(document.getElementById("delegateAddress").value).then((ver) => {
                                                            sendHash(ver.to, ver.methodABI, ver.gasEstimate)
                                                        })
                                                        toggleModal2()
                                                    }}>Delegate Delegation</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* dsasa */}
                        <div className='col-12 col-lg-12'>
                            <div className=" col-12 ml-12 pb-2 text-start" >
                                <h4 className="h5"> <strong> Güncel Teklifler</strong></h4>
                            </div>
                        </div>
                        <div className=" p-0 mb-2 pt-0 col-lg-12 div-white rounded" id="positiondiv" >
                            <div className="contanier justify-content-start col-lg-12 rounded  " >
                                <Table hover className="border-0">
                                    <tbody id="tabletr-active">
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className='col-12 col-lg-12'>
                            <div className=" col-12 ml-12 pb-2 text-start" >
                                <h4 className="h5"> <strong> Geçmiş Teklifler</strong></h4>
                            </div>
                        </div>
                        <div className=" p-0 mb-2 pt-0 col-lg-12 div-white rounded" id="positiondiv" >
                            <div className="contanier justify-content-end col-lg-12 rounded border-0 " >
                                <Table hover className="border-0  ">
                                    <tbody id="tabletr-passive ">
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi  */}
            < div id="countdown_container" style={display} >
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div >
        </section >
    );
}

export default Vote;