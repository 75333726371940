import React, { useEffect } from 'react'
import { Alert, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import getSigner from '../../signer'
import axios from 'axios';
import { BigNumber } from '@ethersproject/bignumber';




function toFixed(x) {
    if (Math.abs(x) < 1.0) {
       var e = parseInt(x.toString().split('e-')[1]);
       if (e) {
          x *= Math.pow(10, e - 1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
       }
    } else {
       var e = parseInt(x.toString().split('+')[1]);
       if (e > 20) {
          e -= 20;
          x /= Math.pow(10, e);
          x += (new Array(e + 1)).join('0');
       }
    }
    return x;
 }




var range;
var imageurl;
var increase;
var decrease;
var percentToken0
var percentToken1

const display = {
    display: 'none ',

}
const divstye12 = {
    backgroundColor: '#f3fbfb ',
}
const divstye2 = {
    padding: '50px',

}

const divstye3 = {
    backgroundColor: "transparent"

}
const divstye4 = {
    backgroundImage: "https://storageapi.fleek.co/esongur-team-bucket/image/yotta21.png",

}
const divstye5 = {
    display: "grid",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px 5px 10px 5px",


}
const divstye7 = {
    backgroundColor: "#39babc",
}
const divstye8 = {
    backgroundColor: "#E0F7FA",
    color: "black"
}
const divstylecontent = {
    justifyContent: "flex-end",
    alignıtems: "baseline"
}

const divstyledot = {
    height: "10px",
    width: "10px",

}

function Pool(props) {

    const { nftid } = useParams();
    increase = "/increase/" + nftid
    decrease = "/remove/" + nftid

    function calculateNftLiquidityPercentageValues(currentPrice, token0Amount, token1Amount) {
        var token0AsToken1 = Number(currentPrice) * Number(token0Amount); //Cast token0 to token1
        var sum = Number(token0AsToken1) + Number(token1Amount);
        var percentage = ((Number(token1Amount) / Number(sum)) * 100); //Calculate percentage of token1
        return Math.ceil(Number(percentage))
    }

    async function nftDetail(_nftid) {
        var _datanft = {
            "nftId": _nftid,
            "recipient": props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {

            fetch(process.env.REACT_APP_NFT, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datanft)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    var response

    useEffect(async () => {
        var _datanft = {
            "nftId": nftid,
            "recipient": props.address
        }
        response = await axios.post(process.env.REACT_APP_NFT, _datanft);


    
            if (Number(response.data.minPrice) <= Number(response.data.currentPrice) && Number(response.data.currentPrice) <= Number(response.data.maxPrice)) {
                document.getElementById("range").innerText = "Aralıkta"
                document.getElementById("imageurl1 ").setAttribute("src", "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png")
            }
            else {
                document.getElementById("range").innerText = "Aralık Dışı"
                document.getElementById("imageurl1 ").setAttribute("src", "https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png")




            }
        

        // img ayarlanması

        //token0 img
        if (response.data.token0 == "TTFF") {
            document.getElementById("token0imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token0img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"

        }
        else if (response.data.token0 == "LpTTFF") {
            document.getElementById("token0imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token0img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"        
        }
        else if (response.data.token0 == "OTTA") {
            document.getElementById("token0imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token0img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
        }
        else if (response.data.token0 == "WETH") {
            document.getElementById("token0imginc").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token0img1").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token0image").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
        }
        else {
            document.getElementById("token0imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token0img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token0image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"

        }

        // token1 img
        if (response.data.token1 == "WETH") {
            document.getElementById("token1imginc").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token1img1").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token1image").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
        }
        else if (response.data.token1 == "OTTA") {
            document.getElementById("token1imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token1img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
        }
        else if (response.data.token1 == "LpTTFF") {
            document.getElementById("token1imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token1img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
        }
        else if (response.data.token1 == "TTFF") {
            document.getElementById("token1imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token1img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"

        }
        else {
            document.getElementById("token1imginc").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token1img1").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token1image").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
        }



        document.getElementById("token0Name").innerText = response.data.token0
        document.getElementById("token1Name").innerText = response.data.token1

        document.getElementById("tabs-text-1-tab").innerHTML = "&nbsp;&nbsp;" + response.data.token0 + "&nbsp;&nbsp;"
        document.getElementById("tabs-text-2-tab").innerText = response.data.token1

        document.getElementById("token0Name2").innerText = response.data.token0
        document.getElementById("token1Name2").innerText = response.data.token1
        document.getElementById("token0Name4").innerText = response.data.token0
        document.getElementById("token1Name4").innerText = response.data.token1
        document.getElementById("token-1-1-1").innerText = response.data.token0
        document.getElementById("token-1-1-2").innerText = response.data.token1
        document.getElementById("token-2-1-1").innerText = response.data.token0
        document.getElementById("token-2-1-2").innerText = response.data.token1
        document.getElementById("currentpricetoken-1-1-1").innerText = response.data.token0
        document.getElementById("currentpricetoken-1-1-2").innerText = response.data.token1


        document.getElementById("fee").innerText = response.data.fee
        if (response.data.token0NftAmount == 0 || response.data.token1NftAmount == 0) {
            // document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toPrecision(8)
            // document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(7)
            percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
            percentToken0 = 100 - Number(percentToken1)
            if (percentToken0 == 100) {
                document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                document.getElementById("percenttoken1").innerText = percentToken1 + "%"

                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    if ((Number(response.data.token0NftAmount)) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(8)
                    }
                }
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {


                    // token1 amount 
                    if ((Number(response.data.token1NftAmount)) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(8)
                    }
                }
            }
            else if (percentToken1 == 100) {
                document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                document.getElementById("percenttoken1").innerText = percentToken1 + "%"
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    if ((Number(response.data.token0NftAmount)) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(8)
                    }
                }
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    // token1 amount 
                    if ((Number(response.data.token1NftAmount)) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(8)
                    }
                }
            }
            else {
                document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                document.getElementById("percenttoken1").innerText = percentToken1 + "%"
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    if ((Number(response.data.token0NftAmount)) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(8)
                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    if ((Number(response.data.token1NftAmount)) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(8)
                    }
                }

            }
        }
        else {
            // document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toPrecision(8)
            // document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(7)
            percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
            percentToken0 = 100 - Number(percentToken1)
            if (percentToken0 == 100) {
                document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                document.getElementById("percenttoken1").innerText = percentToken1 + "%"

                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {

                    if ((Number(response.data.token0NftAmount)) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(8)

                    }
                }


                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(8)

                    }
                }
            }

            else {
                document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                document.getElementById("percenttoken1").innerText = percentToken1 + "%"
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token0NftAmount)) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token0NftAmount)) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(8)

                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(5)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)

                    }
                    else if ((Number(response.data.token1NftAmount)) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(7)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(8)
                    }
                }
            }
        }
        if (response.data.uncollectedFeeToken0 == 0 || response.data.uncollectedFeeToken1 == 0) {
            document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(8)
            document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(8)

        }
        else {
            document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(8)
            document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(8)


        }
        if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
            document.getElementById("minpricevalue").innerText = "0"
            document.getElementById("maxpricevalue").innerText = "∞"
        }
        else {
            document.getElementById("maxpricevalue").innerText = Number(1 / response.data.minPrice).toFixed(8)
            document.getElementById("minpricevalue").innerText = Number(1 / response.data.maxPrice).toFixed(8)
        }
        document.getElementById("currentprice-1").innerText = (1 / (response.data.currentPrice)).toFixed(8)

        if (Number(document.getElementById("token1Valuefee").innerText) == 0 && Number(document.getElementById("token0Valuefee").innerText) == 0) {
            document.getElementById("collectFee").disabled = true;

        } else {
            document.getElementById("collectFee").disabled = false;

        }

        document.getElementById("tabs-text-2-tab").addEventListener("click", () => {
            document.getElementById("tabs-text-1-tab").className = "nav-link mb-sm-3 mb-md-0  font-xsmall"
            document.getElementById("tabs-text-2-tab").className = "nav-link mb-sm-3 mb-md-0 active  font-xsmall"

            document.getElementById("tabs-text-1-tab").ariaSelected = "false";
            document.getElementById("tabs-text-2-tab").ariaSelected = "true";
            document.getElementById("token0Name").innerText = response.data.token1
            document.getElementById("token1Name").innerText = response.data.token0
            document.getElementById("token0Name2").innerText = response.data.token1
            document.getElementById("token1Name2").innerText = response.data.token0
            document.getElementById("token0Name4").innerText = response.data.token1
            document.getElementById("token1Name4").innerText = response.data.token0
            document.getElementById("token-1-1-1").innerText = response.data.token1
            document.getElementById("token-1-1-2").innerText = response.data.token0
            document.getElementById("token-2-1-1").innerText = response.data.token1
            document.getElementById("token-2-1-2").innerText = response.data.token0
            document.getElementById("currentpricetoken-1-1-1").innerText = response.data.token1
            document.getElementById("currentpricetoken-1-1-2").innerText = response.data.token0


            document.getElementById("fee").innerText = response.data.fee
            if (response.data.token0NftAmount == 0 || response.data.token1NftAmount == 0) {
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token0NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)
                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)
                    }
                    percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
                    percentToken0 = 100 - Number(percentToken1)
                    document.getElementById("percenttoken1").innerText = percentToken0 + "%"
                    document.getElementById("percenttoken0").innerText = percentToken1 + "%"
                }
            }
            else {
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token0NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)
                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)
                    }

                    percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
                    percentToken0 = 100 - Number(percentToken1)
                    document.getElementById("percenttoken1").innerText = percentToken0 + "%"
                    document.getElementById("percenttoken0").innerText = percentToken1 + "%"
                }
            }
            if (response.data.uncollectedFeeToken0 == 0 || response.data.uncollectedFeeToken1 == 0) {
                document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(5)
                document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(5)
                if (document.getElementById("token1Valuefee") == 0 && document.getElementById("token0Valuefee") == 0) {
                    document.getElementById("collectFee").disabled = true;

                } else {
                    document.getElementById("collectFee").disabled = false;

                }
            }
            else {
                document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(5)
                document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(5)
                document.getElementById("collectFee").disabled = false;

            }
            if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                document.getElementById("minpricevalue").innerText = "0"
                document.getElementById("maxpricevalue").innerText = "∞"
            }
            else {
                document.getElementById("minpricevalue").innerText = Number(response.data.minPrice).toFixed(5)
                document.getElementById("maxpricevalue").innerText = Number(response.data.maxPrice).toFixed(5)
            }

            document.getElementById("currentprice-1").innerText = Number(response.data.currentPrice).toFixed(5)

            if (Number(document.getElementById("token1Valuefee").innerText) == 0 && Number(document.getElementById("token0Valuefee").innerText) == 0) {
                document.getElementById("collectFee").disabled = true;

            } else {
                document.getElementById("collectFee").disabled = false;

            }
        })


        document.getElementById("tabs-text-1-tab").addEventListener("click", () => {
            document.getElementById("tabs-text-1-tab").ariaSelected = "true";
            document.getElementById("tabs-text-2-tab").ariaSelected = "false";
            document.getElementById("tabs-text-1-tab").className = "nav-link mb-sm-3 mb-md-0 active font-xsmall"
            document.getElementById("tabs-text-2-tab").className = "nav-link mb-sm-3 mb-md-0   font-xsmall"
            document.getElementById("token0Name").innerText = response.data.token0
            document.getElementById("token1Name").innerText = response.data.token1
            document.getElementById("token0Name2").innerText = response.data.token0
            document.getElementById("token1Name2").innerText = response.data.token1
            document.getElementById("token0Name4").innerText = response.data.token0
            document.getElementById("token1Name4").innerText = response.data.token1
            document.getElementById("token-1-1-1").innerText = response.data.token0
            document.getElementById("token-1-1-2").innerText = response.data.token1
            document.getElementById("token-2-1-1").innerText = response.data.token0
            document.getElementById("token-2-1-2").innerText = response.data.token1
            document.getElementById("currentpricetoken-1-1-1").innerText = response.data.token0
            document.getElementById("currentpricetoken-1-1-2").innerText = response.data.token1


            document.getElementById("fee").innerText = response.data.fee
            if (response.data.token0NftAmount == 0 || response.data.token1NftAmount == 0) {
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token0NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)
                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)
                    }
                    percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
                    percentToken0 = 100 - Number(percentToken1)
                    document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                    document.getElementById("percenttoken1").innerText = percentToken1 + "%"
                }
            }
            else {
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token0NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token0Value").innerText = Number(response.data.token0NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token0NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token0Value").innerText = (Number(response.data.token0NftAmount)).toFixed(6)
                    }
                }
                // token1 amount 
                if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                    document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount) / Math.pow(10, 18)).toPrecision(4)

                }
                else {
                    if ((Number(response.data.token1NftAmount)).toFixed(6) > 99999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(1)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(2)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 999) {
                        document.getElementById("token1Value").innerText = Number(response.data.token1NftAmount).toFixed(3)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 99) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(4)

                    }
                    else if ((Number(response.data.token1NftAmount)).toFixed(6) > 9) {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(5)
                    }
                    else {
                        document.getElementById("token1Value").innerText = (Number(response.data.token1NftAmount)).toFixed(6)
                    }
                    percentToken1 = calculateNftLiquidityPercentageValues(response.data.currentPrice, response.data.token0NftAmount, response.data.token1NftAmount)
                    percentToken0 = 100 - Number(percentToken1)
                    document.getElementById("percenttoken0").innerText = percentToken0 + "%"
                    document.getElementById("percenttoken1").innerText = percentToken1 + "%"
                }
            }
            if (response.data.uncollectedFeeToken0 == 0 || response.data.uncollectedFeeToken1 == 0) {
                document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(5)
                document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(5)
                if (document.getElementById("token1Valuefee") == 0 && document.getElementById("token0Valuefee") == 0) {
                    document.getElementById("collectFee").disabled = true;

                } else {
                    document.getElementById("collectFee").disabled = false;

                }


            }
            else {
                document.getElementById("token0Valuefee").innerText = Number(response.data.uncollectedFeeToken0).toFixed(5)
                document.getElementById("token1Valuefee").innerText = Number(response.data.uncollectedFeeToken1).toFixed(5)
                document.getElementById("collectFee").disabled = false;

            }
            if (response.data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || response.data.maxPrice == 338492131855223780000000000000000000000) {
                document.getElementById("minpricevalue").innerText = "0"
                document.getElementById("maxpricevalue").innerText = "∞"
            }
            else {
                document.getElementById("minpricevalue").innerText = Number(1 / response.data.minPrice).toFixed(5)
                document.getElementById("maxpricevalue").innerText = Number(1 / response.data.maxPrice).toFixed(5)
            }
            document.getElementById("currentprice-1").innerText = (1 / (response.data.currentPrice)).toFixed(5)

            if (Number(document.getElementById("token1Valuefee").innerText) == 0 && Number(document.getElementById("token0Valuefee").innerText) == 0) {
                document.getElementById("collectFee").disabled = true;

            } else {
                document.getElementById("collectFee").disabled = false;

            }
        })

    })




    async function CollectFee(_nftid) {
        var _datanft = {
            "nftId": _nftid,
            "recipient": props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_NFT_CLLFE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datanft)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }


    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: "0x0",
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', console.log)

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', console.log)
    }


    window.addEventListener("load", () => {
        document.getElementById("collectFee").disabled = true;
    })

    nftDetail(nftid).then((data) => {


        // if (data.isClosed == true || data.isClosed == "undefined") {
        //     alert(range)
        //     document.getElementById("imageurl1").src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Disc_Plain_red.svg/1200px-Disc_Plain_red.svg.png"    
        //     range = "Closed" 
        //     document.getElementById("range").innerText=range;
        //     document.getElementById("range2").innerText=range;

        // }
        // else{
        //     if (data.currentPrice > data.minPrice && data.currentPrice < data.maxPrice) {
        //         alert(range)

        //         range = "in Range";
        //         document.getElementById("range").innerText=range;
        //         document.getElementById("range2").innerText=range;
        //         document.getElementById("imageurl1").src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png"   

        //     }
        //     else {
        //         alert(range)

        //         range = "Out Of Range"
        //         document.getElementById("range").innerText=range;
        //         document.getElementById("range2").innerText=range;
        //         document.getElementById("imageurl1").src="https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png" 



        //     }
        // }


        // document.getElementById("token0Name").innerText = data.token0
        // document.getElementById("token1Name").innerText = data.token1
        // document.getElementById("token0Name2").innerText = data.token0
        // document.getElementById("token1Name2").innerText = data.token1
        // document.getElementById("token0Name4").innerText = data.token0
        // document.getElementById("token1Name4").innerText = data.token1
        // document.getElementById("token-1-1-1").innerText = data.token0
        // document.getElementById("token-1-1-2").innerText = data.token1
        // document.getElementById("token-2-1-1").innerText = data.token0
        // document.getElementById("token-2-1-2").innerText = data.token1
        // document.getElementById("currentpricetoken-1-1-1").innerText = data.token0
        // document.getElementById("currentpricetoken-1-1-2").innerText = data.token1


        // document.getElementById("fee").innerText = data.fee
        // if (data.token0NftAmount == 0 || data.token1NftAmount == 0) {
        //     document.getElementById("token0Value").innerText = Number(data.token0NftAmount).toFixed(3)
        //     document.getElementById("token1Value").innerText = Number(data.token1NftAmount).toFixed(3)
        // }
        // else {
        //     document.getElementById("token0Value").innerText = (Number(data.token0NftAmount)).toFixed(4)
        //     document.getElementById("token1Value").innerText = Number(data.token1NftAmount).toFixed(4)
        // }
        // if (data.uncollectedFeeToken0 == 0 || data.uncollectedFeeToken1 == 0) {
        //     document.getElementById("token0Valuefee").innerText = Number(data.uncollectedFeeToken0).toFixed(4)
        //     document.getElementById("token1Valuefee").innerText = Number(data.uncollectedFeeToken1).toFixed(4)

        // }
        // else {
        //     document.getElementById("token0Valuefee").innerText = Number(data.uncollectedFeeToken0).toPrecision(4)
        //     document.getElementById("token1Valuefee").innerText = Number(data.uncollectedFeeToken1).toPrecision(3)

        // }

        // document.getElementById("minpricevalue").innerText = (1 / (data.minPrice)).toPrecision(6)
        // document.getElementById("maxpricevalue").innerText = (1 / (data.maxPrice)).toPrecision(6)

        // document.getElementById("currentprice-1").innerText = (1 / (data.currentPrice)).toPrecision(6)

        // if (Number(document.getElementById("token1Valuefee").innerText) == 0 && Number(document.getElementById("token0Valuefee").innerText) == 0) {
        //     document.getElementById("collectFee").disabled = true;
        //     console.log("iksi de 0 oldu")

        // } else {
        //     document.getElementById("collectFee").disabled = false;
        //     console.log("iksinden biri  0 değil ");

        // }
    })



    return (
        <section className='responsive' >

            <div className="row justify-content-start pt-7"  >
                {/*  component start  */}
                <div className="col-12 col-lg-5 " >
                    <div >

                        <div className="row justify-content-start" >
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className=" col-12 ml-10 pt-2 text-center" >
                                {/* <img className="" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="20px"></img>&nbsp; */}

                                <img className="rounded token1 " id="token0imginc" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="15px" width="15px" alt="ice museum" />

                                <img className="rounded token2  " id="token1imginc" src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/TaumTokenlogo1.png" height="15px" width="15px" alt="ice museum" />


                                <span className=" font-xlarge  text-right   ">

                                    <span className="  font-xlarge   " id="token0Name">  -</span>
                                    <span className=" font-xlarge  ">    /</span>
                                    <span className="  font-xlarge   " id="token1Name">   -</span>
                                </span> &nbsp;
                                <span className=" font-xlarge " id="fee" > 0%</span> &nbsp;&nbsp;
                                <img className="image-xs font-medium" id="imageurl1 " style={divstyledot} src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Disc_Plain_red.svg/1200px-Disc_Plain_red.svg.png" />    &nbsp;
                                <span className="font-xlarge" id="range" >{range}</span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 ">

                    <div className="row  py-4" >
                        <div className="col-12 col-md-12 col-lg-10">
                            <div>
                                <div className="pt-0">

                                    <div className="row justify-content-end ">
                                        <Link to={increase}> <Button className="btn btn-pill  btn-yotta21 font-base div-yotta21" ><i className="fas fa-plus " style={divstye3} ></i> Likiditeyi Arttırın </Button>
                                            &nbsp;&nbsp; &nbsp;&nbsp;
                                        </Link>
                                        <Link to={decrease}>
                                            <Button className="btn btn-pill  btn-yotta21 font-base div-yotta21"  ><i className="fas fa-minus " style={divstye3} ></i> Likiditeyi Kaldır </Button>
                                        </Link>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div className="row justify-content-center mb-1 pt-0 py-0 " >
                {/* Component to Card start */}
                <div className="col-8 col-md-8 col-lg-4 mb-4 mb-lg-0 pt-0 border border-black" id="pool2" >
                    <br></br>
                    <div className="card-tabs  border-light p-4 rounded div-white " id="pool" >
                        <div className="card-header-tabs   pb-0" >
                            <h2 className="font-medium">Likidite</h2>
                        </div>
                        <div className="card-body-tabs pt-0 " id="pool">

                            <div className="mt-3 mb-4 " id="pool">
                                <span className=" font-base ">&nbsp;</span>
                                <span className=" font-base">&nbsp;</span>
                            </div>
                            {/* djadjskajkdk */}
                            <div className="row pt-0 p-0 pl-0 " >
                                <div className="card-tabs card-body-tabs col-12 p-0 mt-0 border border-black rounded div-white " id="pool"  >
                                    <div className="row justify-content-start pt-2" >
                                        {/*  component start  */}
                                        <div className=" col-lg-7 text-left " >

                                            <div className="rowtext-left">
                                                &nbsp;&nbsp;
                                                <img className="image-xs " id="token0img1" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img>&nbsp;
                                                <span className="  font-base   ">
                                                    <span className="  font-base   " id="token0Name2">  -</span>
                                                </span>

                                            </div>
                                            <br />
                                            <div className="rowtext-left">
                                                &nbsp;&nbsp;

                                                <img className="image-xs " id="token1img1" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img>&nbsp;
                                                <span className=" font-base   ">
                                                    <span className="  font-base   " id="token1Name2">  -</span>
                                                </span>

                                            </div>

                                        </div>
                                        <div className="col-12 col-lg-5  ">
                                            <div className="row  justify-content-center text-right py-1" >
                                                <span id="token0Value">0,0</span>
                                                &nbsp;

                                                <span id="percenttoken0" className="badge badge-dark div-white">0%</span>


                                            </div>
                                            <div className="row justify-content-center text-right py-4" >
                                                <span id="token1Value">0,0</span>
                                                &nbsp;
                                                <span id="percenttoken1" className="badge badge-dark">0%</span>


                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* djadjskajkdk */}

                            </div>

                        </div>
                    </div>
                    <br></br>
                    <div className="card-tabs   border-light p-4 pt-0 rounded div-white" id="pool"  >
                        <div className="   p-2 mb-2 pt-0 "  >
                            <div className="row " >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6  " >
                                    <div >
                                        <div className="row">

                                            <div className=" col-12 ml-12 pb-0 p-2" >
                                                <h5 className="font-medium"> Talep edilmemiş ücretler
                                                </h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <button type="button" id="modal-from" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0" data-toggle="modal" data-target="#modal-form" */}
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-8 col-lg-12">
                                            <div className="row justify-content-end">
                                                <Button className=" btn btn-pill  btn-yotta21 font-base div-yotta21" id="collectFee" data-toggle="modal" data-target="#modal-form" onClick={() => {
                                                    document.getElementById("token0sum").innerText = document.getElementById("token0Name4").innerText
                                                    document.getElementById("token1sum").innerText = document.getElementById("token1Name4").innerText

                                                    document.getElementById("value0sum").innerText = document.getElementById("token0Valuefee").innerText
                                                    document.getElementById("value1sum").innerText = document.getElementById("token1Valuefee").innerText
                                                }}>

                                                    <a className=" pt-2">Fee Topla</a> &nbsp;
                                                    <img src="https://storageapi.fleek.co/deneme-team-bucket/share2.svg " height="20px" style={divstye3} />
                                                </Button>


                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="modal fade" id="modal-form" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body p-0">
                                                <div className="card  shadow-soft border-light  p-4 div-white " >
                                                    <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" id="close">×</span>
                                                    </button>
                                                    <div className="card-header  text-center pb-0 pt-0">
                                                        <h2 className="h4 font-medium text-center pt-0">Fee Topla</h2>
                                                    </div>


                                                    <div className="card-body text-left pb-0 pt-0 font-smedium py-2 ">
                                                        <div className="row justify-content-center pt-0 ">

                                                            <div className="col-10 col-sm-10  pt-0 border border-light rounded div-white" >

                                                                <div className=" col-12 col-lg-12 text-left  font-small " >
                                                                    <output className=" col-lg-5 text-left font-small py-2">
                                                                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"  height="20px" />

                                                                        <a className="font-small pt-2 " id="token0sum">-</a>

                                                                    </output>
                                                                    <output className="col-lg-6 text-right"  >
                                                                        <a className="text-right" id="value0sum">0,0
                                                                        </a>
                                                                    </output>

                                                                    <output className=" col-lg-5 text-left font-small py-2">
                                                                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"  height="20px" />

                                                                        <a className="font-small" id="token1sum">-</a>
                                                                    </output>
                                                                    <output className="col-lg-6 text-right font-small" >
                                                                        <a className="text-left" id="value1sum">0,0</a>

                                                                    </output>


                                                                </div>


                                                            </div>
                                                        </div>




                                                        <button type="button" id="modal-from" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0 text-center" data-toggle="modal" data-target="#modal-form" onClick={() => {
                                                            CollectFee(nftid).then((data) => {
                                                                sendHashoriginal(data.to, data.methodABI, data.gasEstimate)
                                                            });
                                                        }} >
                                                            Topla
                                                        </button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Liqudity Ekleme eski hali */}

                                </div>




                                {/* <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-8 col-lg-12">
                                                    <div className="row justify-content-end">
                                                        <Button className=" btn btn-pill  btn-yotta21 font-base div-yotta21"  id="collectFee" onClick={() => {
                                                            CollectFee(nftid).then((data) => {
                                                                console.log("collect fee data", data);
                                                                sendHashoriginal(data.to, data.methodABI, data.gasEstimate)
                                                            });
                                                        }}>

                                                            <a className=" pt-2">Collect fees</a> &nbsp;
                                                            <img src="https://storageapi.fleek.co/deneme-team-bucket/share2.svg " height="20px" style={divstye3} />
                                                        </Button>

                                                
                                                </div>

                                        </div>

                                    </div>

                                </div> */}
                            </div>
                        </div>

                        <div className="card-body-tabs pt-0">


                            {/* djadjskajkdk */}
                            <div className="row pt-0 p-0 pl-0 " >
                                <div className="card-tabs card-body-tabs col-12 p-0 mt-0  border border-black rounded div-white" id="pool" >
                                    <div className="  row justify-content-start pt-2 " >
                                        {/*  component start  */}
                                        <div className="  col-lg-7 text-left pt-2 ">

                                            <div className="rowtext-left py-3">
                                                &nbsp;&nbsp;
                                                <img className=" " src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token0image" height="25px"></img>&nbsp;
                                                <span className="  font-base   ">
                                                    <span className="  font-base   " id="token0Name4">  -</span>

                                                </span>

                                            </div>
                                            <div className="row text-left py-1">
                                                &nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;

                                                <img className=" " src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token1image" height="25px"></img>&nbsp;
                                                <span className="  font-base   ">
                                                    <span className="  font-base   " id="token1Name4">  -</span>

                                                </span>

                                            </div>

                                        </div>
                                        <div className=" col-12 col-lg-5 ">
                                            <div className="row text-right justify-content-center py-3 " >
                                                <span id="token0Valuefee">0</span>&nbsp;


                                            </div>
                                            <div className="row text-right justify-content-center py-1" >
                                                <span id="token1Valuefee">0</span>&nbsp;


                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* djadjskajkdk */}

                            </div>

                        </div>
                    </div>
                </div>
                &nbsp;
                <div className="col-12 col-lg-4 pt-0 border border-black  " id="pool2">
                    <div className="row pt-0  " >
                        <div className="col-md-12 col-lg-12 pt-0 ">
                            {/* <!-- Tab Nav --> */}
                            <div className="  mb-0 pt-0 ">
                                <br />
                                {/* <ul className="nav nav-pills flex-column flex-sm-row justify-content-start pt-0 mb-0 pb-0" id="tabs-text" role="tablist">

                                    <li className="nav-item">

                                        <a className="" > Price Range
                                        </a> &nbsp;
                                        <span style={divstyledot}></span>
                                        <span className="font-base" > In range</span>
                                    </li>
                                </ul> */}

                            </div>

                            {/* <!-- End of Tab Nav --> */}
                            {/* <!-- Tab Content --> */}
                            <div className="card-tabs border-light p-3 rounded mb-3 div-white " id="pool"> {/*shadow-inset-card için 3 boyutlu içine çökükmüş gibi gösteriyor*/}
                                <div className="card-body-tabs p-0 div-white" id="pool">
                                    <div className="tab-content" id="tabcontent1">
                                        <div className="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
                                            {/* ds */}
                                            <ul className="nav nav-pills flex-column flex-sm-row justify-content-start pt-0 mb-0 pb-0  col-lg-10" id="tabs-text" role="tablist">

                                                <li className="nav-item">


                                                    <a className="" > Fiyat Aralığı
                                                    </a> &nbsp;

                                                </li>
                                            </ul>
                                            <ul className="nav nav-pills flex-column flex-sm-row justify-content-end  " id="tabs-text" role="tablist">



                                                <a className="nav-link mb-sm-3 mb-md-0 active font-xsmall div-white" id="tabs-text-1-tab" data-toggle="tab" href="#tabs-text-1" role="tab" aria-controls="tabs-text-1" aria-selected="true" onClick={() => {

                                                }} >-</a> &nbsp;
                                                <a disabled className="nav-link mb-sm-3 mb-md-0 font-xsmall div-white" id="tabs-text-2-tab" data-toggle="tab" href="#tabs-text-2" role="tab" aria-controls="tabs-text-2" aria-selected="false" onClick={() => {

                                                }}>

                                                    &nbsp;  -&nbsp;&nbsp;</a>
                                            </ul>

                                            <div className="   p-2 mb-2  " >
                                                <div className="row" >
                                                    <div className="col-12 col-lg-12 card-tags card-headers-tabs pt-0  " id="pool" >
                                                        <div className="row "  >

                                                            <div className="col-12 col-md-12 col-lg-12   " >
                                                                <div>
                                                                    <div className="card-body-tabs " id="pool">
                                                                        <br />

                                                                        <div className="row justify-content-center  " id="pool">
                                                                            {/* jeton1 gelecek */}
                                                                            <button type="button" value="WETH" className=" border border-black rounded div-white col-md-10 py-2" id="btnselect" style={divstye3}>
                                                                                <a id="minprice" className="font-small text-center "  >Min. Price </a>
                                                                                <br />
                                                                                <a className="col-md-10 font-small pt-2" id="minFiyatvalue" style={divstye3} disabled >0,0</a>

                                                                                <br />
                                                                                <a className="font-xsmall " id="token-1-1-1">- </a>
                                                                                <a className="font-xsmall "> / </a>
                                                                                <a className="font-xsmall " id="token-1-1-2"> -</a>
                                                                                <br />


                                                                            </button>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>

                                                    <div className=" col-12 row justify-content-center p-0 pt-2 m-0 div-white" id="pool">
                                                        <img src="https://img.icons8.com/small/32/000000/sorting-arrows.png" height="25px" width="25px  " />
                                                    </div>

                                                    <div className="col-12 col-lg-12 card-tags pt-0 pt-2">
                                                        <div className="row " >
                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                <div>
                                                                    <div className="card-body-tabs" id="pool" >

                                                                        <div className="row justify-content-center">
                                                                            {/* jeton1 gelecek */}
                                                                            <button type="button" value="WETH" className="border border-black rounded div-white col-md-10 py-2" id="btnselect" style={divstye3}>
                                                                                <a id="minprice" className="font-small col-md-10 "  >Max. Fiyat</a>
                                                                                <br />
                                                                                <a className="col-md-10 font-small" id="maxpricevalue" style={divstye3} disabled >0,0</a>

                                                                                <br />
                                                                                <a className="font-xsmall " id="token-2-1-1">- </a>
                                                                                <a className="font-xsmall "> / </a>
                                                                                <a className="font-xsmall " id="token-2-1-2"> -</a>
                                                                                <br />


                                                                            </button>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className=" col-12 col-lg-12 pt-4">
                                                        <div className="row justify-content-center" >
                                                            <div className="col-11 col-md-12 col-lg-11  py-4">
                                                                <div>
                                                                    <div className="card-tabs card-body-tabs border border-black     rounded  div-white" id="pool" >

                                                                        <div className="row justify-content-end">
                                                                            {/* jeton2 gelecek */}
                                                                            <button type="button" value="WETH" className="border-0 col-md-12 mb-1 div-white" id="btnselect" style={divstye3}>
                                                                                <a className="font-small"  >  Mevcut Fiyat</a>&nbsp;&nbsp;
                                                                                <br />

                                                                                <a className="font-small " id="currentprice-1">0.0</a>

                                                                                <br />
                                                                                <a className="font-xsmall" id="currentpricetoken-1-1-1">- </a>
                                                                                <a className="font-xsmall"> / </a>
                                                                                <a className="font-xsmall" id="currentpricetoken-1-1-2"> - </a>
                                                                            </button>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <!-- End of Tab Content --> */}
                        </div>
                    </div>

                </div>
            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi 

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>
        </section>
    );

}

export default Pool;