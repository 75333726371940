import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import BigInt from "big-integer";
import { Bgtech, Ecosys, Hybrid, ttfAddress, wethAddress } from '../protocolAdress'
import yotta21logo from "../../assets/img/yotta21-logo21.png"













function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

Modal.setAppElement("#index");
var slippage = 0.5
var deadlineMinute = 30

const display = {
    display: 'none ',
}

const divstye3 = {
    backgroundColor: "transparent"

}




function TtfBuy(props) {


    const { tokenid } = useParams();
    const { tokenname } = useParams();




    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);


    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }

    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }
    function toggleModal4() {
        setIsOpen4(!isOpen4);
    }

    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    // Show 4 characters after the balance point
    var blnc1 = props.balance / 1000000000000000000;
    var blnc = Number(blnc1).toFixed(4);

    //transaction data
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', end => {
            if (_transactiontype == "approve") {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = true;

                }



            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;

                }
            }
        })
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approve") {
                console.log("Approve Başarılı oldu.");
                document.getElementById("btn-approve").disabled = true;
                document.getElementById("btn-approve").innerText = "işleminiz Onaylandı";
                console.log(end);

                CalculatingFRomValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").textContent, document.getElementById("to").innerText, 1);
                CalculatingToValue(document.getElementById("tokenid").value);
            }
            else {

                console.log(end);
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;

                }
            }

        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            if (document.getElementById("btn-approve") != null) {
                document.getElementById("btn-approve").disabled = false;

            }
            console.log(end);

        })
        emitter.on('txFailed', end => {
            console.log(end);
        })
        if (document.getElementById("btn-approve") != null) {
            document.getElementById("btn-approve").disabled = false;
        }

        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }


    //transaction data model 1
    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', console.log)

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', console.log)
    }


    //function that performs swap
    //TODO slippagePercentage EKLENECEK
    async function Swap(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        const _dataswap = {
            isEth: false,
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "TTFF"],

        }
        const data = JSON.stringify(_dataswap)
        console.log("data?", _dataswap)
        let result;
        return result = await new Promise((resolve, reject) => {
            fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_dataswap)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                    console.log("veri?", ver)
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingFRomValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        let value;

        document.getElementById("priceRangeMax1").value = null;
        document.getElementById("priceRangeMax2").value = null;
        value = 0.0;
        var _datacalfrom = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "bgtech"],



        }
        fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalfrom),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                const captureValue = function (e) {
                    value = e.target.value * (Number(ver.price).toPrecision(8))
                    document.getElementById("priceRangeMax2").value = toFixed(value)


                    if (Number(document.getElementById("balanceid").innerText) < Number((value))) {

                        document.getElementById("btn_con").innerText = "Insufficient Balance..."
                        document.getElementById("btn_con").disabled = true;

                    }
                    else {

                        document.getElementById("btn_con").disabled = false;
                        document.getElementById("btn_con").innerText = "Devam"
                    }
                    value = 0;

                    balance(document.getElementById("fromid").value, document.getElementById("fromid").innerText).then((ver) => {

                        // if (Number(ver) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        //     if (document.getElementById("btn-approve") == null) {
                        //         document.getElementById("btn_con").disabled = true;
                        //     }
                        //     document.getElementById("btn_con").disabled = true;
                        //     document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."

                        // }
                        // else {
                        // if (Number(document.getElementById("balanceid").value) < Number(toFixed(value))) {
                        //     alert("dsdssaddsa")
                        //     document.getElementById("btn_con").disabled = true;
                        //     document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."
                        // }
                        // else {

                        //     document.getElementById("btn_con").disabled = false;
                        //     document.getElementById("btn_con").innerText = "Devam"
                        // }



                        // }
                    });



                }


                document.getElementById("priceRangeMax1").addEventListener("input", captureValue);



            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })

    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingToValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        let value;

        document.getElementById("priceRangeMax1").value = null;
        document.getElementById("priceRangeMax2").value = null;
        value = 0.0;
        var _datacalto = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "bgtech"],


        }
        fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalto),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                const captureValue = function (e) {

                    var price = Number(ver.price).toPrecision(8)
                    value = (e.target.value) * (1 / (price))

                    document.getElementById("priceRangeMax1").value = (toFixed(value))
                    value = 0;

                    if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        if (document.getElementById("btn-approve") == null) {

                            document.getElementById("btn_con").disabled = true;
                        }

                        document.getElementById("btn_con").disabled = true;
                        document.getElementById("btn_con").innerText = "Insufficient Balance..."

                    }
                    else {
                        //     if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax2").value)) {

                        //         document.getElementById("btn_con").disabled = true;
                        //         document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."
                        //     }
                        //     else {

                        document.getElementById("btn_con").disabled = false;
                        document.getElementById("btn_con").innerText = "Devam"
                    }



                    // }

                }


                document.getElementById("priceRangeMax2").addEventListener("input", captureValue);



            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })


    }

    function walletControl() {
        //document.getElementById("fromid").innerText = tokenname;
        document.getElementById("btn_con").disabled = true
        if (props.address == null) {

            document.getElementById("btn_con").innerText = "Cüzdan Bağlayınız"

        }
        else {
            document.getElementById("btn_con").innerText = "Devam"
            document.getElementById("btn_con").disabled = true


        }
    }

    // useEffect(() => {
    //     document.getElementById("table_ttff").style.display = "none"
    //     document.getElementById("table_bigtech").style.display = "none"
    //     document.getElementById("table_hybrid").style.display = "none"
    //     document.getElementById("table_eko").style.display = "none"
    // }, [])


    useEffect(() => {

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        walletControl()

    })


    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-light rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Values out of range"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }

    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-light rounded div-white form-control"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("danger").innerText = ""

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Values out of range"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }

    async function swapprocess() {
        Swap(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "bgtech", document.getElementById("tokenid").textContent, /*document.getElementById("fromid").innerText*/ Number(document.getElementById("toContent").innerText).toFixed(18)).then(ver => {


            //weth balance hesaplama
            balance(ttfAddress, "TTFF").then((ver) => {
                document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
            })

            sendHash(ver.to, ver.data, Number(ver.gasEstimate), "swap");


        })

        //function to add ttf Token to metamask
        const tokenAddress = ttfAddress;
        const tokenSymbol = 'TTFF';
        const tokenDecimals = 18;
        const tokenImage = 'https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png';

        if (props.provider.connection.url == "metamask") {
            await balance(ttfAddress, "TTFF").then(async (ver) => {
                if (Number(ver) == 0) {
                    const wasAdded = await window.ethereum.request({
                        method: 'wallet_watchAsset',
                        params: {
                            type: 'ERC20', // Initially only supports ERC20, but eventually more!
                            options: {
                                address: tokenAddress, // The address that the token is at.
                                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                decimals: tokenDecimals, // The number of decimals in the token
                                image: tokenImage, // A string url of the token logo
                            },
                        },
                    });
                }
            })

        }


        toggleModal2()
    }

    return (
        <section id="root pt-3">
            <div className="row mb-5 justify-content-center pt-5  " >
                <h4 className=" col-12 col-lg-12 text-center" id="title"><strong>Dao21NFT</strong> </h4>
               
                <div className="  justify-content-center col-12 col-md-12 col-lg-auto mb-5 mb-lg-0 pt-0">
                    <div className="  border-light p-3" id="divcard" >

                        {/* to component start */}
                       
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light  ">
                                        {/* Swap ÖZeti */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0  py-1" >
                                                <div className="App" >
                                                    <button type="button" value="WETH" className="btn col-md-12 font-base " id="btnselect" onClick={() => {

                                                        balance(wethAddress, "WETH").then((ver) => {
                                                            document.getElementById("Daibalance").innerText = Number(ver).toFixed(5) + " WETH";
                                                            document.getElementById("fromid").value = ttfAddress;

                                                        });




                                                        // document.getElementById("btn_con").disabled = true


                                                        toggleModal();


                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://img.icons8.com/small/32/000000/sorting-arrows.png" width="0px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Token Seçiniz" >Token Seçiniz  </a>&nbsp;&nbsp;
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                    </button>
                                                    <br></br>
                                                    <br></br>

                                                    <div className="row pt- m-0">&nbsp;&nbsp;&nbsp;
                                                        <a className="font-small text-bold text-left">Bakiye:   </a> &nbsp;
                                                        <a className="font-small text-bold" id="balanceid">0,0 </a>

                                                    </div>

                                                    {/* Token select Modal Start */}

                                                    <Modal
                                                        isOpen={isOpen}
                                                        onRequestClose={toggleModal}
                                                        contentLabel="My dialog"
                                                        className="mymodal"
                                                        overlayClassName="myoverlay"
                                                        closeTimeoutMS={500}
                                                    >

                                                        <div className="card-header border-bottom text-center ">
                                                            <span className="d-block">
                                                                <span className="display-3 font-weight-bold">
                                                                    Token Listesi
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="card-body p-2 col-mg-12">
                                                            <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                                            <br></br><br></br>
                                                            <ListGroup>


                                                                {/* Token WETH Selected start  */}

                                                                <ListGroupItem tag="button" action onClick={() => {
                                                                    document.getElementById("imageId").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg";
                                                                    document.getElementById("imageId").style.width = "25px";

                                                                    document.getElementById("tokenid").innerText = "WETH";
                                                                    document.getElementById("tokenid").value = wethAddress



                                                                    balance(wethAddress, "WETH").then((ver) => {
                                                                        document.getElementById("balanceid").innerText = Number(ver).toFixed(5);
                                                                    });

                                                                    //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                                                                    document.getElementById("priceRangeMax2").disabled = true;
                                                                    document.getElementById("priceRangeMax1").disabled = true;

                                                                   
                                                                   

                                                                    toggleModal()
                                                                }}>
                                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="35px"></img>
                                                                    <a className="container">WETH</a>

                                                                    <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                        <div className="post-details mb-3 mb-lg-0">
                                                                            <span className="d-block font-small">Wrapped Ethereum </span>
                                                                        </div>
                                                                        <div className="post-meta">
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <span className="font-small">Bakiye: </span>

                                                                            <span className="justify-content-between font-small" id="Daibalance"></span>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>


                                                            </ListGroup>
                                                        </div>

                                                    </Modal>
                                                    {/* token select modal end */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row py-1" >
                                        <div className="col-12 col-md-6 col-lg-12 ">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">

                                                        <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>


                                                            <input className="form-control div-white" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* to component end */}


                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-2 pt-0 div-white" >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light  ">
                                        <div className="row">
                                            <div className=" col-12 ml-12 pb-2 " >
                                                <div className="App" >
                                                    <button type="button" value={ttfAddress} className=" btn col-md-12 font-base " style={divstye3} onClick={() => {

                                                        toggleModal4();
                                                      
                                                
                                                    }}  >
                                                        <img id="imageidfrom" src="https://img.icons8.com/small/32/000000/sorting-arrows.png" width="0px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="fromid" value="token">Token Select </a>
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                    </button>
                                                    <br></br>
                                                    <br></br>

                                                    <div className="row justify-content-left pt- m-0">&nbsp;&nbsp;&nbsp;
                                                        <a className="font-small text-bold text-left ">  Bakiye: </a>&nbsp;
                                                        <a id="frombalance" className="font-small text-bold"> 0,0</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">

                                                        <div className="text-right col-12  pb-4 " id="text-right" >


                                                            <input className="form-control div-white" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false"
                                                            />
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* From componet end */}
                        {/* Token select Modal Start */}

                        <Modal
                            isOpen={isOpen4}
                            onRequestClose={toggleModal4}
                            contentLabel="My dialog"
                            className="mymodal"
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                        >

                            <div className="card-header border-bottom text-center ">
                                <span className="d-block">
                                    <span className="display-3 font-weight-bold">
                                        Token Listesi
                                    </span>
                                </span>
                            </div>
                            <div className="card-body p-2 col-mg-12">
                                <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                <br></br><br></br>
                                <ListGroup>


                                    {/* Token TTFF Selected start  */}

                                    <ListGroupItem tag="button" action onClick={() => {
                                        document.getElementById("imageidfrom").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png";
                                        document.getElementById("imageidfrom").style.width = "25px";

                                        document.getElementById("fromid").innerText = "Dao21NFT"
                                        document.getElementById("fromid").ariaValueText = ttfAddress

                                       

                                        document.getElementById("title").innerText = "Dao21NFT"




                                      

                                        //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                                        document.getElementById("priceRangeMax2").disabled = true;
                                        document.getElementById("priceRangeMax1").disabled = true;

                                      

                                        toggleModal4()
                                    }}>
                                        <img src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png" width="35px"></img>
                                        <a className="container">Dao21NFT</a>

                                        <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                            <div className="post-details mb-3 mb-lg-0">
                                                <span className="d-block font-small">Dao21NFT </span>
                                            </div>
                                            <div className="post-meta">
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <span className="font-small">Bakiye: </span>

                                                <span className="justify-content-between font-small" id="TTFFbalance"></span>
                                            </div>
                                        </div>
                                    </ListGroupItem>

                                
                                </ListGroup>
                            </div>

                        </Modal>
                        {/* token select modal end */}

                        <div id="approvediv"></div>
                        <button type="button" id="btn_con" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                            onClick={() => {
                                


                                // token Değeri yazdırma

                                document.getElementById("fromid").value = ttfAddress;

                                toggleModal2()


                            }} >
                            Devam
                        </button>
                    </div>
                    <Modal
                        isOpen={isOpen2}
                        onRequestClose={toggleModal2}
                        contentLabel="My dialog"
                        className="mymodal-ttff" /*"modal-dialog modal-dialog-centered rounded"*/
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        aria-hidden="true"
                    >
                        {/* <!-- Modal Content --> */}




                        <div className="" id="ttffsum" >
                            <button type="button" className=" pt-0 close ml-auto ">
                                <span id="close" onClick={() => {

                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null

                                }}>×</span>
                            </button>
                            <div className="card-header  text-center pb-0 pt-0">
                                <h2 className="h4 font-medium text-center pt-0"><strong>Transaction Summary</strong></h2>
                            </div>
                            <div className="card-body text-center pb-0 pt-0 font-smedium ">
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white"   >
                                        {/* <div className="text-left">From</div> */}
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">

                                            <output className="text-left  font-base ">
                                                <img id="ToImageContent" src={yotta21logo} height="26px" />

                                                <a id="to" className="font-base py-2  col-lg-4 ">WETH</a>
                                            </output>
                                            <output className="col-lg-9 text-right font-base" >
                                                <a className="text-right" id="toContent"></a>
                                            </output>
                                        </div>

                                    </div>

                                </div>
                                <br />
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >
                                        {/* <div className="text-left">To</div> */}

                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">
                                            <output className="text-left font-base">
                                                <img id="fromImageContent" src={yotta21logo} height="35px" />

                                                <a className="font-base col-lg-5" id="from"></a>
                                            </output>

                                            <output className="col-lg-8 text-right font-base" >
                                                <a id="fromContent" className="text-right"></a>
                                            </output>
                                        </div>

                                    </div>

                                </div>

                                {/* <div className="row  ">

                                                <div className="col-12 col-sm-12 mt-12 pt-0">

                                                    <div className=" col-12 col-lg-12 text-left pt-0 font-small " >
                                                        <output className="text-left font-small">  Fiyat
                                                        </output>
                                                        <output className="col-lg-11 text-right font-small" >
                                                            <a className="text-right"><a>1</a> <a id="tokenname">ETH</a> = <a id="tokenvalue">55555.5555</a></a>
                                                        </output>
                                                    </div>

                                                </div>
                                            </div> */}


                                <div className="row pt-4  ">

                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded">

                                        <div className=" col-12 col-lg-12 pt-0 py-2 font-small " >
                                            <output className=" col-lg-2 text-left font-small">  Price
                                            </output>

                                            <output className="col-lg-9 text-right font-small" >
                                                <a className="text-right"><a>1</a> <a id="tokenname">Dao21NFT</a> = <a id="tokenvalue">0.00</a></a>
                                            </output>
                                        </div>

                                    </div>
                                </div>
                                <br />

                                <div className="row justify-content-start pt-0 ">

                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >

                                        <div className=" col-12 col-lg-12 text-left pt-2 font-small " >
                                            {/* <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-hand-holding-usd"></i>                                                            &nbsp;
                                                Likidite  Ücreti

                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-right" id="liqudity">
                                                </a>
                                            </output> */}
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-hand-holding-usd"></i>                                                            &nbsp;
                                                Management Fee

                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-right" id="managamentfee">0,05
                                                </a>
                                            </output>

                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-route"></i>
                                                &nbsp;
                                                Route
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="route"></a>

                                            </output>
                                            {/* <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-exchange-alt"></i>
                                                &nbsp;
                                                Swap Source
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="swapsource"></a>

                                            </output> */}

                                            <output className=" col-lg-7 text-left font-small" >
                                                <i className="fas fa-coins"></i>
                                                &nbsp;
                                                Minimum Received
                                            </output>
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="minReceived">
                                                </a>
                                            </output>
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-skiing"></i>
                                                &nbsp;
                                                Slippage Tolerance
                                            </output>

                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="slippagePercentage">
                                                </a>
                                            </output>
                                        </div>


                                    </div>
                                </div>

                            </div>
                            <br></br>

                            <button className='btn col-lg-12' id="swappro" action onClick={() => {

                                swapprocess()
                                document.getElementById("priceRangeMax1").value = null
                                document.getElementById("priceRangeMax2").value = null
                                var x = document.getElementById("snackbartottffbuy");
                                x.className = "show";
                                setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);

                            }}>Swap</button>

                        </div>



                    </Modal>
                </div>

                <div id="snackbartottffbuy">İşlem Cüzdanızıa Gönderildi...</div>


            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>

        </section>

    );

}

export default TtfBuy
