import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import BigInt from "big-integer";
import { Bgtech, Ecosys, Hybrid, ttfAddress, wethAddress } from '../protocolAdress'
import yotta21logo from "../../assets/img/yotta21-logo21.png"



// Ekosistem fonu/
import Uniswap from "../../assets/img/TTFFgörsel/Hybrid Fonu/Payment-DAİ.png"
import ageur from "../../assets/img/TTFFgörsel/15024.png"









function eToNumber(num) {
    let sign = "";
    (num += "").charAt(0) == "-" && (num = num.substring(1), sign = "-").toString();
    let arr = num.split(/[e]/ig);
    if (arr.length < 2) return sign + num;
    let dot = (.1).toLocaleString().substr(1, 1),
        n = arr[0],
        exp = +arr[1];
    let w = (n = n.replace(/^0+/, "")).replace(dot, ""),
        pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp,
        L = pos - w.length,
        s = "" + BigInt(w);
    w = exp >= 0 ? (L >= 0 ? s + "0".repeat(L) : r()) : (pos <= 0 ? "0" + dot + "0".repeat(Math.abs(pos)) + s : r());
    if (!+w) w = 0;
    return sign + w;

    function r() {
        return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`)
    }
}


function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

Modal.setAppElement("#index");
var slippage = 0.5
var deadlineMinute = 30

const display = {
    display: 'none ',
}

const divstye3 = {
    backgroundColor: "transparent"

}




function TtfBuy(props) {


    const { tokenid } = useParams();
    const { tokenname } = useParams();




    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);


    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }

    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }
    function toggleModal4() {
        setIsOpen4(!isOpen4);
    }

    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    // Show 4 characters after the balance point
    var blnc1 = props.balance / 1000000000000000000;
    var blnc = Number(blnc1).toFixed(4);

    //transaction data
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', end => {
            if (_transactiontype == "approve") {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = true;

                }



            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;

                }
            }
        })
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approve") {
                console.log("Approve Başarılı oldu.");
                document.getElementById("btn-approve").disabled = true;
                document.getElementById("btn-approve").innerText = "işleminiz Onaylandı";
                console.log(end);

                CalculatingFRomValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").textContent, document.getElementById("to").innerText, 1);
                CalculatingToValue(document.getElementById("tokenid").value);
            }
            else {

                console.log(end);
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;

                }
            }

        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            if (document.getElementById("btn-approve") != null) {
                document.getElementById("btn-approve").disabled = false;

            }
            console.log(end);

        })
        emitter.on('txFailed', end => {
            console.log(end);
        })
        if (document.getElementById("btn-approve") != null) {
            document.getElementById("btn-approve").disabled = false;
        }

        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }


    //transaction data model 1
    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', console.log)

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', console.log)
    }


    //function that performs swap
    //TODO slippagePercentage EKLENECEK
    async function Swap(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        const _dataswap = {
            isEth: false,
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "TTFF"],

        }
        const data = JSON.stringify(_dataswap)
        console.log("data?", _dataswap)
        let result;
        return result = await new Promise((resolve, reject) => {
            fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_dataswap)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                    console.log("veri?", ver)
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingFRomValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        let value;

        document.getElementById("priceRangeMax1").value = null;
        document.getElementById("priceRangeMax2").value = null;
        value = 0.0;
        var _datacalfrom = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "bgtech"],



        }
        fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalfrom),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                const captureValue = function (e) {
                    value = e.target.value * (Number(ver.price).toPrecision(8))
                    document.getElementById("priceRangeMax2").value = toFixed(value)


                    if (Number(document.getElementById("balanceid").innerText) < Number((value))) {

                        document.getElementById("btn_con").innerText = "Yetersiz Bakiye"
                        document.getElementById("btn_con").disabled = true;

                    }
                    else {

                        document.getElementById("btn_con").disabled = false;
                        document.getElementById("btn_con").innerText = "Devam"
                    }
                    value = 0;

                    balance(document.getElementById("fromid").value, document.getElementById("fromid").innerText).then((ver) => {

                        // if (Number(ver) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        //     if (document.getElementById("btn-approve") == null) {
                        //         document.getElementById("btn_con").disabled = true;
                        //     }
                        //     document.getElementById("btn_con").disabled = true;
                        //     document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."

                        // }
                        // else {
                        // if (Number(document.getElementById("balanceid").value) < Number(toFixed(value))) {
                        //     alert("dsdssaddsa")
                        //     document.getElementById("btn_con").disabled = true;
                        //     document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."
                        // }
                        // else {

                        //     document.getElementById("btn_con").disabled = false;
                        //     document.getElementById("btn_con").innerText = "Devam"
                        // }



                        // }
                    });



                }


                document.getElementById("priceRangeMax1").addEventListener("input", captureValue);



            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })

    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingToValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        let value;

        document.getElementById("priceRangeMax1").value = null;
        document.getElementById("priceRangeMax2").value = null;
        value = 0.0;
        var _datacalto = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["WETH", "bgtech"],


        }
        fetch(/*process.env.REACT_APP_EXCHANGE*/"https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalto),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                const captureValue = function (e) {

                    var price = Number(ver.price).toPrecision(8)
                    value = (e.target.value) * (1 / (price))

                    document.getElementById("priceRangeMax1").value = (toFixed(value))
                    value = 0;

                    if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        if (document.getElementById("btn-approve") == null) {

                            document.getElementById("btn_con").disabled = true;
                        }

                        document.getElementById("btn_con").disabled = true;
                        document.getElementById("btn_con").innerText = "Yetersiz Bakiye"

                    }
                    else {
                        //     if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax2").value)) {

                        //         document.getElementById("btn_con").disabled = true;
                        //         document.getElementById("btn_con").innerText = "Bakiye Yetersiz..."
                        //     }
                        //     else {

                        document.getElementById("btn_con").disabled = false;
                        document.getElementById("btn_con").innerText = "Devam"
                    }



                    // }

                }


                document.getElementById("priceRangeMax2").addEventListener("input", captureValue);



            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })


    }

    function walletControl() {
        //document.getElementById("fromid").innerText = tokenname;
        document.getElementById("btn_con").disabled = true
        if (props.address == null) {

            document.getElementById("btn_con").innerText = "Cüzdan Bağlayınız"

        }
        else {
            document.getElementById("btn_con").innerText = "Devam"
            document.getElementById("btn_con").disabled = true


        }
    }

    // useEffect(() => {
    //     document.getElementById("table_ttff").style.display = "none"
    //     document.getElementById("table_bigtech").style.display = "none"
    //     document.getElementById("table_hybrid").style.display = "none"
    //     document.getElementById("table_eko").style.display = "none"
    // }, [])


    useEffect(() => {

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        walletControl()

    })


    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-light rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Değer aralık dışında"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }

    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-light rounded div-white form-control"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("danger").innerText = ""

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Değer aralık dışında"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }

    async function swapprocess() {
        Swap(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "bgtech", document.getElementById("tokenid").textContent, /*document.getElementById("fromid").innerText*/ Number(document.getElementById("toContent").innerText).toFixed(18)).then(ver => {


            //weth balance hesaplama
            balance(ttfAddress, "TTFF").then((ver) => {
                document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
            })

            sendHash(ver.to, ver.data, Number(ver.gasEstimate), "swap");


        })

        //function to add ttf Token to metamask
        const tokenAddress = ttfAddress;
        const tokenSymbol = 'TTFF';
        const tokenDecimals = 18;
        const tokenImage = 'https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png';

        if (props.provider.connection.url == "metamask") {
            await balance(ttfAddress, "TTFF").then(async (ver) => {
                if (Number(ver) == 0) {
                    const wasAdded = await window.ethereum.request({
                        method: 'wallet_watchAsset',
                        params: {
                            type: 'ERC20', // Initially only supports ERC20, but eventually more!
                            options: {
                                address: tokenAddress, // The address that the token is at.
                                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                decimals: tokenDecimals, // The number of decimals in the token
                                image: tokenImage, // A string url of the token logo
                            },
                        },
                    });
                }
            })

        }


        toggleModal2()
    }

    return (
        <section id="root pt-3">
            <div className="row mb-5 justify-content-center pt-5  " >
                <h4 className=" col-12 col-lg-12 text-center" id="title"><strong>SDR21</strong> </h4>
                <div className="col-8 col-md-8 col-lg-auto  mb-lg-0 pt-0">
                    <div className="card-tabs  p-4 rounded pt-7" style={divstye3} id="pool" >
                        <div className="card-body-tabs " id="pool">
                            {/* ttff içeriği listeleme */}
                            <div className="row pt-0 p-0 pl-0   " style={divstye3} >
                                <div className="card-tabs card-body-tabs col-12 p-0 mt-0  rounded  " id="pool"  >
                                    <div className="table-wrapper-scroll-y my-custom-scrollbar scrollbar-light-blue text-center" >
                                        {/*  TTFF içeriği tablo   */}



                                        <Table hover className="table table-bordered table-striped mb-0 text-center" id="table_ttff" >
                                            <thead>

                                                <tr  >
                                                    <th >İsim </th>
                                                    <th id="allocation ">Alakasyon</th>

                                                </tr>
                                            </thead>
                                            <tbody id="tabletr" className='text-left'>
                                                <tr >

                                                    <td  >
                                                        <a >
                                                            <img height="25px" className=" font-medium" src={Uniswap}></img>&nbsp;&nbsp;&nbsp;
                                                            <span >Dai  </span></a>
                                                    </td>
                                                    <td>
                                                        <a >
                                                            <span className="font-base" >%50</span>
                                                        </a>
                                                    </td>

                                                </tr>
                                                <tr >

                                                    <td  >
                                                        <a >
                                                            <img height="25px" className=" font-medium" src={ageur}></img>&nbsp;&nbsp;&nbsp;
                                                            <span >AgEUR  </span></a>
                                                    </td>
                                                    <td>
                                                        <a >
                                                            <span className="font-base" >%50</span>
                                                        </a>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </Table>


                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                    <br></br>
                </div>
                <div className="  justify-content-center col-12 col-md-12 col-lg-auto mb-5 mb-lg-0 pt-0">
                    <div className="  border-light p-3" id="divcard" >
                        {/* ds */}
                        <div className="p-2 mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-9 ">
                                    <br />
                                </div>
                                <div className="col-12 col-lg-3 py-4">
                                    <div className="row " >
                                        <div className="col-12 col-md-12 col-lg-11">
                                            <div>
                                                <div className="row justify-content-end">
                                                    <div className="row justify-content-end">
                                                        <a onClick={() => {
                                                            toggleModal3()
                                                            setTimeout(() => {
                                                                document.getElementById("slippage").value = slippage
                                                                document.getElementById("deadline").value = deadlineMinute
                                                            }, 1000);

                                                        }}> <Button className=" btn-pill btn-yotta21  div-yotta21"  ><i className="fas fa-cog  " style={divstye3} onClick={() => {
                                                        }} ></i></Button></a>


                                                    </div>
                                                    {/* modal setting start */}
                                                    <Modal
                                                        isOpen={isOpen3}
                                                        onRequestClose={toggleModal3}
                                                        contentLabel="My dialog"
                                                        className="mymodal4" /*"modal-dialog modal-dialog-centered rounded"*/
                                                        overlayClassName="myoverlay"
                                                        closeTimeoutMS={500}
                                                        aria-hidden="true"

                                                    >


                                                        <div className="rounded " id="sums">
                                                            <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                                                document.getElementById("deadline").value = 0
                                                                document.getElementById("slippage").value = 0

                                                            }}>
                                                                <span aria-hidden="true" id="close" onClick={() => {
                                                                    if (document.getElementById("dangers") != null) {
                                                                        document.getElementById("danger").removeChild(document.getElementById("dangers"))
                                                                        document.getElementById("slippage").style.borderColor = "white"
                                                                    }
                                                                    if (document.getElementById("dangers2") != null) {
                                                                        document.getElementById("danger2").removeChild(document.getElementById("dangers2"))
                                                                        document.getElementById("deadline").style.borderColor = "white"
                                                                    }
                                                                    toggleModal3()
                                                                }}>×</span>
                                                            </button>
                                                            <div className="card-header  text-center pb-0 pt-0">
                                                                <h2 className="h4 font-medium text-center pt-0">Ayarlar</h2>
                                                            </div>
                                                            <div className="card-body text-center pb-0 pt-0 font-smedium  div-white " >
                                                                <div className="row ">
                                                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                        <div className="text-left">
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <a className="font-medium"> Kayma Toleransı</a>
                                                                            <button className="border-0 font-medium div-white" id="Popover1" >
                                                                                <i className="far fa-question-circle"></i>
                                                                            </button>
                                                                            <Popover isOpen={popoverOpen1} target="Popover1" toggle={toggle1}>

                                                                                <PopoverBody>Fiyatın istenmeyen şekilde bu yüzdeden daha fazla değişmesi durumunda işleminiz geri döner.</PopoverBody>
                                                                            </Popover>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-12 text-left pt-0  font-medium py-2">

                                                                            <output className="text-right div-white col-lg-8" >
                                                                                <InputGroup >
                                                                                    <InputGroupAddon addonType="prepend" >
                                                                                        <InputGroupText className="div-white">
                                                                                            <Input addon type="checkbox" aria-label="Checkbox for following text input" id="toleranscheck" className="div-white" onChange={() => {
                                                                                                if (document.getElementById("toleranscheck").checked == true) {

                                                                                                    document.getElementById("slippage").value = 0.05;
                                                                                                }
                                                                                                else {
                                                                                                    document.getElementById("slippage").value = null

                                                                                                }

                                                                                            }} />&nbsp;  Otomatik
                                                                                        </InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <Input className="div-white " placeholder="%0.5" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="50" id="slippage" onChange={() => {
                                                                                        settingControlslippage()
                                                                                    }}></Input>
                                                                                </InputGroup>
                                                                            </output>
                                                                        </div>

                                                                    </div>
                                                                    <div id="danger"></div>

                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                        <div className="text-left " >
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <a className="font-medium">İşlem Son Tarihi</a>
                                                                            <button className="border-0 div-white" id="Popover2"  >
                                                                                <i className="far fa-question-circle font-medium" ></i>
                                                                            </button>
                                                                            <Popover isOpen={popoverOpen2} target="Popover2" toggle={toggle2} className="div-white" >

                                                                                <PopoverBody>İşleminiz bu süreden daha uzun bir süredir beklemedeyse geri alınacaktır.</PopoverBody>
                                                                            </Popover>

                                                                        </div>
                                                                        <div className=" col-12 col-lg-12 text-left pt-0 font-medium py-2">

                                                                            <output className="text-left col-lg-6 font-medium div-white" >
                                                                                <Input placeholder="30" id="deadline" className="div-white" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="180" onChange={() => {
                                                                                    settingControldeadline()
                                                                                }} />

                                                                            </output>
                                                                            <output className="col-lg-3 text-left" >
                                                                                <a className="font-left">Dakika</a>
                                                                            </output>
                                                                        </div>

                                                                    </div>
                                                                    <div id="danger2"></div>
                                                                </div>

                                                            </div>
                                                            <br></br>
                                                            <button className="btn btn-yotta21 col-lg-12" onClick={() => {

                                                                if (document.getElementById("toleranscheck").checked == true) {
                                                                    slippage = 0.05
                                                                    if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                        deadlineMinute = 30;
                                                                    }
                                                                    else {
                                                                        deadlineMinute = document.getElementById("deadline").value

                                                                    }
                                                                    console.log("slipage1", slippage + "deadline", deadlineMinute);

                                                                }
                                                                else {
                                                                    if (document.getElementById("slippage").value == "" || document.getElementById("slippage").value == null) {
                                                                        slippage = 0.05
                                                                    }
                                                                    else {
                                                                        slippage = document.getElementById("slippage").value

                                                                    }
                                                                    console.log("slipage1", slippage + "deadline", deadlineMinute);

                                                                    if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                        deadlineMinute = 30;
                                                                    }
                                                                    else {
                                                                        deadlineMinute = document.getElementById("deadline").value

                                                                    }
                                                                }

                                                                toggleModal3()

                                                            }} id="save">Kaydet</button>

                                                        </div>
                                                    </Modal>
                                                    {/* modal setting end */}
                                                    {/* modal setting end */}
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="mb-2 pt-0 py-0"  >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12  btn-group " >
                                    <button className="col-12 col-lg-6 btn div-white active  " id="buy" onClick={() => {

                                        document.getElementById("sell").className = "col-12 col-lg-6 btn "
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn active "
                                        document.getElementById("redeem").className = "col-12 col-lg-6 btn"


                                        var url = "/#/Sdr21Buy/" + tokenid + "/" + tokenname
                                        window.location.href = url

                                    }}>Al</button>
                                    <button className="col-11 col-lg-6 btn div-white  " id="sell" onClick={() => {

                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn  active"
                                        document.getElementById("redeem").className = "col-12 col-lg-6 btn"

                                        var url = "/#/Sdr21Sell/" + tokenid + "/" + tokenname
                                        window.location.href = url


                                    }}>Sat</button>
                                    <button className="col-11 col-lg-6 btn div-white  " id="redeem" onClick={() => {

                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn  "
                                        document.getElementById("redeem").className = "col-12 col-lg-6 btn  active"

                                        var url = "/#/Sdr21Redeem/" + tokenid + "/" + tokenname
                                        window.location.href = url


                                    }}>Redeem</button>

                                </div>
                                <div className="col-12 col-lg-6 pt-2 ">

                                </div>
                            </div>
                        </div>
                        {/* dsasa */}

                        {/* to component start */}
                        {/* <div className=" row justify-content-center p-0 pt-0 m-0">
                            <Button className="btn btn-pill" style={divstye}><img src="https://img.icons8.com/small/32/000000/sorting-arrows.png" height="15px" width="15" /></Button>
                        </div> */}

                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light  ">
                                        {/* Swap ÖZeti */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0  py-1" >
                                                <div className="App" >
                                                    <button type="button" value="WETH" className="btn col-md-12 font-base " id="btnselect" onClick={() => {

                                                        balance(wethAddress, "WETH").then((ver) => {
                                                            document.getElementById("Daibalance").innerText = Number(ver).toFixed(5) + " WETH";
                                                            document.getElementById("fromid").value = ttfAddress;

                                                        });




                                                        // document.getElementById("btn_con").disabled = true


                                                        toggleModal();


                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://img.icons8.com/small/32/000000/sorting-arrows.png" width="0px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Token Seçiniz" >  Token Seçiniz  </a>&nbsp;&nbsp;
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                    </button>
                                                    <br></br>
                                                    <br></br>

                                                    <div className="row pt- m-0">&nbsp;&nbsp;&nbsp;
                                                        <a className="font-small text-bold text-left">Bakiye:   </a> &nbsp;
                                                        <a className="font-small text-bold" id="balanceid">0,0 </a>

                                                    </div>

                                                    {/* Token select Modal Start */}

                                                    <Modal
                                                        isOpen={isOpen}
                                                        onRequestClose={toggleModal}
                                                        contentLabel="My dialog"
                                                        className="mymodal"
                                                        overlayClassName="myoverlay"
                                                        closeTimeoutMS={500}
                                                    >

                                                        <div className="card-header border-bottom text-center ">
                                                            <span className="d-block">
                                                                <span className="display-3 font-weight-bold">
                                                                    Token Listesi
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="card-body p-2 col-mg-12">
                                                            <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                                            <br></br><br></br>
                                                            <ListGroup>


                                                                {/* Token WETH Selected start  */}

                                                                <ListGroupItem tag="button" action onClick={() => {
                                                                    document.getElementById("imageId").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg";
                                                                    document.getElementById("imageId").style.width = "25px";

                                                                    document.getElementById("tokenid").innerText = "WETH";
                                                                    document.getElementById("tokenid").value = wethAddress



                                                                    balance(wethAddress, "WETH").then((ver) => {
                                                                        document.getElementById("balanceid").innerText = Number(ver).toFixed(5);
                                                                    });

                                                                    //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                                                                    document.getElementById("priceRangeMax2").disabled = true;
                                                                    document.getElementById("priceRangeMax1").disabled = true;

                                                                    // approve control
                                                                    Swap(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, /* document.getElementById("tokenid").innerText*/ "TTY1121", document.getElementById("tokenid").textContent, 1).then(ver => {

                                                                        if (ver.isAllowed == false) {
                                                                            if (document.getElementById("btn-approve") == null) {
                                                                                var Button = document.createElement("button");
                                                                                Button.className = "btn btn-yotta21 col-12";
                                                                                var btninnertext = "Let Yotta21 Protcol use  " + document.getElementById("tokenid").innerText
                                                                                Button.innerText = btninnertext;
                                                                                Button.setAttribute("id", "btn-approve");
                                                                                Button.setAttribute("name", "btn-approve");
                                                                                document.getElementById("approvediv").appendChild(Button);

                                                                                Button.onclick = function () {
                                                                                    sendHash(ver.to, ver.data, ver.gasEstimate, "approve")
                                                                                    document.getElementById("btn-approve").disabled = true;
                                                                                    //weth balance hesaplama
                                                                                    balance(wethAddress, "WETH").then((ver) => {
                                                                                        document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
                                                                                    })
                                                                                }
                                                                            }




                                                                        }
                                                                        else {
                                                                            if (document.getElementById("btn-approve") != null) {
                                                                                var deneme = document.getElementById("approvediv")
                                                                                var deneme2 = document.getElementById("btn-approve");
                                                                                deneme.removeChild(deneme2);


                                                                                CalculatingFRomValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                                                CalculatingToValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);



                                                                            }
                                                                            else {
                                                                                CalculatingFRomValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                                                CalculatingToValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                                            }


                                                                        }


                                                                    })

                                                                    toggleModal()
                                                                }}>
                                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="35px"></img>
                                                                    <a className="container">WETH</a>

                                                                    <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                        <div className="post-details mb-3 mb-lg-0">
                                                                            <span className="d-block font-small">Wrapped Ethereum </span>
                                                                        </div>
                                                                        <div className="post-meta">
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <span className="font-small">Bakiye: </span>

                                                                            <span className="justify-content-between font-small" id="Daibalance"></span>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>


                                                            </ListGroup>
                                                        </div>

                                                    </Modal>
                                                    {/* token select modal end */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row py-1" >
                                        <div className="col-12 col-md-6 col-lg-12 ">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">

                                                        <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>


                                                            <input className="form-control div-white" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* to component end */}


                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-2 pt-0 div-white" >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light  ">
                                        <div className="row">
                                            <div className=" col-12 ml-12 pb-2 " >
                                                <div className="App" >
                                                    <button type="button" value={ttfAddress} className=" btn col-md-12 font-base " style={divstye3} onClick={() => {

                                                        toggleModal4();
                                                        balance(ttfAddress, "TTFF").then((ver) => {
                                                            document.getElementById("TTFFbalance").innerText = Number(ver).toFixed(5)
                                                        });
                                                        // balance(Ecosys, "Eco").then((ver) => {
                                                        //     document.getElementById("Ekobalance").innerText = Number(ver).toFixed(5)
                                                        // });
                                                        // balance(Bgtech, "BGT").then((ver) => {
                                                        //     document.getElementById("Bigtechbalance").innerText = Number(ver).toFixed(5)
                                                        // });
                                                        // balance(Hybrid, "Hybr").then((ver) => {
                                                        //     document.getElementById("Hybridbalance").innerText = Number(ver).toFixed(5)
                                                        // });
                                                    }}  >
                                                        <img id="imageidfrom" src="https://img.icons8.com/small/32/000000/sorting-arrows.png" width="0px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="fromid" value="token">Token Seçiniz </a>
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                    </button>
                                                    <br></br>
                                                    <br></br>

                                                    <div className="row justify-content-left pt- m-0">&nbsp;&nbsp;&nbsp;
                                                        <a className="font-small text-bold text-left ">  Bakiye: </a>&nbsp;
                                                        <a id="frombalance" className="font-small text-bold"> 0,0</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">

                                                        <div className="text-right col-12  pb-4 " id="text-right" >


                                                            <input className="form-control div-white" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false"
                                                            />
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* From componet end */}
                        {/* Token select Modal Start */}

                        <Modal
                            isOpen={isOpen4}
                            onRequestClose={toggleModal4}
                            contentLabel="My dialog"
                            className="mymodal"
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                        >

                            <div className="card-header border-bottom text-center ">
                                <span className="d-block">
                                    <span className="display-3 font-weight-bold">
                                        Token Listesi
                                    </span>
                                </span>
                            </div>
                            <div className="card-body p-2 col-mg-12">
                                <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                <br></br><br></br>
                                <ListGroup>


                                    {/* Token TTFF Selected start  */}

                                    <ListGroupItem tag="button" action onClick={() => {
                                        document.getElementById("imageidfrom").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png";
                                        document.getElementById("imageidfrom").style.width = "25px";

                                        document.getElementById("fromid").innerText = "SDR21"
                                        document.getElementById("fromid").ariaValueText = ttfAddress

                                        document.getElementById("table_ttff").style.display = ""

                                        document.getElementById("title").innerText = "SDR21"




                                        balance(ttfAddress, "TTFF").then((ver) => {
                                            document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
                                        });

                                        //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                                        document.getElementById("priceRangeMax2").disabled = true;
                                        document.getElementById("priceRangeMax1").disabled = true;

                                        // approve control
                                        Swap(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, /* document.getElementById("tokenid").innerText*/ "TTY1121", document.getElementById("tokenid").textContent, 1).then(ver => {

                                            if (ver.isAllowed == false) {
                                                if (document.getElementById("btn-approve") == null) {
                                                    var Button = document.createElement("button");
                                                    Button.className = "btn btn-yotta21 col-12";
                                                    var btninnertext = "Let Yotta21 Protcol use " + document.getElementById("tokenid").innerText
                                                    Button.innerText = btninnertext;
                                                    Button.setAttribute("id", "btn-approve");
                                                    Button.setAttribute("name", "btn-approve");
                                                    document.getElementById("approvediv").appendChild(Button);

                                                    Button.onclick = function () {
                                                        sendHash(ver.to, ver.data, ver.gasEstimate, "approve")
                                                        document.getElementById("btn-approve").disabled = true;
                                                        //weth balance hesaplama
                                                        balance(wethAddress, "WETH").then((ver) => {
                                                            document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
                                                        })
                                                    }
                                                }




                                            }
                                            else {
                                                if (document.getElementById("btn-approve") != null) {
                                                    var deneme = document.getElementById("approvediv")
                                                    var deneme2 = document.getElementById("btn-approve");
                                                    deneme.removeChild(deneme2);


                                                    CalculatingFRomValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                    CalculatingToValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);



                                                }
                                                else {
                                                    CalculatingFRomValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                    CalculatingToValue(document.getElementById("fromid").ariaValueText, document.getElementById("tokenid").value, "TTY1121", document.getElementById("tokenid").textContent, 1);
                                                }


                                            }


                                        })

                                        toggleModal4()
                                    }}>
                                        <img src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png" width="35px"></img>
                                        <a className="container">SDR21</a>

                                        <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                            <div className="post-details mb-3 mb-lg-0">
                                                <span className="d-block font-small">Sdr21 </span>
                                            </div>
                                            <div className="post-meta">
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <a className="text-dark mr-3" href="#"></a>
                                                <span className="font-small">Bakiye: </span>

                                                <span className="justify-content-between font-small" id="TTFFbalance"></span>
                                            </div>
                                        </div>
                                    </ListGroupItem>


                                </ListGroup>
                            </div>

                        </Modal>
                        {/* token select modal end */}

                        <div id="approvediv"></div>
                        <button type="button" id="btn_con" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                            onClick={() => {
                                //weth balance hesaplama
                                balance(wethAddress, "WETH").then((ver) => {
                                    document.getElementById("frombalance").innerText = Number(ver).toFixed(5);

                                    document.getElementById("fromContent").innerText = (document.getElementById("priceRangeMax1").value)
                                    if (document.getElementById("fromid").innerText == "TTFF") {
                                        document.getElementById("from").innerHTML = document.getElementById("fromid").innerText + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        document.getElementById("fromImageContent").src = document.getElementById("imageidfrom").src
                                    }
                                    else if (document.getElementById("fromid").innerText == "Ekosistem") {
                                        document.getElementById("from").innerHTML = document.getElementById("fromid").innerText + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        document.getElementById("fromImageContent").src = document.getElementById("imageidfrom").src
                                    }
                                    else if (document.getElementById("fromid").innerText == "Bigtech") {
                                        document.getElementById("from").innerHTML = document.getElementById("fromid").innerText + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        document.getElementById("fromImageContent").src = document.getElementById("imageidfrom").src
                                    }
                                    else if (document.getElementById("fromid").innerText == "Hybrid") {
                                        document.getElementById("from").innerHTML = document.getElementById("fromid").innerText + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                        document.getElementById("fromImageContent").src = document.getElementById("imageidfrom").src
                                    }


                                    document.getElementById("toContent").innerText = (document.getElementById("priceRangeMax2").value)
                                    if (document.getElementById("tokenid").innerText == "DAI" || document.getElementById("tokenid").innerText == "ETH") {
                                        document.getElementById("to").innerText = document.getElementById("tokenid").innerText + "&nbsp;"
                                    } else {
                                        document.getElementById("to").innerText = document.getElementById("tokenid").innerText

                                    }
                                    document.getElementById("ToImageContent").src = document.getElementById("imageId").src

                                })



                                // token Değeri yazdırma

                                document.getElementById("fromid").value = ttfAddress;



                                Swap(document.getElementById("tokenid").value, document.getElementById("fromid").ariaValueText, document.getElementById("fromid").value, document.getElementById("tokenid").textContent, Number(document.getElementById("priceRangeMax2").value).toFixed(18)).then(ver => {

                                    document.getElementById("route").innerText = ""
                                    //  document.getElementById("swapsource").innerText = ""


                                    document.getElementById("route").innerText = " "

                                    // document.getElementById("liqudity").innerText = ver.feeRecipient;

                                    for (let x in ver.routePath) {
                                        document.getElementById("route").innerText += ver.routePath[x]

                                    }
                                    // for (let x in ver.source) {
                                    //     document.getElementById("swapsource").innerText += ver.source[x] + "->"

                                    // }

                                    document.getElementById("minReceived").innerText = Number(ver.minReceived).toFixed(8);

                                    document.getElementById("slippagePercentage").innerText = Number(ver.slippagePercentage);

                                    document.getElementById("tokenvalue").innerText = Number(ver.price).toFixed(8) + " " + document.getElementById("tokenid").innerText


                                })

                                toggleModal2()


                            }} >
                            Devam
                        </button>
                    </div>
                    <Modal
                        isOpen={isOpen2}
                        onRequestClose={toggleModal2}
                        contentLabel="My dialog"
                        className="mymodal-ttff" /*"modal-dialog modal-dialog-centered rounded"*/
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        aria-hidden="true"
                    >
                        {/* <!-- Modal Content --> */}




                        <div className="" id="ttffsum" >
                            <button type="button" className=" pt-0 close ml-auto ">
                                <span id="close" onClick={() => {

                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null

                                }}>×</span>
                            </button>
                            <div className="card-header  text-center pb-0 pt-0">
                                <h2 className="h4 font-medium text-center pt-0"><strong>İşlem Özeti</strong></h2>
                            </div>
                            <div className="card-body text-center pb-0 pt-0 font-smedium ">
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white"   >
                                        {/* <div className="text-left">From</div> */}
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">

                                            <output className="text-left  font-base ">
                                                <img id="ToImageContent" src={yotta21logo} height="26px" />

                                                <a id="to" className="font-base py-2  col-lg-4 ">WETH</a>
                                            </output>
                                            <output className="col-lg-9 text-right font-base" >
                                                <a className="text-right" id="toContent"></a>
                                            </output>
                                        </div>

                                    </div>

                                </div>
                                <br />
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >
                                        {/* <div className="text-left">To</div> */}

                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">
                                            <output className="text-left font-base">
                                                <img id="fromImageContent" src={yotta21logo} height="35px" />

                                                <a className="font-base col-lg-5" id="from"></a>
                                            </output>

                                            <output className="col-lg-8 text-right font-base" >
                                                <a id="fromContent" className="text-right"></a>
                                            </output>
                                        </div>

                                    </div>

                                </div>

                                {/* <div className="row  ">

                                                <div className="col-12 col-sm-12 mt-12 pt-0">

                                                    <div className=" col-12 col-lg-12 text-left pt-0 font-small " >
                                                        <output className="text-left font-small">  Fiyat
                                                        </output>
                                                        <output className="col-lg-11 text-right font-small" >
                                                            <a className="text-right"><a>1</a> <a id="tokenname">ETH</a> = <a id="tokenvalue">55555.5555</a></a>
                                                        </output>
                                                    </div>

                                                </div>
                                            </div> */}


                                <div className="row pt-4  ">

                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded">

                                        <div className=" col-12 col-lg-12 pt-0 py-2 font-small " >
                                            <output className=" col-lg-2 text-left font-small">  Fiyat
                                            </output>

                                            <output className="col-lg-9 text-right font-small" >
                                                <a className="text-right"><a>1</a> <a id="tokenname">TTFF</a> = <a id="tokenvalue">0.00</a></a>
                                            </output>
                                        </div>

                                    </div>
                                </div>
                                <br />

                                <div className="row justify-content-start pt-0 ">

                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >

                                        <div className=" col-12 col-lg-12 text-left pt-2 font-small " >
                                            {/* <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-hand-holding-usd"></i>                                                            &nbsp;
                                                Likidite  Ücreti

                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-right" id="liqudity">
                                                </a>
                                            </output> */}
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-hand-holding-usd"></i>                                                            &nbsp;

                                                Yönetim ücreti

                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-right" id="managamentfee">0,05
                                                </a>
                                            </output>

                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-route"></i>
                                                &nbsp;
                                                Rota
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="route"></a>

                                            </output>
                                            {/* <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-exchange-alt"></i>
                                                &nbsp;
                                                Swap Source
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="swapsource"></a>

                                            </output> */}

                                            <output className=" col-lg-7 text-left font-small" >
                                                <i className="fas fa-coins"></i>
                                                &nbsp;
                                                Minimum Alınan
                                            </output>
                                            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="minReceived">
                                                </a>
                                            </output>
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-skiing"></i>
                                                &nbsp;
                                                Kayma Toleransı
                                            </output>

                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="slippagePercentage">
                                                </a>
                                            </output>
                                        </div>


                                    </div>
                                </div>

                            </div>
                            <br></br>

                            <button className='btn col-lg-12' id="swappro" action onClick={() => {

                                swapprocess()
                                document.getElementById("priceRangeMax1").value = null
                                document.getElementById("priceRangeMax2").value = null
                                var x = document.getElementById("snackbartottffbuy");
                                x.className = "show";
                                setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);

                            }}>Swap</button>

                        </div>



                    </Modal>
                </div>

                <div id="snackbartottffbuy">İşlem Cüzdanınıza Gönderildi.</div>


            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>

        </section>

    );

}

export default TtfBuy
