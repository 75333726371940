import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import liquidityMath from './liquidityMath';
import bigNumber from 'bignumber.js'
import web3 from 'web3'
import yotta21logo from '../../assets/img/yotta21-logo21.png'
import { ottaAddress, taumAddress, ttfAddress, wethAddress } from '../protocolAdress'

function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

Modal.setAppElement("#index");

var currentTick;
var normalizeTickmin;
var normalTickmin;
var normalizeTickmax;
var normalTickmax;
var _token0Decimal;
var _token1Decimal;
var tickspacing;
var fee;
var amountRatio;
var ToOrFrom;
var token0Balance;
var token1Balance;
var slippage = 0.05
var deadlineMinute = 130;
var isToken0Allowed
var isToken1Allowed
var token0
var image0
var token0value
var token1
var image1
var token1value
var currentPrice
var MinPrice
var MaxPrice
var poolLiqudity

const divstye = {
    backgroundColor: '#f3fbfb ',
    borderRadius: "2rem"
}
const display = {
    display: 'none ',
}
const divstye3 = {
    backgroundColor: "transparent"

}
const divstye4 = {
    backgroundImage: "https://storageapi.fleek.co/esongur-team-bucket/image/yotta21.png",
}
const divstye7 = {
    padding: "0.15rem 0.15rem",
    lineHeight: "1rem",
    fontSize: "1rem"
}
const divstyledot = {
    height: "10px",
    width: "10px",
}

// Uniswap MAth Functions Start
function getPriceFromTick(tick) { //Gets price from tick using this(price = 1.0001^tick) formula
    return Math.pow(1.0001, tick)
}
function getPriceFromTickDecimal(tick, token0Decimal, token1Decimal) {
    const decimalDifference = parseInt(token1Decimal) - parseInt(token0Decimal);
    return (Math.pow(1.0001, tick) * Math.pow(10, decimalDifference))
}
async function getCurrentPrice(currentSqrtPriceX96) {
    const currentPrice = Math.pow((currentSqrtPriceX96 / 2 ** 96), 2)
    return currentPrice
}
async function getNormalizedTick(currentTick, tickSpacing, token0Decimal, token1Decimal) {
    const normalizedTick = currentTick - (currentTick % tickSpacing)
    var normalizedTickPrice = getPriceFromTickDecimal(normalizedTick, token0Decimal, token1Decimal);
    return normalizedTick
}
function getTickLowerAndPrice(normalizedTick, tickSpacing, token0Decimal, token1Decimal, addOrRemove) {
    var tickLower;
    if (addOrRemove) {
        tickLower = parseInt(normalizedTick) + parseInt(tickSpacing);
    }
    else {
        tickLower = parseInt(normalizedTick) - parseInt(tickSpacing);
    }
    var minPrice = getPriceFromTickDecimal(tickLower, token0Decimal, token1Decimal)
    return { tickLower, minPrice }
}
function getTickUpperAndPrice(normalizedTick, tickSpacing, token0Decimal, token1Decimal, addOrRemove) {
    var tickUpper;
    if (addOrRemove) {
        tickUpper = parseInt(normalizedTick) + parseInt(tickSpacing);
    }
    else {
        tickUpper = parseInt(normalizedTick) - parseInt(tickSpacing);
    }
    var maxPrice = getPriceFromTickDecimal(tickUpper, token0Decimal, token1Decimal)
    return { tickUpper, maxPrice }
}
// Uniswap MAth Functions end

function Investment_comp_det(props) {

    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }
    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }
    function toggleModal4() {
        setIsOpen4(!isOpen4);
    }

    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    //transaction data 
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)
        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })
        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approvetoken0") {
                var aprove = document.getElementById("approvediv")
                var button0 = document.getElementById("btn-approve-token0")
                button0.disabled = true
                button0.innerText = "Approve Başarılı"
                aprove.removeChild(button0)
                console.log(end);
                if (document.getElementById("btn-approve-token1") != null) document.getElementById("btn-approve-token1").className = "btn btn-yotta21 col-12"

            }
            else if (_transactiontype == "approvetoken1") {
                var aprove = document.getElementById("approvediv")
                var button1 = document.getElementById("btn-approve-token1")
                button1.disabled = true
                button1.innerText = "Approve Başarılı"
                aprove.removeChild(button1)
                console.log(end);

                if (document.getElementById("btn-approve-token0") != null) document.getElementById("btn-approve-token0").className = "btn btn-yotta21 col-12"

            }
            else {
                console.log(end);
            }
        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            console.log(end);
        })
        emitter.on('txFailed', end => {
            console.log(end);
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
    }
    //transaction data 
    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)
        const { hash } = await signer.sendTransaction({
            to: _to,
            value: "0x0",
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)
        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })
        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', (ver) => {
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', (ver) => {
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(18)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // Price Control-min and max values are equal or min. the state of being less than
    function PriceControl() {
        if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
            if (document.getElementById("minpricevalue").value >= document.getElementById("maxpricevalue").value || document.getElementById("minpricevalue").value > document.getElementById("currentprice-1").innerText) {
                if (document.getElementById("alertwarning") == null) {
                    var span = document.createElement("span");
                    span.className = "alert-inner--text ";
                    span.setAttribute("id", "alertwarning");
                    span.innerText = " Max Prace Min Pricedan küçük veya eşit olmaz.."
                    document.getElementById("alertdiv").className = "alert alert-danger shadow-soft text-center"
                    document.getElementById("alertdiv").appendChild(span);
                    document.getElementById("token0fromid").disabled = true;
                    document.getElementById("balancetoken0").disabled = true;
                    document.getElementById("priceRangeMax1").disabled = true;
                    document.getElementById("priceRangeMax2").disabled = true;
                    document.getElementById("token1id").disabled = true;
                    document.getElementById("balancetoken1").disabled = true;
                }
                else {
                    var span = document.getElementById("alertwarning");
                    span.innerText = "Max Prace Min Pricedan küçük veya eşit olmaz.."
                    document.getElementById("alertdiv").className = "alert alert-danger shadow-soft"
                    document.getElementById("token0fromid").disabled = true;
                    document.getElementById("balancetoken0").disabled = true;
                    document.getElementById("priceRangeMax1").disabled = true;
                    document.getElementById("priceRangeMax2").disabled = true;
                    document.getElementById("token1id").disabled = true;
                    document.getElementById("balancetoken1").disabled = true;
                }
            }
            else {
                var span = document.getElementById("alertwarning");
                if (span != null) {
                    span.innerText = ""
                    span.className = ""
                }
                document.getElementById("alertdiv").className = "";
                document.getElementById("token0fromid").disabled = false;
                document.getElementById("balancetoken0").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("token1id").disabled = false;
                document.getElementById("balancetoken1").disabled = false;
            }

        }
        //token 2 to token 1 
        else {
            if (document.getElementById("minpricevalue2").value >= document.getElementById("maxpricevalue2").value || document.getElementById("minpricevalue2").value > document.getElementById("currentprice-2").innerText) {
                if (document.getElementById("alertwarning1") == null) {
                    var span = document.createElement("span");
                    span.className = "alert-inner--text ";
                    span.setAttribute("id", "alertwarning1");
                    span.innerText = " Max Prace Min Pricedan küçük veya eşit olmaz.."
                    document.getElementById("alertdiv1").className = "alert alert-danger shadow-soft text-center"
                    document.getElementById("alertdiv1").appendChild(span);
                    document.getElementById("token0fromid").disabled = true;
                    document.getElementById("balancetoken0").disabled = true;
                    document.getElementById("priceRangeMax1").disabled = true;
                    document.getElementById("priceRangeMax2").disabled = true;
                    document.getElementById("token1id").disabled = true;
                    document.getElementById("balancetoken1").disabled = true;
                }
                else {
                    var span = document.getElementById("alertwarning");
                    span.innerText = "Max Prace Min Pricedan küçük veya eşit olmaz.."
                    document.getElementById("alertdiv1").className = "alert alert-danger shadow-soft"
                    document.getElementById("token0fromid").disabled = true;
                    document.getElementById("balancetoken0").disabled = true;
                    document.getElementById("priceRangeMax1").disabled = true;
                    document.getElementById("priceRangeMax2").disabled = true;
                    document.getElementById("token1id").disabled = true;
                    document.getElementById("balancetoken1").disabled = true;
                }
            }
            else {
                var span = document.getElementById("alertwarning1");
                span.innerText = ""
                span.className = ""
                document.getElementById("alertdiv1").className = "";
                document.getElementById("token0fromid").disabled = false;
                document.getElementById("balancetoken0").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("token1id").disabled = false;
                document.getElementById("balancetoken1").disabled = false;


            }
        }
    }
    // disable buttons
    function disabled() {
        document.getElementById("modal-from").disabled = true
        document.getElementById("token-1-1-decrease").disabled = true;
        document.getElementById("token-1-1-increase").disabled = true;
        document.getElementById("token-1-2-decrease").disabled = true;
        document.getElementById("token-1-2-increase").disabled = true;
    }
    // the function that checks the Cüzdan Bağlayınızion
    function walletControl() {
        if (props.address == null) {
            document.getElementById("modal-from").innerText = "Cüzdan Bağlayınız"
            document.getElementById("modal-from").disabled = true

        }
        else {
            document.getElementById("modal-from").disabled = true
            document.getElementById("modal-from").innerText = "Devam"
        }
    }

    useEffect(() => {
        disabled()
        walletControl()

        document.getElementById("btnfullrange").disabled = "true";
        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })
        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

    })

    // add liqudity getPoolData Api 
    async function getPoolData(_token0, _token1, _fee) {
        const _getpoolData = {
            token0: _token0,
            token1: _token1,
            fee: _fee
        };
        let result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_POOL, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_getpoolData)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });

    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingFRomValue() {
        var value;
        document.getElementById("priceRangeMax1").value = 0.0;
        document.getElementById("priceRangeMax2").value = 0.0;
        value = 0.0;
        const sqrtPriceRatios0 = Math.sqrt(currentPrice)
        const sqrtPriceRatios1 = Math.sqrt((MinPrice))
        const sqrtPriceRatios2 = Math.sqrt((MaxPrice))

        const amountsFromLiquidity = liquidityMath.getAmountsForLiquidity(bigNumber(sqrtPriceRatios0), bigNumber(sqrtPriceRatios1), bigNumber(sqrtPriceRatios2), bigNumber(poolLiqudity));
        var number = 1 * Math.pow(10, 18)
        const amountsRatio = ((bigNumber((amountsFromLiquidity[1]) * number) / bigNumber((amountsFromLiquidity[0]) * number)));

        const captureValue = function (e) {
            if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                var token1values = web3.utils.hexToNumberString(token1value)
                var token0values = web3.utils.hexToNumberString(token0value)
                if (Number(token0values) > Number(token1values)) {
                    value = (e.target.value * (1 / amountsRatio)).toFixed(18)
                }
                else {
                    value = (e.target.value * (amountsRatio)).toFixed(18)
                }
                ToOrFrom = true;
                amountRatio = Number(amountsRatio)
                document.getElementById("priceRangeMax2").value = toFixed(value);
                value = 0;

                if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                }
                else {
                    if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                    } else {
                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Devam"
                    }
                }
            }
            else {
                var token1values = web3.utils.hexToNumberString(token1value)
                var token0values = web3.utils.hexToNumberString(token0value)
                if (Number(token0values) > Number(token1values)) {
                    value = (e.target.value * (amountsRatio)).toFixed(18)
                }
                else {
                    value = (e.target.value * (1 / amountsRatio)).toFixed(18)
                } ToOrFrom = true;

                amountRatio = Number(amountsRatio)
                document.getElementById("priceRangeMax2").value = toFixed(value)
                value = 0;

                if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                }
                else {
                    if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                    } else {
                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Devam"
                    }
                }
            }
        }
        document.getElementById("priceRangeMax1").addEventListener("input", captureValue);

        AddLiqudityAbi(token1value, token0value, fee, normalTickmax, normalTickmin, 0, 0, deadlineMinute, slippage).then((ver) => {

            //token0Allowed start
            if (ver.isToken0Allowed == false) {
                if (document.getElementById("btn-approve-token0") == null) {
                    var Button_token0 = document.createElement("button");
                    Button_token0.className = "btn btn-yotta21 col-12";
                    var btninnertext = "Approve " + document.getElementById("tokenid").innerText
                    Button_token0.innerText = btninnertext;
                    Button_token0.setAttribute("id", "btn-approve-token0");
                    Button_token0.setAttribute("name", "btn-approve-token0");
                    document.getElementById("approvediv").appendChild(Button_token0);
                    document.getElementById("approvediv").innerHTML += "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"

                    document.getElementById("btn-approve-token0").onclick = function () {
                        sendHash(ver.token0To, ver.approveToken0Data, /*ver.token0GasEstimate*/800000, "approvetoken0")
                    }
                }
            }
            //token0Allowed end

            //token1Allowed start
            if (ver.isToken1Allowed == false) {
                if (document.getElementById("btn-approve-token1") == null) {
                    var Button_token1 = document.createElement("button");
                    Button_token1.className = "btn btn-yotta21 col-12";
                    var btninnertext1 = "Approve " + document.getElementById("fromid").innerText
                    Button_token1.innerText = btninnertext1;
                    Button_token1.setAttribute("id", "btn-approve-token1");
                    Button_token1.setAttribute("name", "btn-approve-token1");
                    document.getElementById("approvediv").appendChild(Button_token1);

                    if (document.getElementById("btn-approve-token0") != null) {
                        Button_token1.className = " btn btn-yotta21 col-5";
                        document.getElementById("btn-approve-token0").className = "  btn btn-yotta21 col-5";
                    }
                    Button_token1.onclick = function () {
                        sendHash(ver.token1To, ver.approveToken1Data, /*ver.token1GasEstimate*/800000, "approvetoken1")
                    }
                }
            }
        })
    }

    // Calculating other to coin value corresponding to the entered Set Value
    function CalculatingToValue() {
        var value;
        var value2;
        document.getElementById("priceRangeMax1").value = 0.0;
        document.getElementById("priceRangeMax2").value = 0.0;
        value = 0.0;
        value2 = 0.0;

        const sqrtPriceRatios0 = Math.sqrt(currentPrice)
        const sqrtPriceRatios1 = Math.sqrt(MinPrice)
        const sqrtPriceRatios2 = Math.sqrt(MaxPrice)

        const amountsFromLiquidity = liquidityMath.getAmountsForLiquidity(bigNumber(sqrtPriceRatios0), bigNumber(sqrtPriceRatios1), bigNumber(sqrtPriceRatios2), bigNumber(poolLiqudity));
        var number = 1 * Math.pow(10, 18)
        const amountsRatio = ((bigNumber((amountsFromLiquidity[1]) * number) / bigNumber((amountsFromLiquidity[0]) * number)));

        const captureValue = function (e) {
            if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                ToOrFrom = false;
                var token1values = web3.utils.hexToNumberString(token1value)
                var token0values = web3.utils.hexToNumberString(token0value)
                if (Number(token0values) > Number(token1values)) {
                    value = (e.target.value * (amountsRatio)).toFixed(18)
                }
                else {
                    value = (e.target.value * (1 / amountsRatio)).toFixed(18)
                } amountRatio = amountsRatio;

                document.getElementById("priceRangeMax1").value = toFixed(value)
                value = 0;
                if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {

                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                }
                else {
                    if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                    } else {
                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Devam"
                    }
                }
            }
            else {
                var token1values = web3.utils.hexToNumberString(token1value)
                var token0values = web3.utils.hexToNumberString(token0value)
                if (Number(token0values) > Number(token1values)) {
                    value = (e.target.value * (1 / amountsRatio)).toFixed(18)
                }
                else {
                    value = (e.target.value * (amountsRatio)).toFixed(18)
                }
                ToOrFrom = false;
                amountRatio = amountsRatio;
                document.getElementById("priceRangeMax1").value = toFixed(value2)
                value2 = 0;
                if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {

                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                }
                else {
                    if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Yetersiz Bakiye..."
                    } else {
                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Devam"
                    }
                }
            }
        }

        document.getElementById("priceRangeMax2").addEventListener("input", captureValue);
    }

    //Function that returns transaction data by giving ticks and values to add liquidity to the pool
    async function AddLiqudityAbi(_token0, _token1, _fee, _tickLower, _tickUpper, _amount0Desired, _amount1Desired, _deadlineMinute, _slippage) {
        const _dataAddLiqudityAbi = {
            token0: _token0,
            token1: _token1,
            fee: _fee,
            tickLower: _tickLower,
            tickUpper: _tickUpper,
            amount0Desired: _amount0Desired,
            amount1Desired: _amount1Desired,
            recipient: props.address,
            deadlineMinute: _deadlineMinute,
            slippage: _slippage
        }
        let result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_LQABI, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_dataAddLiqudityAbi)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //This function settings section controls the deadline upper limit.
    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-light rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Değer aralık dışında"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }
    //This function settings section controls the slippage upper limit.
    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-light rounded div-white form-control"
                document.getElementById("toleranscheck").className = "border border-light rounded div-white"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("toleranscheck").style.borderColor = "red"
                document.getElementById("danger").innerText = " "

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Değer aralık dışında"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }

    return (
        <section className="root pt-0">
            <div className=" p-0  pt-6 " >
                <div className=" row  justify-content-center pt-0 " >
                    <div className=" card-body-tabs justify-content-center col-12 col-md-5 col-lg-5 mb-5  pt-0 div-white rounded" id="addliqudity"  >
                        <div className=" border-black p-3 rounded" id="divcardliqudity" >
                            {/* ds */}
                            <div className="   p-2 mb-2 pt-0 " >
                                <div className="row" >
                                    {/* From component start  */}
                                    <div className="col-12 col-lg-6 ">
                                        <div >
                                            <br />
                                            <div className="row">
                                                <div className=" col-12 ml-12 pb-2" >
                                                    <h4><strong>Likidite Ekle</strong></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            <Button className=" btn-pill btn-yotta21  div-yotta21" onClick={() => {
                                                                toggleModal3()
                                                                setTimeout(() => {
                                                                    document.getElementById("slippage").value = slippage
                                                                    document.getElementById("deadline").value = deadlineMinute
                                                                }, 1000);
                                                            }} ><i className="fas fa-cog  " style={divstye3}  ></i></Button>
                                                        </div>
                                                        {/* modal setting start */}
                                                        <Modal
                                                            isOpen={isOpen3}
                                                            onRequestClose={toggleModal3}
                                                            contentLabel="My dialog"
                                                            className="mymodal4" /*"modal-dialog modal-dialog-centered rounded"*/
                                                            overlayClassName="myoverlay"
                                                            closeTimeoutMS={500}
                                                            aria-hidden="true"
                                                        >
                                                            <div className="rounded " id="sums">
                                                                <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                                                    document.getElementById("deadline").value = 0
                                                                    document.getElementById("slippage").value = 0
                                                                }}>
                                                                    <span aria-hidden="true" id="close" onClick={() => {
                                                                        if (document.getElementById("dangers") != null) {
                                                                            document.getElementById("danger").removeChild(document.getElementById("dangers"))
                                                                            document.getElementById("slippage").style.borderColor = "white"
                                                                        }
                                                                        if (document.getElementById("dangers2") != null) {
                                                                            document.getElementById("danger2").removeChild(document.getElementById("dangers2"))
                                                                            document.getElementById("deadline").style.borderColor = "white"
                                                                        }
                                                                        toggleModal3()
                                                                    }}>×</span>
                                                                </button>
                                                                <div className="card-header  text-center pb-0 pt-0">
                                                                    <h2 className="h4 font-medium text-center pt-0">Ayarlar</h2>
                                                                </div>
                                                                <div className="card-body text-center pb-0 pt-0 font-smedium  div-white " >
                                                                    <div className="row ">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium">Kayma Toleransı </a>
                                                                                <button className="border-0 font-medium div-white" id="Popover1" >
                                                                                    <i className="far fa-question-circle"></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen1} target="Popover1" toggle={toggle1}>
                                                                                    <PopoverBody>Fiyatın istenmeyen şekilde bu yüzdeden daha fazla değişmesi durumunda işleminiz geri döner.</PopoverBody>
                                                                                </Popover>
                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0  font-medium py-2">
                                                                                <output className="text-right div-white col-lg-8" >
                                                                                    <InputGroup >
                                                                                        <InputGroupAddon addonType="prepend" >
                                                                                            <InputGroupText className="div-white">
                                                                                                <Input addon type="checkbox" aria-label="Checkbox for following text input" id="toleranscheck" className="div-white" onChange={() => {
                                                                                                    if (document.getElementById("toleranscheck").checked == true) {
                                                                                                        document.getElementById("slippage").value = 0.05;
                                                                                                    }
                                                                                                    else {
                                                                                                        document.getElementById("slippage").value = null
                                                                                                    }
                                                                                                }} />&nbsp;  Otomatik
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input className="div-white " placeholder="%0.5" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="50" id="slippage" onChange={() => {
                                                                                            settingControlslippage()
                                                                                        }}></Input>
                                                                                    </InputGroup>
                                                                                </output>
                                                                            </div>
                                                                        </div>
                                                                        <div id="danger"></div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left " >
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium">İşlem Son Tarihi</a>
                                                                                <button className="border-0 div-white" id="Popover2"  >
                                                                                    <i className="far fa-question-circle font-medium" ></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen2} target="Popover2" toggle={toggle2} className="div-white" >
                                                                                    <PopoverBody>İşleminiz bu süreden daha uzun bir süredir beklemedeyse geri alınacaktır.</PopoverBody>
                                                                                </Popover>
                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0 font-medium py-2">
                                                                                <output className="text-left col-lg-6 font-medium div-white" >
                                                                                    <Input placeholder="30" id="deadline" className="div-white" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="180" onChange={() => {
                                                                                        settingControldeadline()
                                                                                    }} />
                                                                                </output>
                                                                                <output className="col-lg-3 text-left" >
                                                                                    <a className="font-left">Dakika</a>
                                                                                </output>
                                                                            </div>
                                                                        </div>
                                                                        <div id="danger2"></div>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <button className="btn btn-yotta21 col-lg-12" onClick={() => {
                                                                    if (document.getElementById("toleranscheck").checked == true) {
                                                                        slippage = 0.05
                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value
                                                                        }
                                                                    }
                                                                    else {
                                                                        if (document.getElementById("slippage").value == "" || document.getElementById("slippage").value == null) {
                                                                            slippage = 0.05
                                                                        }
                                                                        else {
                                                                            slippage = document.getElementById("slippage").value

                                                                        }
                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value
                                                                        }
                                                                    }
                                                                    toggleModal3()
                                                                }} id="save">Kaydet</button>
                                                            </div>
                                                        </Modal>
                                                        {/* modal setting end */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-12 col-ml-12 pt-0 pb-0 m-0 ml-0 p-0 " >
                                        <a className="text-center">Çift seçiniz</a>
                                    </div>
                                    <div className="col-12 col-lg-6 card-tags card-headers pt-0 " >
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12  ">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            {/* jeton1 gelecek */}
                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect1" style={divstye3} onClick={() => {
                                                                balance(ottaAddress, "OTTA").then((ver) => {
                                                                    document.getElementById("Ottabalance").innerHTML = Number(ver).toPrecision(5) + " OTTA";
                                                                });
                                                                balance(taumAddress, "TAUM").then((ver) => {
                                                                    document.getElementById("Taumbalance").innerHTML = Number(ver).toPrecision(5) + " LpDaiSdr21";
                                                                });
                                                                balance(ttfAddress, "TTFF").then((ver) => {
                                                                    document.getElementById("TTFbalance").innerHTML = Number(ver).toPrecision(5) + " Sdr21";
                                                                });
                                                                toggleModal();
                                                            }} >
                                                                <img id="imageId" src={yotta21logo} width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <a id="token0" className="font-small" value="deneme" >Token Seçiniz</a>&nbsp;&nbsp;
                                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                            </button>
                                                            {/* Token select Modal Start */}
                                                            <Modal
                                                                isOpen={isOpen}
                                                                onRequestClose={toggleModal}
                                                                contentLabel="My dialog"
                                                                className="mymodal"
                                                                overlayClassName="myoverlay"
                                                                closeTimeoutMS={500}
                                                            >
                                                                <div className="card-header border-bottom text-center ">
                                                                    <span className="d-block">
                                                                        <span className="display-3 font-weight-bold">
                                                                            Token Listesi
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="card-body p-2 col-mg-12">
                                                                    <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                                                    <br></br><br></br>
                                                                    {/* Token ETH Selected  start */}
                                                                    <ListGroup>
                                                                        <ListGroupItem tag="button" action onClick={() => {
                                                                            document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png";
                                                                            document.getElementById("imageId").style.width = "25px";
                                                                            image0 = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                                                                            document.getElementById("token0").textContent = "Sdr21Shares"
                                                                            token0 = "Sdr21Shares"
                                                                            document.getElementById("token0").value = ottaAddress
                                                                            token0value = ottaAddress
                                                                            document.getElementById("tabs-text-1-tab").innerHTML = "&nbsp;&nbsp;" + "Sdr21Shares" + "&nbsp;&nbsp;"
                                                                            document.getElementById("token-1-1-1").innerText = "Sdr21Shares";
                                                                            document.getElementById("token-1-2-1").innerText = "Sdr21Shares";
                                                                            document.getElementById("currentpricetoken-1-1-1").innerText = "Sdr21Shares";
                                                                            document.getElementById("token-2-2-1").innerText = "Sdr21Shares";
                                                                            document.getElementById("token-2-2-2-2").innerText = "Sdr21Shares";
                                                                            document.getElementById("currentpricetoken-2-2-1").innerText = "Sdr21Shares";
                                                                            document.getElementById("modal-from").disabled = true
                                                                            document.getElementById("tabs-icons-text-1-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-2-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-3-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            toggleModal()
                                                                        }}>
                                                                            <img src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png" width="35px"></img>
                                                                            <a className="container">Sdr21Shares</a>
                                                                            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                                <div className="post-details mb-3 mb-lg-0">
                                                                                    <span className="d-block font-small">Sdr21Shares</span>
                                                                                </div>
                                                                                <div className="post-meta">
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <span className="font-small">Bakiye </span>
                                                                                    <span className="justify-content-between font-small" id="Ottabalance"></span>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                        {/* Token DAI Selected end  */}

                                                                        {/* Token DAI Selected start  */}
                                                                        <ListGroupItem tag="button" action onClick={() => {
                                                                            document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png";
                                                                            document.getElementById("imageId").style.width = "25px";
                                                                            image0 = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                                                                            document.getElementById("token0").textContent = "LpDaiSdr21";
                                                                            document.getElementById("tabs-text-1-tab").innerHTML = "&nbsp;&nbsp;" + "LpDaiSdr21" + "&nbsp;&nbsp;"
                                                                            document.getElementById("token-1-1-1").innerText = "LpDaiSdr21";
                                                                            document.getElementById("token-1-2-1").innerText = "LpDaiSdr21";
                                                                            document.getElementById("currentpricetoken-1-1-1").innerText = "LpDaiSdr21";
                                                                            document.getElementById("token-2-2-1").innerText = "LpDaiSdr21";
                                                                            document.getElementById("token-2-2-2-2").innerText = "LpDaiSdr21";
                                                                            document.getElementById("currentpricetoken-2-2-1").innerText = "LpDaiSdr21";
                                                                            token0 = "LpDaiSdr21"
                                                                            document.getElementById("token0").value = taumAddress
                                                                            token0value = taumAddress
                                                                            document.getElementById("modal-from").disabled = true
                                                                            document.getElementById("tabs-icons-text-1-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-2-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-3-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            toggleModal()
                                                                        }}>
                                                                            <img src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png" width="35px"></img>
                                                                            <a className="container">LpDaiSdr21</a>
                                                                            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                                <div className="post-details mb-3 mb-lg-0">
                                                                                    <span className="d-block font-small">LpDaiSdr21</span>
                                                                                </div>
                                                                                <div className="post-meta">
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <span className="font-small">Bakiye </span>
                                                                                    <span className="justify-content-between font-small" id="Taumbalance"></span>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                        {/* Token DAI Selected end  */}

                                                                        {/* Token BTC Selected start  */}
                                                                        <ListGroupItem tag="button" action onClick={() => {
                                                                            document.getElementById("imageId").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png";
                                                                            document.getElementById("imageId").style.width = "25px";
                                                                            image0 = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                                                                            document.getElementById("token0").textContent = "Sdr21 ";
                                                                            document.getElementById("tabs-text-1-tab").innerHTML = "&nbsp;&nbsp;" + "Sdr21" + "&nbsp;&nbsp;"
                                                                            document.getElementById("token-1-1-1").innerText = "Sdr21";
                                                                            document.getElementById("token-1-2-1").innerText = "Sdr21";
                                                                            document.getElementById("currentpricetoken-1-1-1").innerText = "Sdr21";
                                                                            document.getElementById("token-2-2-1").innerText = "Sdr21";
                                                                            document.getElementById("token-2-2-2-2").innerText = "Sdr21";
                                                                            document.getElementById("currentpricetoken-2-2-1").innerText = "Sdr21";
                                                                            token0 = "Sdr21"
                                                                            document.getElementById("token0").value = ttfAddress
                                                                            token0value = ttfAddress
                                                                            document.getElementById("modal-from").disabled = true
                                                                            document.getElementById("tabs-icons-text-1-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-2-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-3-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            toggleModal()
                                                                        }}>
                                                                            <img src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png" width="35px"></img>
                                                                            <a className="container">Sdr21</a>
                                                                            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                                <div className="post-details mb-3 mb-lg-0">
                                                                                    <span className="d-block font-small">Sdr21</span>
                                                                                </div>
                                                                                <div className="post-meta">
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <span className="font-small">Bakiye </span>
                                                                                    <span className="justify-content-between font-small" id="TTFbalance"></span>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                        {/* Token BTC Selected end  */}
                                                                    </ListGroup>
                                                                </div>
                                                            </Modal>
                                                            {/* token select modal end */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 card-tags">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            {/* jeton2 gelecek */}
                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect2" style={divstye3} onClick={() => {
                                                                balance(wethAddress, "WETH").then((ver) => {
                                                                    document.getElementById("wethBalance").innerText = Number(ver).toPrecision(5) + " WETH";
                                                                });

                                                                document.getElementById("modal-from").disabled = true
                                                                toggleModal2();
                                                            }}>
                                                                <img id="imageId1" src={yotta21logo} width="25px" />&nbsp;&nbsp;
                                                                <a id="token1" className="font-small" value={wethAddress} > Token Seçiniz </a>&nbsp;&nbsp;
                                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                            </button>
                                                            <Modal
                                                                isOpen={isOpen2}
                                                                onRequestClose={toggleModal2}
                                                                contentLabel="My dialog"
                                                                className="mymodal"
                                                                overlayClassName="myoverlay"
                                                                closeTimeoutMS={500}
                                                            >
                                                                <div className="card-header border-bottom text-center ">
                                                                    <span className="d-block">
                                                                        <span className="display-3 font-weight-bold">
                                                                            Token Listesi
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="card-body p-2 col-mg-12">
                                                                    <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz..</span>
                                                                    <br></br><br></br>
                                                                    {/* Token ETH Selected  start */}
                                                                    <ListGroup>
                                                                        <ListGroupItem tag="button" action onClick={() => {
                                                                            document.getElementById("imageId1").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg";
                                                                            document.getElementById("imageId1").style.width = "25px";
                                                                            image1 = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                                                                            document.getElementById("token1").textContent = "WETH";
                                                                            document.getElementById("tabs-text-2-tab").innerHTML = "&nbsp;&nbsp;" + "WETH" + "&nbsp;&nbsp;"
                                                                            document.getElementById("token-1-1-2").innerText = "WETH";
                                                                            document.getElementById("token-1-2-2").innerText = "WETH";
                                                                            document.getElementById("currentpricetoken-1-1-2").innerText = "WETH";
                                                                            document.getElementById("token-2-1-1").innerText = "WETH";
                                                                            document.getElementById("token-2-2-1-2").innerText = "WETH";
                                                                            document.getElementById("currentpricetoken-2-1-1").innerText = "WETH";
                                                                            token1 = "WETH"
                                                                            //sadece burada DAI için işlem yapılıyor.
                                                                            document.getElementById("token1").value = wethAddress
                                                                            token1value = wethAddress
                                                                            balance(wethAddress, "WETH").then((ver) => {
                                                                                document.getElementById("balanceid").innerText = Number(ver).toPrecision(5);
                                                                            });
                                                                            document.getElementById("tabs-icons-text-1-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-2-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            document.getElementById("tabs-icons-text-3-tab").className = "nav-link mb-sm-3 mb-md-0 font-small div-white "
                                                                            toggleModal2()
                                                                        }}>
                                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="35px"></img>
                                                                            <a className="container">WETH</a>
                                                                            <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                                <div className="post-details mb-3 mb-lg-0">
                                                                                    <span className="d-block font-small">WETH</span>
                                                                                </div>
                                                                                <div className="post-meta">
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <a className="text-dark mr-3" href="#"></a>
                                                                                    <span className="font-small">Bakiye </span>
                                                                                    <span className="justify-content-between font-small" id="wethBalance"></span>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </div>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-12 nav-wrapper position-relative mb-0 " >
                                <ul className="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                                    <li>Ücret katmanı</li>
                                </ul>
                                <ul className="nav nav-pills nav-fill flex-column flex-md-row pt-1  " id="tabs-icons-text" role="tablist" >
                                    {/* 0.05 Range */}
                                    <li className="nav-item">
                                        <button className="nav-link mb-sm-3 mb-md-0  font-small div-white " id="tabs-icons-text-1-tab" data-toggle="tab" role="tab" aria-controls="tabs-icons-text-1" aria-selected="false" onClick={async () => {
                                            fee = 500;
                                            tickspacing = 10;
                                            //token1
                                            document.getElementById("token-1-1-decrease").innerText = "-0.1%";
                                            document.getElementById("token-1-1-decrease").value = "0.1";
                                            document.getElementById("token-1-2-decrease").innerText = "-0.1%";
                                            document.getElementById("token-1-2-decrease").value = "0.1";
                                            document.getElementById("token-1-1-increase").innerText = "+0.1%";
                                            document.getElementById("token-1-1-increase").value = "0.1";
                                            document.getElementById("token-1-2-increase").innerText = "+0.1%";
                                            document.getElementById("token-1-2-increase").value = "0.1";
                                            //token2 
                                            document.getElementById("token-2-1-1-decrease").innerText = "-0.1%";
                                            document.getElementById("token-2-1-1-decrease").value = "0.1";
                                            document.getElementById("token-2-2-1-decrease").innerText = "-0.1%";
                                            document.getElementById("token-2-2-1-decrease").value = "0.1";
                                            document.getElementById("token-2-1-1-incraese").innerText = "+0.1%";
                                            document.getElementById("token-2-1-1-incraese").value = "0.1";
                                            document.getElementById("token-2-2-1-incraese").innerText = "+0.1%";
                                            document.getElementById("token-2-2-1-incraese").value = "0.1";
                                            //getPoolData ile currentprice hesaplama
                                            const ver = await getPoolData(token1value, token0value, fee)
                                            currentTick = ver.currentTick;
                                            _token0Decimal = ver.token0Decimal;
                                            _token1Decimal = ver.token1Decimal;
                                            poolLiqudity = ver.poolLiquidity
                                            normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmin = normalizeTickmin;
                                            normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmax = normalizeTickmax;
                                            getCurrentPrice(ver.currentSqrtPriceX96).then((ver1) => {
                                                currentPrice = ver1
                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                if (Number(token0values) > Number(token1values)) {
                                                    document.getElementById("currentprice-1").innerText = (ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(1 / ver1).toPrecision(6)
                                                }
                                                else {
                                                    document.getElementById("currentprice-1").innerText = (1 / ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(ver1).toPrecision(6)
                                                }
                                                if (document.getElementById("token0").innerText != "Token Seçiniz" && document.getElementById("token1").innerText != "Token Seçiniz") {
                                                    document.getElementById("token-1-1-decrease").disabled = false;
                                                    document.getElementById("token-1-1-increase").disabled = false;
                                                    document.getElementById("token-1-2-decrease").disabled = false;
                                                    document.getElementById("token-1-2-increase").disabled = false;
                                                    document.getElementById("btnfullrange").disabled = false;
                                                    document.getElementById("btnselect3").disabled = false;
                                                    document.getElementById("btnselect4").disabled = false;
                                                    document.getElementById("btnselect5").disabled = false;
                                                    document.getElementById("btnselect6").disabled = false;
                                                    document.getElementById("btnselect7").disabled = false;
                                                    document.getElementById("btnselect8").disabled = false;
                                                }
                                                balance(token1value, "DAI").then((data) => {
                                                    token1Balance = data;
                                                    if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                        document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                                                    }
                                                    else {
                                                        document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                                                    }
                                                })
                                                balance(token0value, "WETH").then((data) => {
                                                    token0Balance = data;
                                                    if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                        document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                                                    }
                                                    else {
                                                        document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                                                    }
                                                })
                                            })
                                            document.getElementById("tabs-icons-text-3-tab").ariaSelected = "false"
                                            document.getElementById("tabs-icons-text-2-tab").ariaSelected = "false"
                                        }}>
                                            <i className="fas fa-tint"></i>
                                            0.05% fee
                                            <br />
                                            {/* <a className="font-xsmall">Best for stable pairs</a> */}
                                        </button>
                                    </li>
                                    {/* 0.3 Range */}
                                    <li className="nav-item">
                                        <button className="nav-link mb-sm-3 mb-md-0 font-small div-white" id="tabs-icons-text-2-tab" data-toggle="tab" role="tab" aria-controls="tabs-icons-text-2" aria-selected="false" onClick={async () => {
                                            fee = 3000;
                                            tickspacing = 60;
                                            document.getElementById("token-1-1-decrease").innerText = "-0.6%";
                                            document.getElementById("token-1-1-decrease").value = "0.6";
                                            document.getElementById("token-1-2-decrease").innerText = "-0.6%";
                                            document.getElementById("token-1-2-decrease").value = "0.6";
                                            document.getElementById("token-1-1-increase").innerText = "+0.6%";
                                            document.getElementById("token-1-1-increase").value = "0.6";
                                            document.getElementById("token-1-2-increase").innerText = "+0.6%";
                                            document.getElementById("token-1-2-increase").value = "0.6";
                                            //token2 
                                            document.getElementById("token-2-1-1-decrease").innerText = "-0.6%";
                                            document.getElementById("token-2-1-1-decrease").value = "0.6";
                                            document.getElementById("token-2-2-1-decrease").innerText = "-0.6%";
                                            document.getElementById("token-2-2-1-decrease").value = "0.6";
                                            document.getElementById("token-2-1-1-incraese").innerText = "+0.6%";
                                            document.getElementById("token-2-1-1-incraese").value = "0.6";
                                            document.getElementById("token-2-2-1-incraese").innerText = "+0.6%";
                                            document.getElementById("token-2-2-1-incraese").value = "0.6";
                                            //getPoolData ile currentprice hesaplama
                                            const ver = await getPoolData(token0value, token1value, fee)
                                            currentTick = ver.currentTick;
                                            _token0Decimal = ver.token0Decimal;
                                            _token1Decimal = ver.token1Decimal;
                                            poolLiqudity = ver.poolLiquidity
                                            normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmin = normalizeTickmin;
                                            normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmax = normalizeTickmax;
                                            getCurrentPrice(ver.currentSqrtPriceX96).then((ver1) => {
                                                currentPrice = ver1
                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                if (Number(token0values) > Number(token1values)) {
                                                    document.getElementById("currentprice-1").innerText = (ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(1 / ver1).toPrecision(6)
                                                }
                                                else {
                                                    document.getElementById("currentprice-1").innerText = (1 / ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(ver1).toPrecision(6)
                                                }
                                                if (document.getElementById("token0").innerText != "Token Seçiniz" && document.getElementById("token1").innerText != "Token Seçiniz") {
                                                    document.getElementById("token-1-1-decrease").disabled = false;
                                                    document.getElementById("token-1-1-increase").disabled = false;
                                                    document.getElementById("token-1-2-decrease").disabled = false;
                                                    document.getElementById("token-1-2-increase").disabled = false;
                                                    document.getElementById("btnfullrange").disabled = false;
                                                    document.getElementById("btnselect3").disabled = false;
                                                    document.getElementById("btnselect4").disabled = false;
                                                    document.getElementById("btnselect5").disabled = false;
                                                    document.getElementById("btnselect6").disabled = false;
                                                    document.getElementById("btnselect7").disabled = false;
                                                    document.getElementById("btnselect8").disabled = false;
                                                }
                                            })
                                            document.getElementById("tabs-icons-text-3-tab").ariaSelected = "false"
                                            document.getElementById("tabs-icons-text-1-tab").ariaSelected = "false"

                                            balance(token0value, "WETH").then((data) => {
                                                token0Balance = data;
                                                if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                                                }
                                                else {
                                                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                                                }
                                            })
                                        }}>
                                            <i className="fas fa-bug"></i>
                                            0.3% fee&nbsp;
                                            <br />
                                        </button>
                                    </li>
                                    {/* 1 Range */}
                                    <li className="nav-item">
                                        <button className="nav-link mb-sm-3 mb-md-0 font-small div-white " id="tabs-icons-text-3-tab" data-toggle="tab" role="tab" aria-controls="tabs-icons-text-3" aria-selected="false" onClick={async () => {
                                            fee = 10000;
                                            tickspacing = 200;
                                            document.getElementById("token-1-1-decrease").innerText = "-2%";
                                            document.getElementById("token-1-1-decrease").value = "2";
                                            document.getElementById("token-1-2-decrease").innerText = "-2%";
                                            document.getElementById("token-1-2-decrease").value = "2";
                                            document.getElementById("token-1-1-increase").innerText = "+2%";
                                            document.getElementById("token-1-1-increase").value = "2";
                                            document.getElementById("token-1-2-increase").innerText = "+2%";
                                            document.getElementById("token-1-2-increase").value = "2";
                                            //token2 
                                            document.getElementById("token-2-1-1-decrease").innerText = "-2%";
                                            document.getElementById("token-2-1-1-decrease").value = "2";
                                            document.getElementById("token-2-2-1-decrease").innerText = "-2%";
                                            document.getElementById("token-2-2-1-decrease").value = "2";
                                            document.getElementById("token-2-1-1-incraese").innerText = "+2%";
                                            document.getElementById("token-2-1-1-incraese").value = "2";
                                            document.getElementById("token-2-2-1-incraese").innerText = "+2%";
                                            document.getElementById("token-2-2-1-incraese").value = "2";

                                            //getPoolData ile currentprice hesaplama
                                            const ver = await getPoolData(token0value, token1value, fee)
                                            currentTick = ver.currentTick;
                                            _token0Decimal = ver.token0Decimal;
                                            _token1Decimal = ver.token1Decimal;
                                            poolLiqudity = ver.poolLiquidity
                                            normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmin = normalizeTickmin;
                                            normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                            normalTickmax = normalizeTickmax;

                                            getCurrentPrice(ver.currentSqrtPriceX96).then((ver1) => {
                                                currentPrice = ver1
                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                if (Number(token0values) > Number(token1values)) {
                                                    document.getElementById("currentprice-1").innerText = (ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(1 / ver1).toPrecision(6)
                                                }
                                                else {
                                                    document.getElementById("currentprice-1").innerText = (1 / ver1).toPrecision(6)
                                                    document.getElementById("currentprice-2").innerText = Number(ver1).toPrecision(6)
                                                }
                                                if (document.getElementById("token0").innerText != "Token Seçiniz" && document.getElementById("token1").innerText != "Token Seçiniz") {
                                                    document.getElementById("token-1-1-decrease").disabled = false;
                                                    document.getElementById("token-1-1-increase").disabled = false;
                                                    document.getElementById("token-1-2-decrease").disabled = false;
                                                    document.getElementById("token-1-2-increase").disabled = false;
                                                    document.getElementById("btnfullrange").disabled = false;
                                                    document.getElementById("btnselect3").disabled = false;
                                                    document.getElementById("btnselect4").disabled = false;
                                                    document.getElementById("btnselect5").disabled = false;
                                                    document.getElementById("btnselect6").disabled = false;
                                                    document.getElementById("btnselect7").disabled = false;
                                                    document.getElementById("btnselect8").disabled = false;
                                                }
                                            })
                                            document.getElementById("tabs-icons-text-1-tab").ariaSelected = "false"
                                            document.getElementById("tabs-icons-text-2-tab").ariaSelected = "false"
                                            balance(token0value, "WETH").then((data) => {
                                                token0Balance = data;
                                                if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                    document.getElementById("frombalance").innerText = Number(data).toPrecision(5)
                                                }
                                                else {
                                                    document.getElementById("balanceid").innerText = Number(data).toPrecision(5)
                                                }
                                            })
                                        }}>
                                            <i className="fas fa-tint"></i>
                                            1% fee &nbsp;&nbsp;&nbsp;&nbsp;
                                            <br />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="row pt-0">
                                <div className="col-md-12 col-lg-12">
                                    {/* <!-- Tab Nav --> */}
                                    <div className="nav-wrapper position-relative mb-0">
                                        <ul className="nav nav-pills flex-column flex-sm-row justify-content-start btn-group    " id="tabs-text" role="tablist" >
                                            <li className="nav-item col-12 col-lg-8 py-2">
                                                <a className="" >Fiyat Aralığını Belirle
                                                </a>
                                            </li>
                                            {/* token0 tab */}
                                            <li className=" mr-sm-0 mr-md-0 font-smalltosmall">
                                                {/*id="fromtoken"*/}
                                                <a className="nav-link mb-sm-3 mb-md-0 active text-center " id="tabs-text-1-tab" data-toggle="tab" href="#tabs-text-1" role="tab" aria-controls="tabs-text-1" aria-selected="true" onClick={async () => {
                                                    document.getElementById("token-1-1-1").innerText = token0
                                                    document.getElementById("token-1-1-2").innerText = token1
                                                    document.getElementById("token-1-2-1").innerText = token0
                                                    document.getElementById("token-1-2-2").innerText = token1
                                                    document.getElementById("currentpricetoken-1-1-1").innerText = token0
                                                    document.getElementById("currentpricetoken-1-1-2").innerText = token1
                                                    document.getElementById("token0").innerText = token0
                                                    document.getElementById("token1").innerText = token1
                                                    document.getElementById("fromid").innerText = token0
                                                    document.getElementById("tokenid").innerText = token1
                                                    document.getElementById("token0").value = token0value
                                                    document.getElementById("token1").value = token1value
                                                    document.getElementById("minpricevalue").value = "0"
                                                    document.getElementById("minpricevalue2").value = "0"
                                                    document.getElementById("maxpricevalue").value = "0"
                                                    document.getElementById("maxpricevalue2").value = "0"
                                                    document.getElementById("frombalance").innerText = token0Balance
                                                    document.getElementById("balanceid").innerText = token1Balance
                                                    document.getElementById("imageId").src = image0
                                                    document.getElementById("imageId1").src = image1

                                                    if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                        fee = 500;
                                                        tickspacing = 10;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                    else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                        fee = 3000;
                                                        tickspacing = 60;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                    else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                        fee = 10000;
                                                        tickspacing = 200;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                }}>&nbsp;&nbsp;&nbsp;---&nbsp;&nbsp;&nbsp;</a>
                                            </li>
                                            &nbsp;
                                            {/* token1 tab */}
                                            <li className=" mr-sm-3 mr-md-0  ">
                                                {/*id="totoken"*/}
                                                <a className="nav-link mb-sm-3 mb-md-0 font-smalltosmall text-center " id="tabs-text-2-tab" data-toggle="tab" href="#tabs-text-2" role="tab" aria-controls="tabs-text-2" aria-selected="false" onClick={async () => {
                                                    document.getElementById("tabs-text-2-tab").ariaSelected = true;
                                                    document.getElementById("balanceid").innerText = token0Balance.toPrecision(5)
                                                    document.getElementById("frombalance").innerText = token1Balance.toPrecision(5)
                                                    document.getElementById("token-2-1-1").innerText = token1
                                                    document.getElementById("token-2-2-1").innerText = token0
                                                    document.getElementById("token-2-2-1-2").innerText = token1
                                                    document.getElementById("token-2-2-2-2").innerText = token0
                                                    document.getElementById("currentpricetoken-2-1-1").innerText = token1
                                                    document.getElementById("currentpricetoken-2-2-1").innerText = token0
                                                    document.getElementById("token0").innerText = token1
                                                    document.getElementById("token1").innerText = token0
                                                    document.getElementById("fromid").innerText = token1
                                                    document.getElementById("tokenid").innerText = token0
                                                    document.getElementById("imageId").src = image1
                                                    document.getElementById("imageId1").src = image0
                                                    document.getElementById("token1").value = token0value
                                                    document.getElementById("token0").value = token1value
                                                    document.getElementById("minpricevalue").value = "0"
                                                    document.getElementById("minpricevalue2").value = "0"
                                                    document.getElementById("maxpricevalue").value = "0"
                                                    document.getElementById("maxpricevalue2").value = "0"
                                                    if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                        fee = 500;
                                                        tickspacing = 10;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                    else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                        fee = 3000;
                                                        tickspacing = 60;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                    else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                        fee = 10000;
                                                        tickspacing = 200;
                                                        const ver = await getPoolData(token0value, token1value, fee)
                                                        poolLiqudity = ver.poolLiquidity
                                                        normalizeTickmin = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmin = normalizeTickmin;
                                                        normalizeTickmax = await getNormalizedTick(currentTick, tickspacing, ver.token0Decimal, ver.token1Decimal)
                                                        normalTickmax = normalizeTickmax;
                                                    }
                                                }}>&nbsp;&nbsp;&nbsp;---&nbsp;&nbsp;&nbsp;
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- End of Tab Nav --> */}
                                    {/* <!-- Tab Content --> */}
                                    <div className="card-tabs shadow-inset  border-light p-3 rounded mb-3 " >
                                        <div className="card-body-tabs p-0 div-white" >
                                            <div className="tab-content" id="tabcontent1" >
                                                <div className="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
                                                    {/* ds */}
                                                    <div className="   p-2 mb-2 pt-0 " >
                                                        <div className="row justify-content-center " >
                                                            <div className="col-12 col-lg-6 card-tags ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-center ">
                                                                                    {/* jeton1 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="btn btn-primary col-md-12 mb-1" id="btnselect3" style={divstye3}>
                                                                                        <a id="minprice" className="font-small text-center"  >Min. Fiyat </a>&nbsp;&nbsp;
                                                                                        <input className="col-md-10 text-center" id="minpricevalue" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" type="number" style={divstye3} disabled />
                                                                                        <br></br>
                                                                                        <a className="font-xsmall " id="token-1-1-1">- </a>
                                                                                        <a className="font-xsmall "> - </a>
                                                                                        <a className="font-xsmall " id="token-1-1-2"> -</a>
                                                                                    </button>
                                                                                    <br></br>
                                                                                    {/* min price decrease start */}
                                                                                    <Button className="btn btn-yotta21 col-5 div-yotta21  " id="token-1-1-decrease" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                                                            if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                                fee = 500;
                                                                                                tickspacing = 10;
                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false);
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true);
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }

                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                                fee = 3000;
                                                                                                tickspacing = 60;
                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                                fee = 10000;
                                                                                                tickspacing = 200;
                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                                fee = 500;
                                                                                                tickspacing = 10;
                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false);
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true);
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);

                                                                                                }
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                                fee = 3000;
                                                                                                tickspacing = 60;

                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                                fee = 10000;
                                                                                                tickspacing = 200;
                                                                                                var token1values = web3.utils.hexToNumberString(token1value)
                                                                                                var token0values = web3.utils.hexToNumberString(token0value)
                                                                                                if (Number(token0values) > Number(token1values)) {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                    document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                else {
                                                                                                    var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                    document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                                }
                                                                                                normalTickmin = normalTickminA.tickLower;
                                                                                                MinPrice = normalTickminA.minPrice
                                                                                                PriceControl();
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    }>
                                                                                        -0.3%
                                                                                    </Button>
                                                                                    &nbsp;&nbsp;
                                                                                    &nbsp;&nbsp;
                                                                                    <br></br>
                                                                                    {/* min price decrease end */}

                                                                                    {/* min price increase start */}
                                                                                    <Button className="btn btn-yotta21 col-5 div-yotta21" id="token-1-1-increase" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>+0.3%</Button>
                                                                                    {/* min price decrease end */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 card-tags ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-center"  >
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="btn btn-primary col-md-12 mb-1" id="btnselect4" style={divstye3}>
                                                                                        <a id="maxprice" className="font-small text-center"  >Max. Fiyat </a>&nbsp;&nbsp;
                                                                                        <input className=" text-center col-md-10" id="maxpricevalue" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" type="text" style={divstye3} disabled />
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="token-1-2-1">- </a>
                                                                                        <a className="font-xsmall"> - </a>
                                                                                        <a className="font-xsmall" id="token-1-2-2"> - </a>
                                                                                    </button>
                                                                                    <br></br>

                                                                                    {/* max price decrease start */}
                                                                                    <Button className="btn   btn-yotta21 col-5 div-yotta21" id="token-1-2-decrease" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }

                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>-0.3%</Button>
                                                                                    &nbsp;&nbsp;
                                                                                    &nbsp;&nbsp;
                                                                                    <br></br>
                                                                                    {/* max price decrease end */}

                                                                                    {/* max price increase start */}
                                                                                    <Button className="btn   btn-yotta21 col-5 div-yotta21" id="token-1-2-increase" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>+0.3%</Button>

                                                                                    {/* max price İNCREASE END */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container col-12 col-lg-12">
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 pt-3">
                                                                        <div>
                                                                            <div className="card   div-white " >
                                                                                <div className="row justify-content-end border border-light rounded">
                                                                                    {/* jeton2 gelecek */}
                                                                                    {/* TODO: Tam aralık çalışmıyor yapılacak */}
                                                                                    <button type="button" className=" btn border-0 col-md-12 mb-1 div-white" id="btnfullrange" style={display} onClick={() => {
                                                                                        if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                                                                            if (document.getElementById("tabs-icons-text-1-tab").ariaSelected == "true") {
                                                                                                normalTickmin = -887270
                                                                                                normalTickmax = 887270

                                                                                                let _MaxPrice = getPriceFromTickDecimal(normalTickmax, _token0Decimal, _token1Decimal)
                                                                                                let _MinPrice = getPriceFromTickDecimal(normalTickmin, _token0Decimal, _token1Decimal)
                                                                                                MaxPrice = _MaxPrice.toFixed(40)
                                                                                                MinPrice = _MinPrice.toFixed(40)
                                                                                                document.getElementById("maxpricevalue").value = "∞"
                                                                                                document.getElementById("minpricevalue").value = "0"
                                                                                                PriceControl()
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("tabs-icons-text-2-tab").ariaSelected == "true") {
                                                                                                normalTickmin = -887220
                                                                                                normalTickmax = 887220
                                                                                                MaxPrice = (getPriceFromTickDecimal(normalTickmax, _token0Decimal, _token1Decimal))
                                                                                                MinPrice = (getPriceFromTickDecimal(normalTickmin, _token0Decimal, _token1Decimal))
                                                                                                document.getElementById("maxpricevalue").value = "∞"
                                                                                                document.getElementById("minpricevalue").value = "0"

                                                                                                PriceControl()
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                            else if (document.getElementById("tabs-icons-text-3-tab").ariaSelected == "true") {
                                                                                                normalTickmin = -887200
                                                                                                normalTickmax = 887200
                                                                                                MaxPrice = getPriceFromTickDecimal(normalTickmax, _token0Decimal, _token1Decimal);
                                                                                                MinPrice = getPriceFromTickDecimal(normalTickmin, _token0Decimal, _token1Decimal);
                                                                                                document.getElementById("maxpricevalue").value = "∞"
                                                                                                document.getElementById("minpricevalue").value = "0"
                                                                                               
                                                                                                PriceControl()
                                                                                                CalculatingFRomValue()
                                                                                                CalculatingToValue()
                                                                                            }
                                                                                        }
                                                                                    }} >
                                                                                        Tam Aralık
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id="alertdiv" disabled role="alert">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container col-12 col-lg-12">
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 pt-3">
                                                                        <div>
                                                                            <div className="card   div-white " >
                                                                                <div className="row justify-content-end border border-light rounded">
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="border-0 col-md-12 mb-1 div-white" id="btnselect5" >
                                                                                        <a className="font-small"  >  Mevcut fiyat</a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentprice-1">0.0</a>
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentpricetoken-1-1-1">-</a>
                                                                                        <a className="font-xsmall"> - </a>
                                                                                        <a className="font-xsmall" id="currentpricetoken-1-1-2">- </a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br></br>
                                                                        <div id="alertdiv" disabled role="alert">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* token pair 2 start  */}
                                                <div className="tab-pane fade" id="tabs-text-2" role="tabpanel" aria-labelledby="tabs-text-2-tab">
                                                    <div className="   p-2 mb-2 pt-0 " >
                                                        <div className="row justify-content-center" >
                                                            <div className="col-12 col-lg-6 card-tags card-headers pt-0 " >
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12   ">
                                                                        <div>
                                                                            <div className="card-body-tabs">
                                                                                <br />
                                                                                <div className="row justify-content-center">
                                                                                    {/* jeton1 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="btn btn-primary col-md-12 mb-1" id="btnselect6" style={divstye3}>
                                                                                        <a id="minprice" className="font-small text-center" disabled >Min. Fiyat </a>&nbsp;&nbsp;
                                                                                        <input className="col-md-10 text-center" id="minpricevalue2" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" type="number" style={divstye3} disabled />
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="token-2-1-1">- </a>
                                                                                        <a className="font-xsmall "> - </a>
                                                                                        <a className="font-xsmall " id="token-2-2-1"> -</a>
                                                                                    </button>
                                                                                    <br></br>
                                                                                    {/* min price decrease start */}
                                                                                    <Button className="btn btn-yotta21 col-5 div-yotta21  " id="token-2-1-1-decrease" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }

                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;

                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                               document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;

                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);

                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>
                                                                                        -0.3%
                                                                                    </Button>
                                                                                    {/* min price decrease end */}
                                                                                    &nbsp;&nbsp;
                                                                                    &nbsp;&nbsp;
                                                                                    <br></br>
                                                                                    {/* min price increase start */}
                                                                                    <Button className="btn btn-yotta21 col-5 div-yotta21" id="token-2-1-1-incraese" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()

                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue(token1value, token0value, fee, normalTickmin, normalTickmax)
                                                                                            CalculatingToValue(token0value, token1value, fee, normalTickmin, normalTickmax)
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("minpricevalue2").value = (1 / normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickminA = await getTickLowerAndPrice(normalTickmin, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("minpricevalue2").value = (normalTickminA.minPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmin = normalTickminA.tickLower;
                                                                                            MinPrice = normalTickminA.minPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>+0.3%</Button>
                                                                                    {/* min price increase end */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 card-tags ">
                                                                <div className="row " >
                                                                    <div className="col-12 col-md-12 col-lg-12">
                                                                        <div>
                                                                            <div className="card-body-tabs" >
                                                                                <br />
                                                                                <div className="row justify-content-center"  >
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="btn btn-primary col-md-12 mb-1" id="btnselect7" style={divstye3}>
                                                                                        <a id="maxprice2" className="font-small text-center"  >Max. Fiyat </a>&nbsp;&nbsp;
                                                                                        <input className="text-center col-md-10" id="maxpricevalue2" placeholder="0.0" type="text" style={divstye3} disabled />
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="token-2-2-1-2">- </a>
                                                                                        <a className="font-xsmall"> -  </a>
                                                                                        <a className="font-xsmall" id="token-2-2-2-2"> - </a>
                                                                                    </button>
                                                                                    <br></br>
                                                                                    <Button className="btn   btn-yotta21 col-5 div-yotta21" id="token-2-2-1-decrease" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>-0.3%</Button>
                                                                                    &nbsp;&nbsp;
                                                                                    &nbsp;&nbsp;<br></br>
                                                                                    <Button className="btn   btn-yotta21 col-5 div-yotta21" id="token-2-2-1-incraese" style={divstye7} onClick={async () => {
                                                                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                                                            fee = 500;
                                                                                            tickspacing = 10;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice

                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                                                            fee = 3000;
                                                                                            tickspacing = 60;

                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = await getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                        else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                                                            fee = 10000;
                                                                                            tickspacing = 200;
                                                                                            var token1values = web3.utils.hexToNumberString(token1value)
                                                                                            var token0values = web3.utils.hexToNumberString(token0value)
                                                                                            if (Number(token0values) > Number(token1values)) {
                                                                                                var normalTickmaxA = getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, false)
                                                                                                document.getElementById("maxpricevalue2").value = (1 / normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            else {
                                                                                                var normalTickmaxA = getTickUpperAndPrice(normalTickmax, tickspacing, _token0Decimal, _token1Decimal, true)
                                                                                                document.getElementById("maxpricevalue2").value = (normalTickmaxA.maxPrice).toPrecision(5);
                                                                                            }
                                                                                            normalTickmax = normalTickmaxA.tickUpper;
                                                                                            MaxPrice = normalTickmaxA.maxPrice
                                                                                            PriceControl();
                                                                                            CalculatingFRomValue()
                                                                                            CalculatingToValue()
                                                                                        }
                                                                                    }}>+0.3%</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="container col-12 col-lg-12">
                                                                <div className="row" >
                                                                    <div className="col-12 col-md-12 col-lg-12 pt-3">
                                                                        <div>
                                                                            <div className="card   div-white" >
                                                                                <div className="row justify-content-end border border-light rounded">
                                                                                    {/* jeton2 gelecek */}
                                                                                    <button disabled type="button" value="WETH" className="border-0 col-md-12 mb-1 div-white" id="btnselect8" style={divstye3}>
                                                                                        <a id="maxprice" className="font-small"  > Mevcut fiyat</a>&nbsp;&nbsp;
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentprice-2">0.0</a>
                                                                                        <br />
                                                                                        <a className="font-xsmall" id="currentpricetoken-2-1-1">- </a>
                                                                                        <a className="font-xsmall"> -  </a>
                                                                                        <a className="font-xsmall" id="currentpricetoken-2-2-1">  - </a>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br></br>
                                                                        <div id="alertdiv1" disabled role="alert">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End of Tab Content --> */}
                                </div>
                            </div>
                            {/* dsasa */}
                            <div className="card-body shadow-soft border border-light rounded p-2 mb-3 pt-0 div-white " >
                                <div className="row" >
                                    <div className="col-12 col-lg-5">
                                        <div className="border-light py-4 ">
                                            <div className="row ">
                                                <div className=" col-12 ml-12  " >
                                                    <div className="App" >
                                                        <button id="token0fromid" type="button" value="0xad6d458402f60fd3bd25163575031acdce07538d" className="btn btn-primary col-md-12" style={divstye3} disabled >
                                                            <img id="token0fromimageid" src={image0} width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <a id="fromid" >{token0} </a>
                                                        </button>
                                                        <div className="container justify-content-left m-0" >&nbsp;&nbsp;
                                                            <button disabled id="balancetoken0" className="col-md-12 border-0" style={divstye3} >
                                                                <a className="font-small  " id="token0balance" ><strong> Bakiye</strong>&nbsp;<a id="frombalance" className="text-small div-white" >0.0</a></a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row " >
                                            <div className="col-12 col-md-6 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <div className="row">
                                                            <div className="text-right col-12  pb-4 " id="text-right" style={divstye3}>
                                                                <br></br>
                                                                <input disabled className="form-control div-white" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                                <div className="row" >
                                    <div className="col-12 col-lg-5">
                                        <div className="border-light py-4 ">
                                            <div className="row ">
                                                <div className=" col-12 ml-12 pb-2 " >
                                                    <div className="App" >
                                                        <button type="button" value="WETH" className="btn btn-primary col-md-12" id="token1id" disabled style={divstye3}>
                                                            <img id="imageId" src={image1} width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <a id="tokenid" className="font-small" value="Jeton Seçiniz" >{token1} </a>&nbsp;&nbsp;
                                                        </button>
                                                        <div className="row pt- m-0">&nbsp;&nbsp;&nbsp;
                                                            <button disabled id="balancetoken1" className=" col-md-12 border-0" style={divstye3}  >
                                                                <a className="font-small " id="token1balance" disabled ><strong>Bakiye</strong>&nbsp;<a disabled className="font-small" id="balanceid">0,0 </a>  </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="row " >
                                            <div className="col-12 col-md-6 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <div className="row">
                                                            <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>
                                                                <br></br>
                                                                <input disabled className="form-control" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* to component end */}
                            <div className=" col-12 col-lg-12" id="approvediv">
                            </div>
                            <button type="button" id="modal-from" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                                onClick={() => {
                                    balance(ttfAddress, "TTFF").then((ver) => {
                                        document.getElementById("token0Name").innerText = document.getElementById("token0").innerText
                                        document.getElementById("token1Name").innerText = document.getElementById("token1").innerText
                                        document.getElementById("token0sum").innerText = document.getElementById("token0").innerText
                                        document.getElementById("token1sum").innerText = document.getElementById("token1").innerText
                                        document.getElementById("tabs-text-1-tab-sum").innerText = document.getElementById("token0").innerText
                                        document.getElementById("tabs-text-2-tab-sum").innerText = document.getElementById("token1").innerText
                                        document.getElementById("token0image").src = document.getElementById("imageId").src
                                        document.getElementById("token1image").src = document.getElementById("imageId1").src
                                        document.getElementById("token0img").src = document.getElementById("imageId").src
                                        document.getElementById("token1img").src = document.getElementById("imageId1").src
                                        document.getElementById("value0sum").innerText = Number(document.getElementById("priceRangeMax1").value).toFixed(14)
                                        document.getElementById("value1sum").innerText = Number(document.getElementById("priceRangeMax2").value).toFixed(14)
                                        if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                            document.getElementById("fee").innerText = "0,05%"
                                            document.getElementById("feetier").innerText = "0,05%"
                                        }
                                        else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                            document.getElementById("fee").innerText = "0,3%"
                                            document.getElementById("feetier").innerText = "0,3%"
                                        }
                                        else if (document.getElementById("token-1-1-decrease").innerText = "-2%") {
                                            document.getElementById("fee").innerText = "1%"
                                            document.getElementById("feetier").innerText = "1%"
                                        }
                                        document.getElementById("minprice1").innerText = document.getElementById("minpricevalue").value
                                        document.getElementById("maxprice1").innerText = document.getElementById("maxpricevalue").value
                                        document.getElementById("currentprice-11").innerText = document.getElementById("currentprice-1").innerText
                                        document.getElementById("token0minprice").innerText = document.getElementById("token0").innerText
                                        document.getElementById("token1minprice").innerText = document.getElementById("token1").innerText
                                        document.getElementById("token0maxprice").innerText = document.getElementById("token0").innerText
                                        document.getElementById("token1maxprice").innerText = document.getElementById("token1").innerText
                                        document.getElementById("currentpricetoken0").innerText = document.getElementById("token0").innerText
                                        document.getElementById("currentpricetoken1").innerText = document.getElementById("token1").innerText
                                    })
                                    toggleModal4()
                                }} >
                                Devam
                            </button>
                        </div>
                        {/* <!-- Modal Content --> */}
                        <Modal
                            isOpen={isOpen4}
                            onRequestClose={toggleModal4}
                            contentLabel="My dialog"
                            className="mymodal-liqudity" /*"modal-dialog modal-dialog-centered rounded"*/
                            overlayClassName="myoverlay"
                            closeTimeoutMS={500}
                            aria-hidden="true"
                        >
                            <div className="" id="ttffsum" >
                                <button type="button" className=" pt-0 close ml-auto " >
                                    <span aria-hidden="true" id="close" onClick={() => {
                                        toggleModal4()
                                        document.getElementById("priceRangeMax1").value = null
                                        document.getElementById("priceRangeMax2").value = null
                                    }}>×</span>
                                </button>
                                <div className="card-header  text-center pb-0 pt-0">
                                    <h2 className="h4 font-medium text-center pt-0"><strong>İşlem Özeti</strong></h2>
                                </div>
                                <div className="row justify-content-start " >
                                    {/*  component start  */}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className="col-12 col-lg-12 ">
                                        <div >
                                            <div className="row justify-content-start">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div className=" col-12 ml-12" >
                                                    <img className="rounded token1increase  " id="token0img" src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/TaumTokenlogo1.png" height="25px" width="25px" alt="ice museum" />
                                                    <img className="rounded token2increase " id="token1img" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="25px" width="25px" alt="ice museum" />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <span className=" font-base    ">
                                                        <span className="  font-base   " id="token0Name">DAI</span>
                                                        <span className=" font-base   ">    /</span>
                                                        <span className="  font-base   " id="token1Name">ETH</span>
                                                    </span> &nbsp;
                                                    <span className=" font-base " id="fee" > 0,3%</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <img className="font-medium text-right" id="imageurl1 " style={divstyledot} src="https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png" />    &nbsp;
                                                    <span className="font-base text-right" id="range" >in Range</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body text-left pb-0 pt-0 font-smedium py-2 ">
                                    <div className="row justify-content-start pt-0 ">
                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >
                                            <div className=" col-12 col-lg-12 text-left  font-base " >
                                                <output className=" col-lg-6 text-left font-base py-2">
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token0image" height="30px" />
                                                    &nbsp;
                                                    <a className="font-base pt-2 " id="token0sum">-</a>
                                                </output>
                                                <output className="col-lg-6 text-right font-base"  >
                                                    <a className="text-right" id="value0sum">0,0
                                                    </a>
                                                </output>
                                                <output className=" col-lg-6 text-left font-base py-">
                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" id="token1image" height="30px" />
                                                    &nbsp;
                                                    <a className="font-base" id="token1sum"> -</a>
                                                </output>
                                                <output className="col-lg-6 text-right font-base" >
                                                    <a className="text-left" id="value1sum">0,0</a>
                                                </output>
                                                <output className=" col-lg-6 text-left font-base">
                                                    <img src="https://storageapi.fleek.co/deneme-team-bucket/icon/share.svg" height="30px" />
                                                    &nbsp;
                                                    <a className="font-base"> Ücret Katmanı</a>
                                                </output>
                                                <output className="col-lg-6 text-right font-base" >
                                                    <a className="text-left " id="feetier">0,0</a>
                                                </output>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-0" >
                                        <div className="col-md-12 col-lg-12">
                                            {/* <!-- Tab Nav --> */}
                                            <div className="nav-wrapper  mb-0">
                                                <ul className="nav nav-pills  justify-content-start  " id="tabs-text" role="tablist">
                                                    <li className=" col-lg-8 " >
                                                        <h5 className="text-left" style={divstye4}>Aralık Seç
                                                        </h5>
                                                    </li>
                                                    <li className=" mr-sm-0 mr-md-0">
                                                        {/*id="fromtoken"*/}
                                                        <a className="nav-link mb-sm-3 mb-md-0 active font-xsmall" id="tabs-text-1-tab-sum" data-toggle="tab" href="#tabs-text-1" role="tab" aria-controls="tabs-text-1" aria-selected="true" onClick={() => {
                                                            var minprice1 = (1 / Number(document.getElementById("minprice1").innerText)).toPrecision(5)
                                                            document.getElementById("minprice1").innerText = minprice1
                                                            var maxprice1 = (1 / Number(document.getElementById("maxprice1").innerText)).toPrecision(5)
                                                            document.getElementById("maxprice1").innerText = maxprice1
                                                            var currentprice_11 = (1 / Number(document.getElementById("currentprice-11").innerText)).toPrecision(5)
                                                            document.getElementById("currentprice-11").innerText = currentprice_11
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("token0minprice").innerText = token0min
                                                            document.getElementById("token1minprice").innerText = token1min
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("token0maxprice").innerText = token0min
                                                            document.getElementById("token1maxprice").innerText = token1min
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("currentpricetoken0").innerText = token0min
                                                            document.getElementById("currentpricetoken1").innerText = token1min
                                                            document.getElementById("tabs-text-2-tab").ariaSelected = true
                                                            document.getElementById("tabs-text-2-tab").ariaSelected = false
                                                        }} >WETH</a>
                                                    </li>
                                                    &nbsp;
                                                    <li className=" mr-sm-0 mr-md-0">
                                                        {/*id="totoken"*/}
                                                        <a className="nav-link mb-sm-3 mb-md-0 font-xsmall" id="tabs-text-2-tab-sum" data-toggle="tab" href="#tabs-text-2" role="tab" aria-controls="tabs-text-2" aria-selected="false" onClick={() => {
                                                            var minprice1 = (1 / Number(document.getElementById("minprice1").innerText)).toPrecision(5)
                                                            document.getElementById("minprice1").innerText = minprice1
                                                            var maxprice1 = (1 / Number(document.getElementById("maxprice1").innerText)).toPrecision(5)
                                                            document.getElementById("maxprice1").innerText = maxprice1
                                                            var currentprice_11 = (1 / Number(document.getElementById("currentprice-11").innerText)).toPrecision(5)
                                                            document.getElementById("currentprice-11").innerText = currentprice_11
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("token0minprice").innerText = token1min
                                                            document.getElementById("token1minprice").innerText = token0min
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("token0maxprice").innerText = token1min
                                                            document.getElementById("token1maxprice").innerText = token0min
                                                            var token0min = document.getElementById("token0").innerText
                                                            var token1min = document.getElementById("token1").innerText
                                                            document.getElementById("currentpricetoken0").innerText = token1min
                                                            document.getElementById("currentpricetoken1").innerText = token0min
                                                            document.getElementById("tabs-text-2-tab").ariaSelected = false
                                                            document.getElementById("tabs-text-2-tab").ariaSelected = true
                                                        }} >&nbsp;&nbsp;DAI&nbsp;&nbsp;</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- End of Tab Nav --> */}
                                            {/* <!-- Tab Content --> */}
                                            <div className="   pt-0 py-0 border-light  rounded div-white  " >
                                                <div className="card-body p-0"  >
                                                    <div className="tab-content" id="tabcontent1">
                                                        <div className="tab-pane fade show active" id="tabs-text-1" role="tabpanel" aria-labelledby="tabs-text-1-tab">
                                                            {/* ds */}
                                                            <div className="  py-0 pt-0" >
                                                                <div className="row" >
                                                                    <div className="col-12 col-lg-6 card-tags card-headers ">
                                                                        <div className="row " >
                                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                                <div>
                                                                                    <div className="card-body-tabs" >
                                                                                        <br />
                                                                                        <div className="row justify-content-end"  >
                                                                                            {/* jeton1 gelecek */}
                                                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect" style={divstye3}>
                                                                                                <a className="font-small"  >Min. Fiyat </a>
                                                                                                <br />
                                                                                                <a className="col-md-10 font-base text-center" id="minprice1" style={divstye3} disabled >555,555 </a>
                                                                                                <br />
                                                                                                <a className="font-xsmall " id="token0minprice">- </a>
                                                                                                <a className="font-xsmall "> - </a>
                                                                                                <a className="font-xsmall " id="token1minprice"> -</a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 card-tags card-headers ">
                                                                        <div className="row " >
                                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                                <div>
                                                                                    <div className="card-body-tabs" >
                                                                                        <br />
                                                                                        <div className="row justify-content-end"  >
                                                                                            {/* jeton2 gelecek */}
                                                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" style={divstye3}>
                                                                                                <a className="font-small"  >Max. Fiyat </a>
                                                                                                <br />
                                                                                                <a className="col-md-10 font-base text-center" id="maxprice1" style={divstye3} disabled >555,555 </a>
                                                                                                <br />
                                                                                                <a className="font-xsmall " id="token0maxprice">-</a>
                                                                                                <a className="font-xsmall "> - </a>
                                                                                                <a className="font-xsmall " id="token1maxprice"> -</a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" col-12 col-lg-12 card-tags card-headers">
                                                                        <div className="row" >
                                                                            <div className="col-12 col-md-12 col-lg-12 pt-3 ">
                                                                                <div>
                                                                                    <div className="card-body-tabs  rounded div-white "  >
                                                                                        <div className="row justify-content-end">
                                                                                            {/* jeton2 gelecek */}
                                                                                            <button type="button" value="WETH" className=" border border-light btn btn-primary col-md-12 mb-1" id="btnselect" style={divstye3}>
                                                                                                <a className="font-small" > Mevcut fiyat </a>&nbsp;&nbsp;
                                                                                                <br />
                                                                                                <a className="font-small" id="currentprice-11">-</a>
                                                                                                <br />
                                                                                                <a className="font-xsmall" id="currentpricetoken0">-</a>
                                                                                                <a className="font-xsmall"> - </a>
                                                                                                <a className="font-xsmall" id="currentpricetoken1">- </a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* token pair 2 start  */}
                                                        <div className="tab-pane fade" id="tabs-text-2" role="tabpanel" aria-labelledby="tabs-text-2-tab">
                                                            <div className="   p-2 mb-2 pt-0 " >
                                                                <div className="row" >
                                                                    <div className="col-12 col-lg-6 card-tags card-headers ">
                                                                        <div className="row " >
                                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                                <div>
                                                                                    <div className="card-body-tabs" >
                                                                                        <br />
                                                                                        <div className="row justify-content-end"  >
                                                                                            {/* jeton1 gelecek */}
                                                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect" style={divstye3}>
                                                                                                <a className="font-small"  >Min. Fiyat </a>&nbsp;&nbsp;
                                                                                                <br />
                                                                                                <a className="col-md-10 font-base text-center" id="minprice2" style={divstye3} disabled >555,555 </a>
                                                                                                <br />
                                                                                                <a className="font-xsmall " id="token2-1-1-1">-</a>
                                                                                                <a className="font-xsmall "> - </a>
                                                                                                <a className="font-xsmall " id="token2-1-1-2"> -</a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 card-tags card-headers ">
                                                                        <div className="row " >
                                                                            <div className="col-12 col-md-12 col-lg-12">
                                                                                <div>
                                                                                    <div className="card-body-tabs" >
                                                                                        <br />
                                                                                        <div className="row justify-content-end"  >
                                                                                            {/* jeton2 gelecek */}
                                                                                            <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect" style={divstye3}>
                                                                                                <a id="maxprice" className="font-small"  >Max. Fiyat </a>&nbsp;&nbsp;
                                                                                                <br />
                                                                                                <a className="col-md-10 font-base text-center" id="maxprice2" style={divstye3} disabled >555,555 </a>
                                                                                                <br />
                                                                                                <a className="font-xsmall " id="token2-2-1-1">- </a>
                                                                                                <a className="font-xsmall "> - </a>
                                                                                                <a className="font-xsmall " id="token2-2-1-2"> -</a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="container col-12 col-lg-12">
                                                                        <div className="row" >
                                                                            <div className="col-12 col-md-12 col-lg-12 ">
                                                                                <div>
                                                                                    <div className="card  border border-light rounded" style={divstye}  >
                                                                                        <div className="row justify-content-end  border border-light">
                                                                                            {/* jeton2 gelecek */}
                                                                                            <button type="button" value="WETH" className="border-0 col-md-12 mb-1" id="btnselect" style={divstye3}>
                                                                                                <a className="font-small"  >  Mevcut fiyat </a>&nbsp;&nbsp;
                                                                                                <br />
                                                                                                <a className="font-xsmall" id="currentprice-2">-</a>
                                                                                                <br />
                                                                                                <a className="font-xsmall" id="currentpricetoken-2-1-1">- </a>
                                                                                                <a className="font-xsmall"> -  </a>
                                                                                                <a className="font-xsmall" id="currentpricetoken-2-2-1">  - </a>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- End of Tab Content --> */}
                                        </div>
                                    </div>
                                </div>
                                <button type="button" id="modal-from" className=" col-12  btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                                    onClick={() => {
                                        var token1values = web3.utils.hexToNumberString(token1value)
                                        var token0values = web3.utils.hexToNumberString(token0value)
                                        var token0gec = token0value
                                        var token1gec = token1value
                                        var normalTickmaxgec = normalTickmax
                                        var normalTickmingec = normalTickmin
                                        if (Number(token0values) > Number(token1values)) {
                                            token0value = token1gec
                                            token1value = token0gec
                                            normalTickmax = normalTickmingec
                                            normalTickmin = normalTickmaxgec
                                        }
                                        else {
                                            token0value = token0gec
                                            token1value = token1gec
                                            normalTickmax = normalTickmaxgec
                                            normalTickmin = normalTickmingec
                                        }
                                        if (document.getElementById("tabs-text-1-tab").ariaSelected == "true") {
                                            if (ToOrFrom == true) {
                                                //to from true start 
                                                if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                    const fee = 500;
                                                    const tickspacing = 10;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = (1 / (amount1Desired * amountRatio)).toString();

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data, /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                    const fee = 3000;
                                                    const tickspacing = 60;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = ((amount1Desired * amountRatio)).toString();

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                    const fee = 10000;
                                                    const tickspacing = 200;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = (1 / (amount1Desired * amountRatio)).toString();

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                            }
                                            // to or from false gelirse
                                            else {
                                                if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                    const fee = 500;
                                                    const tickspacing = 10;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                    const fee = 3000;
                                                    const tickspacing = 60;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                    const fee = 10000;
                                                    const tickspacing = 200;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token0value, token1value, fee, normalTickmax, normalTickmin, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                            }
                                            // WETH TO DAI start
                                        }
                                        // WETH TO DAI end

                                        // DAI to WETH start
                                        else {
                                            if (ToOrFrom == true) {
                                                //to from true start 
                                                if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                    const fee = 500;
                                                    const tickspacing = 10;
                                                    const amount1Desired = document.getElementById("priceRangeMax1").value;
                                                    const amount2Desired = ((amount1Desired * amountRatio).toPrecision(10)).toString();

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                    const fee = 3000;
                                                    const tickspacing = 60;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = ((amount1Desired * amountRatio).toPrecision(10)).toString();

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                    const fee = 10000;
                                                    const tickspacing = 200;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = ((amount1Desired * amountRatio).toPrecision(10)).toString();

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                            }
                                            // to or from false gelirse
                                            else {
                                                if (document.getElementById("token-1-1-decrease").innerText == "-0.1%") {
                                                    const fee = 500;
                                                    const tickspacing = 10;
                                                    const amount1Desired = document.getElementById("priceRangeMax1").value;
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-0.6%") {
                                                    const fee = 3000;
                                                    const tickspacing = 60;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data,  /*gasEstimate*/800000);
                                                    })
                                                }
                                                else if (document.getElementById("token-1-1-decrease").innerText == "-2%") {
                                                    const fee = 10000;
                                                    const tickspacing = 200;
                                                    const amount1Desired = (document.getElementById("priceRangeMax1").value);
                                                    const amount2Desired = document.getElementById("priceRangeMax2").value;

                                                    AddLiqudityAbi(token1value, token0value, fee, normalTickmin, normalTickmax, amount1Desired, amount2Desired, deadlineMinute, slippage).then((ver) => {
                                                        const to = (ver.to)
                                                        const data = (ver.methodABI)
                                                        const gasEstimate = (ver.gasEstimate)
                                                        sendHashoriginal(to, data, gasEstimate);
                                                    })
                                                }
                                            }
                                            // DAI to WETH end
                                        }
                                        toggleModal4()
                                        document.getElementById("priceRangeMax1").value = null
                                        document.getElementById("priceRangeMax2").value = null
                                        var x = document.getElementById("snackbartottffbuy");
                                        x.className = "show";
                                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                                    }} >
                                    Devam
                                </button>
                            </div>
                        </Modal>
                    </div >
                    <div id="snackbartottffbuy">Transaction sent to your wallet.</div>
                </div >
            </div >
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi */}
            < div id="countdown_container" style={display} >
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div >
        </section >
    );
}

export default Investment_comp_det
