import React,{useEffect } from 'react';
const display= {
    display:"none",
}

function Notfound() {

    useEffect(()=>{
        var darkmode = document.cookie

        if(darkmode !="darkmode=DarkMode") {
            document.getElementById("imagenotfound").src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww5kz9n.gif"
        }
        else {
            document.getElementById("imagenotfound").src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww6qo1n.gif"
    
        }

        if(document.body.className =="body--night-mode") {
            document.getElementById("imagenotfound").src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww6qo1n.gif"

        }
        else {
            document.getElementById("imagenotfound").src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww5kz9n.gif"

        }
    })

    return (
        <div>
         <div className="min-vh-100 d-flex  align-items-center  pt-4">
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 align-items-center text-center">
                        <div className="   p-3 ">
                            <div className="    pt-2">
                                <a href="/index">
                                <img id="imagenotfound" alt="Logo light" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww5kz9n.gif"></img>  

                                 </a>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-8 py-2" >
                                        <h1 className="mt-5 font-weight-light">Sayfa  <span className="font-weight-bolder ">Bulunamadı.</span></h1>
                                        <p className="lead my-4">Oops! Görünüşe göre yanlış bir bağlantıya tıklamışsınız. Bunun bizimle ilgili bir sorun olduğunu düşünüyorsanız, lütfen bize bildirin.</p>
                                        <a className="btn btn-yotta21 animate-hover py-2" href="/#/">Geri Dön</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
             {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi 

    */}
                                        <div id="countdown_container" style={display}>
                                        <div id="countdown_timer" >
                                     
                                        </div>
                                        <div ></div>
                                    </div>
        </div>


    );
}


export default Notfound;
