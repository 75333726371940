import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import yotta21logo from '../../assets/img/yotta21-logo21.png'

var decreasePercentage;
var slippage = 0.5
var deadlineMinute = 30;

const display = {
    display: 'none'
}

const divstye3 = {
    backgroundColor: "transparent"

}

const divstye7 = {
    padding: "0.15rem 0.15rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    width: "25px"



}
const divstye8 = {
    padding: "0.05rem 0.05rem",
    lineHeight: "1.75rem",
    fontSize: "0.75rem",
    width: "2rem"

}
const divstye9 = {
    padding: "0.05rem 0.05rem",
    lineHeight: "1.75rem",
    fontSize: "0.75rem",
    width: "2.4rem"

}

const imagestyle = {
    height: "0.5rem",
    alignItems: "center"


}

Modal.setAppElement("#index");

var range
var imageurl


function Incraese(props) {


    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }
    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    const { nftid } = useParams();
    var backurl = "/#/pool/" + nftid;

    async function nftDetail(_nftId) {
        var _datanft = {
            "nftId": _nftId,
            "recipient": props.address,
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_NFT, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datanft)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    async function decreaseLiqudity(_nftId, _decreasePercentage, _slippage, _deadlineMinute) {
        var _datadecrease = {
            "nftId": _nftId,
            "recipient": props.address,
            "decreasePercentage": _decreasePercentage,
            "slippage": _slippage,
            "deadlineMinute": _deadlineMinute
        }

        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_NFT_DECLQ, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datadecrease)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: "0x0",
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', (ver)=>{
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
             }, 3000);
        })

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', (ver)=>{
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
             }, 3000);
        })
    }

    var token0NftAmount
    var token1NftAmount

    nftDetail(nftid).then((data) => {
        document.getElementById("token0name").innerText = data.token0
        document.getElementById("token1name").innerText = data.token1
        document.getElementById("pooltoken0").innerText = data.token0
        document.getElementById("pooltoken1").innerText = data.token1
        document.getElementById("feetoken0").innerText = data.token0
        document.getElementById("feetoken1").innerText = data.token1
        document.getElementById("fee").innerText = data.fee
        document.getElementById("token0collect").innerText = Number(data.uncollectedFeeToken0).toFixed(8)
        document.getElementById("token1collect").innerText = Number(data.uncollectedFeeToken1).toFixed(8)

        // img ayarlanması

        //token0 img
        if (data.token0 == "TTFF") {
            document.getElementById("token1decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token0poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token0feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
           
            
        }
        else if (data.token0 == "LpTTFF") {
            document.getElementById("token1decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token0poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token0feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            }
        else if (data.token0 == "OTTA") {
            document.getElementById("token1decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token0poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token0feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
              }
        else if (data.token0 == "WETH") {
            document.getElementById("token1decrease").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token0poolimg").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token0feeimg").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
           }
        else {
            document.getElementById("token1decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token0poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token0feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
              }

        // token1 img
        if (data.token1 == "WETH") {
            document.getElementById("token2decrease").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token1poolimg").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
            document.getElementById("token1feeimg").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
               }
        else if (data.token1 == "OTTA") {
            document.getElementById("token2decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token1poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            document.getElementById("token1feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
            }
        else if (data.token1 == "TAUM") {
            document.getElementById("token2decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token1poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
            document.getElementById("token1feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
           }
        else if (data.token1 == "TTFF") {
            document.getElementById("token2decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token1poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
            document.getElementById("token1feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
             }
        else {
            document.getElementById("token2decrease").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token1poolimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            document.getElementById("token1feeimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
            
        }



        if (data.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || data.maxPrice == 338492131855223780000000000000000000000) {
            token0NftAmount = (data.token0NftAmount / Math.pow(10, 18)).toPrecision(8)
            token1NftAmount = (data.token1NftAmount / Math.pow(10, 18)).toPrecision(8)
        }

        else {
            token0NftAmount = data.token0NftAmount
            token1NftAmount = data.token1NftAmount
        }
        if (Number(data.currentPrice) >= Number(data.minPrice) && Number(data.currentPrice) <= Number(data.maxPrice)) {
            range = "İn Range";
            document.getElementById("range").innerText = range;
            document.getElementById("imageurl").src = "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png"
        }
        else {
            range = "Out of Range"
            document.getElementById("range").innerText = range;
            document.getElementById("imageurl").src = "https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png"
        }
    })
    function walletControl() {
        // document.getElementById("fromid").innerText = tokenname;


        if (props.address == null) {

            document.getElementById("togglebtn").innerText = "Wallet Connect"
            document.getElementById("togglebtn").disabled = true

        }
        else {
            document.getElementById("togglebtn").disabled = true
            document.getElementById("togglebtn").innerText = "Continue"

        }
    }

    useEffect(() => {

        document.getElementById("togglebtn").disabled = true;
        walletControl()

    })


    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-black rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Values out of range"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }

    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-black rounded div-white form-control"
                document.getElementById("toleranscheck").className = "border border-black rounded div-white"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("toleranscheck").style.borderColor = "red"
                document.getElementById("danger").innerText = " "

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Values out of range"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }

    return (
        <section id="root ">

            <div className=" p-0 mb-2 pt-6 " >
                <div className=" row mb-5 justify-content-center pt-0 " >
                    {/* col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 */}


                    <div className=" card-body-tabs justify-content-center col-12 col-md-12 col-lg-auto mb-5 mb-lg-0 pt-0 div-white rounded" >

                        <div className="card-tabs   border-black p-3" id="divcardliqudity" >
                            {/* ds */}
                            <div className="   p-2 mb-2 pt-0 " >
                                <div className="row" >
                                    {/* From component start  */}
                                    <div className="col-12 col-lg-6 ">

                                        <div >
                                            <br />

                                            <div className="row">

                                                <div className=" col-12 ml-12 " >
                                                    <Button className="btn btn-pill  btn-yotta21  div-yotta21 " style={divstye7} href={backurl}><i className="fas fa-long-arrow-alt-left"></i></Button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />
                                                        <div className="row justify-content-end">
                                                            <Button className=" btn-pill btn-yotta21  div-yotta21"  ><i className="fas fa-cog  " style={divstye3} onClick={() => {
                                                                toggleModal3()
                                                                setTimeout(() => {
                                                                    document.getElementById("slippage").value = slippage
                                                                    document.getElementById("deadline").value = deadlineMinute
                                                                }, 1000);
                                                            }} ></i></Button>

                                                        </div>
                                                        {/* modal setting start */}
                                                        <Modal
                                                            isOpen={isOpen3}
                                                            onRequestClose={toggleModal3}
                                                            contentLabel="My dialog"
                                                            className="mymodal3" /*"modal-dialog modal-dialog-centered rounded"*/
                                                            overlayClassName="myoverlay"
                                                            closeTimeoutMS={500}
                                                            aria-hidden="true"
                                                        >


                                                            <div className="rounded " id="sums">
                                                                <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                                                    document.getElementById("deadline").value = 0
                                                                    document.getElementById("slippage").value = 0

                                                                }}>
                                                                    <span aria-hidden="true" id="close" onClick={() => {
                                                                        if (document.getElementById("dangers") != null) {
                                                                            document.getElementById("danger").removeChild(document.getElementById("dangers"))
                                                                            document.getElementById("slippage").style.borderColor = "white"
                                                                        }
                                                                        if (document.getElementById("dangers2") != null) {
                                                                            document.getElementById("danger2").removeChild(document.getElementById("dangers2"))
                                                                            document.getElementById("deadline").style.borderColor = "white"
                                                                        }
                                                                        toggleModal3()
                                                                    }}>×</span>
                                                                </button>
                                                                <div className="card-header  text-center pb-0 pt-0">
                                                                    <h2 className="h4 font-medium text-center pt-0">Ayarlar</h2>
                                                                </div>
                                                                <div className="card-body text-center pb-0 pt-0 font-smedium  div-white " >
                                                                    <div className="row ">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium"> Kayma Toleransı</a>
                                                                                <button className="border-0 font-medium div-white" id="Popover1" >
                                                                                    <i className="far fa-question-circle"></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen1} target="Popover1" toggle={toggle1}>

                                                                                    <PopoverBody>Fiyatın istenmeyen şekilde bu yüzdeden daha fazla değişmesi durumunda işleminiz geri döner.</PopoverBody>
                                                                                </Popover>
                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0  font-medium py-2">

                                                                                <output className="text-right div-white col-lg-8" >
                                                                                    <InputGroup >
                                                                                        <InputGroupAddon addonType="prepend" >
                                                                                            <InputGroupText className="div-white">
                                                                                                <Input addon type="checkbox" aria-label="Checkbox for following text input" id="toleranscheck" className="div-white" onChange={() => {
                                                                                                    if (document.getElementById("toleranscheck").checked == true) {

                                                                                                        document.getElementById("slippage").value = 0.05;
                                                                                                    }
                                                                                                    else {
                                                                                                        document.getElementById("slippage").value = null

                                                                                                    }

                                                                                                }} />&nbsp;  Otomatik
                                                                                            </InputGroupText>
                                                                                        </InputGroupAddon>
                                                                                        <Input className="div-white " placeholder="%0.5" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="50" id="slippage" onChange={() => {
                                                                                            settingControlslippage()
                                                                                        }}></Input>
                                                                                    </InputGroup>
                                                                                </output>
                                                                            </div>

                                                                        </div>
                                                                        <div id="danger"></div>

                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                            <div className="text-left " >
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                <a className="font-medium">İşlem Son Tarihi</a>
                                                                                <button className="border-0 div-white" id="Popover2"  >
                                                                                    <i className="far fa-question-circle font-medium" ></i>
                                                                                </button>
                                                                                <Popover isOpen={popoverOpen2} target="Popover2" toggle={toggle2} className="div-white" >

                                                                                    <PopoverBody>İşleminiz bu süreden daha uzun bir süredir beklemedeyse geri alınacaktır.</PopoverBody>
                                                                                </Popover>

                                                                            </div>
                                                                            <div className=" col-12 col-lg-12 text-left pt-0 font-medium py-2">

                                                                                <output className="text-left col-lg-6 font-medium div-white" >
                                                                                    <Input placeholder="30" id="deadline" className="div-white" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="180" onChange={() => {
                                                                                        settingControldeadline()
                                                                                    }} />

                                                                                </output>
                                                                                <output className="col-lg-3 text-left" >
                                                                                    <a className="font-left">Dakika</a>
                                                                                </output>
                                                                            </div>

                                                                        </div>
                                                                        <div id="danger2"></div>
                                                                    </div>

                                                                </div>
                                                                <br></br>
                                                                <button className="btn btn-yotta21 col-lg-12" onClick={() => {

                                                                    if (document.getElementById("toleranscheck").checked == true) {
                                                                        slippage = 0.05
                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value

                                                                        }
                                                                        console.log("slipage1", slippage + "deadline", deadlineMinute);

                                                                    }
                                                                    else {
                                                                        if (document.getElementById("slippage").value == "" || document.getElementById("slippage").value == null) {
                                                                            slippage = 0.05
                                                                        }
                                                                        else {
                                                                            slippage = document.getElementById("slippage").value

                                                                        }
                                                                        console.log("slipage1", slippage + "deadline", deadlineMinute);

                                                                        if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                            deadlineMinute = 30;
                                                                        }
                                                                        else {
                                                                            deadlineMinute = document.getElementById("deadline").value

                                                                        }
                                                                    }

                                                                    toggleModal3()

                                                                }} id="save">Save</button>

                                                            </div>
                                                        </Modal>
                                                        {/* modal setting end */}
                                                        {/* modal setting end */}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-12  ">
                                        <div className="row  text-center justify-content-center ">
                                            <h4 className="font-xl"><strong> Likidite Kaldır</strong></h4>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-8 card-tags card-headers pt-0 py-0 " >
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12   ">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />

                                                        <div className="row justify-content-end">
                                                            {/* jeton1 gelecek */}
                                                            <div className=" col-12 ml-12 pt-0 " >
                                                                <img className="rounded token1decrease" id="token1decrease" src={yotta21logo} height="25px" width="25px" alt="ice museum" />

                                                                <img className="rounded token2decrease" id="token2decrease" src={yotta21logo} height="25px" width="25px" alt="ice museum" />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                                <span className="font-weight-bolder  font-base   ">
                                                                    <span className="font-weight-bolder  font-base   " id="token0name">-</span>
                                                                    <span className="font-weight-bolder  font-base   ">   / </span>
                                                                    <span className="font-weight-bolder  font-base   " id="token1name">-</span>
                                                                </span> &nbsp;&nbsp;&nbsp;
                                                                <span className="font-weight-bolder font-base " id="fee"> % </span> &nbsp;&nbsp;

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-12 col-lg-4 card-tags pt-0">
                                        <div className="row " >
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div>
                                                    <div className="card-body-tabs">
                                                        <br />

                                                        <div className="row justify-content-end">
                                                            <div className='py-'>
                                                                <img id="imageurl" style={imagestyle} src="https://upload.wikimedia.org/wikipedia/commons/7/70/Solid_white.svg"></img>
                                                                &nbsp;<span className="font-base " id="range">-</span>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="  mb-0 pt-0 py-0">

                                {/* djadjskajkdk */}
                                <div className="row pt-0 p-0 py-0  justify-content-center">
                                    <div className="card card-body-tabs col-11 p-0 mt-0 border border-black rounded div-white" >
                                        <a className="container text-left py-2">Miktar</a>

                                        <div className="row justify-content-start  p-0" >
                                            {/*  component start  */}
                                            <div className=" col-lg-5 ">

                                                <div className="container ">
                                                    &nbsp;&nbsp;
                                                    <span id="percent" className="font-weight-bolder font-xl   ">0%   </span>




                                                </div>




                                            </div>
                                            <div className="col-12 col-lg-7 p-0 py-1 ">

                                                <div className="container text-left" >
                                                    <button className="btn btn-yotta21 " style={divstye8} onClick={() => {
                                                        document.getElementById("percent").innerText = "25%";
                                                        var slider = document.getElementById("myRange");
                                                        // slider.value = 25;
                                                        document.getElementById("token0pooled").innerText = ((token0NftAmount * 25) / 100).toFixed(8)
                                                        document.getElementById("token1pooled").innerText = ((token1NftAmount * 25) / 100).toFixed(8)
                                                        decreasePercentage = "25";
                                                        if (props.address != null || props.address != undefined) {
                                                            document.getElementById("togglebtn").disabled = false
                                                            document.getElementById("togglebtn").innerText = "Devam"
                                                        }
                                                        else document.getElementById("togglebtn").disabled = true
                                                    }}>25%</button> &nbsp;&nbsp;
                                                    &nbsp;
                                                    <button className="btn btn-yotta21" style={divstye8} onClick={() => {
                                                        document.getElementById("percent").innerText = "50%";
                                                        var slider = document.getElementById("myRange");
                                                        // slider.value = 50;
                                                        document.getElementById("token0pooled").innerText = ((token0NftAmount * 50) / 100).toFixed(8)
                                                        document.getElementById("token1pooled").innerText = ((token1NftAmount * 50) / 100).toFixed(8)
                                                        decreasePercentage = "50";
                                                        if (props.address != null || props.address != undefined) {
                                                            document.getElementById("togglebtn").disabled = false
                                                            document.getElementById("togglebtn").innerText = "Devam"
                                                        }
                                                        else document.getElementById("togglebtn").disabled = true

                                                    }}>50%</button> &nbsp;&nbsp;
                                                    &nbsp;
                                                    <button className="btn btn-yotta21" style={divstye8} onClick={() => {
                                                        document.getElementById("percent").innerText = "75%";
                                                        var slider = document.getElementById("myRange");
                                                        // slider.value = "75";
                                                        document.getElementById("token0pooled").innerText = ((token0NftAmount * 75) / 100).toFixed(8)
                                                        document.getElementById("token1pooled").innerText = ((token1NftAmount * 75) / 100).toFixed(8)
                                                        decreasePercentage = 75;
                                                        if (props.address != null || props.address != undefined) {
                                                            document.getElementById("togglebtn").disabled = false
                                                            document.getElementById("togglebtn").innerText = "Devam"
                                                        }
                                                        else document.getElementById("togglebtn").disabled = true


                                                    }}>75%</button> &nbsp;&nbsp;
                                                    &nbsp;
                                                    <button className="btn btn-yotta21 col-12" style={divstye9}
                                                        onClick={() => {
                                                            document.getElementById("percent").innerText = "100%";
                                                            var slider = document.getElementById("myRange");
                                                            // slider.value = 100;
                                                            decreasePercentage = "100";

                                                            document.getElementById("token0pooled").innerText = Number(token0NftAmount).toFixed(8)
                                                            document.getElementById("token1pooled").innerText = Number(token1NftAmount).toFixed(8)
                                                            // var output = document.getElementById("percent");
                                                            // output.innerHTML = slider.value;

                                                            // slider.oninput = function () {
                                                            //     output.innerHTML = this.value;
                                                            // }

                                                            if (props.address != null || props.address != undefined) {
                                                                document.getElementById("togglebtn").disabled = false
                                                                document.getElementById("togglebtn").innerText = "Devam"
                                                            }
                                                            else document.getElementById("togglebtn").disabled = true
                                                        }}>100%</button>

                                                </div>

                                            </div>
                                            {/* <div className="slidecontainer col-lg-12">

                                                <input type="range" min="1" max="100" value="10" className="slider col-lg-12" id="myRange" />
                                                <a aria-disabled="true">.</a>

                                            </div> */}
                                        </div>


                                    </div>

                                    {/* djadjskajkdk */}

                                </div>
                            </div>


                            &nbsp;


                            <div className="  mb-0">
                                {/* djadjskajkdk */}
                                <div className="row pt-0 p-0 pl-0  justify-content-center">
                                    <div className="card card-body-tabs col-11 p-0 mt-0 border border-black rounded div-white "  >
                                        <div className="row pt-2 p-0 py-2" >
                                            {/*  component start  */}
                                            <div className=" col-lg-8 pt-2 ">

                                                <div className="container ">
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <img className="image-xs " src={yotta21logo} id="token0poolimg"></img>&nbsp;

                                                    <span className="font-weight-bolder  font-small   ">Havuza alınmış  <a className='font-weight-bolder' id="pooltoken0"></a></span>


                                                </div>
                                                <div className="container text-left">
                                                    &nbsp;&nbsp;
                                                    &nbsp;

                                                    <img className="image-xs " src={yotta21logo} id="token1poolimg"></img>&nbsp;


                                                    <span className="font-weight-bolder  font-small   ">Havuza alınmış   <a className='font-weight-bolder' id="pooltoken1"></a></span>


                                                </div>

                                                <div className="container text-left">
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <img className="image-xs " src={yotta21logo}id="token0feeimg"></img>&nbsp;

                                                    <span className="font-weight-bolder  font-small   "><a className='font-weight-bolder' id="feetoken0"></a> Kazanılan Ücretler
                                                    </span>


                                                </div>



                                                <div className="container text-left">
                                                    &nbsp;&nbsp;
                                                    &nbsp;
                                                    <img className="image-xs " src={yotta21logo} id="token1feeimg"></img>&nbsp;

                                                    <span className="font-weight-bolder  font-small   "><a className='font-weight-bolder' id="feetoken1"></a> Kazanılan Ücretler
                                                    </span>


                                                </div>

                                            </div>

                                            <div className="col-12 col-lg-4 py-1 text-right ">
                                                <div className="container text-right py-2 " >

                                                    <span className="font-small   " id="token0pooled">0</span>


                                                </div>
                                                <div className="container text-right   " >

                                                    <span className="font-small " id="token1pooled">0</span>




                                                </div>

                                                <div className="container text-right py-2" >


                                                    <span className="font-small  " id="token0collect">0</span>


                                                </div>

                                                <div className="container text-right  " >

                                                    <span className="font-small " id="token1collect">0</span>


                                                </div>
                                            </div>


                                        </div>

                                    </div>

                                    {/* djadjskajkdk */}

                                </div>
                            </div>
                            {/* to component end */}
                            <br></br>
                            <div id="approvediv"></div>

                            <button type="button" id="togglebtn" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                                onClick={async () => {

                                    // // document.getElementById("tokendoubleimage").src = "";
                                    //  document.getElementById("token0Name").innerText = document.getElementById("token0").innerText
                                    //  document.getElementById("token1Name").innerText = document.getElementById("token1").innerText
                                    balance("0xd0A1E359811322d97991E03f863a0C30C2cF029C", "WETH").then((ver) => {

                                    document.getElementById("token0sum").innerText = document.getElementById("token0name").innerText
                                    document.getElementById("token1sum").innerText = document.getElementById("token1name").innerText

                                    document.getElementById("token0sum1").innerText = document.getElementById("token0name").innerText
                                    document.getElementById("token1sum1").innerText = document.getElementById("token1name").innerText

                                    //  document.getElementById("token0image").src = document.getElementById("token0fromimageid").src
                                    //  document.getElementById("token1image").src = document.getElementById("imageId").src

                                    document.getElementById("value0sum").innerText = document.getElementById("token0pooled").innerText
                                    document.getElementById("value1sum").innerText = document.getElementById("token1pooled").innerText

                                    document.getElementById("value0sum1").innerText = document.getElementById("token0collect").innerText
                                    document.getElementById("value1sum1").innerText = document.getElementById("token1collect").innerText
                                    document.getElementById("token0imagesum").src = document.getElementById("token0poolimg").src
                                    document.getElementById("token0imagesumm").src = document.getElementById("token0poolimg").src
                                    document.getElementById("token1imagesum").src = document.getElementById("token1poolimg").src
                                    document.getElementById("token1imagesumm").src = document.getElementById("token1poolimg").src
                                    })
                                    //      document.getElementById("feesum").innerText = document.getElementById("fee").innerText
                                    //      document.getElementById("feetier").innerText = document.getElementById("fee").innerText



                                    //  document.getElementById("minprice1").innerText = document.getElementById("minprice").innerText
                                    //  document.getElementById("maxprice1").innerText = document.getElementById("maxprice").innerText
                                    //  document.getElementById("currentprice-11").innerText = document.getElementById("currentprice-1").innerText

                                    // // document.getElementById("token0minprice").innerText = document.getElementById("token0").innerText
                                    // // document.getElementById("token1minprice").innerText = document.getElementById("token1").innerText

                                    // // document.getElementById("token0maxprice").innerText = document.getElementById("token0").innerText
                                    // // document.getElementById("token1maxprice").innerText = document.getElementById("token1").innerText

                                    // // document.getElementById("currentpricetoken0").innerText = document.getElementById("token0").innerText
                                    // // document.getElementById("currentpricetoken1").innerText = document.getElementById("token1").innerText

                                    // document.getElementById("rangesum").innerText = document.getElementById("range").innerText
                                    toggleModal()
                                }} >
                                Continue
                            </button>


                            <Modal
                                isOpen={isOpen}
                                onRequestClose={toggleModal}
                                contentLabel="My dialog"
                                className="mymodal2" /*"modal-dialog modal-dialog-centered rounded"*/
                                overlayClassName="myoverlay"
                                closeTimeoutMS={500}
                                aria-hidden="true"
                            >

                                <div className=" " id="sums">
                                    <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" id="close" onClick={()=>{
                                            toggleModal()
                                        }}>×</span>
                                    </button>
                                    <div className="card-header  text-center pb-0 pt-0">
                                        <h2 className="h4 font-medium text-center pt-0"><strong>İşlem Özeti</strong></h2>
                                    </div>


                                    <div className="card-body text-left pb-0 pt-0 font-smedium py-2 ">
                                        <div className="row justify-content-center pt-0 ">

                                            <div className="col-12 col-sm-12  pt-0 border border-black rounded div-white" >

                                                <div className=" col-12 col-lg-12 text-left  font-small " >
                                                    <output className=" col-lg-7 text-left font-small py-2">
                                                        <img src={yotta21logo} id="token0imagesum" height="25px" />
                                                        Havuza alınmış  &nbsp;
                                                        <a className="font-small pt-2 " id="token0sum">-</a>

                                                    </output>
                                                    <output className="col-lg-5 text-right"  >
                                                        <a className="text-right" id="value0sum">0
                                                        </a>
                                                    </output>

                                                    <output className=" col-lg-7 text-left font-small py-2">
                                                        <img src={yotta21logo} id="token1imagesum" height="25px" />
                                                        Havuza alınmış  &nbsp;
                                                        <a className="font-small" id="token1sum">-</a>
                                                    </output>
                                                    <output className="col-lg-5 text-right font-small" >
                                                        <a className="text-left" id="value1sum">0</a>

                                                    </output>


                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 mt-12 py-2 ">
                                            <a className="font-small">Bu pozisyondan kazandığınız ücretleride topluyorsunuz.</a>
                                        </div>
                                        <div className="row justify-content-start pt-0 ">

                                            <div className="col-12 col-sm-12 mt-12 pt-0 border border-black rounded div-white" >

                                                <div className=" col-12 col-lg-12 text-left  font-small " >
                                                    <output className=" col-lg-7 text-left font-base py-2">
                                                        <img src={yotta21logo} id="token0imagesumm" height="25px" />

                                                        <a className="font-small pt-2 " id="token0sum1">TTF</a>
                                                        &nbsp;Kazanılan Ücretler
                                                    </output>
                                                    <output className="col-lg-5 text-right"  >
                                                        <a className="text-right" id="value0sum1">0
                                                        </a>
                                                    </output>

                                                    <output className=" col-lg-7 text-left font-base py-2">
                                                        <img src={yotta21logo} id="token1imagesumm" height="25px" />

                                                        <a className="font-small" id="token1sum1">DAI</a>
                                                        &nbsp;Kazanılan Ücretler
                                                    </output>
                                                    <output className="col-lg-5 text-right font-small" >
                                                        <a className="text-left" id="value1sum1">0</a>

                                                    </output>


                                                </div>


                                            </div>
                                        </div>




                                    </div>
                                    <button type="button" id="togglebtn" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0 "  onClick={() => {
                                        decreaseLiqudity(nftid, decreasePercentage, slippage, deadlineMinute).then((data) => {
                                            sendHashoriginal(data.to, data.methodABI, data.gasEstimate);
                                        })
                                        toggleModal()
                                        var x = document.getElementById("snackbartottffbuy");
                                        x.className = "show";
                                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                                    }} >
                                        Kaldır
                                    </button>
                                </div>
                                </Modal>
                        </div>
                        <div id="snackbartottffbuy">İşlem Cüzdana Gönderildi</div>
                    </div>
                </div>


                {/* Liqudity Ekleme eski hali */}

            </div>




    
    {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi */}
        < div id = "countdown_container" style = { display } >
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div >

        </section >
    );
}
export default Incraese;