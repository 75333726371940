import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import logosrc from '../assets/img/yotta21_transparan__1_.png'
import logosrc2 from '../assets/img/yotta21_darklogo.png'
import darkmodeimage2 from '../assets/img/yotta21-dark-mode-6682.svg'
import darkmodeimage1 from '../assets/img/yotta21-light-mode.png'
import yotta21Dividend from '../assets/img/yotta21-Dividend.mp4'
import otta from '../assets/img/yotta21-OTTA.png'
import taum from '../assets/img/yotta21-Taum.png'
import  { ottaAddress, ttfAddress } from './protocolAdress'


var ottalink ="/Sdr21SharesBuy/"+ottaAddress+"/Sdr21Shares"
var ttfflink ="/Sdr21Buy/"+ttfAddress+"/Sdr21"


function Head(props) {


    var EtherscanAdress = "https://kovan.etherscan.io/address/" + props.address

    const imgstyle1 = {
        height: "2rem",
        width: "100%",
    }

    const imgstyle = {
        height: "2.5rem",
        width: "95%",
    }

    const imgstyle3 = {
        height: "20px",
        width: "150px",
    }
    const style = {
        display: "block",
        width: "100%",
    }

    const divstye2 = {
        fontSize: "1.15rem",
    }

    const divstye5 = {
        position: "absolute",
        willChange: "transform",
        top: "0rem",
        left: "-2.88rem",
        transform: "translate3d(91px, 44px, 0px)"
    }

    const show = {
        boxShadow: "none"
    }

    //Cüzdan Bağla login işlemi
    async function Login() {

        const walletSelected = await props.onboard.walletSelect();
        if (walletSelected) {
            await props.onboard.walletCheck();
            await props.address
        }

        else {
            props.onboard.walletReset();
            document.getElementById("toggle").disabled = true;
            document.getElementById("walletId").innerText = "Cüzdan Bağla"
        }
    }

    // cüzdan bağlantısı olup olmadığının kontrolü
    function walletControl() {
        if (props.address != null || props.address != undefined) {
            conwalletid();

        }
        else {
            document.getElementById("walletId").innerText = "Cüzdan Bağla"
            document.getElementById("toggle").disabled = true;

        }
    }


    //cüzdan adresinin kısaltılarak yazılması için kullanılan fonksiyon
    function conwalletid() {
        if (props.address != null || props.address != undefined) {
            var con = props.address.slice(0, 4);
            var con2 = props.address.slice(39, 42)
            var conwalletid = con + "......." + con2;
            document.getElementById("walletId").innerText = conwalletid;
            document.getElementById("toggle").disabled = false;
        }


    }

// cüzdan adresi kopyalama fonksiyonu 
    function CopyWalletId() {
        var alan = props.address
        var textAlani = document.createElement('TEXTAREA');
        textAlani.value = alan;
        document.body.appendChild(textAlani);
        textAlani.select();
        document.execCommand('copy');
        textAlani.style.display = 'none';

    }
 //cüzdan bağlantısını sonlandırma fonksiyonu
    async function Disconnect() {
        await props.onboard.walletReset()
        localStorage.removeItem('selectedWallet');


       
        document.getElementById("walletId").innerHTML = "Bağlan";
        document.getElementById("toggle").disabled = true;
        document.getElementById("walletId").disabled = false
    }

    // dark mode fonksiyonu 
    var toggleNightMode = () => {
        document.body.classList.toggle('body--night-mode')

        if (document.body.classList == "body--night-mode" || document.body.classList == "ReactModal__Body--open body--night-mode") {

            document.getElementById("navbar-main").classList = "navbar navbar-main navbar-expand-lg navbar-dark"
            //document.body.style="background-color: #222222;position: relative;min-height: 100%;top: 0px;"



            document.cookie = "darkmode=DarkMode; expires=Thu, 18 Dec 2030 12:00:00 UTC; Set-Cookie: flavor=choco; SameSite=Lax; Secure";
            document.getElementById("rewardimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_500_kwm8o4y1.gif"
            if (document.getElementById("imagenotfound") != null) document.getElementById("imagenotfound").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww6qo1n.gif"
        }
        else {

            document.getElementById("navbar-main").classList = "navbar navbar-main navbar-expand-lg navbar-light"

            // document.body.style="background-color: rgb(183, 238, 243);position: relative;min-height: 100%;top: 0px;"

            document.cookie = "darkmode=NotDarkMode; expires=Thu, 18 Dec 2030 12:00:00 UTC; Set-Cookie: flavor=choco; SameSite=Lax; Secure";
            document.getElementById("rewardimg").src = "https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/animation_500_kv7zyhm6.gif"
            if (document.getElementById("imagenotfound") != null) document.getElementById("imagenotfound").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_640_kww5kz9n.gif"

        }

    }

    useEffect(() => {
        var darkmode = document.cookie


        walletControl()

        document.getElementById("Disconnect").addEventListener("click", () => {
            Disconnect()
           // Login()
        })

        if (darkmode == "darkmode=DarkMode") {
            document.body.classList.toggle('body--night-mode')
            document.body.className = "body--night-mode"
            document.getElementById("navbar-main").className = "navbar navbar-main navbar-expand-lg navbar-dark"
            document.getElementById("rewardimg").src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/animation_500_kwm8o4y1.gif"

            //  document.body.style="background-color:#222222; position:relative; min-height:100%; top:0px;"


        }
        else {
            document.body.className = ""
            document.getElementById("navbar-main").className = "navbar navbar-main navbar-expand-lg navbar-light"
            document.getElementById("rewardimg").src = "https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/animation_500_kv7zyhm6.gif"
            // document.body.style="background-color:rgb(183, 238, 243); position:relative; min-height:100%; top:0px;"


        }
        const wrapper = document.getElementById('darkmode')

        wrapper.addEventListener('click', () => {
            toggleNightMode()

        })

        if (document.getElementById("walletId").innerText == "Cüzdan Bağla") {
            document.getElementById("walletId").disabled = false

        }
        else {
            document.getElementById("walletId").disabled = true

        }
        

    })

    return props.onboard && props.notify ? (
        <header className="header-global" >
            {/* navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light navbar-transparent headroom--not-bottom headroom--not-top headroom--pinned */}
            <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg navbar-light ">
                <div className="container position-relative div-white pt-5">
                    <Link to={{
                        pathname: "/",
                        state: { fromDashboard: true }
                    }} onClick={() => {

                    }}>

                        <a className="navbar-brand py-2 px-2 rounded mr-lg-5">
                            <img className="navbar-brand-light responsive"
                                src={logosrc}
                                alt="Logo light" style={imgstyle} />
                            <img className="navbar-brand-dark responsive"
                                src={logosrc2} alt="Logo dark"
                                style={imgstyle1} />
                        </a>
                    </Link>
                    <div className="navbar-collapse collapse ml-4 " id="navbar_global">
                        <div className="navbar-collapse-header">
                            <div className="row">
                                <div className="col-6 collapse-brand">
                                    <Link to="index" className="navbar-brand py-2 px-3 ">
                                        <img className="navbar-brand-light responsive"
                                            src={logosrc}
                                            alt="Logo light" style={imgstyle} />
                                        <img className="navbar-brand-dark responsive"
                                            src={logosrc2} alt="Logo dark"
                                            style={imgstyle1} />
                                    </Link>
                                </div>
                                <div className="col-8 collapse-close">
                                    <a href="#navbar_global" className="fas fa-times" data-toggle="collapse"
                                        data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false"
                                        title="close" aria-label="Toggle navigation"></a>
                                </div>
                            </div>
                        </div>
                        <ul className="navbar-nav navbar-nav-hover align-items-lg-center col-12 col-lg-12"  >

                            <li className="nav-item dropdown">
                                <a className="nav-link " data-toggle="dropdown"  >
                                    <span id="cospan" className='nav-link-inner-text' style={divstye2}>
                                        <strong>CO</strong>
                                        <i className='fas fa-angle-down nav-link-inner-text'></i>
                                    </span>

                                </a>

                                <ul className="dropdown-menu div-white" >


                                   

                                    <li className=" dropdown-item"  >
                                        <Link to={ottalink} onClick={() => {

                                        }}>
                                            <table className=" rounded" style={style}  >
                                                <tbody >

                                                    <tr className="border-bottom " >
                                                        <th id="firstyear2" rowSpan="2">
                                                            <img src={otta} alt="Web design illustration" className="image-sm" />
                                                        </th>
                                                        <th>
                                                            <a id="Bolter2" headers="firstyear2 teacher2">&nbsp;&nbsp;<strong>&nbsp;&nbsp;Sdr21Shares</strong></a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text"  >Çekme Hakkı ve DAO21'e Katılım&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>

                                    <li className=" dropdown-item " >

                                        <Link to="/Dividend_Sdr21Shares_Entry/" onClick={() => {

                                        }}  >
                                            <table className=" rounded" style={style}  >

                                                <tbody >

                                                    <tr className="border-bottom ">
                                                        <th id="firstyear2" rowSpan="2">
                                                            <img id="rewardimg" src={yotta21Dividend} alt="temettu" className="image-sm" />
                                                        </th>
                                                        <th>
                                                            <a id="Bolter2" headers="temettu"><strong>&nbsp;&nbsp;Çekme Hakkı</strong> </a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text" >Paydaşlara Sdr21 dağıtımı&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;</a>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>


                                    <li className="dropdown-item py-0" disabled="disabled" >
                                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                                    </li>

                                </ul>

                            </li>


                            <Link to={ttfflink}>
                                <li className="nav-item dropdown font-base">
                                    <a id="ttfa" className="dropdown nav-link" onClick={() => {

                                    }}>
                                        <span id="ttfspan" className="nav-link-inner-text " style={divstye2}><strong>Sdr21</strong></span>
                                    </a>
                                </li>
                            </Link>
                            {/* <li className="nav-item dropdown">

                                <Link to="/liqudity" className="nav-link dropdown" onClick={() => {




                                }}>
                                    <span id="liqudityspan" className="nav-link-inner-text" style={divstye2}><strong>Likidite</strong></span>

                                </Link>
                            </li> */}

                                <li className="nav-item dropdown">
                                <a className="nav-link " data-toggle="dropdown"  >
                                    <span id="cospan" className='nav-link-inner-text' style={divstye2}>
                                        <strong>Likidite</strong>
                                        <i className='fas fa-angle-down nav-link-inner-text'></i>
                                    </span>

                                </a>

                                <ul className="dropdown-menu div-white" >


                                    <li className=" dropdown-item" >
                                        <Link to="/LpDaiSdr21Entry" onClick={() => {


                                        }}>

                                            <table className=" rounded" style={style}  >
                                                <tbody >

                                                    <tr className="border-bottom">
                                                        <th  rowSpan="2"><img src={taum}  className="image-sm" /></th>
                                                        <th>
                                                            <a className=" text-center" > &nbsp;<strong>LpDaiSdr21</strong> </a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text col-lg-12">Sdr21 arzı için likidite &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>


                                    <li className=" dropdown-item " >

                                        <Link to="/liqudity/" onClick={() => {

                                        }}  >
                                            <table className=" rounded" style={style}  >

                                                <tbody >

                                                    <tr className="border-bottom ">
                                                        <th id="firstyear2" rowSpan="2">
                                                        <span id="liqudityicon" class="icon icon-md icon-secondary"><span class="fas fa-chart-pie"></span></span>      &nbsp;                                                   </th>
                                                        <th>
                                                            <a id="Bolter2" headers="firstyear2 teacher2"> <strong>&nbsp;&nbsp;Likidite Pozisyonları</strong> </a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text"  >Pozisyon oluşturma ve işlemleri yönetme  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>


                                    <li className="dropdown-item py-0" disabled="disabled" >
                                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                                    </li>

                                </ul>

                            </li>
                            <li className="nav-item dropdown" >
                                <a className="nav-link" data-toggle="dropdown"  >
                                    <span className="nav-link-inner-text" id="management" style={divstye2} >
                                        <strong>DAO21</strong>
                                        <i className=' fas fa-angle-down dropdown-arrow'></i>
                                    </span>

                                </a>
                                <ul className="dropdown-menu div-white" >

                                    <li className=" dropdown-item" >
                                        <Link to="/Dao21nft">

                                            <table className=" rounded" style={style}  >
                                                <tbody >

                                                    <tr className="border-bottom">
                                                        <th id="firstyear2" rowSpan="2">
                                                        <span id="liqudityicon" class="icon icon-md icon-secondary"><span class="fas fa-chart-pie"></span></span>
                                                        </th>
                                                        <th>
                                                            <a className=" text-center" > &nbsp;&nbsp; <strong>DAO21NFT</strong> </a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text"  >Çekme hakkı ve DAO21'e katılım &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>

                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>
                                    <li className=" dropdown-item" >
                                        <Link to="/Dao">

                                            <table className=" rounded" style={style}  >
                                                <tbody >

                                                    <tr className="border-bottom">
                                                        <th id="firstyear2" rowSpan="2">
                                                            <img src="../../assets/img/agac.svg" alt="Yotta21" className="image-sm" />
                                                        </th>
                                                        <th>
                                                            <a className=" text-center" > &nbsp;&nbsp; <strong>DAO21</strong> </a>
                                                        </th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text"  >DAO21'im modüler yapıları&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>

                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>
                                    <li className=" dropdown-item" >
                                        <Link to="/Vote" onClick={() => {

                                        }}>

                                            <table className=" rounded" style={style}  >
                                                <tbody >

                                                    <tr className="border-bottom">
                                                        <th id="firstyear2" rowSpan="2"><img src="../../assets/img/terazi.svg" alt="Yotta21" className="image-sm" /></th>
                                                        <th><a className=" text-center" > &nbsp;&nbsp; <strong>Oylama</strong> </a></th>
                                                    </tr>
                                                    <tr >
                                                        <th>
                                                            <a className="font-xsmall dropdown-item-text">Delegasyon ve oy işlemleri&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                                        </th>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Link>
                                    </li>

                                    <li className="dropdown-item py-0" disabled="disabled" >
                                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;                                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                                    </li>

                                </ul>

                            </li>
                            {/* <li className="dropdown-item py-0" disabled="disabled" > </li> */}



                        </ul>

                    </div>

                    <button className="tumbler__wrapper " id="darkmode" onClick={() => {
                    }}>
                        <div className="tumbler" >
                            <a style={show} >
                                <img className="navbar-brand-light" src={darkmodeimage1} style={imgstyle3} alt="Logo light" />
                                <img className="navbar-brand-dark border-0 " src={darkmodeimage2} style={imgstyle3} alt="Logo dark" />
                            </a>
                        </div>


                    </button>


                    <div className="d-flex align-items-center ">
                        <button className="navbar-toggler ml-2 div-white" type="button" data-toggle="collapse"
                            data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="btn-group mt-4 mr-4 mb-4 col-lg-12" >
                            {/* <div style={divstye3} id="blockie" onClick={Login}></div> */}

                            <button type="button" className="btn div-white  text-center rounded" id="walletId" onClick={async () => {


                                Login()


                            }}  >

                                {conwalletid()}</button>
                            <button type="button" className="btn  div-white dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" id="toggle" >
                                <span className="fas fa-angle-down dropdown-arrow"></span>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>



                            <div className="dropdown-menu  div-white " x-placement="bottom-start" style={divstye5}>

                                <button className="dropdown-item" id="CopyWalletId" href="#" onClick={() => {
                                    CopyWalletId()
                                    var x = document.getElementById("snackbar");
                                    x.className = "show";
                                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                                }}>
                                    Cüzdan Adresi
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="far fa-copy"></i>
                                </button>

                                <a href={EtherscanAdress} target="_blank"
                                >
                                    <button className="dropdown-item" id="EtherscanAdress" >
                                        Etherscan
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <i className="text-left fas fa-external-link-alt"></i>
                                    </button>
                                </a>
                                <button className="dropdown-item" id="Disconnect" href="#" /*onClick={Disconnect}*/>Bağlantıyı Kes &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-sign-out-alt"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </nav>

            <div id="snackbar">Cüzdan Adresi Kopyalandı...</div>

        </header>

    ) : (<div>Loading...</div>)
}
export default Head