const ottaAddress = "0xFAE5Cf7DCff2bc354487Ca69Fb20c2926375D2c4";
const dividendAddress = "0x04581BeBa9C43B9d6974b49C7726E1cb8B7355A4";
const governorBravoDelegateAddress = "0x2a92dA5D402241D70d3c264b68Afe3a94c1E3740";
const governorBravoDelegatorAddress = "0x44531A747DFd7C7E636a05eCC128b24B76fBa4a7";
const ttfAddress = "0xB5523D0f19352cEf7d23b73c44b4A51Bc7C40298";
const taumAddress = "0x1F1cc487549243Db9fc328D68B8c101B270E785E";
const ethereumPoolAddress = "0x5ec3C8197Bb8897EBDCee69447Cb257d35c90200";
const priceAddress = "0xF86E08BD977287ACc213138a763607fbDBa19b17";
const wethAddress ="0xd0A1E359811322d97991E03f863a0C30C2cF029C";
const TtffPoolAddress = "0xf4c673739F1162272cAc5A901E8b523f54f8802F";
const TimeLock = "0x1225126149e6749eA1cB894d2b4eC6Afa2EcEdFE";
const Ecosys= "0x3c67Ee5E1c2A6755411ED8dA344565983225aC93";
const Bgtech= "0x383f4d7fdbbcb080c469b54376ea12c591d0052b";
const Hybrid= "0xaEcC25fa73192D1cDC9518a055210014e96aaBC0";

module.exports = {
    ottaAddress,
    dividendAddress,
    governorBravoDelegateAddress,
    governorBravoDelegatorAddress,
    ttfAddress,
    taumAddress,
    ethereumPoolAddress,
    priceAddress,
    wethAddress,
    TtffPoolAddress,
    TimeLock,
    Ecosys,
    Bgtech,
    Hybrid
}