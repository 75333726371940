import React, { useEffect, useState } from 'react'

let url = process.env.REACT_APP_FAQ_API;
//console.log("URL",url);
function FAQ() {
    const [questions, setQuestions] = useState([]);
    useEffect(() => {
        fetch(url)
            .then(Response => Response.json())
            .then(Response => setQuestions(Response.questionDocs));
    }, []);
    let count = 0;
    //console.log('deneme', questions);

    return (

        <div className="section section-lg pt-6 ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="accordion shadow-soft rounded " id="content">
                            {
                                questions.map(ver => {
                                    count++;
                                    let data = "panel-" + count;
                                    let data1 = "#panel-" + count;

                                    return <div className="card card-sm card-body bg-primary border-light mb-0">
                                        <a href={data1} data-target={data1} className="accordion-panel-header"
                                            data-toggle="collapse" role="button" aria-expanded="false" aria-controls={data} >
                                            <span className="icon-title h6 mb-0 font-weight-bold"><span
                                                className="fab fa-leanpub"></span> {ver.question}
                                            </span>
                                            <span className="icon"><span class="fas fa-plus"></span></span>
                                        </a>
                                        <div className="collapse" id={data} >
                                            <div className="pt-3">
                                                <p className="mb-0">
                                                    {ver.answer}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default FAQ;


