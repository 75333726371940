import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import yotta21logo from "../../assets/img/yotta21-logo21.png"
import tokenAddress, { ottaAddress, wethAddress } from '../protocolAdress'



function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

Modal.setAppElement("#index");
var slippage = 0.05
var deadlineMinute = 30

const display = {
    display: 'none ',
}
const divstye3 = {
    backgroundColor: "transparent"

}

function Yotta21Buy(props) {

    const { tokenid } = useParams();
    const { tokenname } = useParams();

    //modal open function
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }
    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }
    function toggleModal3() {
        setIsOpen3(!isOpen3);
    }

    //state values ​​for Popover1 object for settings tab
    const [popoverOpen1, setPopoverOpen1] = useState(false);
    const toggle1 = () => setPopoverOpen1(!popoverOpen1);
    //state values ​​for Popover2 object for settings tab
    const [popoverOpen2, setPopoverOpen2] = useState(false);
    const toggle2 = () => setPopoverOpen2(!popoverOpen2);

    //transaction data 
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', end => {
            if (_transactiontype == "approve") {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = true;
                }
            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;
                }
            }
        })
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approve") {
                document.getElementById("btn-approve").disabled = true;
                document.getElementById("btn-approve").innerText = "Confirmed";
                console.log(end);

                CalculatingFRomValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").textContent, document.getElementById("to").innerText, 1);
                CalculatingToValue(document.getElementById("tokenid").value);
            }
            else {

                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;

                }
            }
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            if (document.getElementById("btn-approve") != null) {
                document.getElementById("btn-approve").disabled = false;

            }
            document.getElementById("btn-approve").disabled = false;
            console.log(end);

        })
        emitter.on('txFailed', end => {
            console.log(end);
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        if (document.getElementById("btn-approve") != null) {
            document.getElementById("btn-approve").disabled = false;
        }


    }

    //function that performs swap
    async function Swap(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        const _dataswap = {
            "amountIn": _sellAmount,
            "slippagePercentage": slippage.toString(),
            "tokenAddresses": ["0xFAE5Cf7DCff2bc354487Ca69Fb20c2926375D2c4", "0xd0A1E359811322d97991E03f863a0C30C2cF029C"],
            "tokenNames": ["WETH", "OTTA"],
            "owner": props.address,
            "deadlineMinute": deadlineMinute.toString()
        }
        const data = JSON.stringify(_dataswap)
        let result;
        return result = await new Promise((resolve, reject) => {
            /*process.env.REACT_APP_EXCHANGE*/
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_dataswap)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_BALANCE, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingFRomValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        var value;

        document.getElementById("priceRangeMax1").value = null
        document.getElementById("priceRangeMax2").value = null
        value = 0.0;
        var _datacalfrom = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["OTTA", "WETH"],
        }
        /*process.env.REACT_APP_EXCHANGE*/
        fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalfrom),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;

                const captureValue = function (e) {
                    value = e.target.value * ver.receive
                    document.getElementById("priceRangeMax2").value = toFixed(value);
                    value = 0;

                    if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                        if (document.getElementById("btn-approve") == null) {
                            document.getElementById("C").disabled = true;
                        }
                        document.getElementById("C").disabled = true;
                        document.getElementById("C").innerText = "Yetersiz Bakiye..."
                        if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value)) {

                            document.getElementById("C").disabled = true;
                            document.getElementById("C").innerText = "Yetersiz Bakiye..."
                        }
                    }
                    else {
                        document.getElementById("C").disabled = false;
                        document.getElementById("C").innerText = "Devam"
                    }
                }
                document.getElementById("priceRangeMax1").addEventListener("input", captureValue);
            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })

    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingToValue(_tokenBuyAddress, _tokenSellAddress, _buyToken, _sellToken, _sellAmount) {
        var value;

        document.getElementById("priceRangeMax1").value = null
        document.getElementById("priceRangeMax2").value = null
        value = 0.0;
        var _datacalto = {
            tokenAddresses: [_tokenSellAddress, _tokenBuyAddress],
            owner: props.address,
            /*buyToken: _buyToken,
            sellToken: _sellToken,
            sellAmount: _sellAmount,*/
            /*slippagePercentage: slippage*/
            deadlineMinute: deadlineMinute.toString(),
            amountIn: _sellAmount,
            slippagePercentage: slippage.toString(),
            tokenNames: ["OTTA", "WETH"],


        }
        /*process.env.REACT_APP_EXCHANGE*/
        fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/testswap/testSwap", {

            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_datacalto),

        })
            .then(response => response.json())
            .then(ver => {
                //api değer döndürünce inputların disabled değerinin false olması ile yazılabilir oluyor.
                document.getElementById("priceRangeMax2").disabled = false;
                document.getElementById("priceRangeMax1").disabled = false;
                const captureValue = function (e) {
                    value = e.target.value * (1 / ver.price)
                    document.getElementById("priceRangeMax1").value = toFixed((value))
                    value = 0;

                    if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                        if (document.getElementById("btn-approve") == null) {
                            document.getElementById("C").disabled = true;
                        }
                        document.getElementById("C").disabled = true;
                        document.getElementById("C").innerText = "Yetersiz Bakiye..."
                        if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value)) {
                            document.getElementById("C").disabled = true;
                            document.getElementById("C").innerText = "Yetersiz Bakiye..."
                        }
                        else {
                            document.getElementById("C").disabled = false;
                            document.getElementById("C").innerText = "Devam"
                        }

                    }
                    else {
                        document.getElementById("C").disabled = false;
                        document.getElementById("C").innerText = "Devam"

                        if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value)) {
                            document.getElementById("C").disabled = true;
                            document.getElementById("C").innerText = "Bakiye Yetersiz..."
                        }
                        else {
                            document.getElementById("C").disabled = false;
                            document.getElementById("C").innerText = "Devam"
                        }
                    }
                }
                document.getElementById("priceRangeMax2").addEventListener("input", captureValue);
            })
            .catch((eror) => {
                console.log("Hata:", eror);
            })


    }
    //Cüzdan Bağla check
    function walletControl() {
        if (props.address == null) {

            document.getElementById("C").innerText = "Cüzdan Bağla"
            document.getElementById("C").disabled = true
        }
        else {
            document.getElementById("C").disabled = true
            document.getElementById("C").innerText = "Devam"

        }
        // document.getElementById("fromid").innerText = tokenname;
        // document.getElementById("C").disabled = true

    }

    useEffect(() => {

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })


        balance(tokenid, tokenname).then((ver) => {
            document.getElementById("frombalance").innerText = Number(ver).toFixed(5)
            document.getElementById("fromid").innerText = tokenname;
            document.getElementById("fromid").value = tokenid;

            document.getElementById("C").disabled = true

        })
        walletControl()

    })

    //This function settings section controls the deadline upper limit.
    function settingControldeadline() {
        const captureValue = function (e) {
            var value = e.target.value

            if (181 > value) {
                document.getElementById("deadline").className = "border border-light rounded div-white form-control"
                document.getElementById("deadline").style.borderColor = "red"
                document.getElementById("danger2").innerText = ""
                if (51 > document.getElementById("slippage").value && document.getElementById("slippage").value != null) {
                    document.getElementById("save").disabled = false

                }
                else {
                    document.getElementById("save").disabled = true

                }
            }
            else {

                document.getElementById("deadline").className = " div-white form-control"
                var danger2 = document.createElement("span");
                danger2.setAttribute("id", "dangers2");
                danger2.innerText = "*Değerler aralığın dışında"
                danger2.className = "font-xsmall "
                danger2.style.color = "red"
                if (document.getElementById("dangers2") == null) {
                    document.getElementById("danger2").appendChild(danger2);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("deadline").addEventListener("input", captureValue);
    }
    //This function settings section controls the slippage upper limit.
    function settingControlslippage() {
        const captureValue = function (e) {
            var value = e.target.value
            if (51 > value) {
                document.getElementById("slippage").className = "border border-light rounded div-white form-control"
                document.getElementById("slippage").style.borderColor = "red"
                document.getElementById("danger").innerText = ""

                if (181 > document.getElementById("deadline").value && document.getElementById("deadline").value != null) {
                    document.getElementById("save").disabled = false

                }
                else document.getElementById("save").disabled = true
            }
            else {
                document.getElementById("slippage").className = "div-white form-control"
                document.getElementById("toleranscheck").className = "div-white "
                var danger = document.createElement("span");
                danger.setAttribute("id", "dangers");
                danger.innerText = "*Değerler aralığın dışında"
                danger.className = "font-xsmall "
                danger.style.color = "red"

                if (document.getElementById("dangers") == null) {
                    document.getElementById("danger").appendChild(danger);
                }
                document.getElementById("save").disabled = true

            }

        }

        document.getElementById("slippage").addEventListener("input", captureValue);
    }



    return (
        <section id="root pt-0">
            <div className="row mb-5 justify-content-center pt-5 " >
                <div className="  justify-content-center col-12 col-md-12 col-lg-auto mb-5 mb-lg-0 pt-0">
                    <div className="  border-light p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <br />
                                    <h4><strong>Sdr21Shares</strong> </h4>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-12 col-lg-11 py-4">
                                            <div>
                                                <div className="">
                                                    <div className="row justify-content-end">
                                                        <Button className=" btn-pill btn-yotta21  div-yotta21" onClick={() => {
                                                            toggleModal3()
                                                            setTimeout(() => {
                                                                document.getElementById("slippage").value = slippage
                                                                document.getElementById("deadline").value = deadlineMinute
                                                            }, 1000);
                                                        }} ><i className="fas fa-cog  " style={divstye3}  ></i></Button>
                                                    </div>
                                                    {/* modal setting start */}
                                                    <Modal
                                                        isOpen={isOpen3}
                                                        onRequestClose={toggleModal3}
                                                        contentLabel="My dialog"
                                                        className="mymodal3" /*"modal-dialog modal-dialog-centered rounded"*/
                                                        overlayClassName="myoverlay"
                                                        closeTimeoutMS={500}
                                                        aria-hidden="true"

                                                    >
                                                        <div className="rounded " id="sums">
                                                            <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                                                document.getElementById("deadline").value = 0
                                                                document.getElementById("slippage").value = 0
                                                            }}>
                                                                <span aria-hidden="true" id="close" onClick={() => {
                                                                    if (document.getElementById("dangers") != null) {
                                                                        document.getElementById("danger").removeChild(document.getElementById("dangers"))
                                                                        document.getElementById("slippage").style.borderColor = "white"
                                                                    }
                                                                    if (document.getElementById("dangers2") != null) {
                                                                        document.getElementById("danger2").removeChild(document.getElementById("dangers2"))
                                                                        document.getElementById("deadline").style.borderColor = "white"
                                                                    }
                                                                    toggleModal3()
                                                                }}>×</span>
                                                            </button>
                                                            <div className="card-header  text-center pb-0 pt-0">
                                                                <h2 className="h4 font-medium text-center pt-0">Ayarlar</h2>
                                                            </div>
                                                            <div className="card-body text-center pb-0 pt-0 font-smedium  div-white " >
                                                                <div className="row ">
                                                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                        <div className="text-left">
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <a className="font-medium">Kayma Toleransı</a>
                                                                            <button className="border-0 font-medium div-white" id="Popover1" >
                                                                                <i className="far fa-question-circle"></i>
                                                                            </button>
                                                                            <Popover isOpen={popoverOpen1} target="Popover1" toggle={toggle1}>

                                                                                <PopoverBody>Fiyatın istenmeyen şekilde bu yüzdeden daha fazla değişmesi durumunda işleminiz geri döner.</PopoverBody>
                                                                            </Popover>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-12 text-left pt-0  font-medium py-2">
                                                                            <output className="text-right div-white col-lg-8" >
                                                                                <InputGroup >
                                                                                    <InputGroupAddon addonType="prepend" >
                                                                                        <InputGroupText className="div-white">
                                                                                            <Input addon type="checkbox" aria-label="Checkbox for following text input" id="toleranscheck" className="div-white" onChange={() => {
                                                                                                if (document.getElementById("toleranscheck").checked == true) {
                                                                                                    document.getElementById("slippage").value = 0.05;
                                                                                                }
                                                                                                else {
                                                                                                    document.getElementById("slippage").value = null
                                                                                                }
                                                                                            }} />&nbsp;  Otomatik
                                                                                        </InputGroupText>
                                                                                    </InputGroupAddon>
                                                                                    <Input className="div-white " placeholder="%0.5" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="50" id="slippage" onChange={() => {
                                                                                        settingControlslippage()
                                                                                    }}></Input>
                                                                                </InputGroup>
                                                                            </output>
                                                                        </div>
                                                                    </div>
                                                                    <div id="danger"></div>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded py-1 div-white" >
                                                                        <div className="text-left " >
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <a className="font-medium">İşlem Son Tarihi</a>
                                                                            <button className="border-0 div-white" id="Popover2"  >
                                                                                <i className="far fa-question-circle font-medium" ></i>
                                                                            </button>
                                                                            <Popover isOpen={popoverOpen2} target="Popover2" toggle={toggle2} className="div-white" >
                                                                                <PopoverBody>İşleminiz bu süreden daha uzun bir süredir beklemedeyse geri alınacaktır.</PopoverBody>
                                                                            </Popover>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-12 text-left pt-0 font-medium py-2">
                                                                            <output className="text-left col-lg-6 font-medium div-white" >
                                                                                <Input placeholder="30" id="deadline" className="div-white" type="number" pattern="^[0-9]*[.,]?[0-9]*$" min="0" max="180" onChange={() => {
                                                                                    settingControldeadline()
                                                                                }} />
                                                                            </output>
                                                                            <output className="col-lg-3 text-left" >
                                                                                <a className="font-left">Dakika</a>
                                                                            </output>
                                                                        </div>
                                                                    </div>
                                                                    <div id="danger2"></div>
                                                                </div>
                                                            </div>
                                                            <br></br>
                                                            <button className="btn btn-yotta21 col-lg-12" onClick={() => {
                                                                if (document.getElementById("toleranscheck").checked == true) {
                                                                    slippage = 0.05
                                                                    if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                        deadlineMinute = 30;
                                                                    }
                                                                    else {
                                                                        deadlineMinute = document.getElementById("deadline").value
                                                                    }
                                                                }
                                                                else {
                                                                    if (document.getElementById("slippage").value == "" || document.getElementById("slippage").value == null) {
                                                                        slippage = 0.05
                                                                    }
                                                                    else {
                                                                        slippage = document.getElementById("slippage").value

                                                                    }
                                                                    if (document.getElementById("deadline").value == "" || document.getElementById("deadline").value == null) {
                                                                        deadlineMinute = 30;
                                                                    }
                                                                    else {
                                                                        deadlineMinute = document.getElementById("deadline").value
                                                                    }
                                                                }
                                                                toggleModal3()

                                                            }} id="save">Kaydet</button>
                                                        </div>
                                                    </Modal>
                                                    {/* modal setting end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="   mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12  btn-group " >
                                    <button className="col-12 col-lg-6 btn  div-white" id="buy" onClick={() => {
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn active  "
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn  "
                                        document.getElementById("broker").className = "col-12 col-lg-6 btn"

                                        var url = "/#/Sdr21SharesBuy/" + ottaAddress + "/Sdr21Shares"
                                        window.location.href = url
                                    }}>Al</button>
                                    <button className="col-11 col-lg-6 btn div-white active" id="sell" onClick={() => {
                                        var url = "/#/Sdr21SharesSell/" + ottaAddress + "/Sdr21Shares"
                                        window.location.href = url
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn  active"
                                        document.getElementById("broker").className = "col-12 col-lg-6 btn  "

                                    }}>Sat</button>
                                       <button className="col-11 col-lg-6 btn div-white " id="broker" onClick={() => {
                                        var url = "/#/Sdr21SharesBroker/" + ottaAddress + "/Sdr21Shares"
                                        window.location.href = url
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn "
                                        document.getElementById("broker").className = "col-12 col-lg-6 btn  active"

                                    }}>Broker</button>
                                </div>
                            </div>
                        </div>
                        {/* dsasa */}
                        <div className="card-body shadow-soft border border-light rounded p-2 mb-2 pt-0 div-white " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light  ">
                                        <div className="row">
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="App" >
                                                    <button type="button" value={ottaAddress} className="btn btn-primary col-md-12" style={divstye3}  >
                                                        <img id="imageidfrom" src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/Ottatoken1.png" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="fromid" value={ottaAddress} >Sdr21Shares</a>
                                                    </button>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="row justify-content-start m-0 col-12 col-lg-12 text-left">
                                                        <a className="font-small text-bold text-left">  Bakiye:  </a>&nbsp;
                                                        <a id="frombalance" className="font-small text-bold">0.0</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">
                                                        <div className="text-right col-12  pb-4 " id="text-right" style={divstye3}>
                                                            <input className="form-control div-white" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* From componet end */}

                        {/* to component start */}
                        <div className="col-12 col-lg-6 pt-2 ">
                        </div>
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light py-1 ">
                                        {/* Swap ÖZeti */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0 " >
                                                <div className="App" >
                                                    <button type="button" value="WETH" className="btn btn-primary col-md-12" id="btnselect" onClick={() => {
                                                        balance(wethAddress, "WETH").then((ver) => {
                                                            document.getElementById("WethBalance").innerText = Number(ver).toFixed(5) + " WETH";
                                                        });
                                                        document.getElementById("fromid").value = ottaAddress;
                                                        toggleModal();
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://img.icons8.com/small/32/000000/sorting-arrows.png" width="0px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Token Seçiniz" >Token Seçiniz  </a>&nbsp;&nbsp;
                                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                                    </button>
                                                    <br></br>
                                                    <br></br>
                                                    <div className="row pt- m-0">&nbsp;&nbsp;&nbsp;
                                                        <span className="font-small">Bakiye:   </span>
                                                        <span id="balanceid" className="font-small">   </span>
                                                    </div>

                                                    {/* Token select Modal Start */}
                                                    <Modal
                                                        isOpen={isOpen}
                                                        onRequestClose={toggleModal}
                                                        contentLabel="My dialog"
                                                        className="mymodal"
                                                        overlayClassName="myoverlay"
                                                        closeTimeoutMS={500}
                                                    >
                                                        <div className="card-header border-bottom text-center ">
                                                            <span className="d-block">
                                                                <span className="display-3 font-weight-bold">
                                                                    Token Listesi
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="card-body p-2 col-mg-12">
                                                            <span className="display-5 font-weight-bold"> Lütfen Token Seçiniz.. </span>
                                                            <br></br><br></br>
                                                            <ListGroup>
                                                                {/* Token WETH Selected start  */}
                                                                <ListGroupItem tag="button" action onClick={() => {
                                                                    document.getElementById("imageId").src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg";
                                                                    document.getElementById("imageId").style.width = "25px";
                                                                    document.getElementById("tokenid").textContent = "WETH";
                                                                    document.getElementById("tokenid").value = wethAddress
                                                                    balance(wethAddress, "WETH").then((ver) => {
                                                                        document.getElementById("balanceid").innerText = Number(ver).toFixed(5);
                                                                    });

                                                                    //token seçerken inputların disabled özelliği aktif hale geliyor apiden değer dönene kadar açılmıyor.
                                                                    document.getElementById("priceRangeMax2").disabled = true;
                                                                    document.getElementById("priceRangeMax1").disabled = true;
                                                                    Swap(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("fromid").textContent, document.getElementById("tokenid").innerText, 1).then(ver => {

                                                                        if (!ver.isAllowed) {
                                                                            var Button = document.createElement("button");
                                                                            Button.className = "btn btn-yotta21 col-12";
                                                                            var btninnertext = "Let yotta21 protocol use " + document.getElementById("fromid").innerText 
                                                                            Button.innerText = btninnertext;
                                                                            Button.setAttribute("id", "btn-approve");
                                                                            Button.setAttribute("name", "btn-approve");
                                                                            document.getElementById("approvediv").appendChild(Button);
                                                                            Button.onclick = function () {
                                                                                sendHash(ver.to, ver.data, ver.gasEstimate, "approve")
                                                                                document.getElementById("btn-approve").disabled = true;
                                                                                //weth balance hesaplama
                                                                                balance(ottaAddress, "OTTA").then((ver) => {
                                                                                    document.getElementById("frombalance").innerText = Number(ver).toFixed(5);
                                                                                })
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (document.getElementById("btn-approve") != null) {
                                                                                var deneme = document.getElementById("approvediv")
                                                                                var deneme2 = document.getElementById("btn-approve");
                                                                                deneme.removeChild(deneme2);
                                                                                CalculatingFRomValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").innerText, "OT11", 1);
                                                                                CalculatingToValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("to").innerText, "OT11", 1);
                                                                            }
                                                                            else {
                                                                                CalculatingFRomValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").innerText, "OT11", 1);
                                                                                CalculatingToValue(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").innerText, "OT11", 1);
                                                                            }
                                                                        }
                                                                    })
                                                                    toggleModal()
                                                                }}>
                                                                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="35px"></img>
                                                                    <a className="container">WETH</a>
                                                                    <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between pt-2">
                                                                        <div className="post-details mb-3 mb-lg-0">
                                                                            <span className="d-block font-small">WETH</span>
                                                                        </div>
                                                                        <div className="post-meta">
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <a className="text-dark mr-3" href="#"></a>
                                                                            <span className="font-small">Bakiye: </span>
                                                                            <span className="justify-content-between font-small" id="WethBalance"></span>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                {/* Token BTC Selected end  */}
                                                            </ListGroup>
                                                        </div>
                                                    </Modal>
                                                    {/* token select modal end */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12 py-1">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">
                                                        <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>
                                                            <input className="form-control" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* to component end */}
                        <div id="approvediv"></div>
                        <button type="button" id="C" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                            onClick={() => {
                                //weth balance hesaplama
                                balance(ottaAddress, "OTTA").then((ver) => {
                                    document.getElementById("frombalance").innerText = Number(ver).toFixed(8);
                                    var from = document.getElementById("fromid").innerText
                                    var fromValue = document.getElementById("priceRangeMax1").value
                                    var fromimage = document.getElementById("imageidfrom").src
                                    var toimage = document.getElementById("imageId").src
                                    var to = document.getElementById("tokenid").textContent;
                                    var toValue = document.getElementById("priceRangeMax2").value
                                    // token Değeri yazdırma
                                    document.getElementById("fromid").value = ottaAddress;
                                    document.getElementById("fromContent").textContent = Number(fromValue).toFixed(8)
                                    document.getElementById("from").textContent = from
                                    document.getElementById("fromImageContent").src = fromimage
                                    document.getElementById("toContent").textContent = Number(toValue).toFixed(8)
                                    if (to == "DAI" || to == "ETH") {
                                        document.getElementById("to").innerHTML = to + "&nbsp;"
                                    }
                                    else {
                                        document.getElementById("to").textContent = to
                                    }
                                    document.getElementById("ToImageContent").src = toimage
                                })

                                //özet sayfası token değeri
                                Swap(document.getElementById("tokenid").value, document.getElementById("fromid").value, document.getElementById("tokenid").textContent, document.getElementById("fromid").innerText, Number(document.getElementById("priceRangeMax2").value).toFixed(18)).then(ver => {
                                    document.getElementById("route").innerText = ""

                                    for (let x in ver.routePath) {
                                        document.getElementById("route").innerText += ver.routePath[x]
                                    }
                                    var min = document.getElementById("toContent").innerText - (document.getElementById("toContent").innerText * slippage)
                                    document.getElementById("minReceived").innerText = min
                                    document.getElementById("slippagePercentage").innerText = (ver.slippagePercentage)
                                    document.getElementById("tokenname").innerText = document.getElementById("tokenid").innerText
                                    document.getElementById("tokenvalue").innerText = Number(1 / ver.price).toFixed(8) + " " + document.getElementById("fromid").innerText
                                })
                                toggleModal2()
                            }}>
                            Devam
                        </button>
                    </div>
                    {/* <!-- Modal Content --> */}
                    <Modal
                        isOpen={isOpen2}
                        onRequestClose={toggleModal2}
                        contentLabel="My dialog"
                        className="mymodal-otta" /*"modal-dialog modal-dialog-centered rounded"*/
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        aria-hidden="true"
                        backdrop="false"
                    >
                        <div id="ttffsum" >
                            <button type="button" className=" pt-0 close ml-auto " >
                                <span aria-hidden="true" id="close" onClick={() => {
                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null
                                }}>×</span>
                            </button>
                            <div className="card-header  text-center pb-0 pt-0">
                                <h2 className="h4 font-medium text-center pt-0"><strong>İşlem Özeti</strong></h2>
                            </div>
                            <div className="card-body text-right pb-0 pt-0 font-smedium ">
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">
                                            <output className="text-left col- font-base">
                                                <img id="fromImageContent" src={yotta21logo} height="30px" />
                                                <a className="font-base" id="from"></a>
                                            </output>
                                            <output className="col-lg-9 text-right font-base" >
                                                <a id="fromContent" className="text-right"></a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white">
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium pt-4 py-2">
                                            <output className="text-left  font-base">
                                                <img id="ToImageContent" src={yotta21logo} height="24px" />
                                                <a id="to" className="font-base">WETH</a>
                                            </output>
                                            <output className="col-lg-10 text-right font-base" >
                                                <a className="text-right" id="toContent"></a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-4  ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded">
                                        <div className=" col-12 col-lg-12 text-left pt-0 py-2 font-small " >
                                            <output className=" col-lg-5 text-left font-small">  Fiyat
                                            </output>
                                            <output className="col-lg-7 text-right font-small" >
                                                <a className="text-right"><a>1 </a><a id="tokenname"> Sdr21Shares</a> = <a id="tokenvalue">55555.5555</a></a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row justify-content-start pt-0 ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white" >
                                        <div className=" col-12 col-lg-12 text-left pt-2 font-small " >
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-route"></i>
                                                &nbsp;
                                                Rota
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="route"></a>
                                            </output>
                                            <output className=" col-lg-7 text-left font-small" >
                                                <i className="fas fa-coins"></i>
                                                &nbsp;
                                                Minimum Alınan
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="minReceived">
                                                </a>
                                            </output>
                                            <output className=" col-lg-7 text-left font-small">
                                                <i className="fas fa-skiing"></i>
                                                &nbsp;
                                                Kayma Toleransı
                                            </output>
                                            <output className="col-lg-5 text-right" >
                                                <a className="text-left" id="slippagePercentage">
                                                </a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn  btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0" id="swap" data-toggle="modal" data-target="#modal-form"
                                onClick={async () => {
                                    Swap(document.getElementById("fromid").value, document.getElementById("tokenid").value, document.getElementById("tokenid").textContent, /*document.getElementById("fromid").innerText*/"OT11", document.getElementById("fromContent").textContent).then(ver => {
                                        //weth balance hesaplama
                                        balance(ottaAddress, "OTTA").then((ver) => {
                                            document.getElementById("frombalance").innerText = ver;
                                        })
                                        sendHash(ver.to, ver.data, ver.gasEstimate, "swap");
                                    })
                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null
                                    //function to add Otta Token to metamask
                                    const tokenAddress = ottaAddress;
                                    const tokenSymbol = 'Sdr21Shares';
                                    const tokenDecimals = 18;
                                    const tokenImage = 'https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/tokenlogo/A4.png';

                                    if (props.provider.connection.url == "metamask") {
                                        await balance(ottaAddress, "OTTA").then(async (ver) => {
                                            if (Number(ver) == 0) {
                                                const wasAdded = await window.ethereum.request({
                                                    method: 'wallet_watchAsset',
                                                    params: {
                                                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                                                        options: {
                                                            address: tokenAddress, // The address that the token is at.
                                                            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                                            decimals: tokenDecimals, // The number of decimals in the token
                                                            image: tokenImage, // A string url of the token logo
                                                        },
                                                    },
                                                });
                                            }
                                        })
                                    }
                                    var x = document.getElementById("snackbartottffbuy");
                                    x.className = "show";
                                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                                }} >
                                Swap
                            </button>
                        </div>
                    </Modal>
                </div>
                <div id="snackbartottffbuy">İşlem cüzdanınıza gönderildi...</div>
            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi */}
            < div id="countdown_container" style={display} >
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div >
        </section >
    );
}

export default Yotta21Buy