import React, { useState, useEffect } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import Notfound from './Components/notfound';
import Faq from './Components/FAQ/Faq';
import Information from './Components/information';
import PrivacyPolicy from './Components/privacyPolicy';
import TermsandConditions from './Components/termsandConditions';
import Usaeagreement from './Components/usageagreement';
import Head from './Components/Head';
import TtfBuy from './Components/TTF/TtfBuy';
import TtfSell from './Components/TTF/TtfSell';
import TtffRedeem from './Components/TTF/TtfRedeem';
import Addliqudity from './Components/Pool/addliqudity';
import Liqudity from './Components/Pool/liqudity'
import Pool from './Components/Pool/pool'
import { initOnboard, initNotify } from './service';
import { ethers } from 'ethers'
import Incraese from './Components/Pool/increase';
import Remove from './Components/Pool/remove'
import TaumExt from './Components/Co/TaumExt';
import TaumEntry from './Components/Co/TaumEntry';
import Yotta21Buy from './Components/Co/Yotta21Buy';
import Yotta21Sell from './Components/Co/Yotta21Sell';
import Yotta21Broker from './Components/Co/Yotta21Broker';

import Dividend_Broker_Entry from './Components/Dividend/Dividend_Broker_Entry';
import Dividend_Broker_Ext from './Components/Dividend/Dividend_Broker_Ext';

import Dividend_Delegator_Entry from './Components/Dividend/Dividend_Delegator_Entry';
import Dividend_Delegator_Ext from './Components/Dividend/Dividend_Delegator_Ext';

import Dividend_Group_Entry from './Components/Dividend/Dividend_Group_Entry';
import Dividend_Group_Ext from './Components/Dividend/Dividend_Group_Ext';

import Dividend_Kordinator_Entry from './Components/Dividend/Dividend_Kordinator_Entry';
import Dividend_Kordinator_Ext from './Components/Dividend/Dividend_Kordinator_Ext';

import Dividend_MeshNFT_Entry from './Components/Dividend/Dividend_MeshNFT_Entry';
import Dividend_MeshNFT_Ext from './Components/Dividend/Dividend_MeshNFT_Ext';

import Dividend_Otta21_Entry from './Components/Dividend/Dividend_Otta21_Entry';
import Dividend_Otta21_Ext from './Components/Dividend/Dividend_Otta21_Ext';

import Dividend_Yotta21_Entry from './Components/Dividend/Dividend_Yotta21_Entry';
import Dividend_Yotta21_Ext from './Components/Dividend/Dividend_Yotta21_Ext';


import Treemap from './Components/index/treemap';
import Vote from './Components/Governance/Vote';
import VoteDetail from './Components/Governance/VoteDetail';
import VoteDetailActive from './Components/Governance/VoteDetailActive'
import Delegator from './Components/Governance/Delegator'
import NewVote from './Components/Governance/NewVote';
import { Color } from '@arction/lcjs';
import BlockNumber from './Components/index/blocknumber';
import MeshNFT from './Components/Governance/MeshNFT';



let provider;

function App() {


  const [address, setAddress] = useState(null)
  const [network, setNetwork] = useState(null)
  const [balance, setBalance] = useState(null)
  const [wallet, setWallet] = useState({})

  const [onboard, setOnboard] = useState(null)
  const [notify, setNotify] = useState(null)


  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      network: setNetwork,
      balance: setBalance,
      wallet: wallet => {
        if (wallet.provider) {
          setWallet(wallet)

          const ethersProvider = new ethers.providers.Web3Provider(
            wallet.provider
          )

          provider = ethersProvider
          //   internalTransferContract = new ethers.Contract(
          //     '0xb8c12850827ded46b9ded8c1b6373da0c4d60370',
          //     internalTransferABI,
          //     getSigner(ethersProvider)
          //   )

          window.localStorage.setItem('selectedWallet', wallet.name)

          document.getElementById("walletId").disabled = false
        } else {
          provider = null
          setWallet({})
        }
      }
    })

    setOnboard(onboard)

    setNotify(initNotify())
  }, [])

  window.localStorage.setItem("WalletAddress", address)
  useEffect(() => {


    const previouslySelectedWallet = window.localStorage.getItem(
      'selectedWallet'
    )

    if (previouslySelectedWallet && onboard) {
      onboard.walletSelect(previouslySelectedWallet)
    }
  }, [onboard])

  // window.addEventListener("load", () => {
  //   if (address != undefined) {
  //     document.getElementById("toggle").disabled = false
  //   }
  //   else document.getElementById("toggle").disabled = true;

  // });


  return onboard && notify ? (

    <section>
  <div class="py-3 announcement-bar shadow-soft " role="alert" id="announcement-bar">
            <span
                class="alert-inner--text anncouncemnet-span">Yotta21 
                <strong
                    className='anncouncemnet-span'>Ethereum.Kovan</strong>
                     Testnette çalışıyor.&nbsp;&nbsp;
                    
                    </span>
                    <span class="font-right"> DAO21 NFT satın Almak için</span>
                   <a  href ="https://rarible.com/token/0x6aedccebdcbc95a19c73eef54d2ef03e6c998c1e:24?tab=details" target="_blank"><img  src="https://storageapi.fleek.co/fab43a74-19db-4f31-989a-20d88999ab9d-bucket/Rarible.svg" height="30px" /></a> 
                </div>      <Head exact address={address} onboard={onboard} notify={notify} />
      <div className="section">
        <HashRouter>
          <Switch>
            {/* <Route exact path="/" component={index_home} /> */}
            <Route exact path="/" render={() => (
              <Treemap address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/index" render={() => (
              <Treemap address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route exact path="/anasayfa" render={() => (
              <Treemap address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            {/* <Route path="/walletconnect" component={Walletconnect} /> */}
            <Route path="/SSS" component={Faq} />
            <Route path="/bilgi" component={Information} />
            <Route path="/gizlilikpolitikası" component={PrivacyPolicy} />
            <Route path="/sartlarvekosullar" component={TermsandConditions} />
            <Route path="/kullanimsozlesmesi" component={Usaeagreement} />

            {/* TTF route */}
            <Route exact path="/Sdr21Buy/:tokenid/:tokenname" component={() => (
              <TtfBuy address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route exact path="/Sdr21Sell/:tokenid/:tokenname" component={() => (
              <TtfSell address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route exact path="/Sdr21Redeem/:tokenid/:tokenname" component={() => (
              <TtffRedeem address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            {/* Liqudity ROUTE */}
            <Route path="/addliqudity" component={() => (
              <Addliqudity address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route exact path="/liqudity" component={() => (
              <Liqudity address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/pool/:nftid" exact component={() => (
              <Pool address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/increase/:nftid" component={() => (
              <Incraese address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/remove/:nftid" component={() => (
              <Remove address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            {/* TAUM ROUTE */}
            <Route path="/LpDaiSdr21Entry" component={() => (
              <TaumEntry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/LpDaiSdr21Ext" component={() => (
              <TaumExt address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            {/* OTTA ROUTE */}
            <Route path="/Sdr21SharesBuy/:tokenid/:tokenname" component={() => (
              <Yotta21Buy address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Sdr21SharesSell/:tokenid/:tokenname" component={() => (
              <Yotta21Sell address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/Sdr21SharesBroker/:tokenid/:tokenname" component={() => (
              <Yotta21Broker address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            {/* dividend route */}
            <Route path="/Dividend_Broker_Entry/" component={() => (
              <Dividend_Broker_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendBrokerExt/" component={() => (
              <Dividend_Broker_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/Dividend_Delegator_Entry/" component={() => (
              <Dividend_Delegator_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendDelegatorExt/" component={() => (
              <Dividend_Delegator_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dividend_Group_Entry/" component={() => (
              <Dividend_Group_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendGroupExt/" component={() => (
              <Dividend_Group_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dividend_Kordinator_Entry/" component={() => (
              <Dividend_Kordinator_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendKordinatorExt/" component={() => (
              <Dividend_Kordinator_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />
            <Route path="/Dividend_Dao21NFT_Entry/" component={() => (
              <Dividend_MeshNFT_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendDao21NFTExt/" component={() => (
              <Dividend_MeshNFT_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dividend_Sdr21Shares_Entry/" component={() => (
              <Dividend_Otta21_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendSdr21SharesExt/" component={() => (
              <Dividend_Otta21_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dividend_Yotta21_Entry/" component={() => (
              <Dividend_Yotta21_Entry address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/DividendYotta21Ext/" component={() => (
              <Dividend_Yotta21_Ext address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />


            {/* Dao route */}
            <Route path="/Vote/" component={() => (
              <Vote address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet}></Vote>
            )} />
            <Route path="/Proporsal/active/:proporsalid" component={() => (
              <VoteDetailActive address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Proporsal/:proporsalIndex/:proporsalid" component={() => (
              <VoteDetail address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/NewVote" component={() => (
              <NewVote address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dao" component={() => (
              <Delegator address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route path="/Dao21NFT" component={() => (
              <MeshNFT address={address} balance={balance} network={network} onboard={onboard} notify={notify} provider={provider} wallet={wallet} />
            )} />

            <Route component={Notfound}></Route>
          </Switch>
        </HashRouter>

      </div>
      <BlockNumber>

      </BlockNumber>
    </section>


  ) : (<div id="spinner" className="spinner-border font-small " disabled></div>)
}


export default App;


