import React, { useEffect } from 'react'
import getSigner from '../../signer'
import contract from './Contract/Contract.json'
import functions from './Contract/Functions.json'
import EthereumPool from './Contract/LpDaiSdr21Pool.json'
import Otta from './Contract/Sdr21Shares.json'
import Timelock from './Contract/Timelock.json'
import TtffPool from './Contract/Sdr21Rebalance.json'
import governorbravodelegate from './Contract/governorbravodelegate.json'
import governorbravodelegator from './Contract/governorbravodelegator.json'
import  { ethereumPoolAddress, governorBravoDelegateAddress, governorBravoDelegatorAddress, ottaAddress, TimeLock, TtffPoolAddress } from '../protocolAdress'



const web3 = require('./web3Provider.js').web3;
const governorAbi = require('./governorAbi.json').abi; // delegate
const governorAddress = governorBravoDelegateAddress;
const governor = new web3.eth.Contract(governorAbi, governorAddress);

const governorxAbi = require('./governorxAbi.json').abi; //delegator
const governorxAddress = governorBravoDelegatorAddress;
const governorx = new web3.eth.Contract(governorAbi, governorxAddress);


var counter = 0;
var calldata
var ContractAddress
var contractAddresses = []
var Paramatercount
var Paramatervalue
var Paramatervalue2
var Paramatervalue3
var Paramatervalue4
var Paramatervalue5
var Paramatervalue6
var Paramatervalue7
var calldataAll = []
var Signature = []
var signatre
var valuetransaction = []

const buttonstyle = {
    backgroundColor: "#39babc",
    height: "30px",
    alignItems: "center",
    color: "white",

}
const display = {
    display: "none"
}

function NewVote(props) {

    //Transaction oluşturmak için kullanılan fonksiyon
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', end => {

            if (_transactiontype == "approvetoken0") {
                var aprove = document.getElementById("approvediv")
                var button0 = document.getElementById("btn-approve-token0")
                button0.disabled = true
                button0.innerText = "Approve başarılı"
                aprove.removeChild(button0)
                console.log(end);

                if (document.getElementById("btn-approve-token1") != null) document.getElementById("btn-approve-token1").className = "btn btn-yotta21 col-12"

            }
            else if (_transactiontype == "approvetoken1") {

                var aprove = document.getElementById("approvediv")
                var button1 = document.getElementById("btn-approve-token1")
                button1.disabled = true
                button1.innerText = "Approve başarılı"
                aprove.removeChild(button1)
                console.log(end);

                if (document.getElementById("btn-approve-token0") != null) document.getElementById("btn-approve-token0").className = "btn btn-yotta21 col-12"
            }
            else {

                console.log(end);
            }

        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            console.log(end);
        })
        emitter.on('txFailed', end => {
            console.log(end);
        })
    }
//It creates the necessary data to perform the delegate operation.
    async function Delegate() {
        var _datadelegate = {
            "owner": props.address,
            "addressToDelegate": props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getDelegationData", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datadelegate)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    //Cüzdan Bağlayınız check
    async function walletControl() {
        if (props.address == null) {
            document.getElementById("votesave").innerText = "Cüzdan Bağlayınız"
            document.getElementById("votesave").disabled = true
        }
        else {
            await Delegate().then((ver) => {
                var currentVotes = Number(ver.currentVotes)
                var proposalThreshold = Number(ver.proposalThreshold)

                if (currentVotes >= proposalThreshold) {
                    document.getElementById("votesave").disabled = false
                    document.getElementById("votesave").innerText = "Teklif Oluştur."
                    document.getElementById("votesave").className = "btn text-center col-lg-3 "
                }
                else {
                    document.getElementById("votesave").disabled = true
                    document.getElementById("votesave").className = "btn text-center col-lg-4"
                    document.getElementById("votesave").innerText = "Teklif oluşturabilmek için" + proposalThreshold + " oy gücünüz olmalıdır..."
                }
            })

        }

    }

    useEffect(async () => {
        if (document.getElementById("div0") == null) {
            counter = 0;
        }
        // exampleFormControlSelect1 seçim öğesinde seçim yapıldığında exampleFormControlSelect2 seçim öğesinin etkilemesi ve doldurulması
        document.getElementById("exampleFormControlSelect1").addEventListener("change", () => {
            var e = document.getElementById("exampleFormControlSelect1");
            var strUser = e.options[e.selectedIndex].text;

            if (strUser == "Kontrat Seçiniz") {
                var x = document.getElementById("exampleFormControlSelect2");
                for (let index = 0; index <= x.length + 2; index++) {
                    x.remove(x.selected)
                }
                x.remove(x.selected)
                var option = document.createElement("option");
                option.text = "Fonksiyon Seçiniz"
                x.add(option)

                if (document.getElementById("title1-1")) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                    document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                    document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                    document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }
                }

                if (document.getElementById("title1-2") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                    document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }                }
                if (document.getElementById("title1-3") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                    document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                }
                if (document.getElementById("title1-4") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                    document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                }
                if (document.getElementById("title1-5") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                    document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                }
                if (document.getElementById("title1-6") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                    document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                }
                if (document.getElementById("title1-7") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                    document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                }

            }
            else {
                var x = document.getElementById("exampleFormControlSelect2");

                for (let index = 0; index <= x.length + 2; index++) {
                    x.remove(x.selected)
                }
                x.remove(x.selected)

                var option = document.createElement("option");
                option.text = "Fonksiyon Seçiniz"
                x.add(option)

                if (document.getElementById("title1-1")) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                    document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                    document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                    document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }
                }

                if (document.getElementById("title1-2") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                    document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }
                }
                if (document.getElementById("title1-3") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                    document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                }
                if (document.getElementById("title1-4") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                    document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                }
                if (document.getElementById("title1-5") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                    document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                }
                if (document.getElementById("title1-6") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                    document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                }
                if (document.getElementById("title1-7") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                    document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                }

                if (strUser == "Governorbravodelegate") {
                    functions.governorbravodelegate.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = governorBravoDelegatorAddress
                }
                else if (strUser == "Timelock") {
                    functions.Timelock.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = TimeLock

                }
                else if (strUser == "Governorbravodelegator") {
                    functions.governorbravodelegator.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = governorBravoDelegatorAddress

                }
                else if (strUser == "Sdr21SharesTreasury") {
                    functions.Sdr21SharesTreasury.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = ottaAddress

                }
                else if (strUser == "LpDaiSdr21Pool") {
                    functions.LpDaiSdr21Pool.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = ethereumPoolAddress

                }

                else if (strUser == "Sdr21Rebalance") {
                    functions.Sdr21Rebalance.map(ver => {
                        var option = document.createElement("option");
                        option.text = ver
                        x.add(option)
                    })
                    ContractAddress = TtffPoolAddress

                }


            }






        })

        // exampleFormControlSelect2 seçim öğesinde seçim yapıldığında veri girişinin oluşması
        document.getElementById("exampleFormControlSelect2").addEventListener("change", () => {
            var e = document.getElementById("exampleFormControlSelect2");
            var strUser = e.options[e.selectedIndex].text;

            if (strUser == "Fonksiyon Seçiniz") {

                if (document.getElementById("title1-1") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                    document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                    document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                    document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }                }
                if (document.getElementById("title1-2") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                    document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }                }
                if (document.getElementById("title1-3") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                    document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                }
                if (document.getElementById("title1-4") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                    document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                }
                if (document.getElementById("title1-5") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                    document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                }
                if (document.getElementById("title1-6") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                    document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                }
                if (document.getElementById("title1-7") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                    document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                }

            }
            else {
                if (document.getElementById("title1-1") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                    document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                    document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                    document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }
                }
                if (document.getElementById("title1-2") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                    document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                    if (document.getElementById("spanTitle") != null) {

                        document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                    }
                }
                if (document.getElementById("title1-3") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                    document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                }
                if (document.getElementById("title1-4") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                    document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                }
                if (document.getElementById("title1-5") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                    document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                }
                if (document.getElementById("title1-6") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                    document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                }
                if (document.getElementById("title1-7") != null) {
                    document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                    document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                }
                var e = document.getElementById("exampleFormControlSelect1");
                var strUserselect1 = e.options[e.selectedIndex].text;

                var e2 = document.getElementById("exampleFormControlSelect2");
                var strUserselect2 = e2.options[e2.selectedIndex].text;

                // governorbravodelegate Kontratı
                if (strUserselect1 == "Governorbravodelegate") {
                    //setVotingDelay Fonksiyonu
                    if (strUserselect2 == "setVotingDelay") {
                        Paramatercount = governorbravodelegate.setVotingDelay.Paramater
                        Paramatervalue = governorbravodelegate.setVotingDelay.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegate.setVotingDelay.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegate.setVotingDelay.Signature
                            signatre = governorbravodelegate.setVotingDelay.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegate.setVotingDelay.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = " Enter:"+ governorbravodelegate.setVotingDelay.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }//setVotingPeriod Fonksiyonu
                    else if (strUserselect2 == "setVotingPeriod") {
                        Paramatercount = governorbravodelegate.setVotingPeriod.Paramater
                        Paramatervalue = governorbravodelegate.setVotingPeriod.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegate.setVotingPeriod.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegate.setVotingPeriod.Signature
                            signatre = governorbravodelegate.setVotingPeriod.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegate.setVotingPeriod.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = governorbravodelegate.setVotingPeriod.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }//setProposalThreshold Fonksiyonu
                    else if (strUserselect2 == "setProposalThreshold") {
                        Paramatercount = governorbravodelegate.setProposalThreshold.Paramater
                        Paramatervalue = governorbravodelegate.setProposalThreshold.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegate.setProposalThreshold.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegate.setProposalThreshold.Signature
                            signatre = governorbravodelegate.setProposalThreshold.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegate.setProposalThreshold.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = governorbravodelegate.setProposalThreshold.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }//initiate Fonksiyonu
                    else if (strUserselect2 == "initiate") {
                        Paramatercount = governorbravodelegate.initiate.Paramater
                        Paramatervalue = governorbravodelegate.initiate.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegate.initiate.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegate.initiate.Signature
                            signatre = governorbravodelegate.initiate.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegate.initiate.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = " Enter:"+ governorbravodelegate.initiate.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    } //setPendingAdmin Fonksiyonu
                    else if (strUserselect2 == "setPendingAdmin") {
                        Paramatercount = governorbravodelegate.setPendingAdmin.Paramater
                        Paramatervalue = governorbravodelegate.setPendingAdmin.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegate.setPendingAdmin.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegate.setPendingAdmin.Signature
                            signatre = governorbravodelegate.setPendingAdmin.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegate.setPendingAdmin.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = governorbravodelegate.setPendingAdmin.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }
                }// TimeLock Kontratı
                else if (strUserselect1 == "Timelock") {
                    //setPendingAdmin Fonksiyonu
                    if (strUserselect2 == "setPendingAdmin") {
                        Paramatercount = Timelock.setPendingAdmin.Paramater
                        Paramatervalue = Timelock.setPendingAdmin.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = Timelock.setPendingAdmin.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + Timelock.setPendingAdmin.Signature
                            signatre = Timelock.setPendingAdmin.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + Timelock.setPendingAdmin.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = Timelock.setPendingAdmin.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }//setDelay Fonksiyonu
                    else if (strUserselect2 == "setDelay") {
                        Paramatercount = Timelock.setDelay.Paramater
                        Paramatervalue = Timelock.setDelay.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = Timelock.setDelay.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + Timelock.setDelay.Signature
                            signatre = Timelock.setDelay.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + Timelock.setDelay.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = Timelock.setDelay.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }

                }// governorbravodelegator Kontratı
                else if (strUserselect1 == "Governorbravodelegator") {
                    //setPendingAdmin Fonksiyonu
                    if (strUserselect2 == "setImplementation") {
                        Paramatercount = governorbravodelegator.setImplementation.Paramater
                        Paramatervalue = governorbravodelegator.setImplementation.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = governorbravodelegator.setImplementation.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + governorbravodelegator.setImplementation.Signature
                            signatre = governorbravodelegator.setImplementation.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + governorbravodelegator.setImplementation.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = governorbravodelegator.setImplementation.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }

                }// Otta Kontratı
                else if (strUserselect1 == "Sdr21SharesTreasury") {
                    //transfer Fonksiyonu
                    if (strUserselect2 == "transfer") {
                        Paramatercount = Otta.transfer.Paramater
                        Paramatervalue = Otta.transfer.Paramater1
                        Paramatervalue2 = Otta.transfer.Paramater2
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = Otta.transfer.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }

                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + Otta.transfer.Signature
                            signatre = Otta.transfer.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + Otta.transfer.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = Otta.transfer.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }

                        if (document.getElementById("title1-2") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-2")
                            span.innerText = Otta.transfer.Paramater2Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title2-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title2-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = Otta.transfer.Paramater2
                            document.getElementById("title1").appendChild(input)

                        }
                    }
                    else if (strUserselect2 == "setDiscountQuantity") {
                        Paramatercount = Otta.setDiscountQuantity.Paramater
                        Paramatervalue = Otta.setDiscountQuantity.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = Otta.setDiscountQuantity.Paramater1
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + Otta.setDiscountQuantity.Signature
                            signatre = Otta.setDiscountQuantity.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + Otta.setDiscountQuantity.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = Otta.setDiscountQuantity.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }


                    }


                }// EthereumPool Kontratı
                else if (strUserselect1 == "LpDaiSdr21Pool") {
                    //setLimit Fonksiyonu
                    if (strUserselect2 == "setLimit") {
                        Paramatercount = EthereumPool.setLimit.Paramater
                        Paramatervalue = EthereumPool.setLimit.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = EthereumPool.setLimit.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + EthereumPool.setLimit.Signature
                            signatre = EthereumPool.setLimit.Signature
                            span.className = "strong "
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + EthereumPool.setLimit.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = EthereumPool.setLimit.Paramater1
                            document.getElementById("title1").appendChild(input)

                        }
                    }
                    //setMinValue Fonksiyonu
                    else if (strUserselect2 == "setMinValue") {
                        Paramatercount = EthereumPool.setMinValue.Paramater
                        Paramatervalue = EthereumPool.setMinValue.Paramater1
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = EthereumPool.setMinValue.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + EthereumPool.setMinValue.Signature
                            signatre = EthereumPool.setMinValue.Signature
                            span.className = "strong"
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + EthereumPool.setMinValue.Signature

                        }

                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = EthereumPool.setMinValue.Paramater1 
                            document.getElementById("title1").appendChild(input)

                        }
                    }
                }// TTFFPool Kontratı
                else if (strUserselect1 == "Sdr21Rebalance") {
                    //rebalancing  Fonksiyonu
                    if (strUserselect2 == "rebalancing") {
                        Paramatercount = TtffPool.rebalancing.Paramater
                        Paramatervalue = TtffPool.rebalancing.Paramater1
                        Paramatervalue2 = TtffPool.rebalancing.Paramater2
                        Paramatervalue3 = TtffPool.rebalancing.Paramater3
                        Paramatervalue4 = TtffPool.rebalancing.Paramater4
                        Paramatervalue5 = TtffPool.rebalancing.Paramater5
                        Paramatervalue6 = TtffPool.rebalancing.Paramater6
                        Paramatervalue7 = TtffPool.rebalancing.Paramater7

                        //1.paramaetre _setToken   
                        if (document.getElementById("title1-1") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-1")
                            span.innerText = TtffPool.rebalancing.Paramater1Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }


                        if (document.getElementById("title1-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title1-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater1 
                            input.value = "0xB5523D0f19352cEf7d23b73c44b4A51Bc7C40298"
                            input.disabled = true
                            document.getElementById("title1").appendChild(input)

                        }

                        //2.paramaetre _exchangeName
                        if (document.getElementById("title1-2") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-2")
                            span.innerText = TtffPool.rebalancing.Paramater2Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title2-input") == null) {
                            var input = document.createElement("select")
                            input.setAttribute("id", "title2-input")
                            input.className = "form-control1 col-lg-12"
                            var option1 = document.createElement("option");

                            option1.text = "UniswapV2ExchangeAdapter"


                            input.add(option1)

                            document.getElementById("title1").appendChild(input)

                        }

                        //3.parametre _sendToken
                        if (document.getElementById("title1-3") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-3")
                            span.innerText = TtffPool.rebalancing.Paramater3Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title3-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title3-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater3 
                            document.getElementById("title1").appendChild(input)

                        }

                        //4.parametre _sendQuantity
                        if (document.getElementById("title1-4") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-4")
                            span.innerText = TtffPool.rebalancing.Paramater4Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title4-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title4-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater4 
                            document.getElementById("title1").appendChild(input)

                        }

                        //5.parametre _receiveToken
                        if (document.getElementById("title1-5") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-5")
                            span.innerText = TtffPool.rebalancing.Paramater5Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title5-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title5-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater5 
                            document.getElementById("title1").appendChild(input)

                        }

                        //6.parametre _minReceiveQuantity
                        if (document.getElementById("title1-6") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-6")
                            span.innerText = TtffPool.rebalancing.Paramater6Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title6-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title6-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater6 
                            input.value = 0
                            input.disabled = true
                            document.getElementById("title1").appendChild(input)

                        }

                        //7.parametre _data
                        if (document.getElementById("title1-7") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-7")
                            span.innerText = TtffPool.rebalancing.Paramater7Name
                            span.className = "strong "
                            document.getElementById("title1").appendChild(span)
                        }
                        if (document.getElementById("title7-input") == null) {
                            var input = document.createElement("input")
                            input.setAttribute("id", "title7-input")
                            input.className = "col-lg-12 border border-black pt-1 rounded"
                            input.type = "text"
                            input.placeholder = TtffPool.rebalancing.Paramater7 
                            input.value = "0x"
                            input.disabled = true
                            document.getElementById("title1").appendChild(input)

                        }
                        // fonksiyonun imzası
                        if (document.getElementById("title1-signature") == null) {
                            var span = document.createElement("span")
                            span.setAttribute("id", "title1-signature")
                            span.innerText = "İmza:" + TtffPool.rebalancing.Signature
                            signatre = TtffPool.rebalancing.Signature
                            span.className = "strong"
                            document.getElementById("functionselect").appendChild(span)
                        }
                        else {
                            document.getElementById("title1-signature").innerText = "İmza:" + TtffPool.rebalancing.Signature

                        }

                    }

                }



                //KAYDET BUTONU OLUŞTURULMASI
                if (document.getElementById("title1-btn") == null) {
                    var btn = document.createElement("button")
                    btn.setAttribute("id", "title1-btn")
                    btn.innerText = "Kaydet"
                    btn.className = "col-lg-12 btn pt-2"
                    // data-toggle="modal" data-target="#modal-form" 
                    var dataToggle = document.createAttribute("data-toggle");
                    dataToggle.value = "modal"
                    btn.setAttribute(dataToggle.name, dataToggle.value)

                    var dataTarget = document.createAttribute("data-target");
                    dataTarget.value = "#modal-form"
                    btn.setAttribute(dataTarget.name, dataTarget.value)



                    document.getElementById("title2").appendChild(btn)

                    // olaylar kaydet butonu işlevi
                    if (document.getElementById("title1-btn") != null) {
                        document.getElementById("title1-btn").addEventListener("click", () => {

                            var select1 = document.getElementById("exampleFormControlSelect1")
                            var select2 = document.getElementById("exampleFormControlSelect2")

                            var strUser1 = select1.options[select1.selectedIndex].text;
                            var strUser2 = select2.options[select2.selectedIndex].text;
                            var divcounter = "div" + counter
                            var div = document.createElement("div")
                            div.setAttribute("id", divcounter)
                            div.className = "col-lg-12 border-bottomblack py-1 text-left"
                            document.getElementById("actionadd").appendChild(div)

                            var a = document.createElement("a")
                            a.className = "text-left"
                            a.innerText = strUser1 + " Kontratı " + strUser2 + " Fonksiyonu"
                            document.getElementById(divcounter).appendChild(a)

                            var i = document.createElement("i")
                            i.className = "text-right fas fa-external-link-alt"
                            document.getElementById(divcounter).appendChild(i)
                            var br = document.createElement("br")
                            document.getElementById(divcounter).appendChild(br)

                            var x = document.getElementById("exampleFormControlSelect2");

                            for (let index = 0; index <= x.length; index++) {
                                x.remove(x.selected)
                            }
                            x.remove(x.selected)

                            var option = document.createElement("option");
                            option.text = "Fonksiyon Seçiniz"
                            x.add(option)

                            document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                            document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                            document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                            document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                            if (document.getElementById("spanTitle") != null) {

                                document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                            }


                            if (document.getElementById("title1-2") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                                document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                            }
                            if (document.getElementById("title1-3") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                                document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                            }
                            if (document.getElementById("title1-4") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                                document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                            }
                            if (document.getElementById("title1-5") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                                document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                            }
                            if (document.getElementById("title1-6") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                                document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                            }
                            if (document.getElementById("title1-7") != null) {
                                document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                                document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                            }
                            counter++
                            calldataAll.push(calldata)
                            Signature.push(signatre)
                            contractAddresses.push(ContractAddress)
                            valuetransaction.push("0")
                        })

                    }


                }

            }
            //input 1  input değişimi 
            if (document.getElementById("title1-input") != null) {
                var value
                const captureValue = function (e) {
                    value = e.target.value


                    if (document.getElementById("spanTitle") == null) {
                        var spanTitle = document.createElement("span")
                        spanTitle.setAttribute("id", "spanTitle")
                        document.getElementById("title1").appendChild(spanTitle)
                    }
                    if (Paramatervalue == "address") {
                        if (value.length == 42) {
                            calldata = web3.eth.abi.encodeParameters([Paramatervalue], [value]);
                            document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                        }

                    }
                    else {
                        if (Paramatervalue == "uint256" || Paramatervalue == "uint") {
                            var e2 = document.getElementById("exampleFormControlSelect2");
                            var strUserselect2 = e2.options[e2.selectedIndex].text;

                            if(strUserselect2=="setVotingDelay"||strUserselect2=="setVotingPeriod"||strUserselect2=="setDelay") {
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue], [value]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }else {
                                var valuewei = web3.utils.toWei(value, "ether")
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue], [valuewei]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }

                        }
                        else {
                            calldata = web3.eth.abi.encodeParameters([Paramatervalue], [value]);
                            document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                        }

                    }
                }


                document.getElementById("title1-input").addEventListener("input", captureValue);
            }

            //input 2  input değişimi 
            if (document.getElementById("title2-input") != null) {

                var value
                const captureValue = function (e) {
                    document.getElementById("spanTitle").innerHTML = " "
                    value = e.target.value
                    var valuewei = web3.utils.toWei(value, "ether")

                    if (document.getElementById("spanTitle") == null) {
                        var spanTitle = document.createElement("span")
                        spanTitle.setAttribute("id", "spanTitle")
                        document.getElementById("title1").appendChild(spanTitle)
                    }

                    if (Paramatervalue == "address") {
                        if (value.length == 42) {
                            if (Paramatervalue2 == "uint256" || Paramatervalue2 == "uint") {
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title1-input").value, valuewei]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }
                            else {
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title1-input").value, value]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }
                        }
                        else {
                            if (Paramatervalue == "uint256" || Paramatervalue == "uint") {
                                if (Paramatervalue2 == "uint256" || Paramatervalue2 == "uint") {
                                    var paramater1wei = web3.utils.toWei(document.getElementById("title1-input").value, "ether")
                                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [paramater1wei, valuewei]);
                                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                                }
                                else {
                                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [paramater1wei, value]);
                                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                                }
                            }
                            else {
                                if (Paramatervalue2 == "uint256" || Paramatervalue2 == "uint") {
                                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title1-input").value, valuewei]);
                                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                                }
                                else {
                                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title1-input").value, value]);
                                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                                }

                            }
                        }



                    }



                    if (Paramatervalue2 == "address") {

                        if (Paramatercount == 2) {
                            if (value.length == 42) {
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title2-input").value, valuewei]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }
                        }
                        else {
                            if (value.length == 42) {
                                calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2], [document.getElementById("title1-input").value, valuewei]);
                                document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                            }
                        }

                    }

                }


                document.getElementById("title2-input").addEventListener("input", captureValue);
            }

            //input 3 için input değişimi 
            if (document.getElementById("title3-input") != null) {

                var value
                const captureValue = function (e) {
                    if (document.getElementById("spanTitle") != null) {
                        document.getElementById("spanTitle").innerHTML = " "

                    }
                    value = e.target.value

                    if (document.getElementById("spanTitle") == null) {
                        var spanTitle = document.createElement("span")
                        spanTitle.setAttribute("id", "spanTitle")
                        document.getElementById("title1").appendChild(spanTitle)
                    }

                    if (document.getElementById("title4-input").value != "" && document.getElementById("title5-input").value != "")
                        var input4wei = web3.utils.toWei(document.getElementById("title4-input").value, "ether")
                    var input6wei = web3.utils.toWei(document.getElementById("title6-input").value, "ether")
                    var e = document.getElementById("title2-input");
                    var strUser = e.options[e.selectedIndex].text;
                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2, Paramatervalue3, Paramatervalue4, Paramatervalue5, Paramatervalue6, Paramatervalue7], [document.getElementById("title1-input").value, strUser, value, input4wei, document.getElementById("title5-input").value, input6wei, document.getElementById("title7-input").value]);
                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                }


                document.getElementById("title3-input").addEventListener("input", captureValue);
            }
            //input 4 için input değişimi 
            if (document.getElementById("title4-input") != null) {

                var value
                const captureValue = function (e) {
                    if (document.getElementById("spanTitle") != null) {
                        document.getElementById("spanTitle").innerHTML = " "

                    }
                    value = e.target.value

                    if (document.getElementById("spanTitle") == null) {
                        var spanTitle = document.createElement("span")
                        spanTitle.setAttribute("id", "spanTitle")
                        document.getElementById("title1").appendChild(spanTitle)
                    }

                    if (document.getElementById("title3-input").value != "" && document.getElementById("title5-input").value != "")
                        var input4wei = web3.utils.toWei(value, "ether")
                    var input6wei = web3.utils.toWei(document.getElementById("title6-input").value, "ether")
                    var e = document.getElementById("title2-input");
                    var strUser = e.options[e.selectedIndex].text;
                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2, Paramatervalue3, Paramatervalue4, Paramatervalue5, Paramatervalue6, Paramatervalue7], [document.getElementById("title1-input").value, strUser, document.getElementById("title3-input").value, input4wei, document.getElementById("title5-input").value, input6wei, document.getElementById("title7-input").value]);
                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                }


                document.getElementById("title4-input").addEventListener("input", captureValue);
            }
            //input 5 için input değişimi 
            if (document.getElementById("title5-input") != null) {

                var value
                const captureValue = function (e) {
                    if (document.getElementById("spanTitle") != null) {
                        document.getElementById("spanTitle").innerHTML = " "

                    }
                    value = e.target.value

                    if (document.getElementById("spanTitle") == null) {
                        var spanTitle = document.createElement("span")
                        spanTitle.setAttribute("id", "spanTitle")
                        document.getElementById("title1").appendChild(spanTitle)
                    }

                    if (document.getElementById("title4-input").value != "" && document.getElementById("title5-input").value != "")
                        var input4wei = web3.utils.toWei(document.getElementById("title4-input").value, "ether")
                    var input6wei = web3.utils.toWei(document.getElementById("title6-input").value, "ether")
                    var e = document.getElementById("title2-input");
                    var strUser = e.options[e.selectedIndex].text;
                    calldata = web3.eth.abi.encodeParameters([Paramatervalue, Paramatervalue2, Paramatervalue3, Paramatervalue4, Paramatervalue5, Paramatervalue6, Paramatervalue7], [document.getElementById("title1-input").value, strUser, document.getElementById("title3-input").value, input4wei, value, input6wei, document.getElementById("title7-input").value]);
                    document.getElementById("spanTitle").innerHTML = "Data: " + calldata
                }


                document.getElementById("title5-input").addEventListener("input", captureValue);
            }

        })

        walletControl()
    })

    return (
        <section>
            <div className="   p-2 mb-2 pt-0 " >
                <div className="row justify-content-center" >
                    {/* From component start  */}
                    <div className="col-12 col-lg-12 text-center ">
                        <br />
                        <h4> <strong>Yeni Teklif</strong> </h4>
                    </div>
                </div>
            </div>
            {/* yeni Teklif komponenti başlangıcı */}
            <div className="row justify-content-center mb-1 pt-4 py-0 " >
                {/* Component to Card start */}
                <div className="col-12 col-lg-5 mb-4 mb-lg-0 pt-0 border border-black rounded" id="pool2" >
                    <div className="row justify-content-left" >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-left border-bottomblack  ">
                            <br />
                            <h5> <strong>Teklif Açıklaması</strong> </h5>
                        </div>
                    </div>
                    <div className="row justify-content-left  " >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-left   ">
                            <br />
                            <span><strong>Başlık</strong></span>
                        </div>
                    </div>
                    <div className="row justify-content-left  " >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-left   ">
                            <input className='col-lg-12 border border-black rounded ' type='text' id="title" placeholder='Başlık'></input>
                        </div>
                    </div>
                    <div className="row justify-content-left  " >
                        <div className="col-12 col-lg-12 text-left">
                            <br />
                            <span><strong>Tanımlama</strong></span>
                        </div>
                    </div>
                    <div className="row justify-content-left border-bottomblack " >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-left   ">
                            <textarea className='col-lg-12 rounded' cols="50" rows="5" placeholder='Tanımlama' id="describing"></textarea>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-1'></div>
                {/* sağ taraf kart start */}
                <div className="col-12 col-lg-5 pt-0 border border-black rounded " >
                    <div className="row justify-content-left border-bottomblack " >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-left   ">
                            <br />
                            <h5> <strong>Olaylar</strong> </h5>
                        </div>
                    </div>
                    <div id="actionadd" className='text-center pt-1'>
                    </div>
                    <div className="row justify-content-center" >
                        {/* From component start  */}
                        <div className="col-12 col-lg-12 text-center       ">
                            <br />
                            <div className='btn col-12 col-lg-5 text-center py-1' id="modal-from" data-backdrop="static" data-toggle="modal" data-target="#modal-form" style={buttonstyle} onClick={() => {
                                var x = document.getElementById("exampleFormControlSelect1");
                                var contracts = JSON.stringify(contract)
                                for (let index = 0; index <= x.length; index++) {
                                    x.remove(x.selected)
                                }
                                x.remove(x.selected)
                                var option = document.createElement("option");
                                option.text = "Kontrat Seçiniz"
                                x.add(option)

                                contract.map(row => {
                                    var option = document.createElement("option");
                                    if (x.selected == null) {

                                        option.text = row
                                        x.add(option);
                                    }
                                })

                                var e = document.getElementById("exampleFormControlSelect1");
                                var strUser = e.options[e.selectedIndex].text;

                                if (strUser == "Kontrat Seçiniz") {
                                    var x = document.getElementById("exampleFormControlSelect2")
                                    for (let index = 0; index <= x.length; index++) {
                                        x.remove(x.selected)
                                    }
                                    x.remove(x.selected)
                                    var option = document.createElement("option");
                                    option.text = "Fonksiyon Seçiniz"
                                    x.add(option)

                                    if (document.getElementById("title1-1") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-1"))

                                    }

                                    if (document.getElementById("title1-input") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-input"))

                                    }
                                    if (document.getElementById("title1-btn") != null) {
                                        document.getElementById("title2").removeChild(document.getElementById("title1-btn"))

                                    }

                                    if (document.getElementById("title1-signature") != null) {
                                        document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))

                                    }
                                    if (document.getElementById("title1-2") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                                        document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                                    }
                                    if (document.getElementById("title1-3") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                                        document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                                    }
                                    if (document.getElementById("title1-4") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                                        document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                                    }
                                    if (document.getElementById("title1-5") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                                        document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                                    }
                                    if (document.getElementById("title1-6") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                                        document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                                    }
                                    if (document.getElementById("title1-7") != null) {
                                        document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                                        document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                                    }
                                }
                                if (counter > 9) {
                                    document.getElementById("modal-from").disabled = true
                                }
                                else {
                                    document.getElementById("modal-from").disabled = false

                                }

                            }}>
                                <span className='text-left'>Olay Seçiniz</span>&nbsp;
                                <i className='fas fa-plus text-right'></i>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Modal Content --> */}
                    <div className="modal fade" id="modal-form" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="false">
                        <div className="modal-dialog modal-dialog-centered " role="document">
                            <div className="modal-content ">
                                <div className="modal-body p-0 ">
                                    <div className="card" id="ttffsum">
                                        <button type="button" className=" pt-0 close ml-auto " data-dismiss="modal" aria-label="Close" onClick={() => {
                                            var x = document.getElementById("exampleFormControlSelect1");
                                            for (let index = 0; index <= x.length; index++) {
                                                x.remove(x.selected)
                                            }
                                            x.remove(x.selected)
                                            var option = document.createElement("option");
                                            option.text = "Select Contract"

                                            var y = document.getElementById("exampleFormControlSelect2");
                                            for (let index = 0; index <= y.length; index++) {
                                                y.remove(y.selected)
                                            }
                                            y.remove(y.selected)
                                            x.remove(x.selected)
                                            x.add(option)
                                            y.add(option)
                                           
                                            if (document.getElementById("title1-1") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-1"))
                                                document.getElementById("title1").removeChild(document.getElementById("title1-input"))
                                                document.getElementById("title2").removeChild(document.getElementById("title1-btn"))
                                                document.getElementById("functionselect").removeChild(document.getElementById("title1-signature"))
                                                if (document.getElementById("spanTitle") != null) {

                                                    document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                                                }                                            }
                                            if (document.getElementById("title1-2") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-2"))
                                                document.getElementById("title1").removeChild(document.getElementById("title2-input"))
                                                if (document.getElementById("spanTitle") != null) {

                                                    document.getElementById("title1").removeChild(document.getElementById("spanTitle"))
                                                }                                            }
                                            if (document.getElementById("title1-3") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-3"))
                                                document.getElementById("title1").removeChild(document.getElementById("title3-input"))
                                            }
                                            if (document.getElementById("title1-4") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-4"))
                                                document.getElementById("title1").removeChild(document.getElementById("title4-input"))
                                            }
                                            if (document.getElementById("title1-5") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-5"))
                                                document.getElementById("title1").removeChild(document.getElementById("title5-input"))
                                            }
                                            if (document.getElementById("title1-6") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-6"))
                                                document.getElementById("title1").removeChild(document.getElementById("title6-input"))
                                            }
                                            if (document.getElementById("title1-7") != null) {
                                                document.getElementById("title1").removeChild(document.getElementById("title1-7"))
                                                document.getElementById("title1").removeChild(document.getElementById("title7-input"))
                                            }

                                        }}>
                                            <span aria-hidden="true" id="close">×</span>
                                        </button>
                                        <div className="card-header  text-center pb-0 pt-0  border-bottomblack">
                                            <h2 className="h4 font-medium text-left pt-0"><strong>Olay Seçiniz</strong> </h2>
                                        </div>
                                        <div className='col-12 col-lg-12'>
                                            <p><strong>Kontratlar</strong></p>
                                            <select className="form-control1 col-lg-12" id="exampleFormControlSelect1">
                                                <option>Kontrat Seçiniz</option>
                                            </select>
                                            <br></br>
                                        </div>
                                        <div className='col-12 col-lg-12 border-bottomblack' id="functionselect">
                                            <p><strong>Fonksiyonlar</strong></p>
                                            <select className="form-control1 col-lg-12" id="exampleFormControlSelect2">
                                                <option>Fonksiyon Seçiniz</option>
                                            </select>
                                        </div>
                                        <div className='col-12 col-lg-12 py-2' id="title1"></div>
                                        <div className='col-12 col-lg-12' id="title2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal Content end */}
                </div>
                <div id="voteasd"></div>
                <div className='col-12 col-lg-12 text-center pt-6'>
                    <button className='btn col-lg-4 text-center' id="votesave" onClick={() => {
                        var fullDescribing = document.getElementById("title").value + "### " + document.getElementById("describing").value

                        if (fullDescribing == "### " && valuetransaction.length == 0) {
                            // document.getElementById("votesave").disabled = true
                        }
                        else {
                            const proposeData = governor.methods.propose(
                                contractAddresses,
                                valuetransaction,
                                Signature,
                                calldataAll,
                                fullDescribing
                            ).encodeABI();
                            sendHash(governorBravoDelegatorAddress, proposeData, 800000)
                        }
                        var x = document.getElementById("snackbartottffbuy");
                        x.className = "show";
                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                    }}>Save</button>
                </div>
            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi */}
            < div id="countdown_container" style={display} >
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div >
            <div id="snackbartottffbuy">Transaction sent to your wallet.</div>
        </section>
    )
}

export default NewVote