import React, { useState, useEffect } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

// e to number Convert
function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

const clear = { clear: "both" }

Modal.setAppElement("#index");

var counter
var dividendTime
var dividendDay
var lockDay
var interval
var totalLockedOtta
var usersDividend
var totalEthDividend
var usersLockedAmount
var statuses = false



const divstye3 = {
    backgroundColor: "transparent"

}



function RewardsEntry(props) {

    const { tokenid } = useParams();
    const { tokenname } = useParams();

    //transaction data 
    async function sendHash(_to, _data, _gaslimit, _transactiontype) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })
        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', end => {
            if (_transactiontype == "approve") {
                console.log(end);
                document.getElementById("btn-approve").disabled = true;
            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;
                }
            }
        })
        emitter.on('txConfirmed', end => {
            if (_transactiontype == "approve") {
                document.getElementById("btn-approve").disabled = true;
                document.getElementById("btn-approve").innerText = "işleminiz Onaylandı";
                console.log(end);
            }
            else {
                console.log(end);
                if (document.getElementById("btn-approve") != null) {
                    document.getElementById("btn-approve").disabled = false;
                }
            }
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', end => {
            if (document.getElementById("btn-approve") != null) {
                document.getElementById("btn-approve").disabled = false;
            }
            console.log(end);
        })
        emitter.on('txFailed', end => {
            console.log(end);
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        if (document.getElementById("btn-approve") != null) {
            document.getElementById("btn-approve").disabled = false;
        }
    }

    //function that dividend get time
    async function getTime() {
        var _data = {
            "owner": props.address,
        }

        let result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/getDividendUiData", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    // this function,
    /*
        -"isLockingEpoch",
        -"getLockedAmount"
                            */
    //returns values.
    async function isLockingEpochs() {
        var _data = {
            "owner": props.address,
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/isLockingEpochAndGetAmount", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    //This function creates data for the user to withdraw the dividend for the dividend locked to the dividend.
    async function getDividend() {
        var _data = {
            owner: props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/getDividend ", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    //This function creates data for the user to withdraw the dividend and the dividend locked to the dividend.
    async function getDividendAndOttaToken() {
        var _data = {
            owner: props.address
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/dividend/getDividendAndOttaToken ", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)

            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    //This function compares the value that the user writes in the textbox with the balance and makes a transaction.
    async function walletControl() {
        document.getElementById("modal-from").disabled = true
        if (props.address == null) {
            document.getElementById("modal-from").innerText = " Cüzdan Bağlayınız."
        }
        else {
            await isLockingEpochs().then((ver) => {
                if (!ver.isLockingEpoch) {
                    if (Number(document.getElementById("dividend").innerText) == 0) {
                        document.getElementById("modal-from").disabled = true;
                        document.getElementById("modal-from").innerText = "Kilitlenmiş Sdr21Shares'niz bulunmamaktadır."
                    }
                    else {
                        document.getElementById("modal-from").disabled = false;
                        document.getElementById("modal-from").innerText = "Onay"
                    }
                }
                else {
                    document.getElementById("modal-from").disabled = true;
                    document.getElementById("modal-from").innerText = "Çıkış dönemi değil"
                }
            })
        }
    }

    useEffect(async () => {

        document.getElementById("modal-from").disabled = true;

        await getTime().then((ver) => {
            counter = ver.dividendDayCounter
            if (ver.dividendTime == 0) {
                dividendTime = 1642512256
            }
            else dividendTime = ver.dividendTime

            dividendDay = ver.dividendDay
            lockDay = ver.lockDay;
            interval = ver.interval
            totalLockedOtta = ver.totalLockedOtta
            usersDividend = ver.usersDividend
            totalEthDividend = ver.totalEthDividend
            usersLockedAmount = ver.usersLockedAmount

        })

        if (props.address == null) {
            document.getElementById("totallockotta").innerText = 0
            document.getElementById("dividend").innerText = 0
            document.getElementById("dividendOTTA").innerText = 0
            document.getElementById("totaleth").innerText = 0
        }
        else {
            if (Number(totalLockedOtta) == 0) {
                document.getElementById("totallockotta").innerText = Number(totalLockedOtta)
            }
            else {
                if (Number(totalLockedOtta) > 0 && Number(totalLockedOtta) < 1) {
                    document.getElementById("totallockotta").innerText = Number(totalLockedOtta).toFixed(8)
                }
                else {
                    document.getElementById("totallockotta").innerText = Number(totalLockedOtta).toPrecision(8)
                }
            }

            if (Number(usersDividend) == 0) {
                document.getElementById("dividend").innerText = Number(usersDividend)
            }
            else {
                if (Number(usersDividend) > 0 && Number(usersDividend) < 1) {
                    document.getElementById("dividend").innerText = Number(usersDividend).toFixed(12)
                }
                else {
                    document.getElementById("dividend").innerText = Number((usersDividend)).toPrecision(8)
                }
            }
            if (Number(usersLockedAmount) == 0) {
                document.getElementById("dividendOTTA").innerText = Number(usersLockedAmount)
            }
            else {
                if ((usersLockedAmount) > 0 && (usersLockedAmount) < 1) {
                    if (((usersLockedAmount)) == 0) {
                        document.getElementById("dividendOTTA").innerText = "<" + toFixed(0.00000001)
                    }
                    else document.getElementById("dividendOTTA").innerText = Number(toFixed(usersLockedAmount)).toFixed(8)
                }
                else {
                    document.getElementById("dividendOTTA").innerText = Number(toFixed(usersLockedAmount)).toFixed(8)
                }
            }
            if (Number(totalEthDividend) == 0) {
                document.getElementById("totaleth").innerText = totalEthDividend
            }
            else {
                document.getElementById("totaleth").innerText = Number(totalEthDividend).toFixed(8)
            }
        }

        if (counter >= 0 && counter < dividendDay) {
            counter = dividendDay
            document.getElementById("massage").innerText = "Çekme döneminin bitimine kalan süre"
        }
        else {
            counter = (Number(dividendDay) + Number(lockDay))
            document.getElementById("massage").innerText = "Çekme dönemine  kalan süre"
        }

        var onedaytominute = interval * 1000
        var countDownDate = (counter * onedaytominute) + (dividendTime * 1000)
        var dayText = "Gün";
        var hourText = "Saat";
        var minuteText = "DAkika";

        if (countDownDate) { //tarih var ise
            var x = setInterval(function () { //sayacı belirli aralıklarla yenile
                var now = new Date().getTime(); //şimdiki zamanı al
                var distance = Number(countDownDate) - Number(now); //geri sayılacak tarih ile şimdiki tarih arasındaki zaman farkını al
                if (distance < 0) { //zaman farkı yok ise belirtilen zamanı geçti
                    clearInterval(x); //sayacı sil
                    document.getElementById("countdown_timer").innerHTML = "Geçiş döneminde..."
                } else { 
                    //zaman farkı var ise
                    //aradaki zaman farkını gün,saat,dakika,saniye olarak böl
                    if (distance >= 0) {
                        var days = Math.floor(Number(distance) / (1000 * 3600 * 24)),
                            hours = Math.floor((Number(distance) % (1000 * 3600 * 24)) / (1000 * 3600)),
                            minutes = Math.floor((Number(distance) % (1000 * 3600)) / (1000 * 60)),
                            days = (days ? '<div id="days"><div>' + days + '</div><div>' + dayText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + dayText + '</div></div>'), //gun varsa gun degerini yaz
                            hours = (hours ? '<div id="hours"><div>' + hours + '</div><div>' + hourText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + hourText + '</div></div>'), //saat varsa saat degerini yaz
                            minutes = (minutes ? '<div id="minutes"><div>' + minutes + '</div><div>' + minuteText + '</div></div>' : '<div><div>' + "0" + '</div><div>' + minuteText + '</div></div>'); //dakika varsa dakika degerini yaz
                        document.getElementById("countdown_timer").innerHTML = days + hours + minutes; //yazdır
                    }
                }
            }, 5000); //5 saniyede bir sayaç güncellenecek
        }
        walletControl()

    })
    return (
        <section id="root pt-5">
             <div className=" col-12 col-lg-12  row justify-content-center  P-0  pt-4 " >
                            <div className="row justify-content-center "  >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 ">
                                    <div >

                                        <div className="row justify-content-center">

                                        <div className=" col-12 ml-12 pb-2 btn-group" >
                                <Button className="btn btn-pill  " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill  btn-secondary ";
                                        var url = "/#/Dividend_Sdr21Shares_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> Sdr21Shares</Button>

                                    <Button className="btn btn-pill  " id="btn_delegator" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "";

                                        // document.getElementById("btn_delegator").className = "btn btn-pill active btn-secondary";
                                        // document.getElementById("btn_koordinator").className = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").className = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_group").className = "btn btn-pill btn-secondary ";
                                        var url = "/#/Dividend_Delegator_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i> Delegator</Button>

                                    <Button className="btn btn-pill " id="btn_koordinator" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill active  btn-secondary";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_group").classList = "btn btn-pill btn-secondary ";
                                        var url = "/#/Dividend_Kordinator_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i>Coordinator </Button>

                                    <Button className="btn btn-pill active " id="btn_broker" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "";
                                        // document.getElementById("group").style.display = "none";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill active  btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill btn-secondary";
                                        var url = "/#/Dividend_Broker_Entry/"
                                        window.location.href = url

                                    }}><i className="fas fa-plus " style={divstye3} ></i>Broker </Button>

                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Group_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i>  Groups</Button>


                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Dao21NFT_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> DAO21NFT</Button>

                                    <Button className="btn btn-pill " id="btn_group" onClick={() => {
                                        // document.getElementById("koordinator").style.display = "none";
                                        // document.getElementById("broker").style.display = "none";
                                        // document.getElementById("group").style.display = "";
                                        // document.getElementById("delegator").style.display = "none";

                                        // document.getElementById("btn_delegator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_koordinator").classList = "btn btn-pill btn-secondary ";
                                        // document.getElementById("btn_broker").classList = "btn btn-pill btn-secondary";
                                        // document.getElementById("btn_group").classList = "btn btn-pill active btn-secondary ";
                                        var url = "/#/Dividend_Yotta21_Entry/"
                                        window.location.href = url

                                    }} ><i className="fas fa-plus " style={divstye3} ></i> Yotta21Hub</Button>

                                </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
            <div className="row mb-5 justify-content-center pt-5 " >
                <div className=" justify-content-center col-12 col-md-12 col-lg-5  mb-lg-0 pt-0">
                    <div className=" border-light p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row justify-content-center" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 text-center">
                                    <br />
                                    <h4><strong>Broker Çekme Hakkı</strong> </h4>
                                </div>
                            </div>
                        </div>
                        <div className="   mb-2 pt-0 " style={divstye3}>
                            <div className="container justify-content-center "  >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12   p-2 mb-2 pt-0 py-0 " >
                                    <div className="text-center mb-0 font-medium   ">
                                        <span className="ml-1" id="massage"></span>
                                    </div>
                                    <div id="countdown_container">
                                        <div id="countdown_timer"></div>
                                        <div style={clear}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12  btn-group ">
                                    <button className="col-12 col-lg-6 btn  div-white" id="buy" onClick={() => {
                                        var url = "/#/Dividend_Broker_Entry/"
                                        window.location.href = url
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn"
                                    }}>Giriş</button>
                                    <button className="col-11 col-lg-6 btn div-white active " id="sell" onClick={() => {
                                        var url = "/#/DividendBrokerExt/"
                                        window.location.href = url
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn  active"
                                    }}>Çıkış</button>
                                </div>
                            </div>
                        </div>
                        {/* rEWARD sÜRE DİV */}
                        {/* dsasa */}
                        <div className="card-body shadow-soft border border-light rounded p-2 mb-2 pt-0 div-white " >
                            <div className="text-center mb-0 h5 border-bottom-black" > <span>Kar payı haklarınız</span></div>
                            <div className="row" >
                                {/* kar payı listelendiği komponent başlangıcı  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light py-2 ">
                                        <div className="row">
                                            {/* eth kazancı */}
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="App" >
                                                    <button type="text" value="ETH" className=" border border-light rounded py-2 col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span id="tokenid" className=" font-small" value="Jeton Seçiniz" >ETH </span>&nbsp;&nbsp;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row justify-content-end">
                                                        <div className="text-right col-12  py-3 pb-4 " id="text-right" style={divstye3}>
                                                            <output className="text-right"><a id="dividend" >0</a></output>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* kar payı listelendiği komponent bitişi  */}

                                {/* kar payı listelendiği komponent başlangıcı  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light py-2 ">
                                        <div className="row">
                                            {/* kilitlenen otta */}
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="App" >
                                                    <button type="text" value="ETH" className=" border border-light rounded py-2 col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}> &nbsp;&nbsp;
                                                        <img id="imageId" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span id="tokenid" className=" font-small" value="Jeton Seçiniz" >Sdr21Shares </span>&nbsp;&nbsp;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row justify-content-end">
                                                        <div className="text-right col-12   py-4 pb-4 " id="text-right" style={divstye3}>
                                                            <output className="text-right"><a id="dividendOTTA" >0</a></output>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* kar payı listelendiği komponent bitişi  */}

                                {/* Otta ve kar payını beraber veya sadece kar payını çekme işleminin seçiminin yapıldığı komponent başlangıcı */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light py-2 ">
                                        <div className="row">
                                            {/* Otta ile beraber çek butonu texti */}
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="" >
                                                    <output className="iAMsrY css-r99fas">Sdr21Shares ile beraber çek</output>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row justify-content-end">
                                                        <span class="gYNIOi" id="on" onClick={() => {
                                                            document.getElementById("on").className = "grcdTe"
                                                            document.getElementById("close").className = "cZAGCn"
                                                            statuses = true
                                                        }}>Açık</span>
                                                        <span class="gWwunJ" id="close" onClick={() => {
                                                            document.getElementById("on").className = "gYNIOi"
                                                            document.getElementById("close").className = "gWwunJ"
                                                            statuses = false
                                                        }}>Kapalı</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* Otta ve kar payını beraber veya sadece kar payını çekme işleminin seçiminin yapıldığı komponent bitiş */}
                            </div>
                        </div>
                        {/* From componet end */}

                        {/* to component start */}
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 py-0 div-white" >
                            <div className="text-center mb-0 h5 border-bottom-black" > <span>Toplam Miktar</span></div>
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light py-1 ">
                                        {/* tOPLAM KİLİTLENEN OTTA */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0 " >
                                                <div className="App" >
                                                    <button type="text" value="WETH" className="border border-light rounded div-white py-2 col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Jeton Seçiniz" >Sdr21Shares  </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-light py-1 ">
                                        {/* tOPLAM BİRİKEN ETH */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0 " >
                                                <div className="App" >
                                                    <button type="text" value="WETH" className="border border-light rounded div-white py-2 col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Jeton Seçiniz" >ETH  </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5 ">
                                    <div className="row " >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row">
                                                        <div className="text-right col-12  pb-5 py-3 " id="text-right" style={divstye3}>
                                                            <output id="priceRangeMax2" className='font-base' type="number" style={divstye3} ><a id="totallockotta">0</a> </output>
                                                            <br></br>
                                                            <output id="priceRangeMax3" className='pt-4 py-2 font-base' type="number" style={divstye3} ><a id="totaleth">0</a></output>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                        {/* to component end */}
                        <div id="approvediv"></div>
                        <button type="button" id="modal-from" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0" data-toggle="modal" data-target="#modal-form"
                            onClick={() => {
                                if (statuses) {
                                    getDividendAndOttaToken().then((ver) => {
                                        sendHash(ver.to, ver.methodAbi, ver.gasEstimate, "getDividendAndOttaToken")
                                    })
                                }
                                else {
                                    getDividend().then((ver) => {
                                        sendHash(ver.to, ver.methodAbi, ver.gasEstimate, "getDividend")
                                    })
                                }
                                var x = document.getElementById("snackbartorewardext");
                                x.className = "show";
                                setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                            }} >
                            Onay
                        </button>
                    </div>
                </div>
            </div>
            <div id="snackbartorewardext">İşlem cüzdanınıza gönderildi.</div>
        </section>
    );

}

export default RewardsEntry