import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import { Button, Table } from 'reactstrap';
import { ListGroup, ListGroupItem, Popover, PopoverBody, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import axios from 'axios';


var range;
var imageurl
var count = 0;
var maxPrice
var minPrice

const display = {
    display: 'none ',

}
const divstye2 = {
    padding: '50px',

}

const divstye3 = {
    backgroundColor: "transparent"

}
const divstye4 = {
    backgroundImage: "https://storageapi.fleek.co/esongur-team-bucket/image/yotta21.png",

}
const divstye5 = {
    display: "grid",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px 5px 10px 5px",


}
const divstye7 = {
    backgroundColor: "#39babc",



}
const divstylecontent = {
    justifyContent: "flex-end",
    alignıtems: "baseline"
}

var divstyledot = {
    height: "10px",
    width: "10px",
    backgroundColor: "#007d35",
    borderRadius: "50%",
    display: "inline-block"
}


const divstyledotclosed = {
    height: "10px",
    width: "10px",
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block"
}


const divstyledotoutpfrange = {
    height: "10px",
    width: "10px",
    backgroundColor: "yellow",
    borderRadius: "50%",
    display: "inline-block"
}



function Liqudity(props) {

    let history = useHistory();


    function toFixed(x) {
        if (Math.abs(x) < 1.0) {
           var e = parseInt(x.toString().split('e-')[1]);
           if (e) {
              x *= Math.pow(10, e - 1);
              x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
           }
        } else {
           var e = parseInt(x.toString().split('+')[1]);
           if (e > 20) {
              e -= 20;
              x /= Math.pow(10, e);
              x += (new Array(e + 1)).join('0');
           }
        }
        return x;
     }


    var response;
    var count = 0;

    useEffect(async () => {
        var _datanft = {
            "recipient": props.address
        }
        if (props.address != null || props.address != undefined) {
            response = await axios.post(process.env.REACT_APP_NFT_WALLET_ADRESS, _datanft);

            count = response.data.nftCount;
            document.getElementById("position").innerText = "(" + count + ")";
            if (count == 0) {
                if (document.getElementById("notposition") == null) {
                    document.getElementById("spinner").className = "";
                    const span = document.createElement("span")
                    span.setAttribute("id", "notposition")
                    span.innerText = "Pozisyonunuz bulunmamaktadır..."

                    document.getElementById("notwallet").appendChild(span)
                }
            }
            else {

                if (document.getElementById("token0") == null) {
                    response.data.nftData.forEach(element => {

                        if (element.minPrice == 0.0000000000000000000000000000000000000029542784185828856 || element.maxPrice == 338492131855223780000000000000000000000) {
                            maxPrice = "∞"
                            minPrice = "0"
                        }
                        else {
                            maxPrice = (1 / Number(toFixed(element.minPrice))).toFixed(6)
                            minPrice = (1 / Number(toFixed(element.maxPrice))).toFixed(6)
                        }


                            if (Number(element.minPrice) <= Number(element.currentPrice)   && Number(element.currentPrice) <= Number(element.maxPrice)) {
                                range = "Aralıkta";
                                imageurl = "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png"
                            }
                            else {
                                range = "Aralık Dışı"
                                imageurl = "https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png"
                            }
                        
                        document.getElementById("spinner").className = "";

                        var img0 = "img0" + count
                        var img1 = "img1" + count
                        document.getElementById("tabletr").innerHTML +=
                            //   <a href="/#/pool/${ver.nftData[i].id}"> <a href="${history.push('/#/pool/'+ver.nftData[i].id)}">

                            `
            <tr>


             <td > 
             <a href="/#/pool/${element.id}">
             <img className="rounded token1pool" id=${img0} src="https://storageapi.fleek.co/2896c665-7893-4b84-bd96-201684ea23a2-bucket/TaumTokenlogo1.png" height="50px" width="50px" alt="ice museum" />
             <img className="rounded token2pool"  id=${img1} src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" height="50px" width="50px" alt="ice museum" />
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                
             <span className=" font-medium  ">
                <span id="token0">${element.token0}</span>
                <span>/</span>
                <span id="token1">${element.token1}</span>
                </span> &nbsp;&nbsp;&nbsp;
                <span className=" font-medium " id="fee" > ${element.fee}</span> <br></br>
                <span className="font-base ">Min:</span>&nbsp;
                <span className="font-base" id="minPrice">${minPrice}</span>&nbsp;
                <span className="font-base" >${element.token0}</span>&nbsp;
                <span className="font-base ">/</span>&nbsp;
                <span className="font-base">${element.token1}</span>&nbsp;&nbsp;&nbsp;
                <i className="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;&nbsp;
                <span className="font-base ">Max:</span>&nbsp;
                <span className="font-base" id="maxPrice">${maxPrice}</span>&nbsp;
                <span className="font-base">${element.token0}</span>&nbsp;
                <span className="font-base">/</span>&nbsp;
                <span className="font-base">${element.token1}</span>&nbsp;
                </a>
            </td>


        <td>
        <br></br>
        <img  style="height="10px" width="10px"" src=${imageurl}></img> &nbsp;&nbsp;&nbsp;
        <span className="font-base" > ${range}</span>

    </td>
    </tr>


    `
                        count++;


                        // img ayarlanması

                        //token0 img
                        if (element.token0 == "TTFF") {
                            document.getElementById(img0).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                        }
                        else if (element.token0 == "TAUM") {
                            document.getElementById(img0).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                        }
                        else if (element.token0 == "OTTA") {
                            document.getElementById(img0).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                        }
                        else if (element.token0 == "WETH") {
                            document.getElementById(img0).src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                        }
                        else {
                            document.getElementById(img0).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                        }

                        // token1 img
                        if (element.token1 == "WETH") {
                            document.getElementById(img1).src = "https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"
                        }
                        else if (element.token1 == "OTTA") {
                            document.getElementById(img1).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/OTTA.png"
                        }
                        else if (element.token1 == "TAUM") {
                            document.getElementById(img1).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png"
                        }
                        else if (element.token1 == "TTFF") {
                            document.getElementById(img1).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/TTFF.png"
                        }
                        else {
                            document.getElementById(img1).src = "https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/pngegg.png"
                        }



                    });
                }
            }
        }
        else {
            if (document.getElementById("notwallet1") == null) {
                document.getElementById("spinner").className = "";
                const span = document.createElement("span")
                span.setAttribute("id", "notwallet1")
                span.innerText = "Cüzdan Bağlı Değil"

                document.getElementById("notwallet").appendChild(span)
            }

        }


        // async function Nft() {
        //     var _datanft = {
        //         "recipient": props.address
        //     }
        //     var result;
        //     return result = await new Promise((resolve, reject) => {
        //         fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/liquidity/getNftIdsFromWalletAddress", {
        //             method: "POST",
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify(_datanft)
        //         })
        //             .then(response => response.json())
        //             .then(ver => {

        //                 resolve(ver);
        //                 console.log("deneme 1213", ver)
        //             })
        //             .catch((eror) => {
        //                 console.log("Hata:", eror);
        //             })
        //     }).catch((error) => {
        //         console.log(error);
        //     })
        // }


        //     Nft().then((ver) => {

        //         console.log("NFT Count", ver.nftCount)
        //         count = ver.nftCount;
        //         document.getElementById("position").innerText = "(" + count + ")";
        //         var count = 0;

        //         // ver.nftData.forEach(ver1 => {
        //         for (var i = 0; i < ver.nftCount; i++) {
        //             console.log("count", count)
        //             count++;

        //             console.log("dsaasdas", ver.nftData[0].id)
        //             console.log("dsaasdas", ver.nftData[1].id)

        //             if (ver.nftData[i].isClosed == true || ver.nftData[i].isClosed == "undefined") {
        //                 range = "Closed"
        //                 imageurl = "https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Disc_Plain_red.svg/1200px-Disc_Plain_red.svg.png"

        //             }
        //             else {
        //                 if (ver.nftData[i].currentPrice > ver.nftData[i].minPrice && ver.nftData[i].currentPrice < ver.nftData[i].maxPrice) {
        //                     range = "in Range";
        //                     imageurl = "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/512/Trafficlight-green-icon.png"
        //                 }
        //                 else {
        //                     range = "Out Of Range"
        //                     imageurl = "https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Yellow_icon.svg/300px-Yellow_icon.svg.png"
        //                 }
        //             }
        //             document.getElementById("spinner").className = "";
        //             // const dataid = "/#/pool/" + ver.nftData[i].id
        //             // console.log("history.push(dataid)", history.push(dataid))
        //             document.getElementById("tabletr").innerHTML +=
        //                 //   <a href="/#/pool/${ver.nftData[i].id}"> <a href="${history.push('/#/pool/'+ver.nftData[i].id)}">

        //                 `
        //         <tr>


        //          <td > 
        //          <a href="/#/pool/${ver.nftData[i].id}">
        //         <img className="image-xs font-medium" style="height="50px" width="50px" " src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img> &nbsp;&nbsp;&nbsp;
        //             <span className="font-weight-bolder  font-medium  ">
        //             <span id="token0">${ver.nftData[i].token0}</span>
        //             <span>/</span>
        //             <span id="token1">${ver.nftData[i].token1}</span>
        //             </span> &nbsp;&nbsp;&nbsp;
        //             <span className="font-weight-bold font-medium " id="fee" > ${ver.nftData[i].fee}</span> <br></br>
        //             <span className="font-base font-weight-bolder">Min:</span>&nbsp;
        //             <span className="font-base" id="minPrice">${(1 / Number(ver.nftData[i].maxPrice)).toPrecision(6)}</span>&nbsp;
        //             <span className="font-base" >${ver.nftData[i].token0}</span>&nbsp;
        //             <span className="font-base ">per</span>&nbsp;
        //             <span className="font-base">${ver.nftData[i].token1}</span>&nbsp;&nbsp;&nbsp;
        //             <i className="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;&nbsp;
        //             <span className="font-base font-weight-bolder">Max:</span>&nbsp;
        //             <span className="font-base" id="maxPrice">${(1 / Number(ver.nftData[i].minPrice)).toPrecision(6)}</span>&nbsp;
        //             <span className="font-base">${ver.nftData[i].token0}</span>&nbsp;
        //             <span className="font-base">per</span>&nbsp;
        //             <span className="font-base">${ver.nftData[i].token1}</span>&nbsp;
        //             </a>
        //         </td>


        //     <td>
        //     <br></br>
        //     <img  style="height="10px" width="10px"" src=${imageurl}></img> &nbsp;&nbsp;&nbsp;
        //     <span className="font-base" > ${range}</span>

        // </td>
        // </tr>


        // `
        //         }





        //     });



    });







    // var url
    return (

        <section id="root pt-0" >


            <div className="row mb-5 justify-content-center pt-5 " >
                {/* col-12 col-md-6 col-lg-4 mb-5 mb-lg-0 */}


                <div className=" justify-content-center col-12 col-md-12 col-lg-9 mb-5 mb-lg-0 pt-0">

                    <div className="  p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div >
                                        <br />
                                        <div className="row">

                                            <div className=" col-12 ml-12 pb-2" >
                                                <h4 className="h3"> <strong>Havuzlara Genel Bakış </strong></h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row " >
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div>
                                                <div className="">
                                                    <br />

                                                    <div className="row justify-content-end">
                                                        <Button className="btn btn-pill " href="/#/addliqudity"><i className="fas fa-plus " style={divstye3} ></i> Yeni Pozisyon</Button>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* dsasa */}
                        <div className=" p-0 mb-2 pt-0 col-lg-12 div-white rounded" id="positiondiv" >

                            <div className="contanier justify-content-center col-lg-12 rounded  " >
                                <Table hover className="border-0  ">
                                    <thead>
                                        <tr >
                                            <th ><a>Pozisyonlarınız</a><a id="position">(0)</a> </th>
                                            <th>Durum</th>


                                        </tr>
                                    </thead>
                                    <tbody id="tabletr">





                                        {/*    <tr>
                                             <a href="/#/pool">
                                                <td  >

                                                    <img className="image-xs font-medium" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg"></img>&nbsp;&nbsp;&nbsp;
                                                    <span className="font-weight-bolder  font-medium  ">
                                                        <span id="token0">DAI</span>
                                                        <span>/</span>
                                                        <span id="token1">ETH</span>
                                                    </span> &nbsp;&nbsp;&nbsp;
                                                    <span className="font-weight-bold font-medium " id="fee" > 0,5%</span> <br></br><br></br>
                                                    <span className="font-base font-weight-bolder">Min:</span>&nbsp;
                                                    <span className="font-base" id="minPrice">198.21</span>&nbsp;
                                                    <span className="font-base" >DAI</span>&nbsp;
                                                    <span className="font-base ">per</span>&nbsp;
                                                    <span className="font-base">WETH</span>&nbsp;&nbsp;&nbsp;
                                                    <i className="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;&nbsp;
                                                    <span className="font-base font-weight-bolder">Max:</span>&nbsp;
                                                    <span className="font-base" id="maxPrice">799.21</span>&nbsp;
                                                    <span className="font-base">DAI</span>&nbsp;
                                                    <span className="font-base">per</span>&nbsp;
                                                    <span className="font-base">WETH</span>&nbsp;

                                                </td>
                                            </a> 
                                             <td>
                                                <br></br>
                                                <span style={divstyledot}></span>
                                                <span className="font-base" > In range</span>

                                            </td> 

                                        </tr>*/}
                                        {/* <tr>
                                            <td>
                                                <img className="image-xs font-medium" src="https://www.circle.com/hubfs/spot/icon-usdc-1.png" ></img>&nbsp;&nbsp;&nbsp;
                                                <span className="font-weight-bolder  font-medium  ">USDC/ETH</span> &nbsp;&nbsp;&nbsp;
                                                <span className="font-weight-bold font-medium " > 0,3%</span> <br></br><br></br>
                                                <span className="font-base font-weight-bolder">Min:</span>&nbsp;
                                                <a className="font-base">198.21</a>&nbsp;
                                                <a className="font-base" >USDC</a>&nbsp;
                                                <a className="font-base ">per</a>&nbsp;
                                                <a className="font-base">WETH</a>&nbsp;&nbsp;&nbsp;
                                                <i className="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;&nbsp;
                                                <a className="font-base font-weight-bolder">Max:</a>&nbsp;
                                                <a className="font-base">799.21</a>&nbsp;
                                                <a className="font-base">USDC</a>&nbsp;
                                                <a className="font-base">per</a>&nbsp;
                                                <a className="font-base">WETH</a>&nbsp;
                                            </td>
                                            <td>
                                                <br></br>
                                                <span style={divstyledotclosed}></span>
                                                <span className="font-base" > Closed</span>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img className="image-xs font-medium" src="../../assets/img/bitcoin-btc-logo.png"></img>&nbsp;&nbsp;&nbsp;
                                                <span className="font-weight-bolder  font-medium  ">BTC/ETH</span> &nbsp;&nbsp;&nbsp;
                                                <span className="font-weight-bold font-medium " > 0,3%</span> <br></br><br></br>
                                                <span className="font-base font-weight-bolder">Min:</span>&nbsp;
                                                <a className="font-base">198.21</a>&nbsp;
                                                <a className="font-base" >BTC</a>&nbsp;
                                                <a className="font-base ">per</a>&nbsp;
                                                <a className="font-base">WETH</a>&nbsp;&nbsp;&nbsp;
                                                <i className="fas fa-arrows-alt-h"></i>&nbsp;&nbsp;&nbsp;
                                                <a className="font-base font-weight-bolder">Max:</a>&nbsp;
                                                <a className="font-base">799.21</a>&nbsp;
                                                <a className="font-base">BTC</a>&nbsp;
                                                <a className="font-base">per</a>&nbsp;
                                                <a className="font-base">WETH</a>&nbsp;
                                            </td>
                                            <td >
                                                <br></br>
                                                <span style={divstyledotoutpfrange}></span>
                                                <span className="font-base" > Out of range</span>

                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                                <div className="col-lg-12 row justify-content-center">
                                    <div id="spinner" className="spinner-border "></div>
                                    <div id="notwallet" className="h5 "></div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>



            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi 

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>
        </section>

    );
}

export default Liqudity;