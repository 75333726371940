'use strict'

const API_URL = "https://eth-kovan.alchemyapi.io/v2/52gg9GR5f3GTteMsqJT87rtraet-UWUo";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(API_URL);



module.exports = {
    web3
}