import React, { useEffect, useState } from 'react'
import getSigner from '../../signer'
import Modal from 'react-modal'
import yotta21logo from "../../assets/img/yotta21-logo21.png"
import { ottaAddress, taumAddress } from '../protocolAdress'


function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

Modal.setAppElement("#index");

var taumPrice;
const display = {
    display: 'none ',
}
const divstye3 = {
    backgroundColor: "transparent"

}

function TaumEntry(props) {
    //modal open function
    const [isOpen2, setIsOpen2] = useState(false);

    function toggleModal2() {
        setIsOpen2(!isOpen2);
    }

    //transaction data model 1 
    async function sendHashoriginal(_to, _data, _gaslimit) {

        const signer = getSigner(props.provider)

        const { hash } = await signer.sendTransaction({
            to: _to,
            value: 0,
            data: _data,
            gasLimit: _gaslimit,
        })

        const { emitter } = props.notify.hash(hash)

        emitter.on('txPool', transaction => {
            return {
                // message: `Your transaction is pending, click <a href="https://rinkeby.etherscan.io/tx/${transaction.hash}" rel="noopener noreferrer" target="_blank">here</a> for more info.`,
                // or you could use onclick for when someone clicks on the notification itself
                onclick: () =>
                    window.open(`https://kovan.etherscan.io/tx/${transaction.hash}`)
            }
        })

        emitter.on('txSent', console.log)
        emitter.on('txConfirmed', () => {

            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })

        emitter.on('txSpeedUp', console.log)
        emitter.on('txCancel', console.log)
        emitter.on('txFailed', (ver) => {
            console.log(ver)
            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
    }

    //This function returns the taum token price.
    async function TaumPrice() {

        let result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/taum/getTaumPrice", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                    taumPrice = Number(ver.taumPrice)
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //function that performs swap
    async function SellTaum(_sellAmount) {
        const _datataum = {
            owner: props.address,
            taumAmount: _sellAmount,
        }
        let result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/taum/sellTaum", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datataum)
            })
                .then(response => response.json())
                .then(ver => {
                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        });
    }

    //Function that returns the amount of coins in the user's wallet
    async function balance(_tokenaddress, _tokenname) {
        var _data = {
            "owner": props.address,
            "tokenAddresses": [_tokenaddress]
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/swap/getBalance", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data)
            })
                .then(response => response.json())
                .then(ver => {
                    const balanceto = Number(ver.balance[0]).toFixed(12)
                    //const balanceto2= Number(ver.balance[0]) *1000000000000
                    resolve(balanceto);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }



    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingFRomValue() {
        var value;

        // document.getElementById("priceRangeMax1").value = null;
        // document.getElementById("priceRangeMax2").value = null;
        value = 0.0;



        const captureValue = function (e) {
            value = e.target.value * Number(taumPrice)
            document.getElementById("priceRangeMax2").value = toFixed(value)


            value = 0;

            if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value) || Number(document.getElementById("priceRangeMax1").value) == 0) {
                if (document.getElementById("btn-approve") == null) {
                    document.getElementById("togglefrom").disabled = true;
                }
                document.getElementById("togglefrom").disabled = true;
                document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value)) {

                    document.getElementById("togglefrom").disabled = true;
                    document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                }
            }
            else {
                document.getElementById("togglefrom").disabled = false;
                document.getElementById("togglefrom").innerText = "Devam"

                if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value)) {
                    document.getElementById("togglefrom").disabled = true;
                    document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                }
                else {
                    document.getElementById("togglefrom").disabled = false;
                    document.getElementById("togglefrom").innerText = "Devam"
                }
            }
        }

        document.getElementById("priceRangeMax1").addEventListener("input", captureValue);

    }

    // Calculating other from coin value corresponding to the entered Set Value
    function CalculatingToValue() {
        var value;
        // document.getElementById("priceRangeMax1").value = null;
        // document.getElementById("priceRangeMax2").value = null;
        value = 0.0;


        const captureValue = function (e) {
            value = e.target.value * (1 / taumPrice)
            document.getElementById("priceRangeMax1").value = toFixed(value)
            value = 0;

            if (Number(document.getElementById("balanceid").innerText) < Number(document.getElementById("priceRangeMax2").value) || Number(document.getElementById("priceRangeMax2").value) == 0) {
                if (document.getElementById("btn-approve") == null) {
                    document.getElementById("togglefrom").disabled = true;
                }
                document.getElementById("togglefrom").disabled = true;
                document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value)) {
                    document.getElementById("togglefrom").disabled = true;
                    document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                }
                else {
                    document.getElementById("togglefrom").disabled = false;
                    document.getElementById("togglefrom").innerText = "Devam"
                }

            }
            else {
                document.getElementById("togglefrom").disabled = false;
                document.getElementById("togglefrom").innerText = "Devam"

                if (Number(document.getElementById("frombalance").innerText) < Number(document.getElementById("priceRangeMax1").value)) {
                    document.getElementById("togglefrom").disabled = true;
                    document.getElementById("togglefrom").innerText = "Yetersiz Bakiye..."
                }
                else {
                    document.getElementById("togglefrom").disabled = false;
                    document.getElementById("togglefrom").innerText = "Devam"
                }
            }
        }
        document.getElementById("priceRangeMax2").addEventListener("input", captureValue);
    }

    //This function compares the value that the user writes in the textbox with the balance and makes a transaction.
    function walletControl() {

        document.getElementById("togglefrom").disabled = true
        if (props.address == null) {

            document.getElementById("togglefrom").innerText = "Cüzdan Bağlayınız"
            document.getElementById("frombalance").innerText = 0;
            document.getElementById("balanceid").innerText = 0;

        }
        else {
            document.getElementById("togglefrom").innerText = "Ön izleme"
            document.getElementById("togglefrom").disabled = true
            CalculatingFRomValue()
            CalculatingToValue()


        }
    }

    useEffect(() => {
        document.getElementById("togglefrom").disabled = true


        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax1").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })

        // inputlara sadece rakam yazılmasını sağlamaktadır.
        document.getElementById("priceRangeMax2").addEventListener("input", (e) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
        })
        if (document.getElementById("togglefrom").innerText == "Cüzdan Bağla") {
            document.getElementById("frombalance").innerText = 0;
            document.getElementById("balanceid").innerText = 0;
        }
        else {
            document.getElementById("balanceid").innerText = Number(props.balance / Math.pow(10, 18)).toFixed(8);
            document.getElementById("tokenid").innerText = "ETH";


            balance(taumAddress, "TAUM").then((ver) => {
                document.getElementById("frombalance").innerText = Number(ver).toFixed(8);
                document.getElementById("fromid").innerText = "LpDaiSdr21";
            })
        }
        TaumPrice();
        walletControl()
    })


    return (
        <section id="root pt-0">
            <div id="snackbartottffbuy">İşlem cüzdanınıza gönderildi..</div>
            <div className="row mb-5 justify-content-center pt-5 " >
                <div className="  justify-content-center col-12 col-md-12 col-lg-auto mb-5 mb-lg-0 pt-0">
                    <div className="  border-light p-3" id="divcard" >
                        {/* ds */}
                        <div className="   p-2 mb-2 pt-0 " >
                            <div className="row justify-content-center" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12 text-center ">
                                    <br />
                                    <h4> <strong>LpDaiSdr21</strong> </h4>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 pt-0 " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-12  btn-group ">
                                    <button className="col-12 col-lg-6 btn div-white  " id="buy" onClick={() => {
                                        var url = "/#/LpDaiSdr21Entry/"
                                        window.location.href = url
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn  "
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn  active"
                                    }}>Mint</button>
                                    <button className="col-11 col-lg-6 btn  active div-white" id="sell" onClick={() => {
                                        var url = "/#/LpDaiSdr21Ext/"
                                        window.location.href = url
                                        document.getElementById("buy").className = "col-12 col-lg-6 btn "
                                        document.getElementById("sell").className = "col-12 col-lg-6 btn active"
                                    }}>Redeem</button>
                                </div>
                                <div className="col-12 col-lg-6 pt-2 ">
                                </div>
                            </div>
                        </div>
                        {/* dsasa */}
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-2 pt-0 py-0 div-white " >
                            <div className="row" >
                                {/* From component start  */}
                                <div className="col-12 col-lg-6 ">
                                    <div className="border-light  ">
                                        <div className="row">
                                            <div className=" col-12 ml-12 pb-2" >
                                                <div className="App text-left" >
                                                    <button type="button" value={taumAddress} className="btn col-md-12 div-white" style={divstye3}  >
                                                        <img id="imageidfrom" src="https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png" height="25px" />
                                                        <a id="fromid" value={taumAddress} >LpDaiSdr21 </a>
                                                    </button>
                                                    <div className="row justify-content-start m-0 col-12 col-lg-12 text-left pt-3">
                                                        <a className="font-small text-bold text-left"> Bakiye:  </a>&nbsp;
                                                        <a id="frombalance" className="font-small text-bold">0.00000</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <div className="row justify-content-end" >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div >
                                                <div className="card-body-tabs text-right ">
                                                    <div className="row ">
                                                        <div className="text-right col-12 pb-4 " id="text-right" style={divstye3}>
                                                            <input className="form-control" id="priceRangeMax1" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* From componet end */}

                        {/* to component start */}
                        <div className="card-body-tabs shadow-soft border border-light rounded p-2 mb-3 div-white" >
                            <div className="row" >
                                <div className="col-12 col-lg-6">
                                    <div className="border-light  ">
                                        {/* Swap ÖZeti */}
                                        <div className="row ">
                                            <div className=" col-12 ml-12 pb-0 " >
                                                <div className="App" >
                                                    <button type="button" value="ETH" className="btn btn-primary col-md-12" id="btnselect" onClick={() => {
                                                    }} style={divstye3}>
                                                        <img id="imageId" src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg" width="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a id="tokenid" className="font-small" value="Jeton Seçiniz" >ETH </a>&nbsp;&nbsp;
                                                        {/* <i className="fa fa-angle-down" aria-hidden="true"></i> */}
                                                    </button>
                                                    <div className="row pt-3 m-0 text-bold">&nbsp;&nbsp;&nbsp;
                                                        <a className="font-small text-bold">Bakiye:</a>&nbsp;
                                                        <a className=" font-small text-bold" id="balanceid"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <div className="row" >
                                        <div className="col-12 col-md-6 col-lg-12">
                                            <div>
                                                <div className="card-body-tabs">
                                                    <div className="row ">
                                                        <div className="text-right col-12  pb-4" id="text-right" style={divstye3}>
                                                            <input className="form-control" id="priceRangeMax2" inputMode="decimal" autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0.0" minLength="1" maxLength="10" spellCheck="false" style={divstye3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* to component end */}
                        <div id="approvediv"></div>
                        <button type="button" id="togglefrom" className=" enable btn btn-yotta21 mb-0 mt-2 mb-2 col-lg-12 ml-0"
                            onClick={() => {
                                balance(ottaAddress, "OTTA").then((ver) => {
                                    document.getElementById("toContent").innerText = Number(document.getElementById("priceRangeMax2").value).toFixed(8)
                                    document.getElementById("ToImageContent").src = document.getElementById("imageId").src
                                    document.getElementById("to").innerText = document.getElementById("tokenid").innerText
                                    document.getElementById("fromContent").innerText = Number(document.getElementById("priceRangeMax1").value).toFixed(8)
                                    document.getElementById("fromImageContent").src = document.getElementById("imageidfrom").src
                                    document.getElementById("from").innerText = document.getElementById("fromid").innerText
                                })
                                toggleModal2()
                            }}>
                            Devam
                        </button>
                    </div>
                    {/* <!-- Modal Content --> */}
                    <Modal
                        isOpen={isOpen2}
                        onRequestClose={toggleModal2}
                        contentLabel="My dialog"
                        className="mymodal-taum" /*"modal-dialog modal-dialog-centered rounded"*/
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        aria-hidden="true"
                    >
                        <div className="" >
                            <button type="button" className=" pt-0 close ml-auto " >
                                <span aria-hidden="true" id="close" onClick={() => {
                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null
                                }}>×</span>
                            </button>
                            <div className="card-header  text-center pb-0 pt-0">
                                <h2 className="h4 font-medium text-center pt-0"><strong>İşlem Özeti</strong></h2>
                            </div>
                            <div className="card-body text-center pb-0 pt-0 font-smedium ">
                                <div className="row ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white py-2"  >
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-medium">
                                            <output className="text-left">
                                                <img id="fromImageContent" src={yotta21logo} height="30px" />
                                            </output>
                                            <output className="text-left col- font-base"><a className="font-base" id="from">-</a>
                                            </output>
                                            <output className="col-lg-9 text-right font-base" >
                                                <a id="fromContent" className="text-right">-</a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-4 ">
                                    <div className="col-12 col-sm-12 mt-12 pt-0 border border-light rounded div-white py-2" >
                                        <div className=" col-12 col-lg-12 text-left pt-0 font-base font-base">
                                            <output className="text-right">
                                                <img id="ToImageContent" src={yotta21logo} height="30px" /><br></br>
                                            </output>
                                            <output className="text-right  font-base"><a id="to" className="font-base">-</a>
                                            </output>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <output className="col-lg-9 text-right font-base" >
                                                <a className="text-right" id="toContent">-</a>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-end pt-4">
                                    <div className="col-12  col-lg-12 pt-0 border border-light rounded div-white py-2" >
                                        <div className=" col-12 col-lg-12  pt-2 font-small " >
                                            <output className=" text-left  font-small">
                                                <a className="font-base">Günlük yönetim ücreti</a>
                                            </output>
                                            <output className="col-lg-8 text-right" >
                                                <a className="text-right" id="liqudity">%0.0024
                                                </a>
                                                <output className=" text-left col-lg-2 font-small">
                                                    <a className="font-base"></a>
                                                </output>
                                            </output>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn  btn-yotta21 mb-0 mt-2 mb-2 col-12 col-lg-12 ml-0" id="swap" data-toggle="modal" data-target="#modal-form"
                                onClick={async () => {
                                    const tokenAddress = taumAddress;
                                    const tokenSymbol = 'LpDaiSdr21';
                                    const tokenDecimals = 18;
                                    const tokenImage = 'https://storageapi.fleek.co/9e3a4e47-ee08-4191-9bbf-f01f3fa55969-bucket/BrandAssets/Taum.png';
                                    if ((document.getElementById("priceRangeMax1").value).toString().length > 18) {
                                        var value = Number(document.getElementById("priceRangeMax1").value).toFixed(18)
                                    } else {
                                        value = Number(document.getElementById("priceRangeMax1").value)
                                    }
                                    if (props.provider.connection.url == "metamask") {
                                        await balance(taumAddress, "TAUM").then(async (ver) => {
                                            if (Number(ver) == 0) {
                                                const wasAdded = await window.ethereum.request({
                                                    method: 'wallet_watchAsset',
                                                    params: {
                                                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                                                        options: {
                                                            address: tokenAddress, // The address that the token is at.
                                                            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                                            decimals: tokenDecimals, // The number of decimals in the token
                                                            image: tokenImage, // A string url of the token logo
                                                        },
                                                    },
                                                });
                                            }
                                        })
                                    }
                                    SellTaum(value).then((ver => {
                                        sendHashoriginal(ver.to, ver.methodABI, ver.gasEstimate)
                                    }))
                                    toggleModal2()
                                    document.getElementById("priceRangeMax1").value = null
                                    document.getElementById("priceRangeMax2").value = null
                                    var x = document.getElementById("snackbartottffbuy");
                                    x.className = "show";
                                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);
                                }} >
                                Onayla
                            </button>
                        </div>
                    </Modal>
                </div>
                <div id="snackbartottffbuy">İşlem cüzdanınıza gönderildi..</div>
            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi  */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div>
        </section>
    );
}

export default TaumEntry