import React, { useEffect } from 'react'
import { Alert, Button, Table } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { governorBravoDelegateAddress, governorBravoDelegatorAddress } from '../protocolAdress.js';


const web3 = require('./web3Provider.js').web3;
const governorAbi = require('./governorAbi.json').abi; // delegate
const governorAddress = governorBravoDelegateAddress;
const governor = new web3.eth.Contract(governorAbi, governorAddress);

const governorxAbi = require('./governorxAbi.json').abi; //delegator
const governorxAddress = governorBravoDelegatorAddress;
const governorx = new web3.eth.Contract(governorAbi, governorxAddress);

let addressshort

const display = {
    display: 'none ',

}
const progressbar = {
    width: "60%",
    animation: "3s ease 0s 1 normal none running animate-positive",
    opacity: "1"
}
const divstye12 = {
    backgroundColor: '#f3fbfb ',
}
const divstye2 = {
    padding: '50px',

}

const divstye3 = {
    backgroundColor: "transparent"

}
const divstye4 = {
    backgroundImage: "https://storageapi.fleek.co/esongur-team-bucket/image/yotta21.png",

}
const divstye5 = {
    display: "grid",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px 5px 10px 5px",


}
const divstye7 = {
    backgroundColor: "#39babc",
}
const divstye8 = {
    backgroundColor: "#E0F7FA",
    color: "black"
}
const divstylecontent = {
    justifyContent: "flex-end",
    alignıtems: "baseline"
}

const divstyledot = {
    height: "10px",
    width: "10px",

}
var addresses

function VoteDetail(props) {
    useEffect(() => {
        addresses = window.localStorage.getItem(
            'WalletAddress'
        )
    }, addresses)
    const { proporsalIndex } = useParams();
    const { proporsalid } = useParams();
var con = addresses.slice(0, 4);
var con2 = addresses.slice(39, 42)
 addressshort = con + "......." + con2;
    async function getVotesAndCreatorAddress(_id) {
        var _datagetVotesAndCreatorAddress = {
            "id": _id
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getVotesAndCreatorAddress", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datagetVotesAndCreatorAddress)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    async function Votelist(_id) {

        var result;
        return result = await new Promise((resolve, reject) => {
            var _data = {
                "id":_id
            }
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getProposalDetails", {
                method: "Post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data),
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(async () => {
        var totalvote
        var forvotespercentage = 40
        var againstVotespercentage = 60

        //   await  getVotesAndCreatorAddress(proporsalid).then((ver)=>{
        //         console.log("dsadsasdsa",ver.proposals)
        //         document.getElementById("forVotes").innerText=(Number(ver.proposals.forVotes)/ Math.pow(10,18)).toPrecision(8)
        //         document.getElementById("againstVotes").innerText=(Number(ver.proposals.againstVotes)/Math.pow(10,18)).toPrecision(8)
        //         var con1 =(ver.proposals.proposer).toString().slice(0,4)
        //         var con2 =(ver.proposals.proposer).toString().slice(39,42)
        //         var conwalletid = con1 + "......." + con2;

        //         document.getElementById("votewalletid").innerText=conwalletid
        //         document.getElementById("voteid").innerText=ver.proposals.id

        //          totalvote= Number(ver.proposals.forVotes)/ Math.pow(10,18) + Number(ver.proposals.againstVotes)/ Math.pow(10,18)
        //          forvotespercentage= (Number(ver.proposals.forVotes)/ Math.pow(10,18) * 100)/totalvote
        //          againstVotespercentage= (Number(ver.proposals.againstVotes)/ Math.pow(10,18) * 100)/totalvote



        //     })

        Votelist(proporsalid).then((ver)=>{
            console.log("dadsasd",ver)
            document.getElementById("forVotes").innerText = ver.forVotes
            document.getElementById("againstVotes").innerText = ver.againstVotes
            document.getElementById("votetitle").innerText = ver.title

        })
        document.getElementById("progressbaryes").style.width = forvotespercentage.toString() + "%"
        document.getElementById("progressbarno").style.width = againstVotespercentage.toString() + "%"



        // const vote = await governorx.methods.getReceipt(proporsalid, addresses).call();
        // if (vote.hasVoted == true) {
        //     if (vote.support == "1") {
        //         document.getElementById("forvoteaddress").innerText = addressshort
        //         document.getElementById("forvotevalue").innerText = (Number(vote.votes) / Math.pow(10, 18)).toPrecision(8)

        //     }
        //     else {
        //         document.getElementById("foragainaddress").innerText = addressshort
        //         document.getElementById("foragainvalue").innerText = (Number(vote.votes) / Math.pow(10, 18)).toPrecision(8)
        //     }
        // }



    })



    return (
        <section>

            <div className="row justify-content-start pt-7 "  >

                <div className="col-12 col-lg-7  " >
                    <div >

                        <div className="row justify-content-center" >
                            <div className=" col-12 col-lg-7 pt-2 text-start" >

                                <Button className="btn btn-pill  btn-yotta21 div-yotta21 text-center " style={divstye7} href="/#Vote"><i className="fas fa-long-arrow-alt-left"></i></Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row justify-content-start "  >

                <div className="col-12 col-lg-7  " >
                    <div >

                        <div className="row justify-content-center" >
                            <div className=" col-12 col-lg-7 pt-2 text-start" >
                                <h2 id="votetitle">Yotta21 Deneme Oylaması
                                </h2>
                            </div>
                            <div className=" col-12 col-lg-7 pt-2 text-start" >

                                <a>
                                    <span className="font-base text-border-gecti " id="votestatus" >Aktif</span> &nbsp;

                                    <span id="voteid"></span>-
                                    <span id="voteQueued "> 15 Kasım 2021'de sıraya alındı</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 ">

                    <div className="row  py-4" >
                        <div className="col-12 col-md-12 col-lg-10">
                            <div>
                                <div className="pt-0">

                                    <div className="row justify-content-end ">

                                        <Link to="">
                                            <Button className="btn font-base "  >
                                                <a>Wallet id</a>
                                                <br></br>
                                                <a id="votewalletid">0x1754......B5258</a>
                                            </Button>
                                        </Link>
                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            {/* start Component2 to Vote Detail Card*2 */}

            <div className="row justify-content-center mb-1 pt-4 py-0 " >
                {/* Component to Card start */}
                <div className="col-12 col-lg-4 mb-4 mb-lg-0 pt-0 border border-black rounded" id="pool2" >
                    <div className="row justify-content-start"  >

                        <div className="col-12 col-lg-6 " >
                            <div >

                                <div className="row justify-content-start" >
                                    <div className=" col-12 py-2 text-start" >
                                        <h5 id="votetitle">EVET
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 ">

                            <div className="row " >
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div>
                                        <div className="pt-0">

                                            <div className="row justify-content-end py-2">
                                                <h5 id="forVotes">12.500</h5>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="progress">
                        <div className="progress-bar bg-success" id="progressbaryes" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={progressbar}>
                        </div>
                    </div>

                    <div className="contanier justify-content-end col-lg-12 rounded  " >
                        <Table hover className="border-0  ">
                            <thead>
                                <tr >
                                    <th >(0) Address </th>
                                    <th>Votes</th>
                                </tr>
                            </thead>
                            <tbody id="tabletr">
                                <tr>
                                    <td>
                                        <span className="font-base" id="forvoteaddress" > <strong>{addressshort}</strong></span>

                                    </td>
                                    <td className="row justify-content-end">
                                        <span className="font-base" id="forvotevalue" >0</span>

                                    </td>
                                </tr>


                            </tbody>
                        </Table>
                        {/* <div className="col-lg-12 row justify-content-center">
                                    <div id="spinner" className="spinner-border "></div>
                                    <div id="notwallet" className="h5 "></div>
                                </div> */}
                    </div>
                </div>
                <div className='col-12 col-lg-1'></div>
                {/* sağ taraf kart start */}
                <div className="col-12 col-lg-4 pt-0 border border-black rounded " id="pool2">
                    <div className="row justify-content-start "  >

                        <div className="col-12 col-lg-6 " >
                            <div >

                                <div className="row justify-content-start" >
                                    <div className=" col-12 py-2 text-start" >
                                        <h5 id="votetitle">HAYIR
                                        </h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 ">

                            <div className="row " >
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div>
                                        <div className="pt-0">

                                            <div className="row justify-content-end py-2">
                                                <h5 id="againstVotes">5000</h5>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="progress">
                        <div className="progress-bar bg-danger" id="progressbarno" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={progressbar}>
                        </div>
                    </div>
                    <div className="contanier justify-content-end col-lg-12 rounded  " >
                        <Table hover className="border-0  ">
                            <thead>
                                <tr >
                                    <th >(0) Address </th>
                                    <th>Votes</th>
                                </tr>
                            </thead>
                            <tbody id="tabletr">
                                <tr>
                                    <td>
                                        <span className="font-base" id="foragainaddress" > <strong>{addressshort}</strong></span>

                                    </td>
                                    <td className="row justify-content-end">
                                        <span className="font-base  " id="foragainvalue">0</span>

                                    </td>
                                </tr>


                            </tbody>
                        </Table>
                        {/* <div className="col-lg-12 row justify-content-center">
                                    <div id="spinner" className="spinner-border "></div>
                                    <div id="notwallet" className="h5 "></div>
                                </div> */}
                    </div>
                </div>
            </div>


            {/* start Component2 to Vote Detail Card*2 */}

            <div className="row justify-content-center pt-4 py-0 " >
                {/* Component2 to Card start */}
                <div className="col-12 col-lg-6  mb-lg-0 pt-0 border border-black rounded" id="pool2" >
                    <div className=' justify-content-start py-2 border-bottomblack' >
                        &nbsp;&nbsp;
                        <h5 className='text-left '><strong>Detay</strong></h5>
                    </div>
                    <div className='row justify-content-start py-2 border-bottom'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '>1. TAUM Token </h5>
                    </div>
                    <div className='row justify-content-start py-2 border-bottom'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '>2.  OTTA  Token  </h5>
                    </div>
                    <div className='row justify-content-start py-2 border-bottom'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '>3. TTFF  </h5>
                    </div>


                    <div className=' justify-content-start py-2 border-0'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '><strong>Yotta21 Protokolü DAO</strong>
                        </h5>
                    </div>
                    <div className=' justify-content-start  border-0'>
                        &nbsp;&nbsp;
                        <p className='text-left '>Yotta21
                        </p>
                    </div>

                    <div className=' justify-content-start  border-0'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '><strong>Detay</strong>
                        </h5>
                    </div>
                    <div className='text-left  border-0'>
                        &nbsp;&nbsp;
                        <h5 className='text-left '>&nbsp;&nbsp;    Merkeziyetsiz ve Otonom Token Fon Fonu
                            Yotta21'e Hoşgeldiniz
                        </h5>
                    </div>
                </div>
                &nbsp;&nbsp;
                {/* sağ taraf kart start */}
                <div className="col-12 col-lg-3 pt-1 border border-black rounded " id="pool3">
                    <div className='row justify-content-start border-bottomblack'>
                        &nbsp;&nbsp;&nbsp;
                        <h5 className='text-center py-3 '><strong>Proposal History</strong>

                        </h5>
                    </div>
                    <div className='row justify-content-start '>
                        {/* dassdas */}
                        <div className="history-panel__time-line ">
                            <div className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded">
                                <div className="history-panel__time-line__point__content pt-1">
                                    <div>
                                        <div className="history-panel__time-line__point__title ">
                                            Created
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp">
                                            November 10th, 2021 – 12:09am
                                        </div>
                                    </div>
                                    <a target="_blank" rel="external" href="https://kovan.etherscan.io/tx/0x8d987fbe4d731c087f6489d8a2b51955672dceda01c421e12205b6ca07f50448" className="external-link">
                                        <div className="line-icon line-icon--external-link line-icon--external-link--green">
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Active
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp">
                                            November 12th, 2021 – 4:26am
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="history-panel__time-line__point history-panel__time-line__point--active">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Succeeded
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp">
                                            November 15th, 2021 – 4:26am
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="history-panel__time-line__point history-panel__time-line__point--active">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Queued
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp">
                                            November 15th, 2021 – 6:09am
                                        </div>
                                    </div>
                                    <a target="_blank" rel="external" href="https://kovan.etherscan.io/tx/0x8d987fbe4d731c087f6489d8a2b51955672dceda01c421e12205b6ca07f50448" className="external-link">
                                        <div className="line-icon line-icon--external-link line-icon--external-link--green">
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* csdsdsda */}
                    </div>
                </div>
            </div>

            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi 

    */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >

                </div>
                <div ></div>
            </div>
        </section>
    );
}

export default VoteDetail