import React, { useEffect } from 'react'
import { Button, Table } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { governorBravoDelegateAddress, governorBravoDelegatorAddress } from '../protocolAdress.js';


const web3 = require('./web3Provider.js').web3;
const governorAbi = require('./governorAbi.json').abi; // delegate
const governorAddress = governorBravoDelegateAddress;
const governor = new web3.eth.Contract(governorAbi, governorAddress);

const governorxAbi = require('./governorxAbi.json').abi; //delegator
const governorxAddress = governorBravoDelegatorAddress;
const governorx = new web3.eth.Contract(governorAbi, governorxAddress);

let addressshort

const display = {
    display: 'none ',

}
const progressbar = {
    width: "60%",
    animation: "3s ease 0s 1 normal none running animate-positive",
    opacity: "1"
}

const divstye7 = {
    backgroundColor: "#39babc",
}

var addresses

function VoteDetail(props) {
    useEffect(() => {
        addresses = window.localStorage.getItem(
            'WalletAddress'
        )
    }, addresses)
    const { proporsalIndex } = useParams();
    const { proporsalid } = useParams();
  
    async function getVotesAndCreatorAddress(_id) {
        var _datagetVotesAndCreatorAddress = {
            "id": _id
        }
        var result;
        return result = await new Promise((resolve, reject) => {
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getVotesAndCreatorAddress", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_datagetVotesAndCreatorAddress)
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    async function Votelist(_id) {

        var result;
        return result = await new Promise((resolve, reject) => {
            var _data = {
                "id": _id
            }
            fetch("https://us-central1-testprojesi-6498d.cloudfunctions.net/governence/getProposalDetails", {
                method: "Post",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(_data),
            })
                .then(response => response.json())
                .then(ver => {

                    resolve(ver);
                })
                .catch((eror) => {
                    console.log("Hata:", eror);
                })
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(async () => {
        var totalvote
        var againstPercent
        var forPercent
      await Votelist(proporsalid).then((ver) => {
            document.getElementById("forVotes").innerText = Number(ver.forVotes).toFixed(5)
            document.getElementById("againstVotes").innerText = Number(ver.againstVotes).toFixed(5)
            document.getElementById("votetitle").innerText = ver.title
            document.getElementById("votewalletid").innerText = ver.proposer.slice(0, 5) + "........." + ver.proposer.slice(38, 42)
            document.getElementById("votedetail").innerText = ver.description
    
            // oylamada yüzde hesaplama

        var totalvote = Number(ver.forVotes) + Number(ver.againstVotes)
         forPercent = (100 * Number(ver.forVotes)) / totalvote
         againstPercent = (100 * Number(ver.againstVotes)) / totalvote
            var datesucceeed="_"
            var datecreated="-"
            var dateActive="-"
            var datequeued="-"
            var dateexecuted="-"
            if (ver.history.Created.Date != null) {
                datesucceeed = new Date((ver.history.Created.Date._seconds * 1000) + (ver.votingPeriod) * 4000)
                datecreated = new Date(ver.history.Created.Date._seconds * 1000)
                dateActive = new Date((ver.history.Created.Date._seconds * 1000) + (ver.votingDelay) * 4000)
            }
            if (ver.history.Queued.Date != null) {
                datequeued = new Date(ver.history.Queued.Date._seconds * 1000)
            }
            if (ver.history.Execute.Date != null) {
                dateexecuted = new Date(ver.history.Execute.Date._seconds * 1000)
            }
            else {
                dateexecuted = "-*"
            }

            document.getElementById("voteid").innerText = proporsalid

            if (ver.state == "0") {
                document.getElementById("votestatus").innerText = "Pending"
                document.getElementById("voteQueued ").innerText = "Pending Zamanı" + datecreated.toLocaleString()
            }
            else if (ver.state == "1") {
                document.getElementById("votestatus").innerText = "Active"
                document.getElementById("voteQueued ").innerText = "Aktif olma Zamanı" + dateActive.toLocaleString()
            }
            else if (ver.state == "2") {
                document.getElementById("votestatus").innerText = "Canceled"
                document.getElementById("voteQueued ").innerText = "Canceled Zamanı"


            }
            else if (ver.state == "3") {
                document.getElementById("votestatus").innerText = "Defeated"
                document.getElementById("voteQueued ").innerText = "Defeated Zamanı"
            }
            else if (ver.state == "4") {
                document.getElementById("votestatus").innerText = "Succeeded"
                document.getElementById("voteQueued ").innerText = "Succeeded Zamanı"
            }
            else if (ver.state == "5") {
                document.getElementById("votestatus").innerText = "Queued"
                document.getElementById("voteQueued ").innerText = "Queued Zamanı" + datequeued.toLocaleString()
            }
            else if (ver.state == 6) {
                document.getElementById("votestatus").innerText = "Expired"
                document.getElementById("voteQueued ").innerText = "Expired Zamanı"
            }
            else if (ver.state == "7") {
                document.getElementById("votestatus").innerText = "Executed"
                document.getElementById("voteQueued ").innerText = "Executed Zamanı" + dateexecuted.toLocaleString()
            }
            if(ver.history.Created.Hash == null || ver.history.Created.Hash =="") {
                document.getElementById("createdlink").style.display ="none"
                document.getElementById("created").className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded"
            }
            else {
                document.getElementById("createdlink").style.display =""
                document.getElementById("created").className="history-panel__time-line__point history-panel__time-line__point--active"
                document.getElementById("createdtime").innerText = datecreated.toLocaleString()
                document.getElementById("createdlink").href = "https://kovan.etherscan.io/tx/" + ver.history.Created.Hash
            }
            document.getElementById("activetime").innerText = dateActive.toLocaleString()
            
            document.getElementById("succeeededtime").innerText = datesucceeed.toLocaleString()

           if (ver.history.Queued.Hash == null || ver.history.Queued.Hash =="") {
            document.getElementById("queuedlink").style.display ="none"
            document.getElementById("queued").className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded"
           }
           else {
            document.getElementById("queuedlink").style.display =""
            document.getElementById("queued").className="history-panel__time-line__point history-panel__time-line__point--active"
            document.getElementById("queuedlink").href = "https://kovan.etherscan.io/tx/" + ver.history.Queued.Hash
            document.getElementById("queuedtime").innerText = datequeued.toLocaleString()
           }
         
           if (ver.history.Execute.Hash == null || ver.history.Execute.Hash =="") {
            document.getElementById("exequtedlink").style.display="none"
            document.getElementById("executed").className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded"

           }
           else{
            document.getElementById("exequtedlink").style.display=""
            document.getElementById("executed").className="history-panel__time-line__point history-panel__time-line__point--active"
            document.getElementById("exequtedlink").href = "https://kovan.etherscan.io/tx/" + ver.history.Execute.Hash
            document.getElementById("executedtime").innerText = dateexecuted.toLocaleString()
           }
           
        })
         
        document.getElementById("progressbaryes").style.width = (forPercent).toString() + "%"
         document.getElementById("progressbarno").style.width = againstPercent.toString() + "%"

        const vote = await governorx.methods.getReceipt(proporsalid, addresses).call();
        if (vote.hasVoted == true) {
            if (vote.support == "1") {
                document.getElementById("forvoteaddress").innerText = addresses.slice(0, 5) + "........" + addresses.slice(38, 42)
                document.getElementById("forvotevalue").innerText = (Number(vote.votes) / Math.pow(10, 18)).toPrecision(8)
            }
            else {
                document.getElementById("foragainaddress").innerText = addresses.slice(0, 5) + "........" + addresses.slice(38, 42)
                document.getElementById("foragainvalue").innerText = (Number(vote.votes) / Math.pow(10, 18)).toPrecision(8)
            }
        }
    })

    return (
        <section>
            <div className="row justify-content-start pt-7 "  >
                <div className="col-12 col-lg-7  " >
                    <div >
                        <div className="row justify-content-center" >
                            <div className=" col-12 col-lg-7 pt-2 text-start" >
                                <Button className="btn btn-pill  btn-yotta21 div-yotta21 text-center " style={divstye7} href="/#Vote"><i className="fas fa-long-arrow-alt-left"></i></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-start "  >
                <div className="col-12 col-lg-7  " >
                    <div >
                        <div className="row justify-content-center" >
                            <div className=" col-12 col-lg-7 pt-2 text-start" >
                                <h2 id="votetitle">-
                                </h2>
                            </div>
                            <div className=" col-12 col-lg-7 pt-2 text-start" >
                                <a>
                                    <span className="font-base text-border-gecti " id="votestatus" >-</span> &nbsp;
                                    <span id="voteid"></span>-
                                    <span id="voteQueued "> -</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 ">
                    <div className="row  py-4" >
                        <div className="col-12 col-md-12 col-lg-10">
                            <div>
                                <div className="pt-0">
                                    <div className="row justify-content-end ">
                                        <Link to="">
                                            <Button className="btn font-base "  >
                                                <a>Wallet id</a>
                                                <br></br>
                                                <a id="votewalletid">0x1754......B5258</a>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* start Component2 to Vote Detail Card*2 */}
            <div className="row justify-content-center mb-1 pt-4 py-0 " >
                {/* Component to Card start */}
                <div className="col-12 col-lg-4 mb-4 mb-lg-0 pt-0 border border-black rounded" id="pool2" >
                    <div className="row justify-content-start"  >
                        <div className="col-12 col-lg-6 " >
                            <div >
                                <div className="row justify-content-start" >
                                    <div className=" col-12 py-2 text-start" >
                                        <h5 id="votetitle">EVET
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ">
                            <div className="row " >
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div>
                                        <div className="pt-0">
                                            <div className="row justify-content-end py-2">
                                                <h5 id="forVotes">12.500</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="progress">
                        <div className="progress-bar bg-success" id="progressbaryes" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={progressbar}>
                        </div>
                    </div>
                    <div className="contanier justify-content-end col-lg-12 rounded  " >
                        <Table hover className="border-0  ">
                            <thead>
                                <tr >
                                    <th >(0) Address </th>
                                    <th>Votes</th>
                                </tr>
                            </thead>
                            <tbody id="tabletr">
                                <tr>
                                    <td>
                                        <span className="font-base" id="forvoteaddress" > <strong>{addressshort}</strong></span>
                                    </td>
                                    <td className="row justify-content-end">
                                        <span className="font-base" id="forvotevalue" >0</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='col-12 col-lg-1'></div>
                {/* sağ taraf kart start */}
                <div className="col-12 col-lg-4 pt-0 border border-black rounded " id="pool2">
                    <div className="row justify-content-start "  >
                        <div className="col-12 col-lg-6 " >
                            <div >
                                <div className="row justify-content-start" >
                                    <div className=" col-12 py-2 text-start" >
                                        <h5 id="votetitle">HAYIR
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ">
                            <div className="row " >
                                <div className="col-12 col-md-12 col-lg-10">
                                    <div>
                                        <div className="pt-0">
                                            <div className="row justify-content-end py-2">
                                                <h5 id="againstVotes">5000</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="progress">
                        <div className="progress-bar bg-danger" id="progressbarno" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={progressbar}>
                        </div>
                    </div>
                    <div className="contanier justify-content-end col-lg-12 rounded  " >
                        <Table hover className="border-0  ">
                            <thead>
                                <tr >
                                    <th >(0) Address </th>
                                    <th>Votes</th>
                                </tr>
                            </thead>
                            <tbody id="tabletr">
                                <tr>
                                    <td>
                                        <span className="font-base" id="foragainaddress" > <strong>{addressshort}</strong></span>
                                    </td>
                                    <td className="row justify-content-end">
                                        <span className="font-base  " id="foragainvalue">0</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            {/* start Component2 to Vote Detail Card*2 */}
            <div className="row justify-content-center pt-4 py-0 " >
                {/* Component2 to Card start */}
                <div className="col-12 col-lg-6  mb-lg-0 pt-0 border border-black rounded" id="pool2" >
                    <div className=' justify-content-start py-2 border-bottomblack' >
                        &nbsp;&nbsp;
                        <h5 className='text-left '><strong>Detay</strong></h5>
                    </div>
                    <div id="votedetail"></div>
                </div>
                &nbsp;&nbsp;
                {/* sağ taraf kart start */}
                <div className="col-12 col-lg-3 pt-1 border border-black rounded " id="pool3">
                    <div className='row justify-content-start border-bottomblack'>
                        &nbsp;&nbsp;&nbsp;
                        <h5 className='text-center py-3 '><strong>Proposal History</strong>
                        </h5>
                    </div>
                    <div className='row justify-content-start '>
                        {/* history panel */}
                        <div className="history-panel__time-line ">
                            <div id ="created" className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded">
                                <div className="history-panel__time-line__point__content pt-1">
                                    <div>
                                        <div className="history-panel__time-line__point__title ">
                                            Created
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp" id="createdtime">
                                            -
                                        </div>
                                    </div>
                                    <a target="_blank" rel="external" id="createdlink" href="https://kovan.etherscan.io/tx/" className="external-link">
                                        <div className="line-icon line-icon--external-link line-icon--external-link--green">
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div id="active" className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Active
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp" id="activetime">
                                            -
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="succeeded" className="history-panel__time-line__point history-panel__time-line__point--inactive-succeeded">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Succeeded
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp" id="succeeededtime">
                                            -
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="queued" className="history-panel__time-line__point history-panel__time-line__point--active">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Queued
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp" id="queuedtime">
                                            -
                                        </div>
                                    </div>
                                    <a target="_blank" rel="external" id="queuedlink" href="https://kovan.etherscan.io/tx/" className="external-link">
                                        <div className="line-icon line-icon--external-link line-icon--external-link--green">
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div id="executed" className="history-panel__time-line__point history-panel__time-line__point--active">
                                <div className="history-panel__time-line__point__content">
                                    <div>
                                        <div className="history-panel__time-line__point__title">
                                            Executed
                                        </div>
                                        <div className="history-panel__time-line__point__timestamp" id="executedtime">
                                            -
                                        </div>
                                    </div>
                                    <a target="_blank" rel="external" id="exequtedlink" href="https://kovan.etherscan.io/tx/" className="external-link">
                                        <div className="line-icon line-icon--external-link line-icon--external-link--green">
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* hitory panel */}
                    </div>
                </div>
            </div>
            {/* Çözüm bulana kadar display none ile her sayfada hata gizlendi.
            hata reward sayfasındaki sayaç diğer sayfalarda yükleneme hatası vermesi  */}
            <div id="countdown_container" style={display}>
                <div id="countdown_timer" >
                </div>
                <div ></div>
            </div>
        </section>
    );
}

export default VoteDetail